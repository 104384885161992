import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrderDelivery,
  makeSelectSalesOrder,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
} from 'redux/selectors';
import { getProvinceList, getDistrictList, getWardList } from 'redux/actions/locationActions';
import { VN_ID } from 'utils/constanst/locationConstants';

import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';

import classes from './SalesOrderCustomerDeliveryForm.module.scss';

class SalesOrderCustomerDeliveryForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasTaxCode: props.salesOrder?.is_taxcode,
      selectedProvinceId: props.salesOrderDelivery?.getcare_province?.id,
      selectedDistrictId: props.salesOrderDelivery?.getcare_district?.id,
      selectedWardId: props.salesOrderDelivery?.getcare_ward?.id,
    }
  }
  componentDidMount() {
    const { salesOrderDelivery } = this.props;
    this.props.getProvinceList({
      params: { name: '', getcare_country_id: VN_ID },
    });
    this.props.getDistrictList({
      params: { name: '', getcare_province_id: salesOrderDelivery?.getcare_province?.id },
    });
    this.props.getWardList({
      params: { name: '', getcare_district_id: salesOrderDelivery?.getcare_district?.id },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedProvinceId, selectedDistrictId } = this.state;
    if (selectedProvinceId !== prevState.selectedProvinceId) {
      this.props.getDistrictList({
        params: { name: '', getcare_province_id: selectedProvinceId },
      });
    }
    if (selectedDistrictId !== prevState.selectedDistrictId) {
      this.props.getWardList({
        params: { name: '', getcare_district_id: selectedDistrictId },
      });
    }
  }

  _getSelectedOption = (options, optionId) => {
    return options.find(item => item.id === optionId);
  }

  handleSubmit = (values) => {
    this.props.onSubmit(values);
  }

  render() {
    const { salesOrder, salesOrderDelivery, provinceList, districtList, wardList } = this.props;
    const initTaxCode = salesOrder?.taxcode || salesOrder?.customer_taxcode;

    return (
      <Formik
        initialValues={{
          name: salesOrderDelivery?.name,
          phone: salesOrderDelivery?.phone,
          label: salesOrderDelivery?.label,
          hasTaxCode: !!salesOrder?.is_taxcode,
          taxCode: initTaxCode,
          address: salesOrderDelivery?.address,
          countryId: VN_ID,
          provinceId: salesOrderDelivery?.getcare_province?.id,
          districtId: salesOrderDelivery?.getcare_district?.id,
          wardId: salesOrderDelivery?.getcare_ward?.id,
        }}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
          label: Yup.string().max(255, 'Tối đa 255 ký tự'),
          phone: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
          address: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
          provinceId: Yup.number().required("Vui lòng chọn"),
          districtId: Yup.number().required("Vui lòng chọn"),
          wardId: Yup.number().required("Vui lòng chọn"),
          taxCode: this.state.hasTaxCode ? Yup.string().required("Vui lòng nhập").max(20, 'Tối đa 20 ký tự') : undefined,
        })}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <form noValidate autoComplete="off" className={classes.Dialog}>
              <DialogContent className={`${classes.DialogContent} ${this.props.isOnDialog ? '' : classes.DialogContentOneCol}`}>
                <div>
                  <div className={classes.FieldControl}>
                    <label>
                      Khách hàng <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      value={values.name}
                      placeholder="Nhập..."
                      name="name"
                      error={!!errors.name}
                      helperText={errors.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Điện thoại <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.phone}
                      placeholder="Nhập..."
                      name="phone"
                      error={!!errors.phone}
                      helperText={errors.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Cơ sở nhận hàng
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.label}
                      placeholder="Nhập..."
                      name="label"
                      error={!!errors.label}
                      helperText={errors.label}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={`${classes.FieldControl} ${classes.TaxCodeWrap}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={values.hasTaxCode}
                          onChange={(e) => {
                            setFieldValue('hasTaxCode', e.target.checked);
                            if (!e.target.checked) {
                              setFieldValue('taxCode', initTaxCode);
                            }
                            this.setState({
                              hasTaxCode: e.target.checked,
                            });
                          }}
                        />
                      }
                      label="Xuất hoá đơn GTGT"
                    />
                  </div>
                  { this.state.hasTaxCode &&
                    <div className={classes.FieldControl}>
                      <label>
                        Mã số thuế
                      </label>
                      <TextField
                        autoComplete="off"
                        value={values.taxCode}
                        placeholder="Nhập..."
                        name="taxCode"
                        error={!!errors.taxCode}
                        helperText={errors.taxCode}
                        onChange={handleChange}
                      />
                    </div>
                  }
                </div>
                <div>
                  <div className={classes.FieldControl}>
                    <label>
                      Địa chỉ <span className={classes.RequiredMark}>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      value={values.address}
                      placeholder="Nhập..."
                      name="address"
                      error={!!errors.address}
                      helperText={errors.address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Tỉnh/TP <span className={classes.RequiredMark}>*</span>
                    </label>
                    <Autocomplete
                      selectOnFocus
                      name="provinceId"
                      handleHomeEndKeys={false}
                      value={this._getSelectedOption(provinceList, values.provinceId) || null}
                      options={provinceList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          placeholder="Chọn..."
                          {...params}
                          error={!!errors.provinceId}
                          helperText={errors.provinceId}
                        />
                      )}
                      onChange={(e, newValue) => {
                        this.setState({ selectedProvinceId: newValue?.id, selectedDistrictId: null, selectedWardId: null, });
                        setFieldValue('provinceId', newValue?.id || '');
                        setFieldValue('districtId', undefined);
                        setFieldValue('wardId', undefined);
                      }}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Quận/Huyện <span className={classes.RequiredMark}>*</span>
                    </label>
                    <Autocomplete
                      selectOnFocus
                      name="districtId"
                      handleHomeEndKeys={false}
                      value={this._getSelectedOption(districtList, values.districtId) || null}
                      options={districtList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          placeholder="Chọn..."
                          {...params}
                          error={!!errors.districtId}
                          helperText={errors.districtId}
                        />
                      )}
                      onChange={(e, newValue) => {
                        this.setState({ selectedDistrictId: newValue?.id });
                        setFieldValue('districtId', newValue?.id || '');
                        setFieldValue('wardId', undefined);
                      }}
                    />
                  </div>
                  <div className={classes.FieldControl}>
                    <label>
                      Phường/Xã <span className={classes.RequiredMark}>*</span>
                    </label>
                    <Autocomplete
                      selectOnFocus
                      name="wardId"
                      handleHomeEndKeys={false}
                      value={this._getSelectedOption(wardList, values.wardId) || null}
                      options={wardList || []}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(option) => option?.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => (
                        <TextField
                          autoFocus
                          placeholder="Chọn..."
                          {...params}
                          error={!!errors.wardId}
                          helperText={errors.wardId}
                        />
                      )}
                      onChange={(e, newValue) => {
                        this.setState({ selectedWardId: newValue?.id });
                        setFieldValue('wardId', newValue?.id || '');
                      }}
                    />
                  </div>
                </div>
              </DialogContent>
              { this.props.isOnDialog
                ? <DialogActions className={classes.DialogActions}>
                    <Button type="button" variant="outlined" onClick={this.props.onClose}>
                      Huỷ bỏ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Xác nhận
                    </Button>
                  </DialogActions>
                : <DialogActions className={`${classes.DialogActions} ${classes.Actions}`}>
                    <Button type="button" variant="outlined" onClick={this.props.onCancel}>
                      Huỷ
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleSubmit}
                      color="primary"
                      autoFocus
                    >
                      Lưu
                    </Button>
                  </DialogActions>
              }
            </form>
          );
        }}
      </Formik>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrderDelivery: makeSelectSalesOrderDelivery(),
  salesOrder: makeSelectSalesOrder(),
  provinceList: makeSelectProvinceList(),
  districtList: makeSelectDistrictList(),
  wardList: makeSelectWardList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProvinceList: (payload) => dispatch(getProvinceList(payload)),
    getDistrictList: (payload) => dispatch(getDistrictList(payload)),
    getWardList: (payload) => dispatch(getWardList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderCustomerDeliveryForm);
