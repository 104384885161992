import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import {
  getCustomerList,
  saveCustomerDetails,
  exportCustomerListTemplate,
} from 'redux/actions/vendor/customerActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCustomerList,
  makeSelectCustomerListLoading,
  makeSelectCustomerListTotal,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
} from 'utils/constanst/adminCustomerConstants';

import CustomerList from 'components/vendor/CustomerList/CustomerList';
import CustomerFilters from 'components/vendor/CustomerList/CustomerFilters/CustomerFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import FileImportDialog from 'components/vendor/CustomerList/FileImportDialog/FileImportDialog';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import classes from './Customers.module.scss';

class Customers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      isImportDialogOpen: false,
    }
  }

  componentDidMount() {
    const payload = {
      params: this.state.listParams,
    }
    this.props.getCustomerList(payload);
  }

  getSortsMap = (sortStr) => {
    const sortArr = sortStr.split(',');
    return sortArr.reduce((memo, item) => {
      const arr = item.split(' ');
      if (arr[0] && arr[0].trim()) {
        memo[arr[0]] = arr[1] ? arr[1] : '';
      }
      return memo;
    }, {})
  }

  getSortsString = (sortMap) => {
    return Object.keys(sortMap)
      .reduce((memo, key) => {
        memo.push(`${key} ${sortMap[key]}`);
        return memo;
      }, [])
      .join(',')
  }

  getDisplayFields = () => {
    const sortsMap = this.getSortsMap(this.state.listParams.order);
    return listDisplayFields.map((item) => ({
      ...item,
      sortDir: sortsMap[item.name],
    }))
  }

  createNewCustomer = () => {
    // Reset customer detail state
    this.props.saveCustomerDetails(null);
    this.props.history.push(`/customer/new`);
  }

  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {})
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        }
    this.setState({
      listParams: newParams,
    });
    this.props.getCustomerList({
      params: newParams,
    })
  }

  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = this.getSortsString({ [sortBy]: sortDir });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ])
  }

  handleDownloadTemplate = () => {
    this.props.exportCustomerListTemplate();
  }

  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    })
  }

  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    })
  }

  handleCompleteImport = () => {
    // Reload the page with current settings (e.g.. filters)
    this.props.getCustomerList({
      params: this.state.listParams,
    })
  }

  render() {
    const { customerList, customerListTotal, isCustomerListLoading } = this.props;
    const { listParams, isImportDialogOpen } = this.state;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Khách hàng</h1>
            <div className={classes.PageActions}>
              <Button variant="contained" onClick={this.handleImportDialogOpen}>
                Import File
              </Button>
              <Button variant="contained" onClick={this.handleDownloadTemplate}>
                Download template
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={this.createNewCustomer}>
                Thêm mới
              </Button>
            </div>
          </div>
          <div className={classes.PageMain}>
            <CustomerFilters
              onFilterChange={debounce(this.handleFilterChange, 50)}
            />
            <CustomerList
              displayFields={this.getDisplayFields()}
              customerList={customerList}
              isLoading={isCustomerListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={customerListTotal}
              listName="khách hàng"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {isImportDialogOpen && (
          <FileImportDialog
            title="Import danh sách khách hàng"
            isOpen={isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
          />
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  customerList: makeSelectCustomerList(),
  customerListTotal: makeSelectCustomerListTotal(),
  isCustomerListLoading: makeSelectCustomerListLoading(),
})

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerList: (payload) => dispatch(getCustomerList(payload)),
    saveCustomerDetails: (payload) => dispatch(saveCustomerDetails(payload)),
    exportCustomerListTemplate: (payload) => dispatch(exportCustomerListTemplate(payload)),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Customers);
