import React, { PureComponent } from 'react';

import Panel from 'components/Panel/Panel';
import Button from '@material-ui/core/Button';
import CustomerDeliveryView from './CustomerDeliveryView/CustomerDeliveryView';

import classes from './FastOrderCustomerDelivery.module.scss';

class FastOrderCustomerDelivery extends PureComponent {
  render() {
    return (
      <>
        <Panel
          title="Địa chỉ nhận hàng"
          isBorder
          titleAction={
            <Button
              size="small"
              color="primary"
              onClick={this.props.onOpenCustomerDeliveryDialog}
            >
              Thay đổi
            </Button>
          }
          panelClassName={classes.Panel}
          content={<CustomerDeliveryView />}
        />
      </>
    );
  }
}

FastOrderCustomerDelivery.propTypes = {};
FastOrderCustomerDelivery.defaultProps = {};

export default FastOrderCustomerDelivery;
