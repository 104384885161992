import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectFastOrderNotes } from 'redux/selectors';
import { orderBy } from 'lodash';

import FastOrderNoteItem from './FastOrderNoteItem/FastOrderNoteItem';
import FastOrderNoteNewForm from './FastOrderNoteNewForm/FastOrderNoteNewForm';

import classes from './FastOrderNotes.module.scss';

class FastOrderNotes extends PureComponent {
  _getOrderedFastOrderNotes = () => {
    return orderBy(this.props.prNotes, 'created_at', 'desc');
  }

  render() {
    const orderedNotes = this._getOrderedFastOrderNotes();

    return (<section className={classes.Wrap}>

      <h3 className="SectionTitle">Ghi chú</h3>

      <FastOrderNoteNewForm/>
      { orderedNotes && orderedNotes.length > 0 ? (<>
          { orderedNotes.map(note => (
            <FastOrderNoteItem key={`note-${note.idStr || note.id}`} noteItem={note}/>
          )) }
        </>) : ''
      }
    </section>)
  }
}

FastOrderNotes.propTypes = {
  prNotes: PropTypes.array,
};

FastOrderNotes.defaultProps = {
  prNotes: [],
};

const mapStateToProps = createStructuredSelector({
  prNotes: makeSelectFastOrderNotes(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(FastOrderNotes);
