import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionRules,
  makeSelectOriPromotionRules,
  makeSelectPromotionDiscounts,
  makeSelectPromotionOperators,
  makeSelectPromotionBonuses,
} from 'redux/selectors';
import {
  savePromotionRuleItem,
  removePromotionRuleItems,
  savePromotionBonusItem,
  removePromotionBonusItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  promotionRulesDisplayFields,
  promotionRulesGroupDisplayFields,
  promotionRulesLineDisplayFields,
  promotionRulesDocumentDisplayFields,
  promotionRulesParamsMap,
  OPERATOR_AND,
  ORDER_MODE_EXCLUSIVE,
  MAX_LEVEL_NUM,
  getPromotionClassName,
  isPromotionDocument,
  isPromotionGroup,
  isPromotionLine,
  newBonusItem,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionRule from './PromotionRule/PromotionRule';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionRulesGrid.module.scss';
import classes from './PromotionRules.module.scss';

class PromotionRules extends PureComponent {
  _getOperator = (id) => {
    return this.props.promotionOperators.find(item => item.id === id);
  }
  _isExceedMaximum = () => {
    return this.props.promotionRules.length === MAX_LEVEL_NUM;
  }
  handleCreateNewRow = () => {
    const { promotionRules } = this.props;
    this.props.savePromotionRuleItem({
      idStr: genID(),
      id: 0,
      level: promotionRules.length + 1,
      quantity_number: null,
      order_mode: ORDER_MODE_EXCLUSIVE,
      order_amount: null,
      bonus_number: null,
      bonus_mode: null,
      same_bonus: null,
      getcare_promotion_operator: this._getOperator(OPERATOR_AND),
      getcare_promotion_discount: null,
      discount_amount: null,
      maximum_amount: null,
      isEditing: true,
    });
  };
  handleSavePromotionRule = (params) => {
    this.props.savePromotionRuleItem({
      ...params,
    });
    this.handleSavePromotionBonusItem(params);
  };
  handleRemovePromotionRule = (params) => {
    this.props.removePromotionRuleItems([{ ...params }]);
    this.handleRemovePromotionBonusItems(params);
  };
  handleSavePromotionBonusItem = (ruleItem) => {
    const { level, bonus_number, bonus_mode } = ruleItem;
    const isBonusSameProduct = bonus_mode === 1;
    const bonusItems = this.props.promotionBonuses.filter(item => item.level === level);
    if (bonusItems.length && (!bonus_number || isBonusSameProduct)) {
      this.props.removePromotionBonusItems([...bonusItems]);
      return;
    }
    if (!bonusItems.length && (bonus_number && !isBonusSameProduct)) {
      this.props.savePromotionBonusItem({
        ...newBonusItem,
        idStr: genID(),
        level: level,
        quantity_number: bonus_number,
      });
      return;
    }
  }
  handleRemovePromotionBonusItems = (ruleItem) => {
    const { level } = ruleItem;
    const bonusItems = this.props.promotionBonuses.filter(item => item.level === level);
    if (!bonusItems.length) return;
    this.props.removePromotionBonusItems(bonusItems);
  }

  render() {
    const { promotionRules, promotionTypeId } = this.props;
    const displayFields = getDisplayFields(
      promotionRulesParamsMap,
      isPromotionGroup(promotionTypeId)
        ? promotionRulesGroupDisplayFields
        : isPromotionLine(promotionTypeId)
          ? promotionRulesLineDisplayFields
          : isPromotionDocument(promotionTypeId)
            ? promotionRulesDocumentDisplayFields
            : promotionRulesDisplayFields
    );
    const listClassName = getPromotionClassName(promotionTypeId);

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${gridClasses[listClassName]}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${gridClasses[item.className]}`}
              {...item}
            />
          ))}
          <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
            <Button
              disabled={this._isExceedMaximum()}
              color="primary"
              variant="contained"
              size="small"
              onClick={this.handleCreateNewRow}
            >
              Thêm dòng
            </Button>
          </div>
        </div>
        <div className={`${classes.Body}`}>
          {promotionRules.length ? (
            promotionRules.map((item, index) => (
              <PromotionRule
                key={`promotion-rule-${item.id || item.idStr}`}
                {...item}
                index={index}
                currentList={[...promotionRules]}
                promotionTypeId={promotionTypeId}
                savePromotionRule={this.handleSavePromotionRule}
                removePromotionRule={this.handleRemovePromotionRule}
                listClassName={listClassName}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

PromotionRules.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
  onRemovePromotionRuleItem: PropTypes.func,
  onSavePromotionRuleItem: PropTypes.func,
};

PromotionRules.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionRuleItem: (payload) =>
      dispatch(savePromotionRuleItem(payload)),
    removePromotionRuleItems: (payload) =>
      dispatch(removePromotionRuleItems(payload)),
    savePromotionBonusItem: (payload) =>
      dispatch(savePromotionBonusItem(payload)),
    removePromotionBonusItems: (payload) =>
      dispatch(removePromotionBonusItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionRules: makeSelectPromotionRules(),
  oriPromotionRules: makeSelectOriPromotionRules(),
  promotionOperators: makeSelectPromotionOperators(),
  promotionDiscounts: makeSelectPromotionDiscounts(),
  promotionBonuses: makeSelectPromotionBonuses(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionRules);
