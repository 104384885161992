import axiosCommon from 'utils/axios/axiosCommon';
import axiosAuth from 'utils/axios/axiosAuth';

const authApi = {
  checkAuth: () => {
    const vendorId = process.env.REACT_APP_GETCARE_VENDOR_ID;
    const params = vendorId ? {
      getcare_vendor_id: Number(vendorId),
    } : {};
    return axiosCommon.post(`user/authentication/vendor`, params, { withCredentials: true });
  },
  signOut: () => {
    return axiosCommon.post(`user/logout`, {}, { withCredentials: true });
  },
  updatePassword: ({ params }) => {
    return axiosAuth.post(`user/change_password`, params);
  },
  
  getAllowedActions: ({ component }) => {
    return axiosAuth.get(`user/get_allowed_actions`, {
      params: { component },
    });
  },
  // upload document
  uploadMultipleFiles: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if ( Array.isArray(newParams[key]) ) {
        for (let value of newParams[key]) {
          formData.append(key,value)
        }
      } else if (newParams[key]) formData.append(key, newParams[key]);
    });
    return axiosAuth.post(`upload/multiple`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export default authApi;
