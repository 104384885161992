import {
  SAVE_LOADING,
  SAVE_POLICY_PRICE_LIST,
  SAVE_POLICY_PRICE_ITEMS,
  SAVE_POLICY_PRICE_LIST_TOTAL,
  SAVE_PRICE_PIORITIES,
  SAVE_PRICE_UNITS,
  SAVE_ORIGINAL_POLICY_PRICE_DETAILS,
  SAVE_POLICY_PRICE_DETAILS,
  RESET_POLICY_PRICE,
  SAVE_POLICY_PRICE_PRODUCTS,
  SAVE_TEMP_POLICY_PRICE_PRODUCTS,
  SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM,
  REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS,
  SAVE_POLICY_PRICE_CUSTOMERS,
  SAVE_TEMP_POLICY_PRICE_CUSTOMERS,
  SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM,
  REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS,
  SAVE_POLICY_PRICE_CHANNELS,
  SAVE_TEMP_POLICY_PRICE_CHANNELS,
  SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM,
  REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS,
  SAVE_POLICY_PRICE_CUSTOMER_TYPES,
  SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES,
  SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS,
  SAVE_POLICY_PRICE_HISTORY,
  SAVE_POLICY_PRICE_COUNTS,
} from 'redux/constants/mdm/policyPriceConstants';

const defaultPolicyPriceDetails = {
};

const initialState = {
  pricePiorities: [],
  priceUnits: [],
  policyPriceList: [],
  policyPriceListTotal: 0,
  loading: false,

  originalPolicyPriceDetails: { ...defaultPolicyPriceDetails },
  policyPriceDetails: { ...defaultPolicyPriceDetails },

  policyPriceProducts: [],
  tempPolicyPriceProducts: [],

  policyPriceCustomers: [],
  tempPolicyPriceCustomers: [],

  policyPriceChannels: [],
  tempPolicyPriceChannels: [],

  policyPriceCustomerTypes: [],
  tempPolicyPriceCustomerTypes: [],

  policyPriceHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PRICE_PIORITIES: {
      return {
        ...state,
        pricePiorities: action.pricePiorities,
      };
    }
    case SAVE_PRICE_UNITS: {
      return {
        ...state,
        priceUnits: action.priceUnits,
      };
    }
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_POLICY_PRICE_LIST: {
      const list = action.policyPriceList;
      return {
        ...state,
        policyPriceList: list ? [...list] : [],
      };
    }
    case SAVE_POLICY_PRICE_ITEMS: {
      const items = action.items;
      const list = state.policyPriceList.map(policy => {
        const item = items.find(item => item.id === policy.id);
        return !item ? { ...policy } : { ...policy, ...item };
      });
      return {
        ...state,
        policyPriceList: list,
      };
    }
    case SAVE_POLICY_PRICE_LIST_TOTAL: {
      return {
        ...state,
        policyPriceListTotal: action.policyPriceListTotal,
      };
    }
    case RESET_POLICY_PRICE: {
      return {
        ...state,
        originalPolicyPriceDetails: { ...defaultPolicyPriceDetails },
        policyPriceDetails: { ...defaultPolicyPriceDetails },
        policyPriceProducts: [],
        tempPolicyPriceProducts: [],
        policyPriceCustomers: [],
        tempPolicyPriceCustomers: [],
        policyPriceChannels: [],
        tempPolicyPriceChannels: [],
        policyPriceCustomerTypes: [],
        tempPolicyPriceCustomerTypes: [],
        policyPriceHistory: [],
      };
    }

    case SAVE_ORIGINAL_POLICY_PRICE_DETAILS: {
      return {
        ...state,
        originalPolicyPriceDetails: action.originalPolicyPriceDetails,
      };
    }
    case SAVE_POLICY_PRICE_DETAILS: {
      return {
        ...state,
        policyPriceDetails: action.policyPriceDetails,
      };
    }
    case SAVE_POLICY_PRICE_COUNTS: {
      const { count_customer, count_product } = action.policyPriceCountsMap;
      return {
        ...state,
        policyPriceDetails: {
          ...state.policyPriceDetails,
          count_customer,
          count_product,
        },
      };
    }

    case SAVE_POLICY_PRICE_PRODUCTS: {
      const list = action.policyPriceProducts;
      return {
        ...state,
        policyPriceProducts: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_PRODUCTS: {
      const list = action.tempPolicyPriceProducts;
      return {
        ...state,
        tempPolicyPriceProducts: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM: {
      const list = [...state.tempPolicyPriceProducts];
      const comparedField = action.tempPolicyPriceProductItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.tempPolicyPriceProductItem[comparedField]);
      if (index > -1) {
        list[index] = { ...action.tempPolicyPriceProductItem };
      } else {
        list.unshift({ ...action.tempPolicyPriceProductItem });
      }
      return {
        ...state,
        tempPolicyPriceProducts: list,
      };
    }
    case REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS: {
      const list = state.tempPolicyPriceProducts.filter(
        (p) =>
          !action.tempPolicyPriceProductItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        tempPolicyPriceProducts: list,
      };
    }

    case SAVE_POLICY_PRICE_CUSTOMERS: {
      const list = action.policyPriceCustomers;
      return {
        ...state,
        policyPriceCustomers: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CUSTOMERS: {
      const list = action.tempPolicyPriceCustomers;
      return {
        ...state,
        tempPolicyPriceCustomers: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM: {
      const list = [...state.tempPolicyPriceCustomers];
      const comparedField = action.tempPolicyPriceCustomerItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.tempPolicyPriceCustomerItem[comparedField]);
      if (index > -1) {
        list[index] = { ...action.tempPolicyPriceCustomerItem };
      } else {
        list.unshift({ ...action.tempPolicyPriceCustomerItem });
      }
      return {
        ...state,
        tempPolicyPriceCustomers: list,
      };
    }
    case REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS: {
      const list = state.tempPolicyPriceCustomers.filter(
        (p) =>
          !action.tempPolicyPriceCustomerItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        tempPolicyPriceCustomers: list,
      };
    }

    case SAVE_POLICY_PRICE_CHANNELS: {
      const list = action.policyPriceChannels;
      return {
        ...state,
        policyPriceChannels: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CHANNELS: {
      const list = action.tempPolicyPriceChannels;
      return {
        ...state,
        tempPolicyPriceChannels: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM: {
      const list = [...state.tempPolicyPriceChannels];
      const comparedField = action.tempPolicyPriceChannelItem.idStr
        ? 'idStr'
        : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.tempPolicyPriceChannelItem[comparedField]);
      if (index > -1) {
        list[index] = { ...action.tempPolicyPriceChannelItem };
      } else {
        list.unshift({ ...action.tempPolicyPriceChannelItem });
      }
      return {
        ...state,
        tempPolicyPriceChannels: list,
      };
    }
    case REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS: {
      const list = state.tempPolicyPriceChannels.filter(
        (p) =>
          !action.tempPolicyPriceChannelItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        tempPolicyPriceChannels: list,
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS: {
      const counts = action.salesChannelsCounts;
      const list = state.tempPolicyPriceChannels.map((p) => {
        const salesChannelItem = counts.find(
          (item) => item.id === p.getcare_sales_channel.id
        );
        return {
          ...p,
          count: salesChannelItem ? salesChannelItem.count : p.count,
        };
      });
      return {
        ...state,
        tempPolicyPriceChannels: list,
      };
    }

    case SAVE_POLICY_PRICE_CUSTOMER_TYPES: {
      const list = action.policyPriceCustomerTypes;
      return {
        ...state,
        policyPriceCustomerTypes: list ? [...list] : [],
      };
    }
    case SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES: {
      const list = action.tempPolicyPriceCustomerTypes;
      return {
        ...state,
        tempPolicyPriceCustomerTypes: list ? [...list] : [],
      };
    }

    case SAVE_POLICY_PRICE_HISTORY: {
      const list = action.policyPriceHistory;
      return {
        ...state,
        policyPriceHistory: list ? [...list] : [],
      };
    }

    default:
      return state;
  }
};

export default reducer;
