import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import GetcareCategoryFormAutocomplete from 'components/GetcareCategory/FormAutocomplete/FormAutocomplete';

import Button from '@material-ui/core/Button';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import uomApi from 'utils/api/mdm/uomApi';
import { vatsOptions } from 'utils/constanst/common';
import { currencyFormatVN, numberFormat, validNumber,currencyFormatPercent } from 'utils/helper';
import { allInPageSize } from 'utils/constanst/common';

import gridClasses from '../VendorProductListGrid.module.scss';
import classes from './VendorProductListItem.module.scss';

const min = 0;
const max = 100;
class VendorProductListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      params: this._initParams(props),
      commission: props.commission,
      uomList: [],
      categoryInput: '',
      suggettionList: []
    }
  }

  
  componentDidMount() {
    console.log(this.props.displayFields);

    this.setState({
      selectedCategory: this.props.product_vendor_name
        ? {
            id: this.props.id,
            name: this.props.product_vendor_name,
          }
        : null,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { categoryInput } = this.state;
    if (categoryInput && categoryInput !== prevState.categoryInput) {
      this._loadSuggetionByProduct(categoryInput);
      this._loadUOMListByProduct(this.props.getcare_product?.id);
    }
  }


  _initParams = (params) => {
    return {
      product_vendor_code: params.product_vendor_code ? params.product_vendor_code : '' ,
      product_vendor_name: params.product_vendor_name,
      getcare_uom_base: { ...params.getcare_uom_base },
      price_buy_total: params.price_buy_total,
      getcare_product_id: params.getcare_product?.id !== 0 ? params.getcare_product?.id : -1,
      price_sales_total: params.price_sales_total,
      price_sales_retail_total: params.price_sales_retail_total,
      commission: params.commission,
      registration_number: params.registration_number,
      vat: params.vat,
      description: params.description,
      minimum_quantity: params.minimum_quantity,
      estimated_quantity: params.estimated_quantity,
      remaining_quantity: params.remaining_quantity,
      type_label: params.type_label,
      ka_days: params.ka_days,
      getcare_category_ecoms: params.getcare_category_ecoms,
      weight: params.weight,
      images: params.images,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._initParams(this.props),
    });
  }
  _getUomBaseSelected = (uomBaseId) => {
    return this.state.uomList.find(item => item.id === uomBaseId);
  }
  _getVATSelected = (vatId) => {
    return [...vatsOptions].find(item => item.id === vatId);
  }
  _isCodeValid = () => {
    return !!this.state.params?.product_vendor_code;
  }
  _isNameValid = () => {
    return !!this.state.categoryInput;
  }
  _isUomValid = () => {
    return !!this.state.params?.getcare_uom_base?.id;
  }
  _isPriceBuyValid = () => {
    const value = this.state.params?.price_buy_total;
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isPriceSalesValid = () => {
    return (!!this.state.params?.price_sales_total && parseFloat(this.state.params?.price_sales_total) > 0);
  }
  _isPriceSalesRetailValid = () => {
    const value = this.state.params?.price_sales_retail_total;
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isComissionValid = () => {
    const value = this.state.params?.commission;
    return ['', null, undefined].includes(value) || Number(value) > 0;
  }
  _isMinimumQuantityValid = () => {
    const value = this.state.params?.minimum_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isEstimatedQuantityValid = () => {
    const value = this.state.params?.estimated_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isRemainingQuantityValid = () => {
    const value = this.state.params?.remaining_quantity;
    return ['', null, undefined].includes(value) || Number(value) >= 0;
  }
  _isWeightValid = () => {
    const value = this.state.params?.weight;
    return ['', null, undefined].includes(value) || ( Number(value) >= 0 && Number(value) <= 9999999999 );
  }
  _isKADaysValid = () => {
    const value = this.state.params?.ka_days;
    return ['', null, undefined].includes(value) || (Number(value) >= 0 && Number(value) <= 9999);
  }
  _isRowValid = () => {
    return this._isNameValid()
    && this._isUomValid()
    // && this._isPriceBuyValid()
    && this._isPriceSalesValid()
    && this._isComissionValid()
    && this._isMinimumQuantityValid()
    && this._isRemainingQuantityValid()
    && this._isKADaysValid()
    && this._isWeightValid()
  };

  
  _loadSuggetionByProduct = async (params) => {
    const { data: response } = await uomApi.getProductSuggestions({
      params: {
        getcare_product_sugget: params
      },
    });
    this.setState({
      suggettionList: response.data || [],
    });
  }

  _isHighlighted = () => {
    // highlighted if it is mapped
    const { oriItem, getcare_product } = this.props;
    return (!!getcare_product && !!getcare_product.id)
      && (!oriItem || !oriItem.getcare_product || oriItem.getcare_product.id !== getcare_product.id);
  }

  getVisibleFieldFromName = (columnName) => {
    return this.props.displayFields.find(field => field.name === columnName);
  }

  _loadUOMListByProduct = async (value) => {
    const { data: response } = await uomApi.getAllUomBase({
      params: {
        page_size: allInPageSize,
        getcare_product_id: value !== 'undefined' ? value : this.props.getcare_product?.id
      },
    });
    this.setState({
      uomList: response.data || [],
    });
  }

  _loadUOMList = async (params) => {
    const { data: response } = await uomApi.getAllUomBase({
      params: {
        page_size: allInPageSize,
        getcare_product_id: this.state.getcare_product_id !== null ? this.state.getcare_product_id : this.props.getcare_product?.id
      },
    });
    this.setState({
      uomList: response.data || [],
    });
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  }

  handleCheckboxChange = (e) => {
    e.stopPropagation();
    this.props.onItemSelectedToggle({
      item: { ...this.props },
      isSelected: !this.props.isSelected,
    });
  };

  handleSaveFieldImages = ({ action, urls, index }) => {
    let images = Array.isArray(this.state.params?.images) ? [...this.state.params.images] : [];
    switch (action) {
      case "ADD": {
        images = images.concat(urls)
        break;
      }
      case "REMOVE": {
        images.splice(index,1);
        break;
      }
      default:
    }
    this.setState({
      params: {
        ...this.state.params,
        "images": images
      },
    });
  }

  handleSaveFieldDescription = ({ action, params, index }) => {
    this.setState({
      params: {
        ...this.state.params,
        "description": params
      },
    });
  }

  handleSaveField =  (e, value) => {
    let newValue = value;

    if (e.target.name === 'getcare_uom_base') {
      newValue = { ...this._getUomBaseSelected(value) };
    }
    if (e.target.name === 'type_label') {
      newValue = (value + ``).toUpperCase();
    }
    if (e.target.name === 'commission') {
      var value2 = parseInt(value, 10);

      newValue = value2;
      this.setState({
        commission: value2
      });
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue
      },
    });
  }
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeVendorProducts([{
        id: this.props.id,
        idStr: this.props.idStr,
      }]);
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveVendorProduct({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
  }


  render() {
    const {
      product_vendor_code,
      product_vendor_name,
      getcare_uom_base,
      price_buy_total,
      price_sales_total,
      price_sales_retail_total,
      templateColumnsMap,
      vat,
      minimum_quantity,
      estimated_quantity,
      remaining_quantity,
      type_label,
      registration_number,
      ka_days,
      isSelected,
      weight,
      getcare_category_ecoms,
      images,
      description,
    } = this.props;
    const { isEditing,suggettionList,selectedCategory,commission } = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${this._isHighlighted() ? classes.Highlighted : ''}`}
          style={{gridTemplateColumns: templateColumnsMap.template}}
          onClick={this.handleEdit}
        >
          <div className={gridClasses.Col}>
            <Checkbox
              className={classes.Checkbox}
              checked={isSelected}
              onClick={this.stopPropagation}
              onChange={this.handleCheckboxChange}
            />
          </div>


         { this.getVisibleFieldFromName('product_vendor_code') &&
            <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="product_vendor_code"
                value={product_vendor_code || ''}
                displayedValue={product_vendor_code}
                onSave={() => {return;}}
                editModeOnly
                error={!this._isCodeValid()}
                onChange={this.handleSaveField}
              /> : product_vendor_code }
            </div>
          }
           { this.getVisibleFieldFromName('product_vendor_name') &&
          <div className={gridClasses.Col}>
              { isEditing ? 
              <Autocomplete
                freeSolo
                selectOnFocus
                openOnFocus
                size="small"
                handleHomeEndKeys={false}
                value={selectedCategory|| ''}
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    categoryInput: newInputValue,
                  });
                }}
                name='product_vendor_name'
                options={suggettionList || []}
                filterOptions={(x) => x}
                getOptionLabel={(option) => option?.name || ''}
                getOptionSelected={(option, value) =>
                  value && value.id && option ? option.id === value.id : null
                }
                onChange={(e, value) => {
                        this.setState({
                          params: {
                            ...this.state.params,
                            product_vendor_name: value?.name,
                            getcare_product_id: value?.id
                          },
                          selectedCategory:value
                      });
                      this._loadUOMListByProduct(value?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!this._isNameValid()}
                    placeholder={'-- Chọn ---'}
                    onChange={(e, value) => {
                      this.setState({
                        params: {
                          ...this.state.params,
                          product_vendor_name: e.target.value,
                          getcare_product_id: -1,
                          getcare_product: {
                            id: 0,
                            name: "",
                            getcare_id: null,
                          },
                        },
                    });
                    this._loadUOMListByProduct(0);

                    }}
                 
                  />
                )}        
              />
              :  product_vendor_name}
              </div>
              }
              { this.getVisibleFieldFromName('registration_number') &&
              <div className={gridClasses.Col}>
              { isEditing ? <FieldEditable
                fieldName="registration_number"
                value={registration_number || ''}
                displayedValue={registration_number}
                onSave={() => {return;}}
                editModeOnly
                // error={!this._isCodeValid()}
                onChange={this.handleSaveField}
              /> : registration_number }
            </div>
          }
              { this.getVisibleFieldFromName('getcare_uom_base_name') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
                disableClearable
                fieldType="select"
                fieldName="getcare_uom_base"
                value={getcare_uom_base?.id || ''}
                displayedValue={getcare_uom_base?.name}
                editModeOnly
                options={this.state.uomList || []}
                error={!this._isUomValid()}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
                onOpen={this.handleOpenUOMSelect}
              />: getcare_uom_base?.name }
          </div>
            }
            { this.getVisibleFieldFromName('price_buy_total') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="price_buy_total"
              value={price_buy_total || ''}
              displayedValue={![null, undefined, ''].includes(price_buy_total) ? currencyFormatVN(price_buy_total) : ''}
              type="number"
              editModeOnly
              // error={!this._isPriceBuyValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(price_buy_total) ? currencyFormatVN(price_buy_total) : '') }
          </div>
            }
            { this.getVisibleFieldFromName('price_sales_total') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="price_sales_total"
              value={price_sales_total || ''}
              displayedValue={![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : ''}
              type="number"
              editModeOnly
              error={!this._isPriceSalesValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(price_sales_total) ? currencyFormatVN(price_sales_total) : '') }
          </div>
            }
            { this.getVisibleFieldFromName('price_sales_retail_total') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="price_sales_retail_total"
              value={validNumber(price_sales_retail_total)}
              displayedValue={![null, undefined, ''].includes(price_sales_retail_total) ? currencyFormatVN(price_sales_retail_total) : ''}
              type="number"
              editModeOnly
              error={!this._isPriceSalesRetailValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(price_sales_retail_total) ? currencyFormatVN(price_sales_retail_total) : '') }
          </div>
          }
          { this.getVisibleFieldFromName('comission') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="commission"
              value={validNumber(commission)}
              displayedValue={![null, undefined, ''].includes(commission) ? currencyFormatVN(commission) : ''}
              type="number"
              editModeOnly
              error={!this._isComissionValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(commission) ? currencyFormatVN(commission) : '') }
          </div>
          }
          { this.getVisibleFieldFromName('vat') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              editModeOnly
              fieldType="select"
              fieldName="vat"
              value={vat}
              displayedValue={`${![null, undefined, ''].includes(vat) ? vat + `%` : ``}`}
              options={[...vatsOptions]}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(vat) ? vat + `%` : ``) }
          </div>
          }
          { this.getVisibleFieldFromName('minimum_quantity') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="minimum_quantity"
              value={validNumber(minimum_quantity)}
              displayedValue={![null, undefined, ''].includes(minimum_quantity) ? numberFormat(minimum_quantity) : ''}
              type="number"
              isInteger
              editModeOnly
              error={!this._isMinimumQuantityValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(minimum_quantity) ? numberFormat(minimum_quantity) : '') }
          </div>
          }
          { this.getVisibleFieldFromName('remaining_quantity') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="remaining_quantity"
              value={validNumber(estimated_quantity)}
              displayedValue={![null, undefined, ''].includes(remaining_quantity) ? numberFormat(remaining_quantity) : ''}
              type="number"
              isInteger
              editModeOnly
              error={!this._isEstimatedQuantityValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(remaining_quantity) ? numberFormat(remaining_quantity) : '') }
          </div>
          }
          { this.getVisibleFieldFromName('type_label') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="type_label"
              value={type_label || ''}
              displayedValue={type_label}
              editModeOnly
              maxLength={1}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: type_label }
          </div>
          }
          { this.getVisibleFieldFromName('weight') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="weight"
              value={validNumber(weight)}
              displayedValue={![null, undefined, ''].includes(weight) ? numberFormat(weight) : ''}
              type="number"
              editModeOnly
              error={!this._isWeightValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(weight) ? numberFormat(weight) : '') }
          </div>
            }
            { this.getVisibleFieldFromName('ka_days') &&
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="ka_days"
              value={validNumber(ka_days)}
              displayedValue={![null, undefined, ''].includes(ka_days) ? numberFormat(ka_days) : ''}
              type="number"
              editModeOnly
              error={!this._isKADaysValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            />: (![null, undefined, ''].includes(ka_days) ? numberFormat(ka_days) : '') }
          </div>
          }
          { 
            // Getcare category
            this.getVisibleFieldFromName('getcare_category_ecoms') &&
            <div className={gridClasses.Col}>
              { isEditing ? <GetcareCategoryFormAutocomplete
                name="getcare_category_ecoms"
                multiple
                disableCloseOnSelect
                getFullData
                value={this.state.params.getcare_category_ecoms.map( item => item.id)}
                onChange={(e,value) => {
                  this.handleSaveField({
                    target: { name: 'getcare_category_ecoms' }
                  },value)
                }}
              /> : 
                <Box display="flex" flexWrap="wrap" style={{ gap: '.5rem' }}>
                  {
                    getcare_category_ecoms.map( item => (
                      <span key={item.id} className={`Badge BlueGrey Text`} style={{whiteSpace: 'nowrap'}}>
                        {item.name}
                      </span>
                    ))
                  }
                </Box>
              }
            </div>
          }
          { this.getVisibleFieldFromName('images') &&
          <div className={gridClasses.Col}>
            { isEditing ? <>
              {Array.isArray(this.state.params.images) && this.state.params.images.map( (url,index) => (
                <div key={index} className={classes.RemoveImages}>
                  <span><a href={url} target="_blank" rel="noopener noreferrer" >{url}</a><IconButton
                    style={{ padding: '0' }}
                    size="small"
                    onClick={() => this.handleSaveFieldImages({ action: 'REMOVE', index })}
                  >
                    <CancelOutlined fontSize="small" color="secondary" />
                  </IconButton></span>
                </div> )
              )} 
              <Button onClick={() => this.props.addImagesProducts(this.handleSaveFieldImages)} variant="contained" size="small"  color="primary" >Upload</Button>
            </> : <div className={classes.ViewImages}>
                    {Array.isArray(images) ? 
                      images.map( (url,index) => (
                        <span key={index}><a onClick={(e) => e.stopPropagation() } href={url} target="_blank" rel="noopener noreferrer" >{url}</a></span> 
                      )) :
                      ''
                    }
                  </div> 
            }
          </div>
          }
          { this.getVisibleFieldFromName('description') &&
            <div className={gridClasses.Col}>
                { isEditing ? <> 
                  <Button onClick={() => {
                 this.props.addDescriptionProducts(this.handleSaveFieldDescription)
                 this.props.getDescription(description)
              }
              } variant="contained" size="small"  color="secondary" >Thêm thông tin</Button>
              </> :   <div  className={gridClasses.Description}>
                  <span className={gridClasses.editDescription}>{description}</span> 
                </div>
              }
            </div>
           }
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            { isEditing ? <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                disabled={!this._isRowValid()}
                onClick={this.handleSave}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid() ? `primary` : `inherit`}
                />
              </IconButton>
            </> : <>
              <IconButton
                size="small"
                color="primary"
                onClick={this.handleEdit}
              ><EditOutlined fontSize="small"/></IconButton>
            </>}
          </div>
        </div>
      </ClickAwayListener>
    </>);
  }
}

VendorProductListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  product_vendor_code: PropTypes.string,
  product_vendor_name: PropTypes.any,
  getcare_product: PropTypes.object,
  getcare_uom_base: PropTypes.object,
  price_buy_total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isSelected: PropTypes.bool,
  saveVendorProduct: PropTypes.func,
  removeVendorProducts: PropTypes.func,
  oriItem: PropTypes.object,
};

VendorProductListItem.defaultProps = {
  product_vendor_code: '',
  product_vendor_name: '',
  getcare_product: null,
  getcare_uom_base: null,
  price_buy_total: 0,
  isSelected: false,
  oriItem: null,
}

export default VendorProductListItem;
