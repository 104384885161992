export const GET_PR_DRAFT = 'getcare/ecom/fastOrder/GET_PR_DRAFT';
export const CREATE_PR_DRAFT = 'getcare/ecom/fastOrder/CREATE_PR_DRAFT';
export const CREATE_PR = 'getcare/ecom/fastOrder/CREATE_PR';

export const CHECK_PROMOTION = 'getcare/ecom/fastOrder/CHECK_PROMOTION';

export const RATE_ORDERING = 'getcare/ecom/fastOrder/RATE_ORDERING';

// mutation
export const SAVE_SELECTED_CUSTOMER_CODE = 'getcare/ecom/fastOrder/SAVE_SELECTED_CUSTOMER_CODE';

export const SAVE_LOADING = 'getcare/ecom/fastOrder/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/ecom/fastOrder/SAVE_ACTION_LOADING';

export const SAVE_PR_DRAFT_DETAILS = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_DETAILS';

export const SAVE_PR_DRAFT_PRODUCTS = 'getcare/ecom/fastOrder/SAVE_SALES_ORDER_PRODUCTS';
export const SAVE_PR_DRAFT_PRODUCT_ITEM = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_PRODUCT_ITEM';
export const REMOVE_PR_DRAFT_PRODUCT_ITEMS = 'getcare/ecom/fastOrder/REMOVE_PR_DRAFT_PRODUCT_ITEMS';

export const SAVE_PROMOTION_AVAILABLES = 'getcare/ecom/fastOrder/SAVE_PROMOTION_AVAILABLES';
export const SAVE_PROMOTION_ACTIVES = 'getcare/ecom/fastOrder/SAVE_PROMOTION_ACTIVES';
export const SAVE_PROMOTION_INACTIVES = 'getcare/ecom/fastOrder/SAVE_PROMOTION_INACTIVES';
export const ADD_PROMOTION_INACTIVE_ITEMS = 'getcare/ecom/fastOrder/ADD_PROMOTION_INACTIVE_ITEMS';
export const REMOVE_PROMOTION_INACTIVE_ITEMS = 'getcare/ecom/fastOrder/REMOVE_PROMOTION_INACTIVE_ITEMS';
export const SAVE_USED_PROMOTIONS = 'getcare/ecom/fastOrder/SAVE_USED_PROMOTIONS';
export const SAVE_USED_PROMOTION_ITEM = 'getcare/ecom/fastOrder/SAVE_USED_PROMOTION_ITEM';
export const REMOVE_USED_PROMOTION_ITEMS = 'getcare/ecom/fastOrder/REMOVE_USED_PROMOTION_ITEMS';

export const SAVE_PR_DRAFT_BONUSES = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_BONUSES';
export const SAVE_PR_DRAFT_DISCOUNTS = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_DISCOUNTS';

export const SAVE_PR_DRAFT_NOTES = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_NOTES';
export const SAVE_PR_DRAFT_NOTE_ITEM = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_NOTE_ITEM';
export const REMOVE_PR_DRAFT_NOTE_ITEM = 'getcare/ecom/fastOrder/REMOVE_PR_DRAFT_NOTE_ITEM';

export const SAVE_PR_DRAFT_LOGISTIC = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_LOGISTIC';

export const SAVE_PR_DRAFT_SALES_USER = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_SALES_USER';

export const SAVE_PR_DRAFT_DELIVERY = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_DELIVERY';

export const SAVE_PR_DRAFT_ALL_INFO = 'getcare/ecom/fastOrder/SAVE_PR_DRAFT_ALL_INFO';
