import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionCustomers } from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionCustomersDisplayFields,
  promotionCustomersParamsMap,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionCustomer from './GetcarePromotionCustomer/GetcarePromotionCustomer';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionCustomersGrid.module.scss';
import classes from './GetcarePromotionCustomers.module.scss';

class GetcarePromotionCustomers extends PureComponent {
  render() {
    const { promotionCustomers } = this.props;
    const displayFields = getDisplayFields(
      promotionCustomersParamsMap,
      promotionCustomersDisplayFields
    );

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {promotionCustomers.length ? (
            promotionCustomers.map((item) => (
              <GetcarePromotionCustomer
                key={`promotion-customer-${item.id || item.idStr}`}
                {...item}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionCustomers.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionCustomers.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionCustomers: makeSelectPromotionCustomers(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionCustomers);
