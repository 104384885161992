import authReducer from './authReducer';
import wsReducer from './wsReducer';
import { combineReducers } from 'redux';
import locationReducer from './locationReducer';
import logisticReducer from './logisticReducer';
import vendorReducer from './vendor/vendorReducer';
import vendorPriceReducer from './vendor/vendorPriceReducer';
import vendorProductReducer from './vendor/vendorProductReducer';
import salesOrderReducer from './oms/salesOrderReducer';
import pharmacyReducer from './ecom/pharmacyReducer';
import fastOrderReducer from './ecom/fastOrderReducer';
import promotionReducer from './tmk/promotionReducer';
import customerReducer from './vendor/customerReducer';
import productBonusReducer from './vendor/productBonusReducer';
import uomReducer from './mdm/uomReducer';
import policyPriceReducer from './mdm/policyPriceReducer';
import groupReducer from './mdm/groupReducer';
import deliveryReducer from './oms/deliveryReducer';
import getcareCategoryReducer from './vendor/getcareCategoryReducer';

export default combineReducers({
  getcareCategoryReducer,
  authReducer,
  wsReducer,
  locationReducer,
  logisticReducer,
  vendorReducer,
  vendorPriceReducer,
  vendorProductReducer,
  salesOrderReducer,
  pharmacyReducer,
  fastOrderReducer,
  promotionReducer,
  customerReducer,
  productBonusReducer,
  uomReducer,
  policyPriceReducer,
  groupReducer,
  deliveryReducer,
});
