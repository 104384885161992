import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from '../DeliverySentForm.module.scss';

class GetcareDelivery extends PureComponent {
  render() {
    const { values, errors, handleChange, setFieldValue, deliveryUserOptions, validationFields } = this.props;

    return (<div className={classes.DeliveryDetailsForm}>
      <div className={`${classes.FieldGroup}`}>
        <div className={classes.FieldControl}>
          <label>
            Tiền đơn hàng <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
          </label>
          <TextField
            size="small"
            autoComplete="off"
            value={values.amount_goods}
            name="amount_goods"
            error={!!errors.amount_goods}
            helperText={errors.amount_goods}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                allowNegative: true,
              },
            }}
            onChange={(e) => {
              this.props.onAmountChange({
                ...values,
                [e.target.name]: e.target.value,
              }, setFieldValue);

              handleChange(e);
            }}
          />
        </div>
        <div className={classes.FieldControl}>
          <label>
            Người giao { validationFields.transporter_user_id && <span className={classes.RequiredMark}>*</span> }
          </label>
          <Autocomplete
            disableClearable
            handleHomeEndKeys={false}
            name="transporter_user_id"
            value={values.transporter_user?.id ? values.transporter_user : null}
            options={deliveryUserOptions}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(option) => option?.name}
            getOptionSelected={(option, value) =>
              value && value.id ? option.id === value.id : null
            }
            renderInput={(params) => (
              <TextField
                autoFocus
                placeholder="Chọn..."
                {...params}
                error={!!errors.transporter_user_id}
                helperText={errors.transporter_user_id}
              />
            )}
            onChange={(e, newValue) => {
              setFieldValue('transporter_user', newValue?.id ? newValue : null);
              handleChange({target: { name: 'transporter_user_id', value: newValue?.id || '' }});
            }}
          />
        </div>
      </div>
      <div className={`${classes.FieldGroup}`}>
        <div className={classes.FieldControl}>
          <label>
            Phí thu khách <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
          </label>
          <TextField
            autoComplete="off"
            value={values.total_fee}
            name="total_fee"
            error={!!errors.total_fee}
            helperText={errors.total_fee}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              this.props.onAmountChange({
                ...values,
                [e.target.name]: e.target.value,
              }, setFieldValue);

              handleChange(e);
            }}
          />
        </div>
        <div className={classes.FieldControl}>
          <label>
            Trọng lượng <span className={classes.TextNote}>(kg)</span> <span className={classes.RequiredMark}>*</span>
          </label>
          <TextField
            autoComplete="off"
            value={values.weight}
            name="weight"
            error={!!errors.weight}
            helperText={errors.weight}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </div>
      </div>
      <div className={`${classes.FieldGroup}`}>
        <div className={classes.FieldControl}>
          <label>
            COD <span className={classes.TextNote}>(VNĐ)</span> <span className={classes.RequiredMark}>*</span>
          </label>
          <TextField
            disabled
            size="small"
            autoComplete="off"
            value={values.cod}
            name="cod"
            error={!!errors.cod}
            helperText={errors.cod}
            InputProps={{
              inputComponent: NumberFormatCustom,
              inputProps: {
                allowNegative: true,
              },
            }}
            onChange={handleChange}
          />
        </div>
        <div className={classes.FieldControl}>
          <label>
            Kích thước <span className={classes.TextNote}>(cm)</span> <span className={classes.RequiredMark}>*</span>
          </label>
          <div>
            <div className={classes.FieldGroupSize}>
              <TextField
                autoComplete="off"
                value={values.length}
                name="length"
                error={!!errors.length}
                placeholder="Dài"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <TextField
                autoComplete="off"
                value={values.width}
                name="width"
                error={!!errors.width}
                placeholder="Rộng"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <TextField
                autoComplete="off"
                error={!!errors.height}
                value={values.height}
                name="height"
                placeholder="Cao"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            { (!!errors.length || !!errors.width || !!errors.height) && <span className={`${classes.HelperText} TextDanger`}>Vui lòng nhập kích thước, tối đa 99.</span>}
          </div>
        </div>
      </div>
      <div className={classes.FieldControl}>
        <label>
          Ghi chú giao hàng <span className={classes.RequiredMark}>*</span>
        </label>
        <TextField
          autoComplete="off"
          rowsMax={3}
          multiline
          value={values.note}
          error={!!errors.note}
          helperText={errors.note}
          placeholder="Nhập ghi chú giao hàng"
          name="note"
          onChange={handleChange}
        />
      </div>
    </div>)
  }
}

GetcareDelivery.propTypes = {
  deliveryUserOptions: PropTypes.array,
};

GetcareDelivery.defaultProps = {
  deliveryUserOptions: [],
};

export default GetcareDelivery;
