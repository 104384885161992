import { takeLatest } from 'redux-saga/effects';

import { CHECK_AUTH, SIGN_OUT } from 'redux/constants/authConstants';
import { checkAuthentication, signMeOut } from 'saga/authSaga';

// location
import {
  GET_COUNTRY_LIST,
  GET_PROVINCE_LIST,
  GET_DISTRICT_LIST,
  GET_WARD_LIST,
} from 'redux/constants/locationConstants';
import {
  loadCountryList,
  loadProvinceList,
  loadDistrictList,
  loadWardList,
} from 'saga/locationSaga';

// logistic
import {
  GET_DELIVERY_LOGISTIC_LIST,
  GET_LOGISTIC_LIST,
} from 'redux/constants/logisticConstants';
import {
  loadDeliveryLogisticList,
  loadLogisticList,
} from 'saga/logisticSaga';

// vendor
import {
  GET_VENDOR_LIST,
  GET_VENDOR_DETAILS,
  UPDATE_VENDOR,
} from 'redux/constants/vendor/vendorConstants';
import {
  loadVendorDetails,
  loadVendorList,
  putVendor,
} from 'saga/vendor/vendorSaga';

import {
  GET_GROUP_LIST,
  GET_GROUP,
  UPDATE_GROUP,
  UPDATE_GROUP_PRODUCT,
  DELETE_GROUP_PRODUCTS,
  EXPORT_GROUP_PRODUCTS,
  UPDATE_GROUP_VENDOR,
  DELETE_GROUP_VENDORS,
  EXPORT_GROUP_VENDORS,
  GET_GROUP_CUSTOMERS,
  UPDATE_GROUP_CUSTOMER,
  DELETE_GROUP_CUSTOMERS,
  EXPORT_GROUP_CUSTOMERS,
  GET_GROUP_CUSTOMER_MAPPINGS,
  UPDATE_GROUP_CUSTOMER_MAPPING,
  DELETE_GROUP_CUSTOMER_MAPPINGS,
  GET_GROUP_CUSTOMERS_BY_MAPPINGS,
  GET_CUSTOMERS_TYPES,
} from 'redux/constants/mdm/groupConstants';
import {
  loadGroupList,
  loadGroup,
  putGroup,
  putGroupProduct,
  deleteGroupProducts,
  exportGroupProducts,
  putGroupVendor,
  deleteGroupVendors,
  exportGroupVendors,
  loadGroupCustomers,
  putGroupCustomer,
  deleteGroupCustomers,
  exportGroupCustomers,
  loadGroupCustomerMappings,
  loadGroupCustomersByMappings,
  putGroupCustomerMapping,
  deleteGroupCustomerMappings,
  loadCustomerTypes,
} from 'saga/mdm/groupSaga';

// vendor price
import {
  GET_VENDOR_PRICE_LIST,
  UPDATE_VENDOR_PRICE_LIST,
} from 'redux/constants/vendor/vendorPriceConstants';
import {
  loadVendorPriceList,
  putVendorPriceList,
} from 'saga/vendor/vendorPriceSaga';

// policy price
import {
  GET_POLICY_PRICE_LIST,
  GET_POLICY_PRICE,
  GET_PRICE_PIORITIES,
  GET_PRICE_UNITS,
  UPDATE_POLICY_PRICE,
  GET_POLICY_PRICE_HISTORY,
  EXPORT_POLICY_PRICE_TEMPLATE,
  GET_POLICY_PRICE_COUNTS,
} from 'redux/constants/mdm/policyPriceConstants';
import {
  loadPolicyPriceList,
  loadPricePriorities,
  loadPriceUnits,
  loadPolicyPrice,
  postPolicyPrice,
  loadPolicyPriceHistory,
  exportPolicyPriceTemplate,
  loadPolicyPriceCounts,
} from 'saga/mdm/policyPriceSaga';

// vendor product
import {
  GET_VENDOR_PRODUCT_LIST,
  UPDATE_VENDOR_PRODUCT_LIST,
  EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE,
  EXPORT_VENDOR_PRODUCT_LIST,
} from 'redux/constants/vendor/vendorProductConstants';
import {
  loadVendorProductList,
  postVendorProductList,
  exportVendorProductListTemplate,
  exportVendorProductList,
} from 'saga/vendor/vendorProductSaga';

// sales order
import {
  GET_SALES_ORDER_LIST,
  GET_SALES_ORDER,
  GET_SALES_ORDER_STATUSES,
  UPDATE_LOGISTIC_PROVIDER,
  UPDATE_DELIVERY_INFO,
  CONFIRM_PURCHASING,
  CONFIRM_SUCCESS,
  GET_SALES_ORDER_HISTORY,
  PRINT_DELIVERY_PAPER,
  RETURN_DELIVERY,
  CONFIRM_SUCCESS_DELIVERY,
  UPDATE_ORDER_STATUS,
} from 'redux/constants/oms/salesOrderConstants';
import {
  loadSalesOrderList,
  loadSalesOrder,
  loadSalesOrderStatuses,
  updateSalesOrderLogisticProvider,
  updateSalesOrderDeliveryInfo,
  confirmSalesOrderPurchasing,
  confirmSalesOrderSuccess,
  loadSalesOrderHistory,
  printSalesOrderDeliveryPaper,
  returnSalesOrderDelivery,
  confirmSalesOrderDeliverySuccess,
  updateSalesOrderStatus,
} from 'saga/oms/salesOrderSaga';

import {
  GET_PR_DRAFT,
  CREATE_PR_DRAFT,
  CHECK_PROMOTION,
  CREATE_PR,
  RATE_ORDERING,
} from 'redux/constants/ecom/fastOrderConstants';
import {
  loadPRDraft,
  updatePRDraft,
  testPromotion,
  createPR,
  rateOrdering,
} from 'saga/ecom/fastOrderSaga';

import {
  GET_PHARMACY_LIST,
  GET_PHARMACY,
} from 'redux/constants/ecom/pharmacyConstants';
import {
  loadPharmacyList,
  loadPharmacy,
} from 'saga/ecom/pharmacySaga';

import {
  GET_PROMOTION_TYPES,
  GET_PROMOTION_OPERATORS,
  GET_PROMOTION_COMPARES,
  GET_PROMOTION_PRIORITIES,
  GET_PROMOTION_APPLIES,
  GET_PROMOTION_DISCOUNTS,
  GET_PROMOTION_LIST,
  GET_PROMOTION,
  UPDATE_PROMOTION,
  GET_PROMOTION_HISTORY,
} from 'redux/constants/tmk/promotionConstants';
import {
  loadPromotionTypes,
  loadPromotionOperators,
  loadPromotionCompares,
  loadPromotionPriorites,
  loadPromotionApplies,
  loadPromotionDiscounts,
  loadPromotionList,
  loadPromotion,
  postPromotion,
  loadPromotionHistory,
} from 'saga/tmk/promotionSaga';

import {
  GET_CUSTOMER_LIST,
  EXPORT_CUSTOMER_LIST_TEMPLATE,
  GET_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
} from 'redux/constants/vendor/customerConstants';
import {
  loadCustomerList,
  exportCustomerListTemplate,
  loadCustomerDetails,
  putCustomer,
  postCustomer,
} from 'saga/vendor/customerSaga';
import {
  GET_PRODUCT_BONUS_LIST,
  UPDATE_PRODUCT_BONUS_ITEM,
  EXPORT_PRODUCT_BONUS_LIST_TEMPLATE,
} from 'redux/constants/vendor/productBonusConstants';
import {
  loadProductBonusList,
  postProductBonus,
  exportProductBonusListTemplate,
} from 'saga/vendor/productBonusSaga';

import {
  GET_UOM_LIST,
  GET_UOM_BASE_LIST,
  GET_PRODUCT_UNIT_LIST,
} from 'redux/constants/mdm/uomConstants';
import {
  loadUomList,
  loadUomBaseList,
  loadProductUnits,
} from 'saga/mdm/uomSaga';
// delivery
import { GET_DELIVERY_USER_LIST } from 'redux/constants/oms/deliveryConstans';
import { loadDeliveryUserList } from 'saga/oms/deliverySaga';
/**
 * Root saga manages watcher lifecycle
 */
export default function* defaultSaga() {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // auth
  yield takeLatest(CHECK_AUTH, checkAuthentication);
  yield takeLatest(SIGN_OUT, signMeOut);

  // location
  yield takeLatest(GET_COUNTRY_LIST, loadCountryList);
  yield takeLatest(GET_PROVINCE_LIST, loadProvinceList);
  yield takeLatest(GET_DISTRICT_LIST, loadDistrictList);
  yield takeLatest(GET_WARD_LIST, loadWardList);

  // vendor
  yield takeLatest(GET_VENDOR_DETAILS, loadVendorDetails);
  yield takeLatest(GET_VENDOR_LIST, loadVendorList);
  yield takeLatest(UPDATE_VENDOR, putVendor);

  // vendor price
  yield takeLatest(GET_VENDOR_PRICE_LIST, loadVendorPriceList);
  yield takeLatest(UPDATE_VENDOR_PRICE_LIST, putVendorPriceList);

  // policy price
  yield takeLatest(GET_PRICE_PIORITIES, loadPricePriorities);
  yield takeLatest(GET_PRICE_UNITS, loadPriceUnits);
  yield takeLatest(GET_POLICY_PRICE_LIST, loadPolicyPriceList);
  yield takeLatest(GET_POLICY_PRICE, loadPolicyPrice);
  yield takeLatest(UPDATE_POLICY_PRICE, postPolicyPrice);
  yield takeLatest(GET_POLICY_PRICE_HISTORY, loadPolicyPriceHistory);
  yield takeLatest(EXPORT_POLICY_PRICE_TEMPLATE, exportPolicyPriceTemplate);
  yield takeLatest(GET_POLICY_PRICE_COUNTS, loadPolicyPriceCounts);

  // vendor product
  yield takeLatest(GET_VENDOR_PRODUCT_LIST, loadVendorProductList);
  yield takeLatest(UPDATE_VENDOR_PRODUCT_LIST, postVendorProductList);
  yield takeLatest(EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE, exportVendorProductListTemplate);
  yield takeLatest(EXPORT_VENDOR_PRODUCT_LIST, exportVendorProductList);

  // logistic
  yield takeLatest(GET_DELIVERY_LOGISTIC_LIST, loadDeliveryLogisticList);
  yield takeLatest(GET_LOGISTIC_LIST, loadLogisticList);

  // sales order
  yield takeLatest(GET_SALES_ORDER_STATUSES, loadSalesOrderStatuses);
  yield takeLatest(GET_SALES_ORDER_LIST, loadSalesOrderList);
  yield takeLatest(GET_SALES_ORDER, loadSalesOrder);
  yield takeLatest(UPDATE_LOGISTIC_PROVIDER, updateSalesOrderLogisticProvider);
  yield takeLatest(UPDATE_DELIVERY_INFO, updateSalesOrderDeliveryInfo);
  yield takeLatest(CONFIRM_PURCHASING, confirmSalesOrderPurchasing);
  yield takeLatest(CONFIRM_SUCCESS, confirmSalesOrderSuccess);
  yield takeLatest(GET_SALES_ORDER_HISTORY, loadSalesOrderHistory);
  yield takeLatest(PRINT_DELIVERY_PAPER, printSalesOrderDeliveryPaper);
  yield takeLatest(CONFIRM_SUCCESS_DELIVERY, confirmSalesOrderDeliverySuccess);
  yield takeLatest(RETURN_DELIVERY, returnSalesOrderDelivery);
  yield takeLatest(UPDATE_ORDER_STATUS, updateSalesOrderStatus);

  // group
  yield takeLatest(GET_GROUP_LIST, loadGroupList);
  yield takeLatest(GET_GROUP, loadGroup);
  yield takeLatest(UPDATE_GROUP, putGroup);
  yield takeLatest(UPDATE_GROUP_PRODUCT, putGroupProduct);
  yield takeLatest(DELETE_GROUP_PRODUCTS, deleteGroupProducts);
  yield takeLatest(EXPORT_GROUP_PRODUCTS, exportGroupProducts);
  yield takeLatest(UPDATE_GROUP_VENDOR, putGroupVendor);
  yield takeLatest(DELETE_GROUP_VENDORS, deleteGroupVendors);
  yield takeLatest(EXPORT_GROUP_VENDORS, exportGroupVendors);
  yield takeLatest(GET_GROUP_CUSTOMERS, loadGroupCustomers);
  yield takeLatest(UPDATE_GROUP_CUSTOMER, putGroupCustomer);
  yield takeLatest(DELETE_GROUP_CUSTOMERS, deleteGroupCustomers);
  yield takeLatest(EXPORT_GROUP_CUSTOMERS, exportGroupCustomers);
  yield takeLatest(GET_GROUP_CUSTOMER_MAPPINGS, loadGroupCustomerMappings);
  yield takeLatest(
    GET_GROUP_CUSTOMERS_BY_MAPPINGS,
    loadGroupCustomersByMappings
  );
  yield takeLatest(UPDATE_GROUP_CUSTOMER_MAPPING, putGroupCustomerMapping);
  yield takeLatest(DELETE_GROUP_CUSTOMER_MAPPINGS, deleteGroupCustomerMappings);
  yield takeLatest(GET_CUSTOMERS_TYPES, loadCustomerTypes);

  // fastOrder
  yield takeLatest(GET_PR_DRAFT, loadPRDraft);
  yield takeLatest(CREATE_PR_DRAFT, updatePRDraft);
  yield takeLatest(CHECK_PROMOTION, testPromotion);
  yield takeLatest(CREATE_PR, createPR);
  yield takeLatest(RATE_ORDERING, rateOrdering);

  // pharmacy
  yield takeLatest(GET_PHARMACY_LIST, loadPharmacyList);
  yield takeLatest(GET_PHARMACY, loadPharmacy);

  // promotion
  yield takeLatest(GET_PROMOTION_TYPES, loadPromotionTypes);
  yield takeLatest(GET_PROMOTION_OPERATORS, loadPromotionOperators);
  yield takeLatest(GET_PROMOTION_COMPARES, loadPromotionCompares);
  yield takeLatest(GET_PROMOTION_PRIORITIES, loadPromotionPriorites);
  yield takeLatest(GET_PROMOTION_APPLIES, loadPromotionApplies);
  yield takeLatest(GET_PROMOTION_DISCOUNTS, loadPromotionDiscounts);
  yield takeLatest(GET_PROMOTION_LIST, loadPromotionList);
  yield takeLatest(GET_PROMOTION, loadPromotion);
  yield takeLatest(UPDATE_PROMOTION, postPromotion);
  yield takeLatest(GET_PROMOTION_HISTORY, loadPromotionHistory);

  // customer
  yield takeLatest(GET_CUSTOMER_LIST, loadCustomerList);
  yield takeLatest(EXPORT_CUSTOMER_LIST_TEMPLATE, exportCustomerListTemplate);
  yield takeLatest(GET_CUSTOMER_DETAILS, loadCustomerDetails);
  yield takeLatest(UPDATE_CUSTOMER, putCustomer);
  yield takeLatest(CREATE_CUSTOMER, postCustomer);
  // product bonus
  yield takeLatest(GET_PRODUCT_BONUS_LIST, loadProductBonusList);
  yield takeLatest(UPDATE_PRODUCT_BONUS_ITEM, postProductBonus);
  yield takeLatest(EXPORT_PRODUCT_BONUS_LIST_TEMPLATE, exportProductBonusListTemplate);

  // uom
  yield takeLatest(GET_UOM_LIST, loadUomList);
  yield takeLatest(GET_UOM_BASE_LIST, loadUomBaseList);
  yield takeLatest(GET_PRODUCT_UNIT_LIST, loadProductUnits);

  // delivery
  yield takeLatest(GET_DELIVERY_USER_LIST, loadDeliveryUserList);
}
