import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionMaxProducts } from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionMaxProductsDisplayFields,
  promotionMaxProductsParamsMap,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionMaxProduct from './GetcarePromotionMaxProduct/GetcarePromotionMaxProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionMaxProductsGrid.module.scss';
import classes from './GetcarePromotionMaxProducts.module.scss';

class GetcarePromotionMaxProducts extends PureComponent {
  render() {
    const { promotionMaxProducts } = this.props;
    const displayFields = getDisplayFields(
      promotionMaxProductsParamsMap,
      promotionMaxProductsDisplayFields
    );

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${gridClasses[item.className]}`}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {promotionMaxProducts.length ? (
            promotionMaxProducts.map((item) => (
              <GetcarePromotionMaxProduct
                key={`promotion-max-product-${item.id || item.idStr}`}
                {...item}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionMaxProducts.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionMaxProducts.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionMaxProducts: makeSelectPromotionMaxProducts(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionMaxProducts);
