import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classes from './GetcarePromotionMaxProduct.module.scss';
import gridClasses from '../GetcarePromotionMaxProductsGrid.module.scss';

class GetcarePromotionMaxProduct extends PureComponent {
  render() {
    const {
      getcare_product,
      quantity_number,
      disabled,
    } = this.props;

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row} ${disabled ? classes.Disabled : ''}`}>
          <div className={`${gridClasses.Col}`}>
            { getcare_product?.product_vendor_code }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getcare_product?.product_vendor_name }
          </div>
          <div className={`${gridClasses.Col}`}>
            { quantity_number }
          </div>

        </div>
      </>
    );
  }
}

GetcarePromotionMaxProduct.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionMaxProduct.defaultProps = {
};

export default GetcarePromotionMaxProduct;

