import axiosAuth from 'utils/axios/axiosAuth';

const ADMIN_PRODUCT_API_URL = 'getcare_product/';
const productApi = {
  get: (id, params) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`${ADMIN_PRODUCT_API_URL}${id}`, {
      params: queryParams,
    });
  },
  getAll: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(ADMIN_PRODUCT_API_URL, {
      params: queryParams,
      cancelToken,
    });
  },
  deactiveProducts: (ids) => {
    return {
      data: {
        result: true,
      }
    }
  },

  getMedicinesByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(ADMIN_PRODUCT_API_URL, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
      },
    });
  },

  getOtherProductsByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_other/`, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
        approval: 1,
      },
    });
  },

  getAwaitingProductsByPharmacyId: ({ params, pharmacyId }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_other/`, {
      params: {
        ...queryParams,
        getcare_pharmacy_id: pharmacyId,
        approval: -1,
      },
    });
  },
  updateAwaitingProduct: ({ id, params }) => {
    return axiosAuth.put(`getcare_product_other/${id}`, params);
  },
  approveProducts: ({ params }) => {
    return axiosAuth.post(`getcare_product_other_approval/`, {
      ...params,
      approval: 1,
    });
  },
  deleteProducts: ({ params }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_product_other_delete/`,
      data: {...params},
    });
  },

  getProductBrands: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_brand/`, {
      params: queryParams,
    });
  },

  getProductIndustries: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_product_industry/`, {
      params: queryParams,
    });
  },

  pushGetcareCategoryForProducts: ({params}) => {
    return axiosAuth.post(`getcare_category_ecom_tag_multiple_product`, params);
  },

  updateProductsGetcareCategory: ({params}) => {
    return axiosAuth.post(`getcare_product_tag_multiple_category_ecom`, params);
  },
};

export default productApi;
