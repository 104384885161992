import {
  GET_PHARMACY_LIST,
  GET_PHARMACY,
  SAVE_PHARMACY_LIST,
  SAVE_LOADING,
  SAVE_PHARMACY_LIST_TOTAL,
  SAVE_PHARMACY,
} from 'redux/constants/ecom/pharmacyConstants';

export function getPharmacyList(payload) {
  return {
    type: GET_PHARMACY_LIST,
    payload,
  };
}
export function getPharmacy(payload) {
  return {
    type: GET_PHARMACY,
    payload,
  };
}

// mutation
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function savePharmacyListTotal(pharmacyListTotal) {
  return {
    type: SAVE_PHARMACY_LIST_TOTAL,
    pharmacyListTotal,
  };
}
export function savePharmacyList(pharmacyList) {
  return {
    type: SAVE_PHARMACY_LIST,
    pharmacyList,
  };
}
export function savePharmacy(pharmacy) {
  return {
    type: SAVE_PHARMACY,
    pharmacy,
  };
}
