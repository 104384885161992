import {
    SAVE_STATE_PRODUCT_CATEGORY_LIST,
    SAVE_LOADING_ACTION,
    SAVE_PENDING_ACTION,
    SAVE_PRODUCT_CATEGORY_ALLOWED_ACTIONS,
} from 'redux/constants/vendor/getcareCategoryConstants';
import { 
  mapGetcareCategoryTrees,
} from 'utils/constanst/adminGetcareCategoryConstants';

const initialState = {
    getcareCategoryList: [],
    treeCategories: [],
    levelCategories: [],
    getcareCategoryAllowedActions: [],
    isLoading: false,
    isPending: false,
};
  
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case SAVE_STATE_PRODUCT_CATEGORY_LIST: {
        return {
          ...state,
          getcareCategoryList: mapGetcareCategoryTrees(action.getcareCategoryList),
        };
      }
      case SAVE_LOADING_ACTION: {
        return {
          ...state,
          isLoading: action.isLoading,
        };
      }
      case SAVE_PENDING_ACTION: {
        return {
          ...state
        };
      }
      case SAVE_PRODUCT_CATEGORY_ALLOWED_ACTIONS: {
        return {
          ...state,
          getcareCategoryAllowedActions: action.getcareCategoryAllowedActions,
        };
      }
      default:
        return state;
    }
}

export default reducer;
  