import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { validDate } from 'utils/helper';
import { dateFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { promotionStatusMap, applyStatusMap } from 'utils/constanst/tmkPromotionConstants';
import { Link } from 'react-router-dom';

import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';

import gridClasses from '../PromotionListGrid.module.scss';
import classes from './PromotionListItem.module.scss';

class PromotionListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/promotion/${this.props.id}`);
  };

  render() {
    const {
      id,
      code,
      name,
      description,
      getcare_promotion_type,
      start_date,
      end_date,
      is_empty,
      active,
      apply,
    } = this.props;

    return <Link
      className={`${classes.Item} ${gridClasses.Row}`}
      to={`/promotion/${id}`}
    >
      <div className={`${gridClasses.Col} ${classes.CodeCol}`}>
        { code }
        { is_empty && (
          <Tooltip title={`Chương trình rỗng`} arrow placement="bottom">
            <span className={classes.DangerIcon}><ErrorOutline fontSize="small"/></span>
          </Tooltip>
        )}
      </div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ getcare_promotion_type?.name }</div>
      <div className={gridClasses.Col}>{ description }</div>
      <div className={gridClasses.Col}>{ validDate(start_date) ? format(validDate(start_date), dateFormat) : '' }</div>
      <div className={gridClasses.Col}>{ validDate(end_date) ? format(validDate(end_date), dateFormat) : '' }</div>
      <div className={gridClasses.Col}>{ applyStatusMap[apply] }</div>
      <div className={gridClasses.Col}>{ promotionStatusMap[active] }</div>
    </Link>;
  }
}

PromotionListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  name: PropTypes.string,
  getcare_campaign_id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  getcare_promotion_type: PropTypes.object,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.number,
  apply: PropTypes.number,
};

PromotionListItem.defaultProps = {
};

export default withRouter(PromotionListItem);
