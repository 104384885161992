import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FieldEditable from 'components/FieldEditable/FieldEditable';
import EditOutlined from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import gridClasses from '../ProductBonusListGrid.module.scss';
import classes from './ProductBonusListItem.module.scss';

class ProductBonusListItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: !props.id,
      params: this._getInitParams(props),
    }
  }
  _getInitParams = (params) => {
    return {
      idStr: params.idStr,
      id: params.id,
      code: params.code,
      name: params.name,
      model: params.model,
      getcare_product_unit: params.getcare_product_unit,
      description: params.description,
      active: params.active,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
    });
  }
  _getUnitSelected = (unitId) => {
    return this.props.productUnits.find(item => item.id === unitId);
  }
  _isUnitValid = () => {
    return !!this.state.params?.getcare_product_unit?.id;
  }
  _isNameValid = () => {
    return !!this.state.params?.name;
  }
  _isRowValid = () => {
    return this._isUnitValid() && this._isNameValid();
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    let newValue = value;
    if (e.target.name === 'getcare_product_unit') {
      newValue = { ...this._getUnitSelected(value) };
    }
    if (e.target.name === 'getcare_vendor') {
      newValue = { id: value?.id, name: value?.name };
    }
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: newValue,
      },
    });
  }
  handleEdit = (e) => {
    e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    e.stopPropagation();
    this._resetState();
    if (!this.props.id) {
      this.props.removeProductBonuses([{
        id: this.props.id,
        idStr: this.props.idStr,
      }]);
    }
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.props.saveProductBonus({
      ...this.state.params,
      idStr: this.props.idStr,
      id: this.props.id,
    });
    this.setState({
      isEditing: false,
    });
  }
  handleRowBlur = () => {
  }
  handleInputVendorChange = (e, newValue) => {
    this.setState({
      vendorInput: newValue,
    });
  }

  render() {
    const {
      code,
      name,
      model,
      getcare_product_unit,
      description,
      productUnits,
    } = this.props;
    const { isEditing } = this.state;

    return (<>
      <ClickAwayListener onClickAway={this.handleRowBlur}>
        <div
          className={`${classes.Item} ${gridClasses.Row}`}
          onClick={this.handleEdit}
        >
          <div className={gridClasses.Col}>{ code }</div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="name"
              value={name || ''}
              displayedValue={name}
              editModeOnly
              error={!this._isNameValid()}
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : name }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="model"
              value={model || ''}
              displayedValue={model}
              editModeOnly
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : model }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
                fieldType="select"
                fieldName="getcare_product_unit"
                value={getcare_product_unit?.id || ''}
                displayedValue={getcare_product_unit?.name}
                editModeOnly
                error={!this._isUnitValid()}
                options={productUnits || []}
                onSave={() => {return;}}
                onChange={this.handleSaveField}
              /> : getcare_product_unit?.name }
          </div>
          <div className={gridClasses.Col}>
            { isEditing ? <FieldEditable
              fieldName="description"
              value={description || ''}
              displayedValue={description}
              editModeOnly
              onSave={() => {return;}}
              onChange={this.handleSaveField}
            /> : description }
          </div>
          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            { isEditing ? <>
              <IconButton
                onClick={this.handleCancel}
                style={{ padding: '0' }}
                size="small"
              >
                <CancelOutlined fontSize="small" color="secondary" />
              </IconButton>
              <IconButton
                disabled={!this._isRowValid()}
                onClick={this.handleSave}
                style={{ padding: '0' }}
                size="small"
              >
                <CheckCircleOutlineOutlined
                  fontSize="small"
                  color={this._isRowValid() ? `primary` : `inherit`}
                />
              </IconButton>
            </> : <IconButton
                size="small"
                color="primary"
                onClick={this.handleEdit}
              ><EditOutlined fontSize="small"/></IconButton>
            }
          </div>
        </div>
      </ClickAwayListener>
    </>);
  }
}

ProductBonusListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  name: PropTypes.string,
  model: PropTypes.string,
  getcare_product_unit: PropTypes.object,
  getcare_vendor: PropTypes.object,
  description: PropTypes.string,
  productUnits: PropTypes.array,
};

ProductBonusListItem.defaultProps = {
  code: '',
  name: '',
  model: '',
  getcare_product_unit: null,
  getcare_vendor: null,
  description: '',
  productUnits: [],
}

export default ProductBonusListItem;
