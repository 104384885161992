import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import classes from './ChanhXeDialog.module.scss';

class ChanhXeDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: props.note,
    };
  }
  componentDidUpdate(prevProps) {
    const { note } = this.props;
    if (note !== prevProps.note) {
      this.setState({
        note,
      });
    }
  }
  handleNoteChange = (e) => {
    this.setState({
      note: e.target.value.slice(0, 255),
    });
  }
  handleSubmit = () => {
    this.props.onSave(this.state.note);
    this.props.onClose();
  }

  render() {
    const { isOpen } = this.props;
    return (<Dialog
      open={isOpen}
      onClose={this.props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.Dialog}
    >
      <div className={`${classes.DialogContentWrap}`}>
        <DialogTitle className={classes.DialogTitle}>Gửi xe khách hoặc nhà xe</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <p>Chi phí vận chuyển sẽ được chỉ định sau</p>
          <TextField
            variant="outlined"
            autoComplete="off"
            rows={3}
            rowsMax={3}
            multiline
            value={this.state.note}
            placeholder="Nhập tuyến xe hoặc nhà xe bạn mong muốn gửi hàng"
            autoFocus
            name="note"
            className={classes.NoteField}
            onChange={this.handleNoteChange}
          />
        </DialogContent>
        <DialogActions className={classes.DialogFooter}>
          <Button variant="outlined" onClick={this.props.onClose}>
            Huỷ
          </Button>
          <Button
            disabled={!this.state.note.trim()}
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )}
}

ChanhXeDialog.propTypes = {
  isOpen: PropTypes.bool,
  note: PropTypes.string,
  feeText: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

ChanhXeDialog.defaultProps = {
  isOpen: false,
  title: '',
  note: '',
  feeText: '',
};

export default ChanhXeDialog;
