export const GET_VENDOR_PRODUCT_LIST = 'getcare/vendor/vendorProduct/GET_VENDOR_PRODUCT_LIST';
export const UPDATE_VENDOR_PRODUCT_LIST = 'getcare/vendor/vendorProduct/UPDATE_VENDOR_PRODUCT_LIST';
export const EXPORT_VENDOR_PRODUCT_LIST = 'getcare/vendor/vendorProduct/EXPORT_VENDOR_PRODUCT_LIST';
export const EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE = 'getcare/vendor/vendorProduct/EXPORT_VENDOR_PRODUCT_LIST_TEMPLATE';

// mutation
export const SAVE_VENDOR_PRODUCT_LIST_TOTAL = 'getcare/vendor/vendorProduct/SAVE_VENDOR_PRODUCT_LIST_TOTAL';
export const SAVE_LOADING = 'getcare/vendor/vendorProduct/SAVE_LOADING';
export const SAVE_ACTION_LOADING = 'getcare/vendor/vendorProduct/SAVE_ACTION_LOADING';


export const SAVE_VENDOR_PRODUCT_LIST = 'getcare/vendor/vendorProduct/SAVE_VENDOR_PRODUCT_LIST';
export const REMOVE_VENDOR_PRODUCT_ITEMS = 'getcare/vendor/vendorProduct/REMOVE_VENDOR_PRODUCT_ITEMS';
export const APPROVAL_VENDOR_PRODUCT_ITEMS = 'getcare/vendor/vendorProduct/APPROVAL_VENDOR_PRODUCT_ITEMS';

export const SAVE_VENDOR_PRODUCT_ITEM = 'getcare/vendor/vendorProduct/SAVE_VENDOR_PRODUCT_ITEM';

export const SAVE_ORI_VENDOR_PRODUCT_LIST = 'getcare/vendor/vendorProduct/SAVE_ORI_VENDOR_PRODUCT_LIST';
