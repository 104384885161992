import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { savePRDraftNoteItem, removePRDraftNoteItem } from 'redux/actions/ecom/fastOrderActions';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import { validDate } from 'utils/helper';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './FastOrderNoteItem.module.scss';

class FastOrderNoteItem extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isConfirmDialogOpen: false,
    }
    this.formRef = React.createRef();
  }

  handleEdit = () => {
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = () => {
    this.setState({
      isEditing: false,
    });
  }
  handleSaveEdit = () => {
    this.formRef.current.submitForm();
  }
  handleSubmit = (values) => {
    this.props.savePRDraftNoteItem({
      ...this.props.noteItem,
      created_at: (new Date()).toISOString(),
      note: values.note,
    });
    this.setState({
      isEditing: false,
    });
  };
  handleRemoveNoteItem = (noteItem) => {
    this.handleConfirmDialogOpen();
  }
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  }
  handleSubmitRemoveNoteItem = () => {
    this.props.removePRDraftNoteItem(this.props.noteItem);
  }

  render() {
    const { isEditing } = this.state;
    const { noteItem } = this.props;

    return (<>
        <div className={classes.NoteItem}>
          <div className={classes.NoteInfo}>
            <div>
              <span className={`${classes.NoteOwner}`}>{noteItem.user_name}</span>
              <span className={classes.NoteDate}>{validDate(noteItem.created_at) ? format(validDate(noteItem.created_at), dateTimeFormat) : ''}</span>
            </div>
            <div className={classes.NoteActions}>
              { !isEditing
                ? <><IconButton
                      onClick={() => this.handleRemoveNoteItem(noteItem)}
                      size="small"
                    >
                      <DeleteOutline fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={this.handleEdit}
                      size="small"
                    >
                      <Edit fontSize="small" />
                    </IconButton>
                  </>
                : <><Button size="small" type="button" onClick={this.handleCancel}>
                    Huỷ
                  </Button>
                  <Button
                    type="button"
                    size="small"
                    onClick={this.handleSaveEdit}
                  >
                    Lưu
                  </Button>
                </>
              }
            </div>
          </div>
          { !isEditing
            ? <p className={`${classes.NoteContent} PreWrap`}>{noteItem.note}</p>
            : <Formik
                innerRef={this.formRef}
                initialValues={{
                  note: noteItem.note,
                }}
                onSubmit={(values) => {
                  this.handleSubmit(values, );
                }}
                validateOnChange={false}
                validateOnBlur={true}
                validationSchema={Yup.object().shape({
                  note: Yup.string().required("Vui lòng nhập ghi chú").test('len', 'Tối đa 255 ký tự', val => val?.length <= 255),
                })}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    handleChange,
                  } = props;

                  return (
                    <form noValidate autoComplete="off">
                      <div className={`${classes.FieldControlWrap}`}>
                        <TextField
                          variant="outlined"
                          className={classes.Field}
                          autoComplete="off"
                          rowsMax={3}
                          multiline
                          autoFocus
                          value={values.note}
                          placeholder="Nhập ghi chú"
                          name="note"
                          size="small"
                          error={!!errors.note}
                          helperText={errors.note}
                          onChange={handleChange}
                        />
                      </div>
                    </form>
                  );
                }}
              </Formik>
            }
          </div>

          { this.state.isConfirmDialogOpen
            && <ConfirmationDialog
              isOpen={this.state.isConfirmDialogOpen}
              title="Xác nhận xoá"
              isLoading={this.state.isDialogLoading}
              message="Bạn có chắc xoá ghi chú này?"
              onClose={this.handleConfirmDialogClose}
              onSubmit={this.handleSubmitRemoveNoteItem}
            />
          }
        </>);
  }
}

FastOrderNoteItem.propTypes = {
  noteItem: PropTypes.object,
};

FastOrderNoteItem.defaultProps = {
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePRDraftNoteItem: (payload) => dispatch(savePRDraftNoteItem(payload)),
    removePRDraftNoteItem: (payload) => dispatch(removePRDraftNoteItem(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(FastOrderNoteItem);
