import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateCustomer, createCustomer } from 'redux/actions/vendor/customerActions';
import format from 'date-fns/format';
import { validDate } from 'utils/helper';
import { approvalStatusMap, DEACTIVE } from 'utils/constanst/adminCustomerConstants';
import { dateFormat } from 'utils/constanst/dateConstants';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { VN_ID } from 'utils/constanst/locationConstants';
import customerApi from 'utils/api/mdm/customerApi';
import locationApi from 'utils/api/locationApi';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';

import classes from './CustomerInfo.module.scss';

class CustomerInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      customerTypes: [],
      wards: [],
      provinces: [],
      districts: [],
    }
  }

  handleSaveField = (fieldMap) => {
    this.props.id && this.props.updateCustomer({
      id: this.props.id,
      params: {
        ...fieldMap,
      },
    });
  }

  async componentDidMount() {
    const { setRef } = this.props;
    setRef(this);

    if (!this.state.editMode) {
      const { data: response } = await customerApi.getCustomerTypes();
      if (!response.result || !response.data) return;
      const customerTypes = [...response.data];
      this.setState({
        customerTypes,
      })

      // In edit mode, reload is executed after base info is ready
      this._reload();
    }
  }

  componentWillUnmount() {
    const { setRef } = this.props;
    setRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.setState({
        ...this.props,
      }, () => {
        // Reset to initial state when new item is loaded (via prev/next)
        this.props.onChange && this.props.onChange(false);
        this._reload();
      })
    }
  }

  _reload = async () => {
    await this.loadProvinces();
    await this.loadDistricts();
    await this.loadWards();
    this.formRef && this.formRef.resetForm({
      values: this._getInitialValues()
    })
  }

  // return item having given template or null if not found
  findItem = (list, template) => {
    const items = list.filter(item => item?.id === template?.id);
    return items.length ? items[0] : null;
  }

  loadProvinces = async () => {
    // Load provinces once
    if (this.state.provinces.length === 0) {
      let countryId = this.state.getcare_country?.id;
      if (!countryId) {
        countryId = VN_ID;
      }

      const { data: response } = await locationApi.getProvinces({
        name: '',
        getcare_country_id: countryId
      })

      if (response?.result) {
        this.setState({
          provinces: [...response.data],
        })
      }
    }
  }

  loadDistricts = async () => {
    // Districts loaded only if given province is provided
    // and currently-selected district is invalid
    const { districts, getcare_district, getcare_province } = this.state;
    let currentDistrict = this.findItem(districts, getcare_district);
    if (getcare_province && !currentDistrict) {
      const { data: response } = await locationApi.getDistricts({
        name: '',
        getcare_province_id: getcare_province?.id
      });
      if (response?.result) {
        // Verify currently-selected district
        const newDistrictList = [...response.data];
        currentDistrict = this.findItem(newDistrictList, getcare_district);
        this.setState({
          districts: newDistrictList,
          getcare_district: currentDistrict
        })
      }
    }
  }

  loadWards = async () => {
    let newState;
    let getcare_ward;
    // Wards loaded only a district given
    if (this.state.getcare_district) {
      const { data: response } = await locationApi.getWards({
        name: '',
        getcare_district_id: this.state.getcare_district.id
      })
      if (response?.result) {
        const wards = [...response.data];
        newState = { wards };
        getcare_ward = this.findItem(wards, this.state.getcare_ward);
      }
    }
    newState = {
      ...newState,
      getcare_ward,
    }

    this.setState(newState);
  }

  _getInitialValues = () => {
    const {
      name,
      note,
      phone,
      email,
      address,
      tax_code,
      latitude,
      longitude,
      getcare_ward,
      type_pharmacy,
      expertise_number,
      expertise_person,
      getcare_district,
      getcare_province,
      getcare_customer_type,
      registration_number,
      representative_name,
      registration_company,
      customer_vendor_code,
    } = this.state;

    return {
      name: name || '',
      note: note || '',
      email: email || '',
      phone: phone || '',
      address: address || '',
      tax_code: tax_code || '',
      latitude: latitude || '',
      longitude: longitude || '',
      customer_vendor_code: customer_vendor_code || '',
      getcare_ward: getcare_ward ? getcare_ward : null,
      type_pharmacy: type_pharmacy || '',
      expertise_number: expertise_number || '',
      expertise_person: expertise_person || '',
      getcare_province: getcare_province ? getcare_province : null,
      getcare_district: getcare_district ? getcare_district : null,
      getcare_customer_type: getcare_customer_type ? getcare_customer_type : null,
      representative_name: representative_name || '',
      registration_number: registration_number || '',
      registration_company: registration_company || '',
    }
  }

  _getValidationShape = () => {
    return {
      customer_vendor_code: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      name: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      phone: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
      email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email không đúng định dạng').max(55, 'Tối đa 55 ký tự'),
      note: Yup.string().max(512, 'Tối đa 512 ký tự'),
      registration_number: Yup.string().max(255, 'Tối đa 255 ký tự'),
      type_pharmacy: Yup.string().max(255, 'Tối đa 255 ký tự'),
      address: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      representative_name: Yup.string().max(255, 'Tối đa 255 ký tự'),
      registration_company: Yup.string().max(255, 'Tối đa 255 ký tự'),
      expertise_person: Yup.string().max(255, 'Tối đa 255 ký tự'),
      expertise_number: Yup.string().max(255, 'Tối đa 255 ký tự'),
      tax_code: Yup.string().max(255, 'Tối đa 255 ký tự'),
      getcare_customer_type: Yup.object().nullable().required("Vui lòng chọn"),
      getcare_province: Yup.object().nullable().required("Vui lòng chọn"),
      getcare_district: Yup.object().nullable().required("Vui lòng chọn"),
      getcare_ward: Yup.object().nullable().required("Vui lòng chọn"),
      longitude: Yup.string().matches(/^[-]?\d+\.?\d+$/i, 'Longitude không đúng định dạng').max(30, 'Tối đa 30 ký tự'),
      latitude: Yup.string().matches(/^[-]?\d+\.?\d+$/, 'Latitude không đúng định dạng').max(30, 'Tối đa 30 ký tự'),
    }
  }

  handleChange = (field, value, needReload) => {
    const { onChange } = this.props;
    let newState = {
      ...this.state,
      [field]: value,
    }

    if (field === 'getcare_province') {
      newState = {
        ...newState,
        getcare_district: null,
        getcare_ward: null,
      }
    } else if (field === 'getcare_district') {
      newState = {
        ...newState,
        getcare_ward: null,
      }
    }

    this.setState(newState, () => {
      onChange && onChange(true);
      needReload && this._reload();
    })
  }

  revertChanges = () => {
    // Revert back all changes from props (to state)
    this.setState({
      ...this.props,
    }, () => {
      this.formRef.resetForm();
      this._reload();
    });
  }

  saveChanges = () => {
    // Submit the form for validation
    if (this.formRef.isValid) {
      this.formRef.submitForm();
    } else {
      toast.warning('Thông tin nhập sai quy định, vui lòng kiểm tra lại.');
    }
  }

  handleSubmit = (values) => {
    // Collect changes and submit them to the backend
    let changes = {};
    Object.keys(values).forEach(field => {
      const currentValue = values[field];
      const originalValue = this.props[field] || '';

      let submitField;
      let submitValue;
      let isChanged = false;
      switch (field) {
        case 'getcare_province':
          submitField = 'getcare_province_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_district':
          submitField = 'getcare_district_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_ward':
          submitField = 'getcare_ward_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        case 'getcare_customer_type':
          submitField = 'getcare_customer_type_id';
          submitValue = currentValue?.id;
          isChanged = currentValue?.id !== originalValue?.id;
          break;
        default:
          submitField = field;
          submitValue = currentValue;
          isChanged = currentValue !== originalValue;
      }

      if (isChanged) {
        changes = {
          ...changes,
          [submitField]: submitValue,
        }
      }
    })

    if (Object.keys(changes).length) {
      if (this.props.id) {
        this.props.updateCustomer({
          id: this.props.id,
          params: changes,
        })
      } else {
        this.props.createCustomer({
          params: changes,
        })
      }
    }
  }

  render() {
    const {
      wards,
      active,
      approval,
      editMode,
      districts,
      provinces,
      created_at,
      updated_at,
      deactive_date,
      customerTypes,
      getcare_customer_type,
      customer_vendor_code,
    } = this.state;

    return (
      <>
        <Formik
          innerRef={(ref) => this.formRef = ref}
          initialValues={this._getInitialValues()}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape(this._getValidationShape())}>
          {({ values, errors, handleChange, setFieldValue }) => {
            const currentProvince = this.findItem(provinces, values.getcare_province);
            const currentDistrict = this.findItem(districts, values.getcare_district);
            const currentWard = this.findItem(wards, values.getcare_ward);
            return (
              <form noValidate autoComplete="off">
                <div className={`${classes.Details} ${classes.IsEditing}`}>
                  <div className={classes.DetailsCol}>
                    <div className={classes.FieldControl}>
                      <label>Mã khách hàng*</label>
                      {editMode && (customer_vendor_code)}
                      {!editMode && (
                        <TextField
                          autoComplete="off"
                          value={values.customer_vendor_code}
                          placeholder="Nhập..."
                          autoFocus={true}
                          name="customer_vendor_code"
                          error={!!errors.customer_vendor_code}
                          helperText={errors.customer_vendor_code}
                          onChange={(e) => {
                            handleChange(e);
                            this.handleChange('customer_vendor_code', e.target.value);
                          }}
                        />
                      )}
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Tên khách hàng*</label>
                      <TextField
                        multiline
                        rowsMax={2}
                        autoComplete="off"
                        value={values.name}
                        placeholder="Nhập..."
                        autoFocus={true}
                        name="name"
                        error={!!errors.name}
                        helperText={errors.name}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('name', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Loại khách hàng*</label>
                      {editMode && (getcare_customer_type?.name)}
                      {!editMode && (
                        <Autocomplete
                          disableClearable
                          name="customer_type"
                          value={values.getcare_customer_type}
                          options={customerTypes || []}
                          getOptionLabel={(option) => option?.name || ''}
                          renderOption={(option) => option?.name}
                          getOptionSelected={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField
                              placeholder="Chọn..."
                              {...params}
                              error={!!errors.getcare_customer_type}
                              helperText={errors.getcare_customer_type}
                            />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue('getcare_customer_type', newValue);
                            this.handleChange('getcare_customer_type', newValue);
                          }}
                        />
                      )}
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Mã số thuế</label>
                      <TextField
                        autoComplete="off"
                        value={values.tax_code}
                        placeholder="Nhập..."
                        name="tax_code"
                        error={!!errors.tax_code}
                        helperText={errors.tax_code}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('tax_code', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Điện thoại*</label>
                      <TextField
                        autoComplete="off"
                        value={values.phone}
                        placeholder="Nhập..."
                        name="phone"
                        error={!!errors.phone}
                        helperText={errors.phone}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('phone', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Địa chỉ*</label>
                      <TextField
                        multiline
                        rowsMax={3}
                        autoComplete="off"
                        value={values.address}
                        placeholder="Nhập..."
                        name="address"
                        error={!!errors.address}
                        helperText={errors.address}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('address', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Tỉnh/TP*</label>
                      <Autocomplete
                        disableClearable
                        name="province"
                        value={currentProvince}
                        options={provinces || []}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.getcare_province}
                            helperText={errors.getcare_province}
                          />
                        )}
                        onChange={(e, newValue) => {
                          const needReload = newValue?.id !== this.state.getcare_province?.id;
                          this.handleChange('getcare_province', newValue, needReload);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Quận/Huyện*</label>
                      <Autocomplete
                        disableClearable
                        name="district"
                        value={currentDistrict}
                        options={districts || []}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.getcare_district}
                            helperText={errors.getcare_district}
                          />
                        )}
                        onChange={(e, newValue) => {
                          const needReload = newValue?.id !== this.state.getcare_district?.id;
                          this.handleChange('getcare_district', newValue, needReload);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Phường/Xã*</label>
                      <Autocomplete
                        disableClearable
                        name="ward"
                        value={currentWard}
                        options={wards || []}
                        getOptionLabel={(option) => option?.name || ''}
                        renderOption={(option) => option?.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                          <TextField
                            placeholder="Chọn..."
                            {...params}
                            error={!!errors.getcare_ward}
                            helperText={errors.getcare_ward}
                          />
                        )}
                        onChange={(e, newValue) => {
                          this.handleChange('getcare_ward', newValue, true);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Email</label>
                      <TextField
                        autoComplete="off"
                        value={values.email}
                        placeholder="Nhập..."
                        name="email"
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('email', e.target.value);
                        }}
                      />
                    </div>
                    {editMode && (
                      <div className={classes.FieldControl}>
                        <label>Ngày tạo</label>
                        { validDate(created_at) ? format(validDate(created_at), dateFormat) : ''}
                      </div>

                    )}
                    {editMode && (
                      <div className={classes.FieldControl}>
                        <label>Ngày sửa</label>
                        {validDate(updated_at) ? format(validDate(updated_at), dateFormat) : ''}
                      </div>
                    )}
                  </div>
                  <div className={classes.DetailsCol}>
                    <div className={classes.FieldControl}>
                      <label>Tên doanh nghiệp</label>
                      <TextField
                        autoComplete="off"
                        value={values.registration_company}
                        placeholder="Nhập..."
                        name="registration_company"
                        error={!!errors.registration_company}
                        helperText={errors.registration_company}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('registration_company', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Tên người đại diện</label>
                      <TextField
                        autoComplete="off"
                        value={values.representative_name}
                        placeholder="Nhập..."
                        name="representative_name"
                        error={!!errors.representative_name}
                        helperText={errors.representative_name}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('representative_name', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Số đăng ký</label>
                      <TextField
                        autoComplete="off"
                        value={values.registration_number}
                        placeholder="Nhập..."
                        name="registration_number"
                        error={!!errors.registration_number}
                        helperText={errors.registration_number}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('registration_number', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Loại hình</label>
                      <TextField
                        autoComplete="off"
                        value={values.type_pharmacy}
                        placeholder="Nhập..."
                        name="type_pharmacy"
                        error={!!errors.type_pharmacy}
                        helperText={errors.type_pharmacy}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('type_pharmacy', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Người chiụ trách nhiệm chuyên môn</label>
                      <TextField
                        autoComplete="off"
                        value={values.expertise_person}
                        placeholder="Nhập..."
                        name="expertise_person"
                        error={!!errors.expertise_person}
                        helperText={errors.expertise_person}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('expertise_person', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Số chứng chỉ hành nghề của NCTNCM</label>
                      <TextField
                        autoComplete="off"
                        value={values.expertise_number}
                        placeholder="Nhập..."
                        name="expertise_number"
                        error={!!errors.expertise_number}
                        helperText={errors.expertise_number}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('expertise_number', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Longitude</label>
                      <TextField
                        autoComplete="off"
                        value={values.longitude}
                        placeholder="Nhập..."
                        name="longitude"
                        error={!!errors.longitude}
                        helperText={errors.longitude}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('longitude', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Latitude</label>
                      <TextField
                        autoComplete="off"
                        value={values.latitude}
                        placeholder="Nhập..."
                        name="latitude"
                        error={!!errors.latitude}
                        helperText={errors.latitude}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('latitude', e.target.value);
                        }}
                      />
                    </div>
                    <div className={classes.FieldControl}>
                      <label>Ghi chú</label>
                      <TextField
                        multiline
                        rowsMax={5}
                        autoComplete="off"
                        value={values.note}
                        placeholder="Nhập..."
                        name="note"
                        error={!!errors.note}
                        helperText={errors.note}
                        onChange={(e) => {
                          handleChange(e);
                          this.handleChange('note', e.target.value);
                        }}
                      />
                    </div>
                    {editMode && (
                      <div className={classes.FieldControl}>
                        <label>Trạng thái</label>
                        {approvalStatusMap[approval]}
                      </div>
                    )}
                    {editMode && (
                      <div className={classes.FieldControl}>
                        <label>Ngày dừng hoạt động</label>
                        <div>
                          {(validDate(deactive_date) && active === DEACTIVE) && <span className={classes.DeactivateDate}>{format(validDate(deactive_date), dateFormat)}</span>}
                          <Button
                            disabled
                            variant="contained"
                            size="small"
                            color="secondary">
                            Dừng hoạt động
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </>
    )
  }
}

CustomerInfo.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  active: PropTypes.number,
  address: PropTypes.string,
  approval: PropTypes.number,
  branch_create: PropTypes.string,
  code: PropTypes.string,
  created_at: PropTypes.string,
  deactive_date: PropTypes.string,
  email: PropTypes.string,
  expertise_number: PropTypes.string,
  expertise_person: PropTypes.string,
  getcare_country: PropTypes.object,
  getcare_district: PropTypes.object,
  getcare_province: PropTypes.object,
  getcare_ward: PropTypes.object,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  note: PropTypes.string,
  phone: PropTypes.string,
  registration_number: PropTypes.string,
  representative_name: PropTypes.string,
  type_pharmacy: PropTypes.string,
  tax_code: PropTypes.string,
  updated_at: PropTypes.string,
  registration_company: PropTypes.string,
};

CustomerInfo.defaultProps = {
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCustomer: (payload) => dispatch(updateCustomer(payload)),
    createCustomer: (payload) => dispatch(createCustomer(payload)),
  };
};
const withConnect = connect(null, mapDispatchToProps);
export default compose(withConnect)(CustomerInfo);
