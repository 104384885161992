import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  savePromotionAvailables,
  saveUsedPromotions,
  savePromotionActives,
  savePromotionInactives,
  addPromotionInactiveItems,
  removeUsedPromotionItems,
} from 'redux/actions/ecom/fastOrderActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderUsedPromotions,
  makeSelectFastOrderPromotionInactives,
  makeSelectFastOrderActionLoading,
} from 'redux/selectors';
import { isEqual } from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PromotionAvailables from '../PromotionAvailables/PromotionAvailables';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';
import PromotionTooltip from '../PromotionTooltip/PromotionTooltip';

import classes from './PromotionAvailablesDialog.module.scss';
import PromotionLevels from '../PromotionLevels/PromotionLevels';

class PromotionAvailablesDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPromotionId: null,
      isLevelsSelecting: false,
    }
  }
  componentDidUpdate(prevProps) {
    const { promotionAvailables } = this.props;
    if (promotionAvailables && promotionAvailables.length > 0 && !isEqual(promotionAvailables, prevProps.promotionAvailables)) {
      const enablePromotions = this._getEnablePromotions();
      if (enablePromotions.length > 0) {
        this.setState({
          selectedPromotionId: enablePromotions[0].id,
          isLevelsSelecting: false,
        });
      }
    }
  }

  _getEnablePromotions = ()  => {
    const { promotionAvailables } = this.props;
    if (!promotionAvailables || !promotionAvailables.length) return [];
    return promotionAvailables.filter(promotion => !!promotion.enable && !promotion.apply);
  }
  _getPromotion = (promotionId) => {
    const { promotionAvailables } = this.props;
    if (!promotionAvailables) return null;
    return promotionAvailables.find(promotion => promotion.id === promotionId);
  }
  _getUsedPromotions = (promotionId) => {
    const { usedPromotions } = this.props;
    if (!usedPromotions) return [];
    return usedPromotions.filter(promotion => promotion.promotion_id === promotionId);
  }
  _resetPromotionData = () => {
    this.props.savePromotionAvailables(null);
    this.props.savePromotionActives([]);
    this.props.saveUsedPromotions([]);
    this.props.savePromotionInactives([]);
  }

  handleCompletePromotion = () => {
    this.props.onCompletePromotion();
    this.onClose();
  }
  handleSelectPromotion = (promotionId) => {
    this.setState({
      selectedPromotionId: promotionId,
    });
  }
  handleSubmitSelectedPromotion = async () => {
    // if there's selected promotion -> process as normal
    if (this.state.selectedPromotionId) {
      this.setState({
        isLevelsSelecting: true,
      });
      return;
    }
    // if not -> submit inactive id promotions
    // save inactive id promotions to submit them later if user doesn't select any promotion
    const inactives = this._getEnablePromotions().map(item => ({
      id: item.id,
    }));
    await this.props.addPromotionInactiveItems(inactives);
    this.handleSubmitPromotion();
  }
  handleCancelSelectedPromotion = ({ willRemoveUsedPromotions }) => {
    this.setState({
      isLevelsSelecting: false,
    });
    if (willRemoveUsedPromotions) {
      const selectedUsedPromotions = this._getUsedPromotions(this.state.selectedPromotionId);
      this.props.removeUsedPromotionItems([...selectedUsedPromotions]);
    }
  }
  handleSubmitPromotion = () => {
    this.props.onSubmitPromotion();
  }
  onClose = () => {
    this.props.onClose();
  };
  onEnter = () => {
    this._resetPromotionData();
  }
  onEntered = () => {
    this.props.onCalculatePromotion();
  }

  render() {
    const { isOpen, promotionAvailables, actionLoading, promotionInactives } = this.props;
    const { selectedPromotionId, isLevelsSelecting } = this.state;
    const enablePromotions = this._getEnablePromotions();
    const selectedPromotion = this._getPromotion(selectedPromotionId);
    const hasPromotionAvailables = (promotionAvailables && promotionAvailables.length > 0);
    const hasPromotionInactives = (promotionInactives && promotionInactives.length > 0);

    return (
      <Dialog
        disableBackdropClick={true}
        open={isOpen}
        onClose={this.onClose}
        onEnter={this.onEnter}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}

      >
        <div className={`${classes.DialogContentOuter} ${actionLoading && 'OverlayLoading'}`}>
          { !promotionAvailables &&
            null
          }
          { (promotionAvailables && promotionAvailables.length <= 0 && !hasPromotionInactives) &&
            <div className={classes.DialogInfo}>
              <ErrorOutlineIcon className={classes.DialogInfoIcon}/>
              <h4 className={classes.DialogInfoTitle}>Thông tin khuyến mãi</h4>
              <p>Không có chương trình khuyến mãi cho đơn hàng của bạn!</p>
              <div className={classes.DialogInfoActions}>
                <Button variant="outlined" onClick={this.onClose}>Đóng</Button>
                <Button variant="contained" color="primary" onClick={this.props.onSkipPromotion}>Xác nhận đơn hàng</Button>
              </div>
            </div>
          }
          { (promotionAvailables && promotionAvailables.length > 0) && enablePromotions.length > 0 &&
            <div className={classes.DialogContentWrap}>
              <DialogTitle className={classes.DialogTitle}>
                Danh sách khuyến mãi
                <PromotionTooltip/>

              </DialogTitle>
              <DialogContent className={classes.DialogContent}>
                { !isLevelsSelecting &&
                  <PromotionAvailables
                    selectedPromotionId={selectedPromotionId}
                    enablePromotions={enablePromotions}
                    onSelectPromotion={this.handleSelectPromotion}
                    onSubmitSelectedPromotion={this.handleSubmitSelectedPromotion}
                    onCancelPromotion={this.props.onCancelPromotion}
                    onContinuePurchasing={this.props.onContinuePurchasing}
                    onPromotionLinkClick={this.props.onPromotionLinkClick}
                  />
                }
                { isLevelsSelecting && selectedPromotionId &&
                  <PromotionLevels
                    promotionLevels={selectedPromotion ? selectedPromotion.items : []}
                    promotionId={selectedPromotionId}
                    promotionDetails={selectedPromotion}
                    onSubmitPromotion={this.handleSubmitPromotion}
                    onCancelSelectedPromotion={this.handleCancelSelectedPromotion}
                    onPromotionLinkClick={this.props.onPromotionLinkClick}
                  />
                }
              </DialogContent>
            </div>
          }
          { ((hasPromotionAvailables && enablePromotions.length <= 0) ||
              (promotionAvailables && promotionAvailables.length <= 0 && hasPromotionInactives)
            ) &&
            (<div className={classes.DialogInfo}>
              <CheckCircleOutlineIcon className={classes.DialogSuccessIcon}/>
              <h4 className={classes.DialogInfoTitle}>Bạn đã chọn xong khuyến mãi</h4>
              <p>Không còn chương trình khuyến mãi cho đơn hàng của bạn!</p>
              <div className={classes.DialogInfoActions}>
                <Button className={classes.BlockButton} variant="outlined" onClick={this.handleCompletePromotion}>Đóng</Button>
              </div>
            </div>)
          }
        </div>
      </Dialog>
    );
  }
}

PromotionAvailablesDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCompletePromotion: PropTypes.func,
  onSubmitPromotion: PropTypes.func,
  onSkipPromotion: PropTypes.func,
  onCancelPromotion: PropTypes.func,
  onCalculatePromotion: PropTypes.func,
  onContinuePurchasing: PropTypes.func,
};

PromotionAvailablesDialog.defaultProps = {
  isOpen: false,
};

const mapStateToProps = createStructuredSelector({
  promotionAvailables: makeSelectFastOrderPromotionAvailables(),
  usedPromotions: makeSelectFastOrderUsedPromotions(),
  promotionInactives: makeSelectFastOrderPromotionInactives(),
  actionLoading: makeSelectFastOrderActionLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionAvailables: (payload) => dispatch(savePromotionAvailables(payload)),
    saveUsedPromotions: (payload) => dispatch(saveUsedPromotions(payload)),
    removeUsedPromotionItems: (payload) => dispatch(removeUsedPromotionItems(payload)),
    savePromotionActives: (payload) => dispatch(savePromotionActives(payload)),
    savePromotionInactives: (payload) => dispatch(savePromotionInactives(payload)),
    addPromotionInactiveItems: (payload) => dispatch(addPromotionInactiveItems(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionAvailablesDialog);
