import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields, promotionStatuses } from 'utils/constanst/tmkPromotionConstants';

import FilterField from 'components/FilterField/FilterField';
import Checkboxes from 'components/FilterField/Checkboxes';

import classes from './PromotionListFilters.module.scss';

class PromotionListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };
  handleFieldChange = ({ name, value }) => {
    const fieldIndex = this._getFieldIndex(name);
    if (fieldIndex < 0) return;
    const fields = [...this.state.fields];
    fields[fieldIndex] = { ...this.state.fields[fieldIndex], value: value };
    this.setState({
      fields: fields,
    });
    this.props.onFilterChange(fields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['active'].includes(field.name) ? [] : ''}
              customField={
                field.name === 'active' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    values={[...promotionStatuses]}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : ''
              }
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

PromotionListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default PromotionListFilters;
