export const GET_PROVINCE_LIST = 'getcare/GET_PROVINCE_LIST';
export const GET_DISTRICT_LIST = 'getcare/GET_DISTRICT_LIST';
export const GET_WARD_LIST = 'getcare/GET_WARD_LIST';
export const GET_COUNTRY_LIST = 'getcare/GET_COUNTRY_LIST';

// mutation
export const SAVE_COUNTRY_LIST = 'getcare/SAVE_COUNTRY_LIST';
export const SAVE_PROVINCE_LIST = 'getcare/SAVE_PROVINCE_LIST';
export const SAVE_DISTRICT_LIST = 'getcare/SAVE_DISTRICT_LIST';
export const SAVE_WARD_LIST = 'getcare/SAVE_WARD_LIST';
