import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  getSalesOrder,
  saveSalesOrderAllInfo,
  updateOrderStatus,
  returnDelivery,
  getSalesOrderHistory,
} from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectSalesOrder,
  makeSelectDeliveryLogisticList,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderProducts,
  makeSelectLoginUser,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import lodash, { isEqual } from 'lodash';

import {
  DELIVERY_SUCCESS,
  SEND_DELIVERY,
  RETURN_DELIVERY,
  SUCCESS_DELIVERY,
  CANCEL,
  getActionItem,
} from 'utils/constanst/omsSalesOrderConstants';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  ArrowBack,
  LocalOffer,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';


import SalesOrderInfo from 'components/so/SalesOrderInfo/SalesOrderInfo';
import SalesOrderProducts from 'components/so/SalesOrderProducts/SalesOrderProducts';
import SalesOrderBonuses from 'components/so/SalesOrderBonuses/SalesOrderBonuses';
import SalesOrderLogisticProvider from 'components/so/SalesOrderLogisticProvider/SalesOrderLogisticProvider';
import SalesOrderNotes from 'components/so/SalesOrderNotes/SalesOrderNotes';
import SalesOrderSalesInfo from 'components/so/SalesOrderSalesInfo/SalesOrderSalesInfo';
import SalesOrderDeliveryInfo from 'components/so/SalesOrderDeliveryInfo/SalesOrderDeliveryInfo';
import SalesOrderAmountInfo from 'components/so/SalesOrderAmountInfo/SalesOrderAmountInfo';
import SalesOrderCancelFormDialog from 'components/so/SalesOrderCancelFormDialog/SalesOrderCancelFormDialog';
import SalesOrderHistory from 'components/so/SalesOrderHistory/SalesOrderHistory';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import SalesOrderDeliverySentDialog from 'components/so/SalesOrderDeliverySentDialog/SalesOrderDeliverySentDialog';

import classes from './SalesOrder.module.scss';

class SalesOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nextSalesOrderId: null,
      isMoreActionsOpen: false,
      openSalesOrderCancelDialog: false,
      isDeliverySentDialogOpen: false,
      openReturnDeliveryDialog: false,
      confirmDialogOpen: false,
      confirmDiaLogTitle: '',
      confirmDialogContent: '',
      confirmDialogSubmit: null,
      // isMine = true if this order had been created from the vendor
      isMine: true,
    };
    this.moreActionsMenuRef = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id && id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { salesOrder } = this.props;
    if (
      salesOrder &&
      prevProps.salesOrder &&
      salesOrder.id === prevProps.salesOrder.id &&
      salesOrder.getcare_order_status?.id !==
        prevProps.salesOrder.getcare_order_status?.id
    ) {
      this._loadSalesOrderHistory();
    }
    if (
      salesOrder &&
      salesOrder.id &&
      isEqual(salesOrder, prevProps.salesOrder)
    ) {
      this._setView();
    }
  }

  _loadData = (id) => {
    this.props.getSalesOrder(id);
  };
  _backToList = () => {
    this.props.history.push('/so');
  };
  _goToNextSalesOrder = () => {
    this.props.history.push(`/so/${this.state.nextSalesOrderId}`);
  };
  _goToAfterItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.next_code}`);
  };
  _goToBeforeItem = () => {
    this.props.history.push(`/so/${this.props.salesOrder?.prev_code}`);
  };
  _loadSalesOrderHistory = () => {
    this.props.getSalesOrderHistory({
      params: { getcare_order_id: this.props.salesOrder?.id },
    });
  };
  _setView = () => {
    const { salesOrder, user } = this.props;
    this.setState({
      isMine: salesOrder?.getcare_vendor_id === Number(user?.getcare_vendor_id),
    });
  };
  _showBtnChangeStatus = () => {
    const { salesOrder } = this.props;
    const actions = salesOrder?.actions;
    return (
      actions &&
      lodash.some(actions, (action) => {
        return [SUCCESS_DELIVERY, CANCEL].includes(action.code);
      })
    );
  };

  handleToggleDropdown = () => {
    this.setState({
      isMoreActionsOpen: !this.state.isMoreActionsOpen,
    });
  };
  handleCloseDropdown = () => {
    this.setState({
      isMoreActionsOpen: false,
    });
  };

  handleOpenCancelDialog = () => {
    this.setState({
      openSalesOrderCancelDialog: true,
    });
  };
  handleCloseCancelDialog = () => {
    this.setState({
      openSalesOrderCancelDialog: false,
    });
  };
  handleSaveSalesOrderAllInfo = (newSalesOrderData) => {
    this.props.saveSalesOrderAllInfo(newSalesOrderData);
    this._loadSalesOrderHistory();
  };
  handleUpdateOrderStatus = ({ statusId }) => {
    this.props.updateOrderStatus({
      params: {
        id: this.props.salesOrder?.id,
        getcare_order_status_id: statusId,
      },
    });
  };
  handleConfirmShipFromVendorDialogSuccess = async () => {
    this.handleCloseConfirmDialog();
  };
  handleOpenConfirmShipFromVendorDialog = () => {
    this.setState({
      confirmDialogOpen: true,
      confirmDiaLogTitle: 'Xác nhận giao hàng từ vendor',
      confirmDialogContent: 'Bạn có chắc muốn giao hàng từ vendor?',
      confirmDialogSubmit: this.handleConfirmShipFromVendorDialogSuccess,
    });
  };
  handleCloseConfirmDialog = () => {
    this.setState({
      confirmDialogOpen: false,
      confirmDiaLogTitle: '',
      confirmDialogContent: '',
      confirmDialogSubmit: null,
    });
  };

  handleOpenReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: true,
    });
  }
  handleCloseReturnDeliveryDialog = () => {
    this.setState({
      openReturnDeliveryDialog: false,
    });
  }

  handleCloseDeliverySentDialog = () => {
    this.setState({
      isDeliverySentDialogOpen: false,
    });
  };
  handleOpenDeliverySentDialog = () => {
    this.setState({
      isDeliverySentDialogOpen: true,
    });
  };

  handleSubmitReturnDelivery = () => {
    this.props.returnDelivery({
      params: {
        id: this.props.salesOrder?.id,
      }
    });
    this.handleCloseReturnDeliveryDialog();
  }
  

  render() {
    const { salesOrder, deliveryLogisticList, loading } = this.props;
    const { isMoreActionsOpen, isMine } = this.state;
    const statusId = salesOrder?.getcare_order_status?.id;
    const actions = salesOrder?.actions;

    return (
      <>
        <div
          className={`${classes.PageWrap} ${loading ? 'OverlayLoading' : ''}`}
        >
          <div className={classes.PageHeader}>
            <IconButton
              disabled={!salesOrder?.prev_code}
              size="small"
              onClick={this._goToBeforeItem}
            >
              <ArrowBackIosOutlined fontSize="small" />
            </IconButton>
            <IconButton
              disabled={!salesOrder?.next_code}
              size="small"
              onClick={this._goToAfterItem}
            >
              <ArrowForwardIosOutlined fontSize="small" />
            </IconButton>
            <h1 className={classes.PageTitle}>
              Chi tiết đơn hàng
              {salesOrder?.is_taxcode && (
                <Tooltip title={`Xuất hoá đơn GTGT`} arrow placement="top">
                  <LocalOffer
                    className={classes.InvoiceIcon}
                    color="secondary"
                  />
                </Tooltip>
              )}
            </h1>
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={this._backToList}
            >
              Trở về
            </Button>

            {isMine && this._showBtnChangeStatus() && (
              <>
                {getActionItem(SUCCESS_DELIVERY, actions) && (
                  <>
                    <Button
                      onClick={() => {
                        this.handleUpdateOrderStatus({
                          statusId: DELIVERY_SUCCESS,
                        });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Giao hàng thành công
                    </Button>
                  </>
                )}

                {getActionItem(CANCEL, actions) && (
                  <Button
                    ref={this.moreActionsMenuRef}
                    aria-controls={
                      isMoreActionsOpen ? 'menu-list-grow' : undefined
                    }
                    aria-haspopup="true"
                    onClick={this.handleOpenCancelDialog}
                    variant="outlined"
                  >
                    Huỷ
                  </Button>
                )}
                    {getActionItem(SEND_DELIVERY, actions) && (
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleOpenDeliverySentDialog}
              >
                {getActionItem(SEND_DELIVERY, actions).name}
              </Button>
            )}

                {getActionItem(RETURN_DELIVERY, actions) && (
              <Button
                variant="contained"
                color="primary"
              onClick={this.handleOpenReturnDeliveryDialog}
              >
                {getActionItem(RETURN_DELIVERY, actions).name}
              </Button>
            )}
              </>
            )}
    
          </div>
          <div className={classes.PageMain}>
            <div className={classes.InfoWrap}>
              <SalesOrderInfo isMine={isMine} />
            </div>
            <div className={classes.Content}>
              <SalesOrderProducts
                salesOrderStatusId={statusId}
                isMine={isMine}
                onCancelSalesOrder={this.handleOpenCancelDialog}
              />
              <SalesOrderBonuses isMine={isMine} />

              {isMine && (
                <>
                  <SalesOrderLogisticProvider
                    deliveryLogisticList={[...deliveryLogisticList]}
                    onOpenDeliverySentDialog={this.handleOpenDeliverySentDialog}
                  />
                  <SalesOrderNotes salesOrderId={salesOrder?.id} />
                  <SalesOrderHistory salesOrderId={salesOrder?.id} />
                </>
              )}
            </div>
            {isMine && (
              <div className={classes.Side}>
                <SalesOrderSalesInfo />
                <SalesOrderDeliveryInfo
                  onSaveDeliveryInfoSuccess={this.handleSaveSalesOrderAllInfo}
                />
                <SalesOrderAmountInfo />
              </div>
            )}
          </div>
        </div>
        {this.state.confirmDialogOpen && (
          <ConfirmationDialog
            // isLoading={loading}
            isOpen={this.state.confirmDialogOpen}
            title={this.state.confirmDiaLogTitle}
            message={
              <>
                <p>{this.state.confirmDialogContent}</p>
              </>
            }
            onClose={this.handleCloseConfirmDialog}
            onSubmit={
              this.state.confirmDialogSubmit
                ? this.state.confirmDialogSubmit
                : this.handleCloseConfirmDialog
            }
          />
        )}

    { this.state.openReturnDeliveryDialog &&
        <ConfirmationDialog
          isOpen={this.state.openReturnDeliveryDialog}
          title={`Xác nhận Đã trả hàng`}
          message={<>
            <p>Đơn hàng <strong>{ salesOrder?.code }</strong> khách hàng không đồng ý mua hoặc không nhận hàng.</p>
            <p>Xác nhận <strong>Đã trả hàng</strong> về hệ thống.</p>
          </>}
          onClose={this.handleCloseReturnDeliveryDialog}
          onSubmit={this.handleSubmitReturnDelivery}
        />
      }

        {this.state.isDeliverySentDialogOpen && (
          <SalesOrderDeliverySentDialog
            salesOrderId={salesOrder?.id}
            salesOrderActions={salesOrder?.actions}
            isOpen={this.state.isDeliverySentDialogOpen}
            onClose={this.handleCloseDeliverySentDialog}
            onSaveDeliverySentSuccess={this.handleSaveSalesOrderAllInfo}
          />
        )}
        {this.state.openSalesOrderCancelDialog && (
          <SalesOrderCancelFormDialog
            isOpen={this.state.openSalesOrderCancelDialog}
            salesOrderId={salesOrder?.id}
            onClose={this.handleCloseCancelDialog}
            onCancelSuccess={this.handleSaveSalesOrderAllInfo}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  loading: makeSelectSalesOrderLoading(),
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrderProducts: makeSelectSalesOrderProducts(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrder: (payload) => dispatch(getSalesOrder(payload)),
    getSalesOrderHistory: (payload) => dispatch(getSalesOrderHistory(payload)),
    returnDelivery: (payload) => dispatch(returnDelivery(payload)),
    updateOrderStatus: (payload) => dispatch(updateOrderStatus(payload)),
    saveSalesOrderAllInfo: (payload) =>
      dispatch(saveSalesOrderAllInfo(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(SalesOrder);
