import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { isEqual, sortBy, debounce } from 'lodash';
import { toast } from 'react-toastify';
import {
  getPurchaseRequestDraft,
  updatePurchaseRequestDraft,
  saveSelectedCustomerCode,
  checkPromotion,
  savePromotionAvailables,
  saveUsedPromotions,
  savePromotionActives,
  savePromotionInactives,
  createPurchaseRequest,
} from 'redux/actions/ecom/fastOrderActions';
import { getPharmacy } from 'redux/actions/ecom/pharmacyActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderLoading,
  makeSelectLoginUser,
  makeSelectFastOrderSelectedCustomerCode,
  makeSelectFastOrderProducts,
  makeSelectFastOrderUsedPromotions,
  makeSelectFastOrderPromotionActives,
  makeSelectFastOrderPromotionInactives,
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderDelivery,
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDetails,
  makeSelectDeliveryLogisticList,
  makeSelectFastOrderNotes,
  makeSelectPharmacy,
  makeSelectFastOrderDiscounts,
} from 'redux/selectors';
import * as QueryString from 'query-string';
import {
  getValidOrderItems,
  prepareUsedPromotionsData,
  getCombinedValidOrderProductsData,
} from 'utils/constanst/ecomFastOrderConstants';
import {
  isChanhXeLogisticProviderId,
  isThirdPartyLogisticProviderId,
  FAST_ORDER_TYPE_ID,
} from 'utils/constanst/common';
import fastOrderApi from 'utils/api/ecom/fastOrderApi';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import FastOrderProducts from 'components/ecom/FastOrder/FastOrderProducts/FastOrderProducts';
import FastOrderAmount from 'components/ecom/FastOrder/FastOrderAmount/FastOrderAmount';
import PromotionAvailablesDialog from 'components/ecom/FastOrder/PromotionAvailablesDialog/PromotionAvailablesDialog';
import FastOrderBonuses from 'components/ecom/FastOrder/FastOrderBonuses/FastOrderBonuses';
import FastOrderPromotions from 'components/ecom/FastOrder/FastOrderPromotions/FastOrderPromotions';
import Button from '@material-ui/core/Button';
import LogisticProvider from 'components/ecom/FastOrder/LogisticProvider/LogisticProvider';
import FastOrderNotes from 'components/ecom/FastOrder/FastOrderNotes/FastOrderNotes';
import FastOrderCustomerDelivery from 'components/ecom/FastOrder/FastOrderCustomerDelivery/FastOrderCustomerDelivery';
import FastOrderSuccessDialog from 'components/ecom/FastOrder/FastOrderSuccessDialog/FastOrderSuccessDialog';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import PromotionDetailsDialog from 'components/ecom/FastOrder/PromotionDetailsDialog/PromotionDetailsDialog';
import CustomerDeliveryFormDialog from 'components/ecom/FastOrder/FastOrderCustomerDelivery/CustomerDeliveryFormDialog/CustomerDeliveryFormDialog';

import classes from './FastOrder.module.scss';

class FastOrder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,

      selectedPromotionDetails: null,

      isPromotionDialogOpen: false,
      isSuccessDialogOpen: false,
      isEditConfirmationDialogOpen: false,
      isSkipPromotionConfirmDialogOpen: false,
      isPromotionDetailsDialogOpen: false,
      isCustomerDeliveryDialogOpen: false,
    };
    this.pageRef = null;
    this.pageSideInnerRef = null;
    this.pageMainInnerRef = null;
  }
  componentDidMount() {
    const { location } = this.props;
    const params = QueryString.parse(location.search);

    if (!params.getcare_customer_code) {
      this._handleOrderRouting({ isCreatedSuccess: false });
      return;
    }

    if (!!params.getcare_customer_code) {
      this._loadDraftPR({
        getcare_customer_code: params.getcare_customer_code,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const params = QueryString.parse(location.search);
    if (
      params.getcare_customer_code !==
      QueryString.parse(prevProps.location.search).getcare_customer_code
    ) {
      if (!!params.getcare_customer_code) {
        this._loadDraftPR({
          getcare_customer_code: params.getcare_customer_code.trim(),
        });
      }
      if (!params.getcare_customer_code) {
        this._handleOrderRouting({ isCreatedSuccess: false });
      }
    }
    // save pr draft on product items change
    const { prProducts } = this.props;
    if (
      this._isPREditing()
      && prProducts
      && this._isProductsChanged(prProducts, prevProps.prProducts)
    ) {
      this.debounceToSavePRDraft({
        prDeliveryData: null,
        prLogisticData: null,
        willSaveLoading: false,
        willUpdatePRDetails: true,
      });
    }
    const { prDetails } = this.props;
    if (prDetails && prDetails.id && !isEqual(prDetails, prevProps.prDetails)) {
      this.handleOpenSuccessDialog();
    }
    const { step } = this.state;
    if (step !== prevState.step) {
      document.querySelector('body').scrollTo({ top: 0 });
    }
  }

  _isProductsChanged = (products, comparedProducts) => {
    if (!products || !comparedProducts) return false;
    return !isEqual(
      sortBy(getCombinedValidOrderProductsData(products)),
      sortBy(getCombinedValidOrderProductsData(comparedProducts)),
    );
  }

  _isPREditing = () => {
    return this.state.step === 1;
  }
  _handleOrderRouting = ({ isCreatedSuccess }) => {
    if (isCreatedSuccess) {
      this.props.history.push(`/so`);
      return;
    }
    const { user } = this.props;
    this.props.history.push(
      user && user.id && user.getcare_pharmacy
        ? `/fastorder?getcare_customer_code=${user.getcare_pharmacy.code}`
        : `/store`
    );
  };
  _loadDraftPR = (params) => {
    this.props.getPharmacy({
      id: params.getcare_customer_code,
      params: {
        mode: 'FASTORDER',
      }
    });
    this.props.saveSelectedCustomerCode(params.getcare_customer_code);
    this._resetPromotionData();
    this.props.getPurchaseRequestDraft({ params });
  };
  _isAbleToCheckPromotion = () => {
    const { selectedCustomerCode, prProducts } = this.props;
    return !!getValidOrderItems(prProducts).length && !!selectedCustomerCode;
  };
  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList } = this.props;
    if (!deliveryLogisticList || !providerId) return null;
    return deliveryLogisticList.find((item) => item.id === providerId);
  };
  _getSelectedService = (providerId, serviceId) => {
    const provider = this._getSelectedProvider(providerId);
    if (!provider) return null;
    return provider.services.find(
      (service) => service.service_id === serviceId
    );
  };

  _getSubTotal = () => {
    const { prProducts } = this.props;
    return prProducts.reduce((memo, product) => {
      const subTotalLine =
        [null, undefined, ''].includes(product.quantity_number) ||
        [null, undefined, ''].includes(product.price_sales_total)
          ? 0
          : product.price_sales_total * Number(product.quantity_number);
      return (memo += subTotalLine);
    }, 0);
  };
  _getAmountDiscount = () => {
    const { prProducts, prDiscounts, promotionActives } = this.props;

    // calculate promotionActives by top priority
    const discountPromotionActives = promotionActives.filter(
      (item) => !!item.amount
    );
    if (discountPromotionActives.length > 0) {
      return discountPromotionActives.reduce((memo, discount) => {
        return (memo += discount.amount);
      }, 0);
    }

    // calculate in order items
    const lineDiscountItems = prProducts
      .filter(
        (product) =>
          product.getcare_purchase_request_item_discounts &&
          product.getcare_purchase_request_item_discounts.length > 0
      )
      .map((product) => [...product.getcare_purchase_request_item_discounts]);
    const lineDiscountsAmount = lineDiscountItems
      .flatMap((item) => [...item])
      .reduce((memo, discount) => {
        return (memo += discount.amount);
      }, 0);

    // calculate in discounts (not by order items)
    const notLineDiscountsAmount = prDiscounts.reduce((memo, discount) => {
      return (memo += discount.amount);
    }, 0);

    return lineDiscountsAmount + notLineDiscountsAmount;
  };
  _getAmountShippingCoupon = () => {
    const { prDetails } = this.props;
    return prDetails?.amount_shipping_coupon || 0;
  };
  _getAmountDelivery = () => {
    return this.props.prLogistic?.total_fee || 0;
  };
  _getTotal = () => {
    return (
      this._getSubTotal() -
      this._getAmountDiscount() +
      this._getAmountDelivery() -
      this._getAmountShippingCoupon()
    );
  };
  _prepareLogisticProviderData = (logisticProvider, isSubmitPR) => {
    const providerId = logisticProvider?.id;
    const providerNote = isSubmitPR
      ? (isChanhXeLogisticProviderId(providerId) ? logisticProvider?.note : undefined)
      : logisticProvider?.note;
      const serviceSelected = this._getSelectedService(
        logisticProvider?.id,
        logisticProvider?.service_id
      );
    return {
      id: providerId,
      name: this._getSelectedProvider(providerId)?.name,
      service_id: logisticProvider?.service_id,
      service_name: this._getSelectedService(
        logisticProvider?.id,
        logisticProvider?.service_id
      )?.service_name,
      service_type_id: serviceSelected?.service_type_id ? serviceSelected?.service_type_id  :logisticProvider?.service_type_id ,
      insurance_value: logisticProvider?.insurance_value,
      note: providerNote,
    };
  };
  _prepareCustomerDeliveryData = (customerDelivery) => {
    return {
      name: customerDelivery.name,
      phone: (/^(84)/).test(customerDelivery.phone) ? customerDelivery.phone.replace(/^(84)/, '+84') : customerDelivery.phone,
      label: customerDelivery.label,
      address: customerDelivery.address,
      getcare_country_id: customerDelivery.getcare_country?.id,
      getcare_province_id: customerDelivery.getcare_province?.id,
      getcare_district_id: customerDelivery.getcare_district?.id,
      getcare_ward_id: customerDelivery.getcare_ward?.id,
      save: customerDelivery.save,
    };
  };
  _preparePRData = ({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails, isSubmitPR }) => {
    const {
      selectedCustomerCode,
      prProducts,
      prDetails,
      prLogistic,
      prDelivery,
      prNotes,
      usedPromotions,
    } = this.props;
    return {
      getcare_customer_code: selectedCustomerCode,
      getcare_order_type_id: FAST_ORDER_TYPE_ID,
      is_taxcode: prDetails?.is_taxcode,
      taxcode: prDetails?.taxcode,
      order_items: getCombinedValidOrderProductsData(prProducts),
      promotion_actives: usedPromotions
        ? prepareUsedPromotionsData(this.props.usedPromotions)
        : undefined,
      is_check_promotion: this.state.step === 3 ? 1 : undefined, // would get the applied promotions if users had finished the choosing promotions step
      logistic_provider: prLogisticData
        ? this._prepareLogisticProviderData(prLogisticData, isSubmitPR)
        : prLogistic
        ? this._prepareLogisticProviderData(prLogistic, isSubmitPR)
        : undefined,
      customer_delivery: prDeliveryData
        ? this._prepareCustomerDeliveryData(prDeliveryData)
        : prDelivery
        ? this._prepareCustomerDeliveryData(prDelivery)
        : undefined,
      notes: prNotes ? prNotes.map((note) => note.note) : undefined,
      willSaveLoading,
      willUpdatePRDetails,
    };
  };
  debounceToSavePRDraft = debounce(({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails }) => {
    this._savePRDraft({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails });
  }, 1000);
  _savePRDraft = ({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails }) => {
    this.props.updatePurchaseRequestDraft({
      params: this._preparePRData({ prDeliveryData, prLogisticData, willSaveLoading, willUpdatePRDetails }),
    });
  };
  _deliveryErrors = () => {
    let messages = [];
    const { prDelivery } = this.props;
    if (!prDelivery) {
      messages.push('• Vui lòng nhập thông tin nhận hàng.');
      return messages;
    }

    !prDelivery.name && messages.push('• Địa chỉ nhận hàng không hợp lệ (không có tên người nhận hàng). Vui lòng chọn địa chỉ khác.');
    !prDelivery.phone && messages.push('• Địa chỉ nhận hàng không hợp lệ (không có số điện thoại nhận hàng). Vui lòng chọn địa chỉ khác.');
    (!prDelivery.getcare_country
      || !prDelivery.getcare_province
      || !prDelivery.getcare_district
      || !prDelivery.getcare_ward
      || !prDelivery.address
    ) && messages.push('• Địa chỉ nhận hàng không hợp lệ. Vui lòng chọn địa chỉ khác.');
    return messages;
  }
  _logisticErrors = () => {
    let messages = [];
    const { prLogistic } = this.props;
    if (!prLogistic || !prLogistic.id) {
      messages.push('• Vui lòng chọn đơn vị vận chuyển.');
      return messages;
    }
    if (isThirdPartyLogisticProviderId(prLogistic.id)) {
      if (!prLogistic.service_id) {
        messages.push('• Vui lòng chọn dịch vụ cho đơn vị vận chuyển.');
        return messages;
      }
      if (!this._getSelectedService(prLogistic.id, prLogistic.service_id)) {
        messages.push('• Bạn đã thay đổi địa chỉ nhận hàng. Vui lòng chọn lại dịch vụ vận chuyển phù hợp với địa chỉ mới.');
        return messages;
      }
      return [];
    }
    if (isChanhXeLogisticProviderId(prLogistic.id) && !prLogistic.note) {
      messages.push('• Vui lòng nhập ghi chú cho đơn vị vận chuyển.');
      return messages;
    }
    return [];
  }
  _hasWarningMessages = () => {
    const messages = [
      ...this._deliveryErrors(),
      ...this._logisticErrors(),
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return messages.join('\n');
    }
    return ``;
  }

  _resetPromotionData = () => {
    this.props.savePromotionAvailables(null);
    this.props.savePromotionActives([]);
    this.props.saveUsedPromotions([]);
    this.props.savePromotionInactives([]);
  };
  _preparePromotionData = ({ willResetPromotionActives }) => {
    const { selectedCustomerCode, prProducts } = this.props;
    return {
      getcare_customer_code: selectedCustomerCode,
      order_items: getCombinedValidOrderProductsData(prProducts),
      promotion_actives: willResetPromotionActives
        ? []
        : prepareUsedPromotionsData(this.props.usedPromotions),
      promotion_inactives: willResetPromotionActives
        ? []
        : this.props.promotionInactives,
    };
  };

  handleCheckPromotion = () => {
    this._savePRDraft({ prDeliveryData: null, prLogisticData: null, willSaveLoading: false, willUpdatePRDetails: true, });
    this.handleOpenPromotionDialog();
  };

  handleOpenPromotionDialog = () => {
    this.setState({
      step: 1,
      isPromotionDialogOpen: true,
    });
  };
  handleClosePromotionDialog = () => {
    this.setState({
      isPromotionDialogOpen: false,
    });
  };
  handleContinuePurchasing = () => {
    this.setState(
      {
        isPromotionDialogOpen: false,
      },
      () => {
        this._resetPromotionData();
      }
    );
  };

  handleOpenSuccessDialog = () => {
    this.setState({
      isSuccessDialogOpen: true,
    });
  };
  handleCloseSuccessDialog = () => {
    this.setState(
      {
        isSuccessDialogOpen: false,
        step: 1,
      },
      () => {
        this._handleOrderRouting({ isCreatedSuccess: true });
      }
    );
  };

  handleCompletePromotion = () => {
    this.setState({
      step: 2,
    });
  };
  handleSkipPromotion = () => {
    this.setState(
      {
        step: 3,
        isPromotionDialogOpen: false,
      },
      () => {
        this._resetPromotionData();
      }
    );
  };
  handleConfirmPR = () => {
    this.setState(
      {
        step: 3,
      },
      () => {
        this._savePRDraft({ prDeliveryData: null, prLogisticData: null, willSaveLoading: true, willUpdatePRDetails: true });
      }
    );
  };
  handleSubmitPR = async () => {
    const orderData = this._preparePRData({
      prDeliveryData: null,
      prLogisticData: null,
      willSaveLoading: true,
      willUpdatePRDetails: true,
      isSubmitPR: true,
    });

    const deliveryErrors = this._deliveryErrors();
    if (deliveryErrors.length) {
      fastOrderApi.saveOrderDraft({
        params: {
          error_description: deliveryErrors.join('\n'),
          data: JSON.stringify(orderData),
        }
      });
      toast.error(deliveryErrors.join('\n'));
      this.handleOpenCustomerDeliveryDialog();
      return;
    }

    const logisticErrors = this._logisticErrors();
    if (logisticErrors.length) {
      fastOrderApi.saveOrderDraft({
        params: {
          error_description: logisticErrors.join('\n'),
          data: JSON.stringify(orderData),
        }
      });
      toast.error(logisticErrors.join('\n'));
      return;
    }

    this.props.createPurchaseRequest({
      params: orderData,
    });
  };

  handleOpenEditConfirmationDialog = () => {
    this.setState({
      isEditConfirmationDialogOpen: true,
    });
  };
  handleCloseEditConfirmationDialog = () => {
    this.setState({
      isEditConfirmationDialogOpen: false,
    });
  };
  handleSubmitEditConfirmationDialog = () => {
    this.setState(
      {
        step: 1,
      },
      () => {
        this.handleCloseEditConfirmationDialog();
        this._resetPromotionData();
      }
    );
  };

  handleOpenSkipPromotionConfirmDialog = () => {
    this.setState({
      isSkipPromotionConfirmDialogOpen: true,
    });
  };
  handleCloseSkipPromotionConfirmDialog = () => {
    this.setState({
      isSkipPromotionConfirmDialogOpen: false,
    });
  };
  handleSubmitSkipPromotionConfirmDialog = () => {
    this.setState(
      {
        isSkipPromotionConfirmDialogOpen: false,
      },
      () => {
        this.handleSkipPromotion();
      }
    );
  };

  handleSubmitPromotion = () => {
    this.props.checkPromotion({
      params: this._preparePromotionData({ willResetPromotionActives: false }),
    });
  };
  handleCalculatePromotion = () => {
    this.props.checkPromotion({
      params: this._preparePromotionData({ willResetPromotionActives: true }),
    });
  };

  handlePromotionLinkClick = (promotionItem) => {
    this.setState(
      {
        selectedPromotionDetails: { ...promotionItem },
      },
      () => {
        this.handleOpenPromotionDetailsDialog();
      }
    );
  };
  handleOpenPromotionDetailsDialog = () => {
    this.setState({
      isPromotionDetailsDialogOpen: true,
    });
  };
  handleClosePromotionDetailsDialog = () => {
    this.setState({
      isPromotionDetailsDialogOpen: false,
      selectedPromotionDetails: null,
    });
  };

  handleOpenCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: true,
    });
  };
  handleCloseCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: false,
    });
  };

  render() {
    const { isPromotionDialogOpen, step, isSuccessDialogOpen } = this.state;
    const { loading, selectedCustomerCode, pharmacyDetails,promotionAvailables } = this.props;

    return (
      <React.Fragment>
        <div
          ref={(ref) => {
            this.pageRef = ref;
          }}
          className={classes.PageWrap}
        >
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                className="BreadscrumbSeparator"
                fontSize="small"
              />
            }
            className="Breadscrumbs"
            aria-label="breadcrumb"
          >
            <Link color="inherit" to="/store">
              Chọn Khách Hàng
            </Link>

            {(step === 1 || step === 2) && (
              <span className="BreadscrumbActive">Đặt Hàng Nhanh</span>
            )}
            {(step === 1 || step === 2) && <span>Xác Nhận Đơn Hàng</span>}

            {step === 3 && (
              <Link
                color="inherit"
                to={`/fastorder?getcare_customer_code=${selectedCustomerCode}`}
                onClick={this.handleOpenEditConfirmationDialog}
              >
                Đặt Hàng Nhanh
              </Link>
            )}
            {step === 3 && (
              <span className="BreadscrumbActive">Xác Nhận Đơn Hàng</span>
            )}
          </Breadcrumbs>

          <div className={classes.PageHeader}>
            <h1 className="PageTitle">
              {step === 3 ? 'Xác Nhận Đơn Hàng' : 'Đặt Hàng Nhanh'}
              <p className={classes.PageSubTitle}>
                Cho nhà thuốc{' '}
                <span className={classes.CustomerName}>
                  {pharmacyDetails?.name || `-`}
                </span>
              </p>
            </h1>
          </div>

          <div className={`${classes.PageContent} ${loading && 'OverlayLoading'}`}>
            <div className={classes.PageMain}>
              <div
                ref={(ref) => {
                  this.pageMainInnerRef = ref;
                }}
              >
                <FastOrderProducts
                  isEditing={this._isPREditing()}
                  step={step}
                  onEditFastOrderProducts={
                    this.handleOpenEditConfirmationDialog
                  }
                />
                {step === 1 && this._isAbleToCheckPromotion() && (
                  <div className={classes.StepGuideControl}>
                    <p>
                      Đơn hàng đã đủ, bạn có muốn tính khuyến mãi cho đơn hàng
                      của bạn không?
                    </p>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={this.handleCheckPromotion}
                    >
                      Tính khuyến mãi
                    </Button>
                  </div>
                )}
                {step === 2 && (
                  <>
                    <FastOrderPromotions
                      step={step}
                      onSubmitPromotion={this.handleSubmitPromotion}
                      onRecalculatePromotion={this.handleOpenPromotionDialog}
                      onPromotionLinkClick={this.handlePromotionLinkClick}
                    />
                    <div className={classes.StepGuideControl}>
                      <p>
                        Bạn đã chọn xong khuyến mãi, bạn có muốn đến bước xác
                        nhận đơn hàng?
                      </p>
                      <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={this.handleConfirmPR}
                      >
                        Tiếp tục
                      </Button>
                    </div>
                  </>
                )}
                {step === 3 && (
                  <>
                    <FastOrderBonuses />
                    <LogisticProvider onUpdatePRDraft={this._savePRDraft} />
                    <FastOrderNotes />
                  </>
                )}
              </div>
            </div>
            <div className={classes.PageSide}>
              <div
                ref={(ref) => {
                  this.pageSideInnerRef = ref;
                }}
                className={classes.PageSideInner}
              >
                {step === 3 && (
                  <FastOrderCustomerDelivery
                    onUpdatePRDraft={this._savePRDraft}
                    onOpenCustomerDeliveryDialog={this.handleOpenCustomerDeliveryDialog}
                  />
                )}
                <FastOrderAmount
                  isFinalStep={step === 3}
                  subTotal={this._getSubTotal()}
                  amountDiscount={this._getAmountDiscount()}
                  amountDelivery={this._getAmountDelivery()}
                  amountShippingCoupon={this._getAmountShippingCoupon()}
                  amountTotal={this._getTotal()}
                />
                {step === 3 && (
                  <Button
                    className={classes.SubmitPRBtn}
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={this.handleSubmitPR}
                  >
                    Gửi đơn đặt hàng
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        {isPromotionDialogOpen && promotionAvailables !== 'Out of stock' && (
          <PromotionAvailablesDialog
            isOpen={isPromotionDialogOpen}
            onClose={this.handleClosePromotionDialog}
            onCompletePromotion={this.handleCompletePromotion}
            onCancelPromotion={this.handleOpenSkipPromotionConfirmDialog}
            onSkipPromotion={this.handleSkipPromotion}
            onSubmitPromotion={this.handleSubmitPromotion}
            onCalculatePromotion={this.handleCalculatePromotion}
            onContinuePurchasing={this.handleContinuePurchasing}
            onPromotionLinkClick={this.handlePromotionLinkClick}
          />
        )}

        {isSuccessDialogOpen && (
          <FastOrderSuccessDialog
            prDetails={this.props.prDetails}
            isOpen={isSuccessDialogOpen}
            onClose={this.handleCloseSuccessDialog}
          />
        )}

        {this.state.isEditConfirmationDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isEditConfirmationDialogOpen}
            title="Chú ý"
            type="info"
            message="Chỉnh sửa đơn hàng sẽ làm ảnh hưởng đến khuyến mãi đã tính. Bạn sẽ cần phải chọn lại khuyến mãi sau khi chỉnh sửa. Bạn có chắc muốn chỉnh sửa đơn hàng?"
            dialogAction={
              <>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseEditConfirmationDialog}
                >
                  Không
                </Button>
                <Button
                  onClick={this.handleSubmitEditConfirmationDialog}
                  variant="contained"
                  color="primary"
                >
                  Sửa đơn hàng
                </Button>
              </>
            }
          />
        )}

        {this.state.isSkipPromotionConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isSkipPromotionConfirmDialogOpen}
            title="Thông báo"
            type="info"
            message="Bạn có chắc bỏ qua khuyến mãi không? Nếu bỏ qua khuyến mãi thì khi chỉnh sửa lại đơn hàng hoặc đặt đơn mới. khuyến mãi có thể thay đổi so với lúc này"
            dialogAction={
              <>
                <Button
                  variant="outlined"
                  onClick={this.handleCloseSkipPromotionConfirmDialog}
                >
                  Không
                </Button>
                <Button
                  onClick={this.handleSubmitSkipPromotionConfirmDialog}
                  variant="contained"
                  color="primary"
                >
                  Bỏ qua khuyến mãi
                </Button>
              </>
            }
          />
        )}

        {this.state.isPromotionDetailsDialogOpen && (
          <PromotionDetailsDialog
            promotionDetails={this.state.selectedPromotionDetails}
            isOpen={this.state.isPromotionDetailsDialogOpen}
            onClose={this.handleClosePromotionDetailsDialog}
          />
        )}

        {this.state.isCustomerDeliveryDialogOpen && (
          <CustomerDeliveryFormDialog
            isOpen={this.state.isCustomerDeliveryDialogOpen}
            onClose={this.handleCloseCustomerDeliveryDialog}
            onUpdatePRDraft={this._savePRDraft}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectFastOrderLoading(),
  user: makeSelectLoginUser(),
  selectedCustomerCode: makeSelectFastOrderSelectedCustomerCode(),
  prProducts: makeSelectFastOrderProducts(),
  prDetails: makeSelectFastOrderDetails(),
  prLogistic: makeSelectFastOrderLogistic(),
  prDelivery: makeSelectFastOrderDelivery(),
  promotionAvailables: makeSelectFastOrderPromotionAvailables(),
  promotionActives: makeSelectFastOrderPromotionActives(),
  usedPromotions: makeSelectFastOrderUsedPromotions(),
  prDiscounts: makeSelectFastOrderDiscounts(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  prNotes: makeSelectFastOrderNotes(),
  pharmacyDetails: makeSelectPharmacy(),
  promotionInactives: makeSelectFastOrderPromotionInactives(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseRequestDraft: (payload) => dispatch(getPurchaseRequestDraft(payload)),
    updatePurchaseRequestDraft: (payload) => dispatch(updatePurchaseRequestDraft(payload)),
    saveSelectedCustomerCode: (payload) => dispatch(saveSelectedCustomerCode(payload)),
    checkPromotion: (payload) => dispatch(checkPromotion(payload)),
    savePromotionAvailables: (payload) => dispatch(savePromotionAvailables(payload)),
    saveUsedPromotions: (payload) => dispatch(saveUsedPromotions(payload)),
    savePromotionActives: (payload) => dispatch(savePromotionActives(payload)),
    createPurchaseRequest: (payload) => dispatch(createPurchaseRequest(payload)),
    getPharmacy: (payload) => dispatch(getPharmacy(payload)),
    savePromotionInactives: (payload) => dispatch(savePromotionInactives(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(FastOrder);
