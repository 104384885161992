import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { createStructuredSelector } from "reselect";
import {
  makeSelectPharmacyList,
  makeSelectPharmacyListTotal,
  makeSelectPharmacyLoading,
} from 'redux/selectors';
import { getSortsString, getDisplayFields } from 'utils/helper';
import axios from 'axios';
import {
  saveCustomerDetails,
} from 'redux/actions/vendor/customerActions';
import { listParamsMap, listDisplayFields } from 'utils/constanst/ecomPharmacyConstants';
import { getPharmacyList } from 'redux/actions/ecom/pharmacyActions';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PharmacyList from 'components/ecom/PharmacyList/PharmacyList';
import Customer from '../../vendor/Customer/Customer';
import ListPagination from 'components/ListPagination/ListPagination';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import classes from './Pharmacies.module.scss';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
class Pharmacies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      open: false,
    }
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.loadCustomers({
      params: this.state.listParams,
    });
  }

  loadCustomers = ({ params }) => {
    this.source && this.source.cancel();
    this._loadPharmacyList({ params });
  }
  _loadPharmacyList = debounce(async ({ params }) => {
    this.source = axios.CancelToken.source();
    try {
      this.props.getPharmacyList({
        params: {
          ...params,
          mode: 'FASTORDER',
        },
        cancelToken: this.source.token,
      });
    } catch(err) {
    }
  }, 200);

  handleKeywordChange = (e) => {
    this.handleFilterChange([{ name: 'keyword', value: e.target.value }]);
  }
  handleClearKeyword = () => {
    this.handleFilterChange([{ name: 'keyword', value: '' }]);
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.loadCustomers({ params: newParams });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };

  handleClickOpen = () => {
    this.props.saveCustomerDetails(null);
    this.setState({
      open: !this.state.open,
    });
  }




  render() {
    const { pharmacyList, loading, pharmacyListTotal } = this.props;
    const { listParams,open,loadingCustomer } = this.state;
    return (<React.Fragment>
      <div className={classes.PageWrap}>
        <div className={classes.PageHeader}>
          <div className={classes.NavContent}>
          <Breadcrumbs
            separator={
              <NavigateNextIcon
                className="BreadscrumbSeparator"
                fontSize="small"
              />
            }
            className="Breadscrumbs"
            aria-label="breadcrumb"
          >
            <span className="BreadscrumbActive">Chọn Khách Hàng</span>
            <span>Đặt Hàng Nhanh</span>
            <span>Xác Nhận Đơn Hàng</span>
          </Breadcrumbs>
          <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.handleClickOpen}
            >Thêm mới khách hàng</Button>
          </div>
          <div className={classes.PageFilter}>
            <h1 className={classes.PageTitle}>Chọn khách hàng muốn tạo đơn</h1>
            <TextField
              value={listParams.keyword}
              autoComplete="off"
              name="keyword"
              variant="outlined"
              size="small"
              className={classes.SearchField}
              placeholder="Nhập mã khách hàng, tên khách hàng, điện thoại để tìm"
              onChange={this.handleKeywordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { !listParams.keyword ? <Search fontSize="small" /> : <IconButton size="small" onClick={this.handleClearKeyword}><ClearIcon fontSize="small" /></IconButton> }
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className={classes.PageMain}>
          <PharmacyList
            displayFields={getDisplayFields(
              listParams,
              listDisplayFields
            )}
            pharmacyList={pharmacyList}
            isLoading={loading}
            onSortChange={this.handleSortChange}
          />
        </div>
        <div className={classes.PageFooter}>
          <ListPagination
            page={listParams.page}
            pageSize={listParams.page_size}
            total={pharmacyListTotal}
            listName="khách hàng"
            onFilterChange={this.handleFilterChange}
          />
        </div>
      </div>

      <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle>Thêm mới khách hàng</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={this.handleClickOpen}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Customer wrappedComponentRef={this.myRef} createCustomer={true} disableClick={this.disableClick} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={this.handleClickOpen}>
          Huỷ
        </Button>
        <Button color="secondary"  disabled={loadingCustomer} variant="contained" onClick={() => {
          this.myRef.current.onSave()
          this.setState({ loadingCustomer: true });
          setTimeout(() => this.setState({ loadingCustomer: false }), 2000); 
        }
          }>
    
          {loadingCustomer && <CircularProgress size={24} />}
      {!loadingCustomer && 'Tạo khách hàng mới'}
        </Button>
      </DialogActions>
    </Dialog>
    </React.Fragment>);
  }
}

const mapStateToProps = createStructuredSelector({
  pharmacyList: makeSelectPharmacyList(),
  pharmacyListTotal: makeSelectPharmacyListTotal(),
  loading: makeSelectPharmacyLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPharmacyList: (payload) => dispatch(getPharmacyList(payload)),
    saveCustomerDetails: (payload) => dispatch(saveCustomerDetails(payload)),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Pharmacies);
