import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { put } from 'redux-saga/effects';
import { salesOrderActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadSalesOrderStatuses(payload) {
  try {
    yield put(salesOrderActions.saveSalesOrderStatuses([]));

    const { data: response } = yield salesOrderApi.getAllStatuses();

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      salesOrderActions.saveSalesOrderStatuses(response.data ? response.data : [])
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadSalesOrderList(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.getAll({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      salesOrderActions.saveSalesOrderList(response.data ? response.data : [])
    );
    yield put(salesOrderActions.saveSalesOrderListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadSalesOrder(payload) {
  try {
    yield put(salesOrderActions.saveLoading(true));
    yield put(salesOrderActions.saveSalesOrder(null));
    yield put(salesOrderActions.saveSalesOrderProducts([]));
    yield put(salesOrderActions.saveSalesOrderBonuses([]));
    yield put(salesOrderActions.saveSalesOrderDiscounts([]));
    yield put(salesOrderActions.saveSalesOrderNotes([]));
    yield put(salesOrderActions.saveSalesOrderLogistic(null));
    yield put(salesOrderActions.saveSalesOrderSalesUser(null));
    yield put(salesOrderActions.saveSalesOrderDelivery(null));

    const { data: response } = yield salesOrderApi.get(payload.id);

    yield put(salesOrderActions.saveLoading(false));

    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }

    yield put(
      salesOrderActions.saveSalesOrder(response.data ? response.data : null)
    );

    yield put(salesOrderActions.saveSalesOrder({
      ...response.data,
      getcare_order_items: undefined,
      getcare_order_bonuss: undefined,
      getcare_order_discounts: undefined,
      getcare_order_notes: undefined,
    }));
    yield put(salesOrderActions.saveSalesOrderProducts(response.data.getcare_order_items || []));
    yield put(salesOrderActions.saveSalesOrderBonuses(response.data.getcare_order_bonuss || []));
    yield put(salesOrderActions.saveSalesOrderDiscounts(response.data.getcare_order_discounts || []));
    yield put(salesOrderActions.saveSalesOrderNotes(response.data.getcare_order_notes || []));
    yield put(salesOrderActions.saveSalesOrderLogistic(response.data.logistic_provider || null));
    yield put(salesOrderActions.saveSalesOrderSalesUser({
      name: response.data.sales_name,
    }));
    yield put(salesOrderActions.saveSalesOrderDelivery(response.data.customer_delivery || null));

  } catch (err) {
    console.log(err);
  }
}
export function* updateSalesOrderLogisticProvider(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield salesOrderApi.updateLogisticProvider({ params });

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* updateSalesOrderDeliveryInfo(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield salesOrderApi.updateDeliveryInfo({ params });

    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* confirmSalesOrderPurchasing(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.confirmPurchasing({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* confirmSalesOrderDeliverySuccess(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.confirmDeliverySuccess({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* confirmSalesOrderSuccess(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.confirmSuccess({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}
export function* updateSalesOrderStatus(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.udpateStatus({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}


export function* loadSalesOrderHistory(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveSalesOrderHistory([]));

    const { data: response } = yield salesOrderApi.getSalesOrderHistory({
      params,
    });

    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      salesOrderActions.saveSalesOrderHistory(
        response.data ? [...response.data] : []
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* printSalesOrderDeliveryPaper(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.exportDeliveryPaper({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.setAttribute('target', `_blank`);
    anchor.click();
    anchor.remove();

    yield put(salesOrderActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}


export function* returnSalesOrderDelivery(payload) {
  const { params } = payload.payload;
  try {
    yield put(salesOrderActions.saveLoading(true));

    const { data: response } = yield salesOrderApi.returnDelivery({ params });

    yield put(salesOrderActions.saveLoading(false));
    if (!response?.result || !response.data) {
      toast.error(response.message);
      return;
    }
    yield put(salesOrderActions.saveSalesOrderAllInfo(response.data));
  } catch (err) {
    console.log(err);
  }
}