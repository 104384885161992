import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderProducts,
  makeSelectFastOrderDiscounts,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';
import {
  prProductsDisplayFields,
  prProductsParamsMap,
} from 'utils/constanst/ecomFastOrderConstants';
import { removePRDraftProductItems } from 'redux/actions/ecom/fastOrderActions';

import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FastOrderProduct from './FastOrderProduct/FastOrderProduct';
import FastOrderDiscount from './FastOrderDiscount/FastOrderDiscount';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import FastOrderProductsToolbar from './FastOrderProductsToolbar/FastOrderProductsToolbar';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './FastOrderProducts.module.scss';
import gridClasses from './FastOrderProductsGrid.module.scss';

class FastOrderProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isClearCartConfirmDialogOpen: false,
      isClearCartLoading: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { isClearCartLoading, isClearCartConfirmDialogOpen } = this.state;
    const { prProducts } = this.props;
    // after clear cart successful
    if (isClearCartConfirmDialogOpen && isClearCartLoading && prProducts.length === 0 && (prevProps.prProducts.length !== prProducts.length)) {
      this.setState({
        isClearCartLoading: false,
        isClearCartConfirmDialogOpen: false,
      });
    }
  }

  handleClearCart = () => {
    this.setState({
      isClearCartLoading: true,
    }, () => {
      this.props.removePRDraftProductItems(this.props.prProducts.map(product => ({
        id: product.id,
        idStr: product.idStr,
      })));
    });
  }

  handleConfirmDialogOpen = () => {
    this.setState({
      isClearCartConfirmDialogOpen: true,
    });
  }
  handleConfirmDialogClose = () => {
    this.setState({
      isClearCartConfirmDialogOpen: false,
    });
  }

  render() {
    const { prProducts, isEditing, prDiscounts } = this.props;
    const { isClearCartConfirmDialogOpen, isClearCartLoading } = this.state;
    const displayFields = getDisplayFields(
      prProductsParamsMap,
      prProductsDisplayFields
    );

    return (
      <section className={classes.Wrap}>
        <div className={classes.HeaderToolbar}>
          {isEditing ? (<>
            <h3 className="SectionTitle">Danh sách sản phẩm đặt hàng</h3>
            { prProducts.length > 0 &&
              <Button
                size="small"
                color="secondary"
                endIcon={<DeleteOutline fontSize="small" />}
                onClick={this.handleConfirmDialogOpen}
              >
                Xoá đơn hàng
              </Button>
            }
          </>) : (<>
            <h3 className="SectionTitle">Danh sách sản phẩm</h3>
            <Button
              size="small"
              color="secondary"
              endIcon={<Edit fontSize="small" />}
              onClick={this.props.onEditFastOrderProducts}
            >
              Sửa đơn hàng
            </Button>
          </>)}
        </div>

        {isEditing && (
          <div className={classes.Toolbar}>
            <FastOrderProductsToolbar selectedVendors={[]} />
          </div>
        )}
        <div className={`${classes.List}`}>
          <div
            className={`${gridClasses.Row} ${
              isEditing ? gridClasses.IsEditing : ''
            } ${gridClasses.Header}`}
          >
            {displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                className={`${gridClasses.Col} ${item.classes}`}
                {...item}
              />
            ))}
            <ListHeaderCol className={`${gridClasses.Col}`} label="" />
          </div>
          <div className={`${classes.Body}`}>
            {prProducts.length ? (
              <>
                {prProducts.map((item, index) => (
                  <FastOrderProduct
                    key={`pr-product-${item.idStr || index + 1}`}
                    {...item}
                    isEditing={isEditing}
                    index={index}
                  />
                ))}
                {!isEditing &&
                  prDiscounts.map((item, index) => (
                    <FastOrderDiscount
                      key={`pr-discount-${item.idStr || index + 1}`}
                      {...item}
                      index={index}
                    />
                  ))}
              </>
            ) : (
              <div className={classes.EmptyBox}>
                <img
                  src={`${process.env.REACT_APP_PATH}static/icons/empty-box2.svg`}
                  width="80"
                  alt="Empty box"
                />
                <p className="NoData">Chưa có sản phẩm</p>
              </div>
            )}
          </div>
        </div>

        { isClearCartConfirmDialogOpen
          && <ConfirmationDialog
            isOpen={isClearCartConfirmDialogOpen}
            isLoading={isClearCartLoading}
            title="Xác nhận xoá đơn hàng"
            message="Bạn có chắc xoá toàn bộ sản phẩm trong đơn hàng?"
            onClose={this.handleConfirmDialogClose}
            onSubmit={this.handleClearCart}
          />
        }
      </section>
    );
  }
}

FastOrderProducts.propTypes = {
  prProducts: PropTypes.array,
  prDiscounts: PropTypes.array,
  isEditing: PropTypes.bool,
  step: PropTypes.number,
  onEditFastOrderProducts: PropTypes.func,
};

FastOrderProducts.defaultProps = {
  prProducts: [],
  prDiscounts: [],
  isEditing: false,
};

const mapStateToProps = createStructuredSelector({
  prProducts: makeSelectFastOrderProducts(),
  prDiscounts: makeSelectFastOrderDiscounts(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    removePRDraftProductItems: (payload) => dispatch(removePRDraftProductItems(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(FastOrderProducts);
