import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionProducts } from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionProductsDisplayFields,
  promotionProductsParamsMap,
  promotionProductsComboDisplayFields,
  PROMOTION_TYPE_COMBO,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionProduct from './GetcarePromotionProduct/GetcarePromotionProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionProductsGrid.module.scss';
import classes from './GetcarePromotionProducts.module.scss';

class GetcarePromotionProducts extends PureComponent {
  render() {
    const { promotionProducts, promotionTypeId } = this.props;
    const displayFields = getDisplayFields(
      promotionProductsParamsMap,
      [PROMOTION_TYPE_COMBO].includes(promotionTypeId)
        ? promotionProductsComboDisplayFields
        : promotionProductsDisplayFields,
    );

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${item.className}`}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {promotionProducts.length ? (
            promotionProducts.map((item) => (
              <GetcarePromotionProduct
                key={`promotion-product-${item.id || item.idStr}`}
                {...item}
                promotionTypeId={promotionTypeId}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionProducts.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionProducts.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionProducts: makeSelectPromotionProducts(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionProducts);
