import {
  GET_VENDOR_DETAILS,
  GET_VENDOR_LIST,
  SAVE_VENDOR_DETAILS,
  SAVE_VENDOR_LIST,
  SAVE_VENDOR_LIST_TOTAL,
  SAVE_VENDOR_LIST_LOADING,
  UPDATE_VENDOR,
} from 'redux/constants/vendor/vendorConstants';

export function getVendorDetails(id) {
  return {
    type: GET_VENDOR_DETAILS,
    id
  };
}
export function getVendorList(payload) {
  return {
    type: GET_VENDOR_LIST,
    payload
  };
}
export function updateVendor(payload) {
  return {
    type: UPDATE_VENDOR,
    payload
  };
}

// mutation
export function saveVendor(vendorDetails) {
  return {
    type: SAVE_VENDOR_DETAILS,
    vendorDetails
  };
}
export function saveVendorList(vendorList) {
  return {
    type: SAVE_VENDOR_LIST,
    vendorList
  };
}
export function saveVendorListLoading(isVendorListLoading) {
  return {
    type: SAVE_VENDOR_LIST_LOADING,
    isVendorListLoading
  };
}
export function saveVendorListTotal(vendorListTotal) {
  return {
    type: SAVE_VENDOR_LIST_TOTAL,
    vendorListTotal
  };
}
