import LayoutAdmin from 'layouts/LayoutAdmin/LayoutAdmin';
import VendorProducts from 'views/vendor/VendorProducts/VendorProducts';
import SalesOrders from 'views/oms/SalesOrders/SalesOrders';
import SalesOrder from 'views/oms/SalesOrder/SalesOrder';
import Pharmacies from 'views/ecom/Pharmacies/Pharmacies';
import FastOrder from 'views/ecom/FastOrder/FastOrder';
import Promotion from 'views/tmk/Promotion/Promotion';
import DuplicatePromotion from 'views/tmk/DuplicatePromotion/DuplicatePromotion';
import Promotions from 'views/tmk/Promotions/Promotions';
import Customer from 'views/vendor/Customer/Customer';
import Customers from 'views/vendor/Customers/Customers';
import ProductBonuses from 'views/vendor/ProductBonuses/ProductBonuses';
import MyProfile from 'views/ecom/MyProfile/MyProfile';
import PolicyPrices from 'views/vendor/PolicyPrices/PolicyPrices';
import PolicyPrice from 'views/vendor/PolicyPrice/PolicyPrice';

import PermissionDenied from 'views/PermissionDenied/PermissionDenied';
import PageNotFound from 'views/PageNotFound/PageNotFound';

export const routes = [
  {
    path: '/',
    component: LayoutAdmin,
    routes: [
      {
        path: '/products',
        component: VendorProducts,
        exact: true,
      },
      {
        path: '/so',
        component: SalesOrders,
        exact: true,
      },
      {
        path: '/so/:id',
        component: SalesOrder,
        exact: true,
      },
      {
        path: '/fastorder',
        component: FastOrder,
        exact: true,
      },
      {
        path: '/store',
        component: Pharmacies,
        exact: true,
      },
      {
        path: '/promotion',
        component: Promotions,
        exact: true,
      },
      {
        path: '/dup/:id',
        component: DuplicatePromotion,
        exact: true,
      },
      {
        path: '/promotion/:id',
        component: Promotion,
        exact: true,
      },
      {
        path: '/customer',
        component: Customers,
        exact: true,
      },
      {
        path: '/customer/:id',
        component: Customer,
        exact: true,
      },
      {
        path: '/product_bonus',
        component: ProductBonuses,
        exact: true,
      },
      {
        path: '/myprofile/changepassword',
        component: MyProfile,
        exact: true,
      },
      {
        path: '/policy',
        component: PolicyPrices,
        exact: true,
        userComponent: 'price',
      },
      {
        path: '/policy/:id',
        component: PolicyPrice,
        exact: true,
        userComponent: 'price',
      },
      {
        path: '/403',
        component: PermissionDenied,
        exact: true,
      },
      {
        path: '/*',
        component: PageNotFound,
      },
    ],
  },
];
