import axiosAuth from 'utils/axios/axiosAuth';

const customerApi = {
  getList: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer/`, {
      params: queryParams,
      cancelToken,
    });
  },
  get: ({ id, params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer/${id}`, {
      params: queryParams,
    });
  },
  
  postAddress: ({ params }) => {
    return axiosAuth.post(`getcare_customer_delivery`, params);
  },
  getAddressBook: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_customer_delivery/`, {
      params: queryParams,
    });
  },

  updateCustomer: ({ id, params }) => {
    return axiosAuth.put(`getcare_customer/${id}`, params);
  },

  createCustomer: ({ params }) => {
    return axiosAuth.post(`getcare_customer/`, params);
  },

  getCustomerTypes: () => {
    return axiosAuth.get(`getcare_customer_type/`);
  },

  exportCustomerTemplate: () => {
    return axiosAuth.post(`export/getcare_customer_template/`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  },

  importCustomers: ({ params }) => {
    const newParams = { ...params };
    const formData = new FormData();
    Object.keys(newParams).forEach((key) => {
      if (newParams[key]) formData.append(key, newParams[key]);
    })

    return axiosAuth.post(`import/getcare_customer`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

};

export default customerApi;
