import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import classes from './PromotionLevels.module.scss';
import PromotionLevel from './PromotionLevel/PromotionLevel';

class PromotionLevels extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this._initValidMap(),
    };
  }

  _initValidMap = () => {
    return this.props.promotionLevels.reduce((memo, item) => {
      memo[`is_valid_${item.id}`] = false;
      return memo;
    }, {});
  };
  _isFormValid = () => {
    return !Object.keys(this.state).some((key) => {
      return key.match(/^is_valid_\d+$/g) && !this.state[key];
    });
  };
  handleLevelValidChange = ({ isValid, usedPromotionItem }) => {
    this.setState({
      [`is_valid_${usedPromotionItem.id}`]: isValid,
    });
  };
  handleCancelSelectedPromotion = ({ willRemoveUsedPromotions }) => {
    this.props.onCancelSelectedPromotion({ willRemoveUsedPromotions });
  };

  render() {
    const {
      promotionLevels,
      promotionId,
      promotionDetails,
      isReselecting,
    } = this.props;
    const isFormValid = this._isFormValid();

    return (
      <div className={`${classes.Wrap}`}>
        <h4
          className={classes.PromotionName}
          onClick={(e) => {
            this.props.onPromotionLinkClick(promotionDetails);
          }}
        >
          {promotionDetails?.name}
        </h4>
        {promotionDetails?.vendor_name ? (
          <p>{promotionDetails.vendor_name}</p>
        ) : (
          ``
        )}
        <div className={`${classes.List}`}>
          {promotionLevels.map((levelItem) => (
            <PromotionLevel
              key={`level-${levelItem.id}`}
              {...levelItem}
              promotionId={promotionId}
              onLevelValidChange={this.handleLevelValidChange}
            />
          ))}
        </div>
        <div className={classes.Actions}>
          {!isReselecting && (
            <Button
              variant="outlined"
              onClick={() => {
                this.handleCancelSelectedPromotion({
                  willRemoveUsedPromotions: true,
                });
              }}
            >
              Quay lại
            </Button>
          )}
          {isReselecting && (
            <Button
              variant="outlined"
              onClick={() => {
                this.handleCancelSelectedPromotion({
                  willRemoveUsedPromotions: false,
                });
              }}
            >
              Huỷ
            </Button>
          )}
          <Button
            disabled={!isFormValid}
            variant="contained"
            color="primary"
            onClick={this.props.onSubmitPromotion}
          >
            Xác nhận
          </Button>
        </div>
      </div>
    );
  }
}

PromotionLevels.propTypes = {
  isReselecting: PropTypes.bool,
  promotionDetails: PropTypes.object,
  promotionId: PropTypes.number,
  promotionLevels: PropTypes.array,
  onSubmitPromotion: PropTypes.func,
  onCancelSelectedPromotion: PropTypes.func,
  onPromotionLinkClick: PropTypes.func,
};

PromotionLevels.defaultProps = {
  isReselecting: false,
};

export default PromotionLevels;
