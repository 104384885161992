import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  GROUP_TYPE_ID,
  PROMOTION_TYPE_COMBO,
} from 'utils/constanst/tmkPromotionConstants';
import vendorProductApi from 'utils/api/mdm/vendorProductApi';
import uomApi from 'utils/api/mdm/uomApi';
import { isEqual, debounce } from 'lodash';
import { validNumber } from 'utils/helper';
import { suggestionPageSize2 } from 'utils/constanst/common';

import Checkbox from '@material-ui/core/Checkbox';
import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './PromotionProduct.module.scss';
import gridClasses from '../PromotionProductsGrid.module.scss';

class PromotionProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      unitOptions: undefined,
      groupProductOptions: this._initGroupProductOptions(props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    };
  }
  async componentDidMount() {
    const units = await this._initUnitOptions(this.props);
    this.setState({
      unitOptions: units,
    });
  }
  async componentDidUpdate(prevProps, prevState) {
    const { groupProductCodeInput, groupProductNameInput, params, unitOptions, isEditing } = this.state;
    if (groupProductCodeInput && groupProductCodeInput !== prevState.groupProductCodeInput) {
      this._loadSuggestedProducts({
        params: {
          product_vendor_code: groupProductCodeInput,
        },
      });
    }
    if (groupProductNameInput && groupProductNameInput !== prevState.groupProductNameInput) {
      this._loadSuggestedProducts({
        params: {
          product_vendor_name: groupProductNameInput,
        },
      });
    }
    if (params.getcare_product && params.getcare_product.product_vendor_code && !isEqual(prevState.params.getcare_product, params.getcare_product)) {
      const newUnitOptions = await this._loadSuggestedUnits({
        params: {
          product_vendor_code: params.getcare_product.product_vendor_code,
        },
      });
      this.setState({
        unitOptions: newUnitOptions,
      });
    }
    if (unitOptions && unitOptions.length && prevState.unitOptions && unitOptions !== prevState.unitOptions && this.state.isEditing) {
      // set default the unit if this row is in edit mode AND the selected product changed (-> make unitOptions changed)
      this.handleSaveField({target: {name: 'getcare_uom_base'}}, unitOptions[0]);
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionProduct({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
      });
    }
  }
  _initUnitOptions = async (params) => {
    if (!params.getcare_product?.product_vendor_code) {
      return [];
    }
    const unitOptions = await this._loadSuggestedUnits({
      params: {
        product_vendor_code: params.getcare_product.product_vendor_code,
      },
    });
    return unitOptions;
  }
  _initGroupProductOptions = (params) => {
    return params.getcare_product
      ? [{...params.getcare_product}]
      : (params.getcare_erp_group ? [{...params.getcare_erp_group}] : []);
  }
  _loadSuggestedProducts = debounce(async ({ params }) => {
    const { data: response } = await vendorProductApi.getProductSuggestions({
      params: {
        ...params,
        page_size: suggestionPageSize2,
        type_id: this.state.params.type_id,
      },
    });
    if (!response?.result) return;
    this.setState({
      groupProductOptions: response.data
        ? this._filteredOptions(
            this.state.params.type_id === GROUP_TYPE_ID
              ? (response.data.erp_groups ? [...response.data.erp_groups] : [])
              : (response.data ? [...response.data] : [])
          )
        : [],
    });
  }, 500);
  _filteredOptions = (fullArr) => {
    const { params } = this.state;
    return fullArr.filter((p) => {
      return params.type_id === GROUP_TYPE_ID ? (
        (p.code === this.props.getcare_erp_group?.code) ||
        !this.props.currentList.some(
          (item) => item.getcare_erp_group?.code === p.code
        )
      ) : (
        (p.product_vendor_code === this.props.getcare_product?.product_vendor_code) ||
        !this.props.currentList.some(
          (item) => item.getcare_product?.product_vendor_code === p.product_vendor_code
        )
      );
    });
  };
  _loadSuggestedUnits = async ({ params }) => {
    const { data: response } = await uomApi.getAllUomBase({ params });
    if (!response?.result || !response?.data) return [];
    return response.data;
  };
  _getProductOptionLabel = (option) => {
    return `${option.code || option.product_vendor_code} - ${option.product_vendor_name}`
  }
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      type_id: params?.type_id,
      getcare_product: params.getcare_product ? {...params.getcare_product} : null,
      getcare_erp_group: params.getcare_erp_group ? {...params.getcare_erp_group} : null,
      getcare_uom_base: params.getcare_uom_base ? {...params.getcare_uom_base} : null,
      focus: params.focus,
      quantity_number: params.quantity_number,
      ratio: params.ratio,
    }
  }
  _resetState = async () => {
    const units = await this._initUnitOptions(this.props);
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      unitOptions: units,
      groupProductOptions: this._initGroupProductOptions(this.props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        type_id: typeIdValue,
        getcare_product: null,
        getcare_erp_group: null,
        getcare_uom_base: null,
        focus: 2,
        quantity_number: null,
        ratio: typeIdValue === GROUP_TYPE_ID ? null : 1,
      }),
      unitOptions: [],
      groupProductOptions: [],
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _isGroupType = (params) => {
    return params.type_id === GROUP_TYPE_ID;
  }
  _hasProduct = (params) => {
    return (!!params.getcare_product && !!params.getcare_product.product_vendor_code)
      || (!!params.getcare_erp_group && !!params.getcare_erp_group.id);
  }
  _hasUnit = (params) => {
    return !!params.getcare_uom_base && !!params.getcare_uom_base.id;
  }
  _hasQuantityNumber = (params) => {
    return !!params.quantity_number && Number(params.quantity_number) > 0;
  }
  _isProductFocus = (params) => {
    return !!params.focus && params.focus === 1;
  }
  _isRowValid = (params) => {
    return this._hasProduct(params)
      && (this._isGroupType(params) || (this._hasUnit(params) && (!this._isProductFocus(params) || this._hasQuantityNumber(params)))
    );
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
      },
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }
  handleSelectGroupChange = (e) => {
    this._resetFormAfterChangeTypeId(e.target.value);
  }
  handleSelectedProductChange = (e, newValue) => {
    const { params } = this.state;
    this.setState({
      params: {
        ...this.state.params,
        getcare_erp_group: params.type_id === GROUP_TYPE_ID ? newValue : null,
        getcare_product: params.type_id === GROUP_TYPE_ID ? null : newValue,
        getcare_uom_base: null,
        focus: 2,
        quantity_number: null,
        ratio: params.type_id === GROUP_TYPE_ID ? null : 1,
      },
    });
  }
  handleDiscountCheckboxChange = (e) => {
    this.setState({
      params: {
        ...this.state.params,
        focus: e.target.checked ? 1 : 2,
        quantity_number: e.target.checked ? this.state.params.quantity_number : null,
      },
    });
  }

  render() {
    const {
      getcare_product,
      getcare_erp_group,
      focus,
      getcare_uom_base,
      promotionTypeId,
    } = this.props;
    const {
      isEditing,
      params,
      groupProductOptions,
      unitOptions,
    } = this.state;
    const isGroupType = this._isGroupType(params);
    const isProductFocus = this._isProductFocus(params);

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group : params.getcare_product}
                onChange={this.handleSelectedProductChange}
                options={groupProductOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => params.type_id === GROUP_TYPE_ID ? ((option && option.code) || '') : ((option && option.product_vendor_code) || '')}
                getOptionSelected={(option, value) =>
                  value && value.product_vendor_code && option ? option.product_vendor_code === value.product_vendor_code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupProductCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              isGroupType ? getcare_erp_group?.code : getcare_product?.product_vendor_code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.type_id === GROUP_TYPE_ID ? params.getcare_erp_group : params.getcare_product}
                onChange={this.handleSelectedProductChange}
                options={groupProductOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => params.type_id === GROUP_TYPE_ID ? ((option && option.name) || '') : ((option && option.product_vendor_name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.code && option ? option.code === value.code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupProductNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              isGroupType ? getcare_erp_group?.name : getcare_product?.product_vendor_name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing && !isGroupType ? (
              <Autocomplete
                disabled={isGroupType}
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                handleHomeEndKeys={false}
                value={params.getcare_uom_base}
                onChange={(e, newValue) => this.handleSaveField({target: {name: 'getcare_uom_base'}}, newValue)}
                options={unitOptions || []}
                filterOptions={(x) => x}
                renderOption={(option) => `${option.name}`}
                getOptionLabel={(option) => (option && option.name) || ''}
                getOptionSelected={(option, value) =>
                  value && value.name && option ? option.name === value.name : null
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              isGroupType ? '' : getcare_uom_base?.name
            )}
          </div>

          { [PROMOTION_TYPE_COMBO].includes(promotionTypeId) && (<>
            <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
              {isEditing && !isGroupType ? (
                <Checkbox
                  disabled={isGroupType}
                  size="small"
                  name="focus"
                  checked={params.focus === 1}
                  onChange={this.handleDiscountCheckboxChange}
                />
              ) : (
                isGroupType ? '' : (focus === 1 ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>)
              )}
            </div>
            <div className={`${gridClasses.Col}`}>
              {isEditing  && !isGroupType && isProductFocus ? (
                <TextField
                  disabled={isGroupType || !isProductFocus }
                  className={classes.Field}
                  value={params.quantity_number || ''}
                  placeholder="Nhập..."
                  name="quantity_number"
                  autoComplete="off"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  error={!this._hasQuantityNumber(params)}
                  onChange={(e) => {
                    this.handleSaveField(e, e.target.floatValue);
                  }}
                />
              ) : (
                isGroupType || !params.quantity_number ? '' : `${params.quantity_number}`
              )}
            </div>
          </>)}

          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionProduct.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  savePromotionProduct: PropTypes.func,
  removePromotionProduct: PropTypes.func,
};

PromotionProduct.defaultProps = {
};

export default PromotionProduct;

