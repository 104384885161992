import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionHistory } from 'redux/selectors';
import { getPromotionHistory } from 'redux/actions/tmk/promotionActions';

import Panel from 'components/Panel/Panel';

import classes from './PromotionHistory.module.scss';

class PromotionHistory extends PureComponent {
  componentDidMount() {
    this._loadData();
  }
  componentDidUpdate(prevProps) {
    const { promotionId } = this.props;
    if (promotionId && promotionId !== prevProps.promotionId) {
      this._loadData();
    }
  }
  _loadData = () => {
    if (this.props.promotionId) {
      this.props.getPromotionHistory({
        params: { getcare_promotion_id: this.props.promotionId },
      });
    }
  };

  render() {
    const { promotionHistory } = this.props;

    return (
      <Panel
        title="Lịch sử chương trình khuyến mãi"
        panelClassName={classes.Panel}
        content={<div className={`${classes.Wrap}`}>
          <div className={`${classes.Inner}`}>
            <ul className={classes.EventList}>
              {promotionHistory.map((event) => (
                <li key={`event-${event.id}`} className={classes.EventItem}>
                  <span className={classes.Bullet}></span>
                  <div className={classes.EventDetails}>
                    <p className={classes.EventTime}>
                      {validDate(event.date)
                        ? format(validDate(event.date), dateTimeFormat)
                        : ''}
                    </p>
                    <p
                      className={classes.EventTitle}
                    >{`${event.name}: ${event.action}`}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>}
        isBorder
      />
    );
  }
}

PromotionHistory.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PromotionHistory.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  promotionHistory: makeSelectPromotionHistory(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionHistory: (payload) =>
      dispatch(getPromotionHistory(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionHistory);
