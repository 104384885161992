import vendorApi from 'utils/api/mdm/vendorApi';
import { put } from 'redux-saga/effects';
import { vendorActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadVendorDetails(payload) {
  try {
    yield put(vendorActions.saveVendor(null));

    const { data: response } = yield vendorApi.get(payload.id);

    if (!response?.result) {
      yield put(vendorActions.saveVendor({}));
      return;
    }
    yield put(vendorActions.saveVendor(response.data ? {...response.data} : {}));
  } catch (err) {
    console.log(err);
  }
}

export function* loadVendorList(payload) {
  const { params } = payload.payload;
  try {
    yield put(vendorActions.saveVendorListLoading(true));

    const { data: response } = yield vendorApi.getAll({ params });

    if (!response?.result) {
      yield put(vendorActions.saveVendorList([]));
      yield put(vendorActions.saveVendorListTotal(0));
      yield put(vendorActions.saveVendorListLoading(false));
      return;
    }
    yield put(vendorActions.saveVendorList(response.data ? [ ...response.data] : []));
    yield put(vendorActions.saveVendorListTotal(response.total_records));
    yield put(vendorActions.saveVendorListLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* putVendor(payload) {
  const { id, params } = payload.payload;
  try {
    const { data: response } = yield vendorApi.updateVendor({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(vendorActions.saveVendor(response.data ? {...response.data} : {}));
  } catch (err) {
    console.log(err);
  }
}
