import {
  GET_GROUP_LIST,
  SAVE_GROUP_LIST,
  SAVE_GROUP_LIST_TOTAL,
  SAVE_LOADING,
  GET_GROUP,
  SAVE_GROUP,
  RESET_GROUP,
  UPDATE_GROUP,
  SAVE_GROUP_PRODUCTS,
  UPDATE_GROUP_PRODUCT,
  SAVE_GROUP_PRODUCT,
  DELETE_GROUP_PRODUCTS,
  REMOVE_GROUP_PRODUCTS,
  EXPORT_GROUP_PRODUCTS,
  UPDATE_GROUP_VENDOR,
  DELETE_GROUP_VENDORS,
  EXPORT_GROUP_VENDORS,
  SAVE_GROUP_VENDORS,
  SAVE_GROUP_VENDOR,
  REMOVE_GROUP_VENDORS,
  GET_GROUP_CUSTOMERS,
  UPDATE_GROUP_CUSTOMER,
  DELETE_GROUP_CUSTOMERS,
  EXPORT_GROUP_CUSTOMERS,
  GET_GROUP_CUSTOMER_MAPPINGS,
  UPDATE_GROUP_CUSTOMER_MAPPING,
  DELETE_GROUP_CUSTOMER_MAPPINGS,
  SAVE_GROUP_CUSTOMER,
  SAVE_GROUP_CUSTOMERS,
  SAVE_GROUP_CUSTOMERS_TOTAL,
  REMOVE_GROUP_CUSTOMERS,
  SAVE_GROUP_CUSTOMER_MAPPINGS,
  SAVE_GROUP_CUSTOMER_MAPPING,
  REMOVE_GROUP_CUSTOMER_MAPPINGS,
  GET_GROUP_CUSTOMERS_BY_MAPPINGS,
  GET_CUSTOMERS_TYPES,
  SAVE_CUSTOMERS_TYPES,
} from 'redux/constants/mdm/groupConstants';

export function getGroupList(payload) {
  return {
    type: GET_GROUP_LIST,
    payload,
  };
}
export function getGroup(id) {
  return {
    type: GET_GROUP,
    id,
  };
}
export function updateGroup(payload) {
  return {
    type: UPDATE_GROUP,
    payload,
  };
}

export function updateGroupProduct(payload) {
  return {
    type: UPDATE_GROUP_PRODUCT,
    payload,
  };
}
export function deleteGroupProducts(payload) {
  return {
    type: DELETE_GROUP_PRODUCTS,
    payload,
  };
}
export function exportGroupProducts(payload) {
  return {
    type: EXPORT_GROUP_PRODUCTS,
    payload,
  };
}

export function updateGroupVendor(payload) {
  return {
    type: UPDATE_GROUP_VENDOR,
    payload,
  };
}
export function deleteGroupVendors(payload) {
  return {
    type: DELETE_GROUP_VENDORS,
    payload,
  };
}
export function exportGroupVendors(payload) {
  return {
    type: EXPORT_GROUP_VENDORS,
    payload,
  };
}

export function getGroupCustomers(payload) {
  return {
    type: GET_GROUP_CUSTOMERS,
    payload,
  };
}
export function updateGroupCustomer(payload) {
  return {
    type: UPDATE_GROUP_CUSTOMER,
    payload,
  };
}
export function deleteGroupCustomers(payload) {
  return {
    type: DELETE_GROUP_CUSTOMERS,
    payload,
  };
}
export function exportGroupCustomers(payload) {
  return {
    type: EXPORT_GROUP_CUSTOMERS,
    payload,
  };
}

export function getGroupCustomerMappings(payload) {
  return {
    type: GET_GROUP_CUSTOMER_MAPPINGS,
    payload,
  };
}
export function getGroupCustomersByMappings(payload) {
  return {
    type: GET_GROUP_CUSTOMERS_BY_MAPPINGS,
    payload,
  };
}
export function updateGroupCustomerMapping(payload) {
  return {
    type: UPDATE_GROUP_CUSTOMER_MAPPING,
    payload,
  };
}
export function deleteGroupCustomerMappings(payload) {
  return {
    type: DELETE_GROUP_CUSTOMER_MAPPINGS,
    payload,
  };
}

export function getCustomerTypes(payload) {
  return {
    type: GET_CUSTOMERS_TYPES,
    payload,
  };
}

// mutation
export function saveActionLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveGroupList(groupList) {
  return {
    type: SAVE_GROUP_LIST,
    groupList,
  };
}
export function saveGroupListTotal(groupTotal) {
  return {
    type: SAVE_GROUP_LIST_TOTAL,
    groupTotal,
  };
}
export function saveGroup(groupInfo) {
  return {
    type: SAVE_GROUP,
    groupInfo,
  };
}
export function resetGroup() {
  return {
    type: RESET_GROUP,
  };
}

export function saveGroupProducts(groupProducts) {
  return {
    type: SAVE_GROUP_PRODUCTS,
    groupProducts,
  };
}
export function saveGroupProduct(groupProduct) {
  return {
    type: SAVE_GROUP_PRODUCT,
    groupProduct,
  };
}
export function removeGroupProducts(groupProducts) {
  return {
    type: REMOVE_GROUP_PRODUCTS,
    groupProducts,
  };
}

export function saveGroupVendors(groupVendors) {
  return {
    type: SAVE_GROUP_VENDORS,
    groupVendors,
  };
}
export function saveGroupVendor(groupVendor) {
  return {
    type: SAVE_GROUP_VENDOR,
    groupVendor,
  };
}
export function removeGroupVendors(groupVendors) {
  return {
    type: REMOVE_GROUP_VENDORS,
    groupVendors,
  };
}

export function saveGroupCustomers(groupCustomers) {
  return {
    type: SAVE_GROUP_CUSTOMERS,
    groupCustomers,
  };
}
export function saveGroupCustomersTotal(groupCustomersTotal) {
  return {
    type: SAVE_GROUP_CUSTOMERS_TOTAL,
    groupCustomersTotal,
  };
}
export function saveGroupCustomer(groupCustomer) {
  return {
    type: SAVE_GROUP_CUSTOMER,
    groupCustomer,
  };
}
export function removeGroupCustomers(groupCustomers) {
  return {
    type: REMOVE_GROUP_CUSTOMERS,
    groupCustomers,
  };
}

export function saveGroupCustomerMappings(groupCustomerMappings) {
  return {
    type: SAVE_GROUP_CUSTOMER_MAPPINGS,
    groupCustomerMappings,
  };
}
export function saveGroupCustomerMapping(groupCustomerMapping) {
  return {
    type: SAVE_GROUP_CUSTOMER_MAPPING,
    groupCustomerMapping,
  };
}
export function removeGroupCustomerMappings(groupCustomerMappings) {
  return {
    type: REMOVE_GROUP_CUSTOMER_MAPPINGS,
    groupCustomerMappings,
  };
}

export function saveCustomerTypes(customerTypes) {
  return {
    type: SAVE_CUSTOMERS_TYPES,
    customerTypes,
  };
}
