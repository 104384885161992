import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN } from 'utils/helper';

import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QuantityInput from 'components/QuantityInput/QuantityInput';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import classes from './FastOrderSelectedProduct.module.scss';
import productsGridClasses from '../FastOrderProductsToolbarGrid.module.scss';

class FastOrderSelectedProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quantityNumber: 1,
    }
  }
  _isOutOfStock = (remainingQty) => {
    return ![undefined, null].includes(remainingQty) && remainingQty <= 0;
  }

  handleQuantityInputChange = (e) => {
    this.setState({
      quantityNumber: e.target.value,
    });
  }
  handleAddProduct = (e) => {
    const { product } = this.props;
    const defaultVendor = (product?.getcare_vendors && product.getcare_vendors[0]) || null;
    const defaultUom = (defaultVendor?.uom && defaultVendor?.uom[0]) || null;
    this.props.onAddProductToCart({
      product: { ...product },
      vendor: defaultVendor ? { ...defaultVendor } : null,
      uom: defaultUom ? { ...defaultUom } : null,
      quantity: this.state.quantityNumber,
    });
  }

  render() {
    const { product } = this.props;
    const defaultVendor = (product.getcare_vendors && product.getcare_vendors.length && product.getcare_vendors[0]) || null;
    const defaultUom = defaultVendor?.uom && defaultVendor.uom.length && defaultVendor.uom[0];
    const typeLabel = defaultUom?.type_label;
    const isOutOfStock = this._isOutOfStock(defaultUom?.remaining_quantity);
    const priceSalesTotal = defaultUom?.price;

    return (<div className={`${productsGridClasses.ProductListRow} ${classes.ProductLine} ${isOutOfStock ? classes.ProductLineDisabled : ''}`}>
      <div
        onClick={isOutOfStock ? undefined : this.handleAddProduct}
        style={{cursor: isOutOfStock ? 'default' : 'pointer'}}
        className={`${productsGridClasses.ProductListCol} ${classes.ProductInfo}`}
      >
        <div className={classes.Thumb}>
          <img alt={product.thumbnail} src={`${product.thumbnail}?size=120`}/>
        </div>
        <div className={classes.Info}>
          <p>{product.name}</p>
          <p className={classes.SideInfo}>{product.getcare_manufacturer?.name} {`(${product.getcare_manufacturer?.getcare_country?.name})`}</p>
          { product.getcare_uom?.name &&
            <p className={classes.SideInfo}>
              <span className={classes.InfoValue}>{product.getcare_uom.name}</span>
            </p>
          }
        </div>
      </div>
      <div className={`${productsGridClasses.ProductListCol} ${classes.PriceWrap} TextCenter`}>
        {![null, undefined, ''].includes(priceSalesTotal)
          ? `${currencyFormatVN(priceSalesTotal)}/${defaultUom?.uom_base_name}`
          : `-`
        }
      </div>
      <div className={`${productsGridClasses.ProductListCol}`}>
        { typeLabel || `-` }
      </div>
      <div className={`${productsGridClasses.ProductListCol} ${classes.QuantityWrap}`}>
        <div className={classes.QuantityWrapInner}>
          <QuantityInput
            name="quantity_input"
            value={isOutOfStock ? 0 : this.state.quantityNumber}
            disabled={isOutOfStock}
            onChange={this.handleQuantityInputChange}
          />
          { isOutOfStock && <Tooltip
            title="Tạm ngừng cung cấp"
            arrow
            placement="top"
          >
            <InfoIcon
              className={classes.MinQuantityIcon}
              fontSize="small"
            />
          </Tooltip> }
        </div>
        { isOutOfStock && <span className={classes.OutOfStockText}>Tạm ngừng cung cấp</span> }
      </div>
      <div style={{paddingLeft: 0, paddingRight: 0}} className={`${productsGridClasses.ProductListCol}`}>
        <IconButton
          size="small"
          color="primary"
          disabled={isOutOfStock}
          onClick={isOutOfStock? undefined : this.handleAddProduct}
        >
          <AddCircleIcon fontSize="large"/>
        </IconButton>
      </div>
    </div>);
  }
}

FastOrderSelectedProduct.propTypes = {
  product: PropTypes.object,
  onAddProduct: PropTypes.func,
};

FastOrderSelectedProduct.defaultProps = {
};

export default FastOrderSelectedProduct;
