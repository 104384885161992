import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionRules } from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionRulesDisplayFields,
  promotionRulesGroupDisplayFields,
  promotionRulesLineDisplayFields,
  promotionRulesDocumentDisplayFields,
  promotionRulesParamsMap,
  getPromotionClassName,
  isPromotionDocument,
  isPromotionGroup,
  isPromotionLine,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionRule from './GetcarePromotionRule/GetcarePromotionRule';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionRulesGrid.module.scss';
import classes from './GetcarePromotionRules.module.scss';

class GetcarePromotionRules extends PureComponent {
  render() {
    const { promotionRules, promotionTypeId } = this.props;
    const displayFields = getDisplayFields(
      promotionRulesParamsMap,
      isPromotionGroup(promotionTypeId)
        ? promotionRulesGroupDisplayFields
        : isPromotionLine(promotionTypeId)
          ? promotionRulesLineDisplayFields
          : isPromotionDocument(promotionTypeId)
            ? promotionRulesDocumentDisplayFields
            : promotionRulesDisplayFields
    );
    const listClassName = getPromotionClassName(promotionTypeId);

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${gridClasses[listClassName]}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${gridClasses[item.className]}`}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {promotionRules.length ? (
            promotionRules.map((item, index) => (
              <GetcarePromotionRule
                key={`promotion-rule-${item.id || item.idStr}`}
                {...item}
                index={index}
                promotionTypeId={promotionTypeId}
                listClassName={listClassName}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionRules.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionRules.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionRules);
