import {
  GET_PRODUCT_BONUS_LIST,
  UPDATE_PRODUCT_BONUS_ITEM,
  DELETE_PRODUCT_BONUS_ITEMS,
  SAVE_TOTAL,
  SAVE_LOADING,
  SAVE_ACTION_LOADING,
  SAVE_PRODUCT_BONUS_LIST,
  SAVE_PRODUCT_BONUS_ITEM,
  REMOVE_PRODUCT_BONUS_ITEMS,
  EXPORT_PRODUCT_BONUS_LIST_TEMPLATE,
} from 'redux/constants/vendor/productBonusConstants';

export function getProductBonusList(payload) {
  return {
    type: GET_PRODUCT_BONUS_LIST,
    payload
  };
}
export function updateProductBonusItem(payload) {
  return {
    type: UPDATE_PRODUCT_BONUS_ITEM,
    payload
  };
}
export function deleteProductBonusItems(payload) {
  return {
    type: DELETE_PRODUCT_BONUS_ITEMS,
    payload
  };
}
export function exportProductBonusListTemplate(payload) {
  return {
    type: EXPORT_PRODUCT_BONUS_LIST_TEMPLATE,
    payload,
  };
}

// mutation
export function saveProductBonusListTotal(productBonusListTotal) {
  return {
    type: SAVE_TOTAL,
    productBonusListTotal
  };
}
export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading
  };
}
export function saveProductBonusList(productBonusList) {
  return {
    type: SAVE_PRODUCT_BONUS_LIST,
    productBonusList
  };
}
export function removeProductBonusItems(productBonusItems) {
  return {
    type: REMOVE_PRODUCT_BONUS_ITEMS,
    productBonusItems
  };
}
export function saveProductBonusItem(productBonusItem) {
  return {
    type: SAVE_PRODUCT_BONUS_ITEM,
    productBonusItem
  };
}
