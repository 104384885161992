import React, { PureComponent } from 'react';

import { Link } from 'react-router-dom';
import { getFullAddressStr } from 'utils/helper';

import gridClasses from '../PharmacyListGrid.module.scss';
import classes from './PharmacyListItem.module.scss';

class PharmacyListItem extends PureComponent {
  _goToDetails = (e) => {
    this.props.history.push(`/pharmacy/${this.props.id}`);
  };
  _getCustomerInfo = () => {
    return {
      getcare_customer: {
        name: this.props.name,
      },
      name: this.props.representative_name,
      phone: this.props.phone,
      address: this.props.address,
      getcare_ward: this.props.getcare_ward,
      getcare_district: this.props.getcare_district,
      getcare_province: this.props.getcare_province,
    };
  };

  handleFastOrder = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      name,
      code,
      tax_code,
      type_pharmacy,
      representative_name,
      address,
      getcare_country,
      getcare_province,
      getcare_district,
      getcare_ward,
      is_mine,
      getcare_sales_id,
    } = this.props;
    const isMine = is_mine;
    const canOrder = isMine || !getcare_sales_id;

    return (
      <Link
        className={`${classes.Item} ${gridClasses.Row} ${canOrder ? '' : classes.DisabledOrder}`}
        to={`/fastorder?getcare_customer_code=${code}`}
      >
        <div className={gridClasses.Col}>{ code }</div>
        <div className={gridClasses.Col}>{ name }</div>
        <div className={gridClasses.Col}>{ tax_code }</div>
        <div className={gridClasses.Col}>{ type_pharmacy }</div>
        <div className={gridClasses.Col}>{ representative_name }</div>
        <div className={gridClasses.Col}>
          { getFullAddressStr(address, getcare_ward, getcare_district, getcare_province, getcare_country) }
        </div>
      </Link>
    );
  }
}

export default PharmacyListItem;
