import productBonusApi from 'utils/api/mdm/productBonusApi';
import { put } from 'redux-saga/effects';
import { productBonusActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadProductBonusList(payload) {
  const { params } = payload.payload;
  try {
    yield put(productBonusActions.saveLoading(true));

    const { data: response } = yield productBonusApi.getProductBonusList({ params });

    yield put(productBonusActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      productBonusActions.saveProductBonusList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      productBonusActions.saveProductBonusListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
export function* postProductBonus(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield productBonusApi.updateProductBonus({ params });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(productBonusActions.saveProductBonusItem({
      ...response.data,
      idStr: params.idStr,
    }));
  } catch (err) {
    console.log(err);
  }
}
export function* exportProductBonusListTemplate(payload) {
  try {
    yield put(productBonusActions.saveLoading(true));

    const { data: response } = yield productBonusApi.exportProductBonusesTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(productBonusActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
