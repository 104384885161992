import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getFullAddressStr } from 'utils/helper';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

import classes from './CustomerAddressItem.module.scss';

class CustomerAddressItem extends PureComponent {

  render() {
    const { address, titleMaxLine, isAllInOneLine } = this.props;

    return (
      <div className={`${classes.AddressItem} ${isAllInOneLine ? classes.InOneLine : ''}`}>
        { !!address.getcare_customer?.name
          && <p className={`${classes.CustomerName} ${classes[`MaxLine${titleMaxLine}`]}`}>{ address.getcare_customer?.name }</p>
        }

        <div className={`${classes.GroupInfo}`}>
          <span className={classes.Info}>
            <PersonOutlineIcon fontSize="small" className={classes.Icon}/>
            <span className={classes.InfoValue}>{address.name ? address.name : '-'}</span>
          </span>
          <span className={classes.InfoDivider}></span>
          <span className={`${classes.Info} ${classes.RightInfo}`}>
            <PhoneOutlinedIcon fontSize="small" className={classes.Icon}/>
            <span className={classes.InfoValue}>{address.phone ? address.phone : '-'}</span>
          </span>
        </div>
        <div className={classes.Info}>
          <RoomOutlinedIcon fontSize="small" className={classes.Icon}/>
          <span className={`${classes.InfoValue} ${classes.MaxLine2}`}>
            { getFullAddressStr(
                address.address,
                address.getcare_ward,
                address.getcare_district,
                address.getcare_province
              )
            }
          </span>
        </div>
      </div>
    );
  }
}

CustomerAddressItem.propTypes = {
  address: PropTypes.object,
  titleMaxLine: PropTypes.number,
  isAllInOneLine: PropTypes.bool,
};

CustomerAddressItem.defaultProps = {
  address: null,
  titleMaxLine: 100,
  isAllInOneLine: false,
};

export default CustomerAddressItem;
