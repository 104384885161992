import {
  SAVE_CUSTOMER_DETAILS,
  SAVE_CUSTOMER_LIST,
  SAVE_CUSTOMER_LIST_TOTAL,
  SAVE_CUSTOMER_LIST_LOADING,
  SAVE_CUSTOMER_ACTION_LOADING,
} from 'redux/constants/vendor/customerConstants';

const initialState = {
  customerList: [],
  customerListTotal: 0,
  customerDetails: null,
  isCustomerListLoading: false,
  isCustomerActionLoading: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_DETAILS: {
      return {
        ...state,
        customerDetails: { ...action.customerDetails },
      }
    }
    case SAVE_CUSTOMER_LIST: {
      return {
        ...state,
        customerList: [...action.customerList],
      }
    }
    case SAVE_CUSTOMER_LIST_TOTAL: {
      return {
        ...state,
        customerListTotal: action.customerListTotal,
      }
    }
    case SAVE_CUSTOMER_LIST_LOADING: {
      return {
        ...state,
        isCustomerListLoading: action.isCustomerListLoading,
      }
    }
    case SAVE_CUSTOMER_ACTION_LOADING: {
      return {
        ...state,
        isCustomerActionLoading: action.isCustomerActionLoading,
      }
    }

    default:
      return state;
  }
}

export default reducer;
