import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { dateFormat } from 'utils/constanst/dateConstants';
import {
  makeSelectOriginalPolicyPriceDetails,
  makeSelectPolicyPriceDetails,
  makeSelectPolicyPriceProducts,
  makeSelectTempPolicyPriceProducts,
  makeSelectPolicyPriceChannels,
  makeSelectTempPolicyPriceChannels,
  makeSelectPolicyPriceCustomers,
  makeSelectTempPolicyPriceCustomers,
  makeSelectPolicyPriceCustomerTypes,
  makeSelectTempPolicyPriceCustomerTypes,
  makeSelectPolicyPriceLoading,
  makeSelectCustomerTypes,
  makeSelectLoginUser,
} from 'redux/selectors';
import {
  getPolicyPrice,
  resetPolicyPrice,
  updatePolicyPrice,
  savePolicyPriceDetails,
  getPolicyPriceCounts,
  saveTempPolicyPriceCustomerTypes,
} from 'redux/actions/mdm/policyPriceActions';
import { getCustomerTypes } from 'redux/actions/mdm/groupActions';
import { POLICY_PRICE_GROUP_TYPE, ACTIVITY_IN_COMING, POLICY_PRICE_CUSTOMER_TYPE } from 'utils/constanst/adminPolicyPriceContants';
import { ACTIVE } from 'utils/constanst/common';
import { isEqual, sortBy } from 'lodash';
import { toast } from 'react-toastify';
import policyPriceApi from 'utils/api/mdm/policyPriceApi';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { Tabs, Tab } from '@material-ui/core';
import PolicyPriceDetails from 'components/vendor/PolicyPriceDetails/PolicyPriceDetails';
import PolicyPriceProducts from 'components/vendor/PolicyPriceProducts/PolicyPriceProducts';
import PolicyPriceCustomers from 'components/vendor/PolicyPriceCustomers/PolicyPriceCustomers';
import ConfirmCreateDialog from 'components/vendor/PolicyPriceDetails/ConfirmCreateDialog/ConfirmCreateDialog';
import PolicyPriceHistory from 'components/vendor/PolicyPriceDetails/PolicyPriceHistory/PolicyPriceHistory';

import classes from './PolicyPrice.module.scss';

class PolicyPrice extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabActive: 'product',
      isDialogOpen: false,
    };
    this.validate = {
      code: {
        template: /^[a-zA-Z0-9\-_\s]*$/,
        required: true,
        maxLength: 20,
      },
      description: {
        maxLength: 55,
      },
      start_date: {
        required: true,
      },
    };
  }
  componentDidMount() {
    this.props.getCustomerTypes();

    const { id } = this.props.match.params;
    this.loadData(id);
  }
  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this.loadData(id);
    }

    const {
      policyPriceDetails,
      tempPolicyPriceProducts,
      tempPolicyPriceCustomers,
      tempPolicyPriceChannels,
      customerTypes,
      policyPriceCustomerTypes,
      tempPolicyPriceCustomerTypes,
    } = this.props;

    if (policyPriceDetails && policyPriceDetails.id) {
      if (!prevProps.policyPriceDetails || policyPriceDetails.id !== prevProps.policyPriceDetails.id) {
        if (!prevProps.policyPriceDetails || !prevProps.policyPriceDetails.id || isNaN(prevProps.policyPriceDetails.id)) {
          // created successfull
          this.props.history.replace(`/policy/${policyPriceDetails.id}`);
          return;
        }
      }
    }

    if (
      customerTypes &&
      customerTypes.length > 0 &&
      prevProps.customerTypes.length === 0 &&
      policyPriceCustomerTypes.length === 0 &&
      tempPolicyPriceCustomerTypes.length === 0
    ) {
      this.props.saveTempPolicyPriceCustomerTypes(
        customerTypes.map((item) => ({
          getcare_customer_type: { ...item },
        }))
      );
    }

    // reload the counting of customers and products after making sure customer types are loaded
    if (customerTypes.length > 0
      && tempPolicyPriceCustomerTypes.length > 0
      && (this._isCustomersChanged(tempPolicyPriceCustomers, prevProps.tempPolicyPriceCustomers)
        || this._isCustomerTypesChanged(tempPolicyPriceCustomerTypes, prevProps.tempPolicyPriceCustomerTypes)
        || this._isChannelsChanged(tempPolicyPriceChannels, prevProps.tempPolicyPriceChannels)
        || this._isProductsChanged(tempPolicyPriceProducts, prevProps.tempPolicyPriceProducts)
      )
    ) {
      this.props.getPolicyPriceCounts({
        params: this._prepareCountingData(),
      });
    }
  }

  _getPolicyPriceDetailsContrains = (params) => {
    return {
      isRetail: params.is_retail,
      code:params.code,
      description: params.description,
      priorityId: params.getcare_policy_priority_id || params.getcare_policy_priority?.id,
      startDate: validDate(params.start_date) ? format(validDate(params.start_date), dateFormat) : '',
      endDate: validDate(params.end_date) ? format(validDate(params.end_date), dateFormat) : '',
    }
  }
  _isDetailsChanged = (details, comparedDetails) => {
    if (!details || !comparedDetails) return false;
    return !isEqual(this._getPolicyPriceDetailsContrains(comparedDetails), this._getPolicyPriceDetailsContrains(details));
  }

  _getPolicyPriceChannelContrains = (params) => {
    return {
      id: params.id,
      code: params.getcare_sales_channel?.code,
    }
  }
  _isChannelsChanged = (channels, comparedChannels) => {
    if (!channels || !comparedChannels) return false;

    const hasDeletedItem = channels.some(item => item.id && !comparedChannels.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return !isEqual(
      sortBy(channels.map(p => this._getPolicyPriceChannelContrains(p))),
      sortBy(comparedChannels.map(p => this._getPolicyPriceChannelContrains(p))),
    );
  }

  _getPolicyPriceCustomerContrains = (params) => {
    return {
      id: params.id,
      typeId: params.type_id,
      getcare_customer_id: params.getcare_customer?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    }
  }
  _isCustomersChanged = (customers, comparedCustomers) => {
    if (!customers || !comparedCustomers) return false;

    const hasDeletedItem = customers.some(item => item.id && !comparedCustomers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return !isEqual(
      sortBy(customers.map(p => this._getPolicyPriceCustomerContrains(p))),
      sortBy(comparedCustomers.map(p => this._getPolicyPriceCustomerContrains(p))),
    );
  }

  _getPolicyPriceCustomerTypeContrains = (params) => {
    return {
      typeId: params.getcare_customer_type?.id,
    }
  }
  _isCustomerTypesChanged = (types, comparedTypes) => {
    const { customerTypes } = this.props;

    if (!types || !comparedTypes) return false;

    // default value of types is all of types
    if (this.isEditing() && types.length === customerTypes.length && comparedTypes.length === customerTypes.length) return false;

    if (types.length !== comparedTypes.length) return true;

    return !isEqual(
      sortBy(types.map(p => this._getPolicyPriceCustomerTypeContrains(p))),
      sortBy(comparedTypes.map(p => this._getPolicyPriceCustomerTypeContrains(p))),
    );
  }

  _getPolicyPriceProductContrains = (params) => {
    return {
      id: params.id,
      product_vendor_code: params.product_vendor_code,
      vendorId: params.vendor?.id,
      uomBaseId: params.uom_base?.id,
      operation: params.operation,
      amount: params.amount,
      policyUnitId: params.policy_unit.id,
      typeId: params.type_id,
    }
  }
  _isProductsChanged = (products, comparedProducts) => {
    if (!products || !comparedProducts) return false;

    const hasDeletedItem = products.some(item => item.id && !comparedProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;
    return !isEqual(
      sortBy(products.map(p => this._getPolicyPriceProductContrains(p))),
      sortBy(comparedProducts.map(p => this._getPolicyPriceProductContrains(p))),
    );
  }
  _isActive = () => {
    return this.props.policyPriceDetails?.active === ACTIVE;
  };
  _getActivityStatus = () => {
    const { policyPriceDetails } = this.props;
    if (!policyPriceDetails) return ACTIVITY_IN_COMING;

    return policyPriceDetails.apply;
  };
  _isIncomingProgress = () => {
    return this._getActivityStatus() === ACTIVITY_IN_COMING && this._isActive();
  }
  loadData = (id) => {
    if (this.isEditing()) {
      this.props.getPolicyPrice(id);
      return;
    }
    this.props.resetPolicyPrice();
  };
  backToList = () => {
    this.props.history.push('/policy');
  };
  isEditing = () => {
    const { id } = this.props.match.params;
    return id && !isNaN(id) && Number(id) > 0;
  };
  isCodeValid = () => {
    if (this.isEditing()) return true;

    const code = this.props.policyPriceDetails?.code || '';
    return (
      code.trim() !== '' &&
      this.validate.code.template.test(code) &&
      code.trim().length <= this.validate.code.maxLength
    );
  };
  isCodeDuplicated = async (codeStr) => {
    let messages = [];
    const value = codeStr.trim();

    const { data: response } = await policyPriceApi.checkPolicyPriceCodeDuplicated({
      params: {
        code: value,
        id: this.props.policyPriceDetails?.id,
      }
    });
    if (!response?.result) {
      messages.push('• Mã chính sách giá bị trùng');
    }
    return messages;
  }
  isDescriptionValid = () => {
    const description = this.props.policyPriceDetails?.description || '';
    return (
      description.trim() !== '' &&
      description.trim().length <= this.validate.description.maxLength
    );
  };
  _isProductItemValid = (item) => {
    return (!!item.product_vendor_code
      && !!item.policy_unit?.id
      && item.amount.toString().trim() !== ''
      && !isNaN(item.amount)
      && !!item.operation
    );
  }
  isProductsValid = () => {
    const { tempPolicyPriceProducts } = this.props;
    return (
      tempPolicyPriceProducts &&
      tempPolicyPriceProducts.length > 0 &&
      tempPolicyPriceProducts.some((item) => this._isProductItemValid(item))
    );
  };
  _isChannelItemValid = (item) => {
    return !!item.getcare_sales_channel;
  }
  _isCustomerItemValid = (item) => {
    if (item.type_id === POLICY_PRICE_CUSTOMER_TYPE) {
      return item.getcare_customer && item.getcare_customer.id;
    }
    return item.getcare_erp_group && item.getcare_erp_group.id;
  }
  isCustomersValid = () => {
    const { tempPolicyPriceCustomers } = this.props;

    const hasCustomers =
      tempPolicyPriceCustomers &&
      tempPolicyPriceCustomers.length > 0 &&
      tempPolicyPriceCustomers.some((item) => this._isCustomerItemValid(item));

    return hasCustomers;
  };
  isStartDateValid = () => {
    if (!this.props.policyPriceDetails) return false;
    if (this.isEditing() && !this._isIncomingProgress()) return true;
    const { start_date } = this.props.policyPriceDetails;
    return !start_date || (validDate(start_date) && (isSameDay(new Date(start_date), new Date()) || isAfter(new Date(start_date), new Date())));
  }
  isEndDateValid = () => {
    if (!this.props.policyPriceDetails) return false;
    const { end_date, start_date } = this.props.policyPriceDetails;
    return !end_date
    || (start_date && validDate(start_date)
        && validDate(end_date)
        && (isSameDay(new Date(end_date), new Date(start_date)) || isAfter(new Date(end_date), new Date(start_date)))
    );
  }
  hasStartDate = () => {
    return !!this.props.policyPriceDetails?.start_date;
  };
  isValid = () => {
    return (
      this.isDescriptionValid() &&
      this.isCodeValid() &&
      this.isStartDateValid() &&
      this.isEndDateValid() &&
      this.isProductsValid() &&
      this.isCustomersValid()
    );
  };
  _getValidProducts = () => {
    const { tempPolicyPriceProducts } = this.props;
    return tempPolicyPriceProducts.filter(p => this._isProductItemValid(p))
      .reduce((memo, p) => {
        if (!memo.some(item => item.product_vendor_code === p.product_vendor_code && item.vendor?.id === p.vendor?.id && item.uom_base?.id === p.uom_base?.id)) {
          memo.push(p);
        }
        return memo;
      }, []);
  }
  _getValidCustomers = () => {
    const { tempPolicyPriceCustomers } = this.props;
    return tempPolicyPriceCustomers.filter(p => this._isCustomerItemValid(p))
      .reduce((memo, p) => {
        if (!memo.some((item) => {
          if (p.type_id === POLICY_PRICE_CUSTOMER_TYPE) return item.getcare_customer?.id === p.getcare_customer?.id;
          return item.getcare_erp_group?.id === p.getcare_erp_group?.id;
        })) {
          memo.push(p);
        }
        return memo;
      }, []);
  }
  _getValidChannels = () => {
    const { tempPolicyPriceChannels } = this.props;
    return tempPolicyPriceChannels.filter(p => this._isChannelItemValid(p))
      .reduce((memo, p) => {
        if (!memo.some(item => item.getcare_sales_channel?.id === p.getcare_sales_channel?.id)) {
          memo.push(p);
        }
        return memo;
      }, []);
  }
  hasWarningMessages = async () => {
    const codeDuplicatedMsgs = await this.isCodeDuplicated(this.props.policyPriceDetails?.code);
    const messages = [
      ...codeDuplicatedMsgs,
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }
  _prepareCountingData = () => {
    const { policyPriceDetails, tempPolicyPriceCustomerTypes } = this.props;
    return {
      code: policyPriceDetails?.code,
      policy_price_products: this._getValidProducts().map((p) => ({
        type_id: p.type_id,
        [`${p.type_id === POLICY_PRICE_GROUP_TYPE
            ? 'getcare_erp_group_id'
            : 'getcare_product_price_vendor_id'
        }`]: p.group_or_vendor_product_id,
      })),

      policy_price_customers: this._getValidCustomers().map((c) => ({
        id: c.id || undefined,
        type_id: c.type_id,
        getcare_customer_id: c.getcare_customer?.id || null,
        getcare_erp_group_id: c.getcare_erp_group?.id || null,
      })),

      policy_price_customer_types: tempPolicyPriceCustomerTypes.map((cT) => ({
        id: cT.id ? cT.id : undefined,
        getcare_customer_type_id: cT.getcare_customer_type?.id,
      })),

      policy_price_sales_channels: this._getValidChannels().map((cl) => ({
        getcare_sales_channel_id: cl.getcare_sales_channel?.id,
      })),
    };
  };
  _prepareData = ({ start_date, end_date, active }) => {
    const isEditing = this.isEditing();
    const { policyPriceDetails } = this.props;
    return {
      id: policyPriceDetails?.id || undefined,
      code: policyPriceDetails.code,
      is_retail: policyPriceDetails.is_retail,
      description: policyPriceDetails.description,
      start_date: start_date ? start_date : policyPriceDetails?.start_date || null,
      end_date: end_date ? end_date : policyPriceDetails?.end_date || null,
      active: !isEditing ? ACTIVE : (active ? active : policyPriceDetails?.active),
      getcare_policy_priority_id: policyPriceDetails.getcare_policy_priority_id,

      policy_price_products: this._getValidProducts().map((p) => ({
        id: p.id ? p.id : undefined,
        type_id: p.type_id,
        getcare_policy_unit_id: p.policy_unit?.id,
        amount: Number(p.amount),
        operation: p.operation,
        [`${
          p.type_id === POLICY_PRICE_GROUP_TYPE
            ? 'getcare_erp_group_id'
            : 'getcare_product_price_vendor_id'
        }`]: p.group_or_vendor_product_id,
      })),

      policy_price_customers: this._getValidCustomers().map((c) => ({
        id: c.id || undefined,
        type_id: c.type_id,
        getcare_customer_id: c.getcare_customer?.id || null,
        getcare_erp_group_id: c.getcare_erp_group?.id || null,
      })),

      policy_price_customer_types: [],

      policy_price_sales_channels: [],
    };
  };
  handleTabChange = (e, value) => {
    this.setState({
      tabActive: value,
    });
  };
  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
    });
  };
  handleDialogClose = () => {
    this.setState({
      isDialogOpen: false,
    });
  };
  handleSubmitConfirmCreateDialog = ({ start_date, end_date }) => {
    const fieldMap = end_date
      ? {
          start_date,
          end_date,
        }
      : { start_date };

    this.props.savePolicyPriceDetails({
      ...this.props.policyPriceDetails,
      ...fieldMap,
    });

    this.props.updatePolicyPrice({
      params: { ...this._prepareData({ ...fieldMap, active: '' }) },
    });

    this.handleDialogClose();
  };
  handleSubmitPolicy = async (e) => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;

    const params = {
      getcare_vendor_id:this.props.user.getcare_vendor_id
    };

    if (!this.hasStartDate()) {
      this.handleDialogOpen();
      return;
    }
    this.props.updatePolicyPrice({
      params: {
        ...this._prepareData({ start_date: '', end_date: '', active: '' }),
      },
    });
    const { data: response } = await policyPriceApi.flushPricesCache({params});
      if (!response?.result) {
        toast.error(response.message);
        return;
      }
  };
  handleCancelPolicy = () => {
    this.loadData(this.props.match.params.id);
  };
  handleUpdateStatus = ({ active }) => {
    this.props.savePolicyPriceDetails({
      ...this.props.policyPriceDetails,
      active: active,
    });
    this.props.updatePolicyPrice({
      params: {
        ...this._prepareData({
          start_date: '',
          end_date: '',
          active: active,
        }),
      },
    });
  };

  render() {
    const {
      originalPolicyPriceDetails,
      policyPriceDetails,
      policyPriceCustomers,
      tempPolicyPriceCustomers,
      policyPriceProducts,
      tempPolicyPriceProducts,
    } = this.props;

    const isEditing = this.isEditing();
    const isProductsChanged = isEditing ? this._isProductsChanged(policyPriceProducts, tempPolicyPriceProducts) : false;
    const isCustomersChanged = isEditing ? this._isCustomersChanged(policyPriceCustomers, tempPolicyPriceCustomers) : false;
    const isDetailsChanged = isEditing ? this._isDetailsChanged(originalPolicyPriceDetails, policyPriceDetails) : false;
    const isChanged = isDetailsChanged || isCustomersChanged || isProductsChanged;
    const isValid = this.isValid();
    const readOnly = this.props.policyPriceDetails?.id && !this._isActive();

    return (
      <div
        className={`${classes.PageWrap} ${
          this.props.loading ? 'OverlayLoading' : ''
        }`}
      >
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>
            {isEditing ? `Xem chính sách giá` : `Tạo mới chính sách giá`}
          </h1>

          {!isEditing && (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={this.backToList}
            >
              Huỷ và Trở về
            </Button>
          )}

          {isEditing && (
            <>
              <Button
                variant="outlined"
                startIcon={<ArrowBack />}
                onClick={this.backToList}
              >
                Trở về
              </Button>
              <Button
                disabled={readOnly || (!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                variant="outlined"
                color="secondary"
                onClick={this.handleCancelPolicy}
              >
                Huỷ thay đổi
              </Button>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            disabled={readOnly || (!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
            startIcon={<CheckOutlined />}
            onClick={this.handleSubmitPolicy}
          >
            {isEditing ? `Xác nhận thay đổi` : `Tạo chính sách`}
          </Button>
        </div>
        <div className={classes.PageMain}>
          <PolicyPriceDetails
            key={`policy-${policyPriceDetails?.id || `new`}`}
            isEditing={isEditing}
            readOnly={readOnly}
            isStartDateValid={this.isStartDateValid()}
            isEndDateValid={this.isEndDateValid()}
            isCodeValid={this.isCodeValid()}
            isDescriptionValid={this.isDescriptionValid()}
            isValid={isValid}
            onUpdateStatus={this.handleUpdateStatus}
          />
          <Tabs
            className={classes.TabsWrap}
            value={this.state.tabActive}
            onChange={this.handleTabChange}
          >
            <Tab value="product" label={<label className={classes.TabLabel}>
              Cấu hình giá theo sản phẩm{` `}
              { isProductsChanged ? <span className={classes.HighlightMark}>*</span> : '' }
            </label>} />
            <Tab value="customer" label={<label className={classes.TabLabel}>
              Loại khách hàng{` `}
              { isCustomersChanged ? <span className={classes.HighlightMark}>*</span> : '' }
            </label>} />
            {isEditing && <Tab value="history" label="Lịch sử" />}
          </Tabs>
          <div className={classes.TabsPanel}>
            {this.state.tabActive === 'product' && (
              <PolicyPriceProducts
                readOnly={readOnly}
                policyPriceId={policyPriceDetails?.id}
              />
            )}
            {this.state.tabActive === 'customer' && (
              <PolicyPriceCustomers
                readOnly={readOnly}
                policyPriceId={policyPriceDetails?.id}
              />
            )}
            {this.state.tabActive === 'history' && (
              <PolicyPriceHistory policyPriceId={policyPriceDetails?.id}/>
            )}
          </div>
        </div>

        {this.state.isDialogOpen && (
          <ConfirmCreateDialog
            title="Xác nhận tạo và kích hoạt chính sách giá"
            isOpen={this.state.isDialogOpen}
            onClose={this.handleDialogClose}
            startDate={policyPriceDetails?.start_date}
            endDate={policyPriceDetails?.end_date}
            onSubmit={this.handleSubmitConfirmCreateDialog}
            isLoading={this.props.loading}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  originalPolicyPriceDetails: makeSelectOriginalPolicyPriceDetails(),
  policyPriceDetails: makeSelectPolicyPriceDetails(),
  policyPriceCustomers: makeSelectPolicyPriceCustomers(),
  tempPolicyPriceCustomers: makeSelectTempPolicyPriceCustomers(),
  policyPriceChannels: makeSelectPolicyPriceChannels(),
  tempPolicyPriceChannels: makeSelectTempPolicyPriceChannels(),
  policyPriceProducts: makeSelectPolicyPriceProducts(),
  tempPolicyPriceProducts: makeSelectTempPolicyPriceProducts(),
  policyPriceCustomerTypes: makeSelectPolicyPriceCustomerTypes(),
  tempPolicyPriceCustomerTypes: makeSelectTempPolicyPriceCustomerTypes(),
  loading: makeSelectPolicyPriceLoading(),
  user: makeSelectLoginUser(),
  customerTypes: makeSelectCustomerTypes(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPolicyPrice: (payload) => dispatch(getPolicyPrice(payload)),
    resetPolicyPrice: (payload) => dispatch(resetPolicyPrice(payload)),
    updatePolicyPrice: (payload) => dispatch(updatePolicyPrice(payload)),
    savePolicyPriceDetails: (payload) => dispatch(savePolicyPriceDetails(payload)),
    getCustomerTypes: () => dispatch(getCustomerTypes()),
    getPolicyPriceCounts: (payload) => dispatch(getPolicyPriceCounts(payload)),
    saveTempPolicyPriceCustomerTypes: (payload) => dispatch(saveTempPolicyPriceCustomerTypes(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PolicyPrice);
