import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderPromotionInactives,
} from 'redux/selectors';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PromotionActives from 'components/ecom/FastOrder/PromotionActives/PromotionActives';
import PromotionReselectionDialog from './PromotionReselectionDialog/PromotionReselectionDialog';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';

import classes from './FastOrderPromotions.module.scss';

class FastOrderPromotions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedPromotionId: null,
      isLevelsDialogOpen: false,
    };
  }

  _getAppliedPromotions = () => {
    return (
      this.props.promotionAvailables?.filter(
        (promotion) => !!promotion.apply
      ) || []
    );
  };
  _getPromotion = (promotionId) => {
    return this.props.promotionAvailables.find(
      (promotion) => promotion.id === promotionId
    );
  };
  _getPromotionLevels = (promotionId) => {
    return this._getPromotion(promotionId)?.items || [];
  };
  _getPromotionDetails = (promotionId) => {
    return this._getPromotion(promotionId) || null;
  };

  handleReselectPromotion = ({ promotionId }) => {
    this.setState({
      selectedPromotionId: promotionId,
      isLevelsDialogOpen: true,
    });
  };
  handleOpenReselectPromotionDialog = () => {
    this.setState({
      isLevelsDialogOpen: true,
    });
  };
  handleCloseReselectPromotionDialog = () => {
    this.setState({
      isLevelsDialogOpen: false,
    });
  };

  handleCancelSelectedPromotion = () => {
    this.setState({
      isLevelsDialogOpen: false,
      selectedPromotionId: null,
    });
  };

  render() {
    const { selectedPromotionId } = this.state;
    const appliedPromotions = this._getAppliedPromotions();

    return (
      <section className={classes.Wrap}>
        <div className={classes.HeaderToolbar}>
          <h3 className="SectionTitle">Danh sách khuyến mãi</h3>
          {this.props.step !== 3 && (
            <Button
              size="small"
              color="primary"
              endIcon={<Edit fontSize="small" />}
              onClick={this.props.onRecalculatePromotion}
            >
              Tính lại khuyến mãi
            </Button>
          )}
        </div>
        <div className={classes.PromotionResult}>
          <div className={classes.PromotionActive}>
            {appliedPromotions.map((item) => (
              <div
                key={`promotion-${item.id}`}
                className={classes.SelectionItem}
              >
                <CheckCircleIcon className={classes.Icon} />
                <div className={`${classes.Item}`}>
                  <div className="PreWrap">
                    <p
                      className={classes.PromotionLink}
                      onClick={(e) => {
                        this.props.onPromotionLinkClick(item);
                      }}
                    >
                      {item.name}
                    </p>
                    {item.vendor_name ? <p>{item.vendor_name}</p> : ``}
                    <p className={classes.TextExplained}>
                      {`Bạn được hưởng `}
                      <strong>{item.count_total}</strong>
                      {` suất khuyến mãi`}
                    </p>
                  </div>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      this.handleReselectPromotion({ promotionId: item.id })
                    }
                  >
                    Chọn lại
                  </Button>
                </div>
              </div>
            ))}
            <div className={`${classes.SelectionItem} ${classes.LastItem}`}>
              <FiberManualRecordIcon className={classes.Icon} />
              <div className={`${classes.Item}`}>
                <p>
                  { (this.props.promotionInactives.length > 0 && appliedPromotions.length <= 0)
                    ? `Bạn đã không chọn chương trình nào cho đơn hàng của bạn`
                    : `Không còn chương trình phù hợp với đơn hàng của bạn`
                  }
                </p>
              </div>
            </div>
          </div>

          <PromotionActives />
        </div>

        {this.state.isLevelsDialogOpen && (
          <PromotionReselectionDialog
            isOpen={this.state.isLevelsDialogOpen}
            promotionLevels={this._getPromotionLevels(selectedPromotionId)}
            promotionId={selectedPromotionId}
            promotionDetails={this._getPromotionDetails(selectedPromotionId)}
            onCancelSelectedPromotion={this.handleCancelSelectedPromotion}
            onSubmitPromotion={this.props.onSubmitPromotion}
            onClose={this.handleCloseReselectPromotionDialog}
            onPromotionLinkClick={this.props.onPromotionLinkClick}
          />
        )}
      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  promotionAvailables: makeSelectFastOrderPromotionAvailables(),
  promotionInactives: makeSelectFastOrderPromotionInactives(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(FastOrderPromotions);
