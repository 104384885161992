import { createSelector } from 'reselect';

// authentication
const selectAuthState = (state) => {
  return state.authReducer || {};
};
const makeSelectLoginUser = () =>
  createSelector(selectAuthState, (substate) => substate.user);
const makeSelectAuthLoading = () =>
  createSelector(selectAuthState, (substate) => substate.authLoading);
const makeSelectAuthError = () =>
  createSelector(selectAuthState, (substate) => substate.authError);
const makeSelectAuthRedirectUrl = () =>
  createSelector(selectAuthState, (substate) => substate.authRedirectUrl);

// customer
const selectCustomerState = (state) => {
  return state.customerReducer || {};
};

const makeSelectCustomerDetails = () =>
  createSelector(selectCustomerState, (substate) => substate.customerDetails);
const makeSelectCustomerList = () =>
  createSelector(selectCustomerState, (substate) => substate.customerList);
const makeSelectCustomerListTotal = () =>
  createSelector(selectCustomerState, (substate) => substate.customerListTotal);
const makeSelectCustomerListLoading = () =>
  createSelector(selectCustomerState, (substate) => substate.isCustomerListLoading);

// websocket
const selectWSState = (state) => {
  return state.wsReducer || {};
};
const makeSelectGlobalWS= () =>
  createSelector(
    selectWSState,
    (substate) => substate.ws
  );
const makeSelectWSEvents= () =>
  createSelector(
    selectWSState,
    (substate) => substate.events
  );

// delivery management
const selectDeliveryState = (state) => {
  return state.deliveryReducer || {};
};

const makeSelectDeliveryUserList = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserList
  );
const makeSelectDeliveryUserListTotal = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserListTotal
  );
const makeSelectDeliveryUserLoading = () =>
  createSelector(
    selectDeliveryState,
    (substate) => substate.deliveryUserLoading
  );

// location
const selectLocationState = (state) => {
  return state.locationReducer || {};
};

const makeSelectCountryList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.countryList
  );
const makeSelectProvinceList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.provinceList
  );
const makeSelectDistrictList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.districtList
  );
const makeSelectWardList = () =>
  createSelector(
    selectLocationState,
    (substate) => substate.wardList
  );

// group
const selectAdminGroupState = (state) => {
  return state.groupReducer || {};
};
const makeSelectGroupList = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupList);
const makeSelectGroupTotal = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupTotal);
const makeSelectGroupLoading = () =>
  createSelector(selectAdminGroupState, (substate) => substate.loading);
const makeSelectGroupInfo = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupInfo);

const makeSelectGroupProducts = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupProducts);

const makeSelectGroupVendors = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupVendors);

const makeSelectGroupCustomers = () =>
  createSelector(selectAdminGroupState, (substate) => substate.groupCustomers);
const makeSelectGroupCustomersTotal = () =>
  createSelector(
    selectAdminGroupState,
    (substate) => substate.groupCustomersTotal
  );

const makeSelectGroupCustomerMappings = () =>
  createSelector(
    selectAdminGroupState,
    (substate) => substate.groupCustomerMappings
  );

const makeSelectCustomerTypes = () =>
  createSelector(selectAdminGroupState, (substate) => substate.customerTypes);

// vendors
const selectAdminVendorState = (state) => {
  return state.vendorReducer || {};
};
const makeSelectVendorDetails = () =>
  createSelector(selectAdminVendorState, (substate) => substate.vendorDetails);
const makeSelectVendorList = () =>
  createSelector(selectAdminVendorState, (substate) => substate.vendorList);
const makeSelectVendorListTotal = () =>
  createSelector(
    selectAdminVendorState,
    (substate) => substate.vendorListTotal
  );
const makeSelectVendorListLoading = () =>
  createSelector(
    selectAdminVendorState,
    (substate) => substate.isVendorListLoading
  );

// vendor price list
const selectAdminVendorPriceState = (state) => {
  return state.vendorPriceReducer || {};
};
const makeSelectVendorPriceList = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.vendorPriceList
  );
const makeSelectTempVendorPriceList = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.tempVendorPriceList
  );
const makeSelectVendorPriceListTotal = () =>
  createSelector(
    selectAdminVendorPriceState,
    (substate) => substate.vendorPriceListTotal
  );
const makeSelectVendorPriceLoading = () =>
  createSelector(selectAdminVendorPriceState, (substate) => substate.loading);

// policy price list
const selectAdminPolicyPriceState = (state) => {
  return state.policyPriceReducer || {};
};

const makeSelectPricePiorities = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.pricePiorities
  );
const makeSelectPriceUnits = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.priceUnits
  );

const makeSelectPolicyPriceList = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceList
  );
const makeSelectPolicyPriceListTotal = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceListTotal
  );
const makeSelectPolicyPriceLoading = () =>
  createSelector(selectAdminPolicyPriceState, (substate) => substate.loading);

const makeSelectOriginalPolicyPriceDetails = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.originalPolicyPriceDetails
  );
const makeSelectPolicyPriceDetails = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceDetails
  );

const makeSelectPolicyPriceProducts = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceProducts
  );
const makeSelectTempPolicyPriceProducts = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceProducts
  );

const makeSelectPolicyPriceCustomers = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceCustomers
  );
const makeSelectTempPolicyPriceCustomers = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceCustomers
  );

const makeSelectPolicyPriceChannels = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceChannels
  );
const makeSelectTempPolicyPriceChannels = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceChannels
  );

const makeSelectPolicyPriceCustomerTypes = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceCustomerTypes
  );
const makeSelectTempPolicyPriceCustomerTypes = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.tempPolicyPriceCustomerTypes
  );

const makeSelectPolicyPriceHistory = () =>
  createSelector(
    selectAdminPolicyPriceState,
    (substate) => substate.policyPriceHistory
  );

// vendor product
const selectVendorProductState = (state) => {
  return state.vendorProductReducer || {};
};
const makeSelectVendorProductList = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.vendorProductList
  );
const makeSelectVendorProductListTotal = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.vendorProductListTotal
  );
const makeSelectVendorProductLoading = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.loading
  );
const makeSelectVendorProductActionLoading = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.actionLoading
  );
const makeSelectOriVendorProductList = () =>
  createSelector(
    selectVendorProductState,
    (substate) => substate.oriVendorProductList
  );

// logistic
const selectLogisticState = (state) => {
  return state.logisticReducer || {};
};

const makeSelectDeliveryLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.deliveryLogisticList
  );
const makeSelectLogisticTotalFee = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.totalFee
  );
const makeSelectLogisticList = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.logisticList
  );
const makeSelectLogisticListLoading = () =>
  createSelector(
    selectLogisticState,
    (substate) => substate.logisticListLoading
  );

// salesOrder
const selectSalesOrderState = (state) => {
  return state.salesOrderReducer || {};
};

const makeSelectSalesOrderStatuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderStatuses
  );

const makeSelectSalesOrderList = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderList
  );
const makeSelectSalesOrderListTotal = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderListTotal
  );
const makeSelectSalesOrderLoading = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.loading
  );

const makeSelectSalesOrder = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrder
  );
const makeSelectSalesOrderProducts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderProducts
  );
const makeSelectSalesOrderBonuses = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderBonuses
  );
const makeSelectSalesOrderDiscounts = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDiscounts
  );
const makeSelectSalesOrderNotes = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderNotes
  );
const makeSelectSalesOrderLogistic = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderLogistic
  );
const makeSelectSalesOrderSalesUser = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderSalesUser
  );
const makeSelectSalesOrderDelivery = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderDelivery
  );
const makeSelectSalesOrderHistory = () =>
  createSelector(
    selectSalesOrderState,
    (substate) => substate.salesOrderHistory
  );

// fast order
const selectFastOrderState = (state) => {
  return state.fastOrderReducer || {};
};

const makeSelectFastOrderLoading = () =>
  createSelector(selectFastOrderState, (substate) => substate.loading);
const makeSelectFastOrderActionLoading = () =>
  createSelector(selectFastOrderState, (substate) => substate.actionLoading);
const makeSelectFastOrderDetails = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDetails);
const makeSelectFastOrderProducts = () =>
  createSelector(selectFastOrderState, (substate) => substate.prProducts);
const makeSelectFastOrderBonuses = () =>
  createSelector(selectFastOrderState, (substate) => substate.prBonuses);
const makeSelectFastOrderDiscounts = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDiscounts);
const makeSelectFastOrderNotes = () =>
  createSelector(selectFastOrderState, (substate) => substate.prNotes);
const makeSelectFastOrderLogistic = () =>
  createSelector(selectFastOrderState, (substate) => substate.prLogistic);
const makeSelectFastOrderDelivery = () =>
  createSelector(selectFastOrderState, (substate) => substate.prDelivery);
const makeSelectFastOrderSelectedCustomerCode = () =>
  createSelector(selectFastOrderState, (substate) => substate.selectedCustomerCode);

const makeSelectFastOrderPromotionAvailables = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionAvailables);
const makeSelectFastOrderPromotionActives = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionActives);
const makeSelectFastOrderUsedPromotions = () =>
  createSelector(selectFastOrderState, (substate) => substate.usedPromotions);
const makeSelectFastOrderPromotionInactives = () =>
  createSelector(selectFastOrderState, (substate) => substate.promotionInactives);

// pharmacy
const selectPharmacyState = (state) => {
  return state.pharmacyReducer || {};
};

const makeSelectPharmacyLoading = () =>
  createSelector(selectPharmacyState, (substate) => substate.loading);
const makeSelectPharmacyList = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyList);
const makeSelectPharmacyListTotal = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyListTotal);
const makeSelectPharmacy = () =>
  createSelector(selectPharmacyState, (substate) => substate.pharmacyDetails);

// promotion
const selectPromotionState = (state) => {
  return state.promotionReducer || {};
};

const makeSelectPromotionTypes = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionTypes
  );
const makeSelectPromotionOperators = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionOperators
  );
const makeSelectPromotionCompares = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionCompares
  );
const makeSelectPromotionPriorites = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionPriorites
  );
const makeSelectPromotionApplies = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionApplies
  );
const makeSelectPromotionDiscounts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionDiscounts
  );

const makeSelectPromotionList = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionList
  );
const makeSelectPromotionListTotal = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionListTotal
  );
const makeSelectPromotionLoading = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.loading
  );

const makeSelectOriPromotionDetails = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionDetails
  );
const makeSelectPromotionDetails = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionDetails
  );

const makeSelectOriPromotionRules = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionRules
  );
const makeSelectPromotionRules = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionRules
  );

const makeSelectOriPromotionProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionProducts
  );
const makeSelectPromotionProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionProducts
  );

const makeSelectOriPromotionMaxProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionMaxProducts
  );
const makeSelectPromotionMaxProducts = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionMaxProducts
  );

const makeSelectOriPromotionBonuses = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionBonuses
  );
const makeSelectPromotionBonuses = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionBonuses
  );

const makeSelectOriPromotionVendors = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionVendors
  );
const makeSelectPromotionVendors = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionVendors
  );

const makeSelectOriPromotionCustomers = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.oriPromotionCustomers
  );
const makeSelectPromotionCustomers = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionCustomers
  );
const makeSelectPromotionHistory = () =>
  createSelector(
    selectPromotionState,
    (substate) => substate.promotionHistory
  );

// product bonus
const selectProductBonusState = (state) => {
  return state.productBonusReducer || {};
};
const makeSelectProductBonusList = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.productBonusList
  );
const makeSelectProductBonusListTotal = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.productBonusListTotal
  );
const makeSelectProductBonusLoading = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.loading
  );
const makeSelectProductBonusActionLoading = () =>
  createSelector(
    selectProductBonusState,
    (substate) => substate.actionLoading
  );

// uoms
const selectAdminUomState = (state) => {
  return state.uomReducer || {};
};
const makeSelectUomList = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomList);
const makeSelectUomBaseList = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomBaseList);
const makeSelectUomTotal = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomTotal);
const makeSelectUomBaseTotal = () =>
  createSelector(selectAdminUomState, (substate) => substate.uomBaseTotal);
const makeSelectProductUnits = () =>
  createSelector(selectAdminUomState, (substate) => substate.productUnits);

// Product Cagtegory
const selectGetcareCategoryState = (state) => {
  return state.getcareCategoryReducer || {};
};
const makeSelectGetcareCategoryList = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.getcareCategoryList);
const makeSelectIsLoading = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.isLoading);
const makeSelectGetcareCategoryAllowedActions = () =>
  createSelector( selectGetcareCategoryState, (substate) => substate.getcareCategoryAllowedActions);

export {
  makeSelectGetcareCategoryList,
  makeSelectIsLoading,
  makeSelectGetcareCategoryAllowedActions,
  
  makeSelectLoginUser,
  makeSelectAuthLoading,
  makeSelectAuthError,
  makeSelectAuthRedirectUrl,

  makeSelectGlobalWS,
  makeSelectWSEvents,
  makeSelectCountryList,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
  makeSelectVendorDetails,
  makeSelectVendorList,
  makeSelectVendorListTotal,
  makeSelectVendorListLoading,
  makeSelectVendorPriceList,
  makeSelectVendorPriceListTotal,
  makeSelectVendorPriceLoading,
  makeSelectTempVendorPriceList,
  makeSelectVendorProductList,
  makeSelectVendorProductListTotal,
  makeSelectVendorProductLoading,
  makeSelectOriVendorProductList,
  makeSelectVendorProductActionLoading,

  makeSelectDeliveryLogisticList,
  makeSelectLogisticTotalFee,
  makeSelectLogisticList,
  makeSelectLogisticListLoading,

  makeSelectSalesOrderList,
  makeSelectSalesOrderListTotal,
  makeSelectSalesOrderLoading,
  makeSelectSalesOrderStatuses,
  makeSelectSalesOrder,
  makeSelectSalesOrderProducts,
  makeSelectSalesOrderBonuses,
  makeSelectSalesOrderDiscounts,
  makeSelectSalesOrderNotes,
  makeSelectSalesOrderLogistic,
  makeSelectSalesOrderSalesUser,
  makeSelectSalesOrderDelivery,
  makeSelectSalesOrderHistory,
  makeSelectFastOrderLoading,
  makeSelectFastOrderActionLoading,
  makeSelectFastOrderDetails,
  makeSelectFastOrderProducts,
  makeSelectFastOrderBonuses,
  makeSelectFastOrderDiscounts,
  makeSelectFastOrderNotes,
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDelivery,
  makeSelectFastOrderSelectedCustomerCode,
  makeSelectFastOrderPromotionAvailables,
  makeSelectFastOrderPromotionActives,
  makeSelectFastOrderUsedPromotions,
  makeSelectFastOrderPromotionInactives,
  makeSelectPharmacyLoading,
  makeSelectPharmacyList,
  makeSelectPharmacyListTotal,
  makeSelectPharmacy,
  makeSelectPromotionList,
  makeSelectPromotionListTotal,
  makeSelectPromotionLoading,
  makeSelectOriPromotionDetails,
  makeSelectPromotionDetails,
  makeSelectPromotionTypes,
  makeSelectPromotionOperators,
  makeSelectPromotionCompares,
  makeSelectPromotionPriorites,
  makeSelectPromotionApplies,
  makeSelectPromotionDiscounts,
  makeSelectOriPromotionRules,
  makeSelectPromotionRules,
  makeSelectOriPromotionBonuses,
  makeSelectPromotionBonuses,
  makeSelectOriPromotionProducts,
  makeSelectPromotionProducts,
  makeSelectOriPromotionMaxProducts,
  makeSelectPromotionMaxProducts,
  makeSelectOriPromotionVendors,
  makeSelectPromotionVendors,
  makeSelectOriPromotionCustomers,
  makeSelectPromotionCustomers,
  makeSelectPromotionHistory,
  makeSelectCustomerDetails,
  makeSelectCustomerList,
  makeSelectCustomerListTotal,
  makeSelectCustomerListLoading,
  makeSelectDeliveryUserList,
  makeSelectDeliveryUserListTotal,
  makeSelectDeliveryUserLoading,

  makeSelectGroupList,
  makeSelectGroupTotal,
  makeSelectGroupLoading,
  makeSelectGroupInfo,
  makeSelectGroupProducts,
  makeSelectGroupVendors,
  makeSelectGroupCustomers,
  makeSelectGroupCustomersTotal,
  makeSelectGroupCustomerMappings,
  makeSelectCustomerTypes,

  makeSelectProductBonusList,
  makeSelectProductBonusListTotal,
  makeSelectProductBonusLoading,
  makeSelectProductBonusActionLoading,

  makeSelectUomList,
  makeSelectUomBaseList,
  makeSelectUomTotal,
  makeSelectUomBaseTotal,
  makeSelectProductUnits,

  makeSelectPricePiorities,
  makeSelectPriceUnits,
  makeSelectPolicyPriceList,
  makeSelectPolicyPriceListTotal,
  makeSelectPolicyPriceLoading,
  makeSelectOriginalPolicyPriceDetails,
  makeSelectPolicyPriceDetails,
  makeSelectPolicyPriceProducts,
  makeSelectTempPolicyPriceProducts,
  makeSelectPolicyPriceCustomers,
  makeSelectTempPolicyPriceCustomers,
  makeSelectPolicyPriceChannels,
  makeSelectTempPolicyPriceChannels,
  makeSelectPolicyPriceCustomerTypes,
  makeSelectTempPolicyPriceCustomerTypes,
  makeSelectPolicyPriceHistory,
};
