import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from "reselect";
import { makeSelectLoginUser } from 'redux/selectors';
import authApi from 'utils/api/authApi';
import { toast } from 'react-toastify';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import classes from './ChangePassword.module.scss';

class ChangePassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,

      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    }
    this.formRef = React.createRef();
  }

  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await authApi.updatePassword({
      params: {
        current_password: values.password,
        new_password: values.newPassword,
      }
    });
    this.setState({
      isLoading: false,
    });
    if (!response.result) {
      toast.error(response.message);
      return;
    }
    toast.success(`Bạn đã đổi mật khẩu thành công.`);
    this.formRef.current.resetForm();
  }

  render() {
    const { isLoading, showPassword, showNewPassword, showConfirmPassword } = this.state;

    return (
      <div className={`${classes.ChangePasswordWrap} ${isLoading ? 'OverlayLoading' : ''}`}>
        <div className={classes.ChangePassword}>
          <h2 className="PageTitle">Đổi mật khẩu</h2>
          <Formik
            innerRef={this.formRef}
            initialValues={{
              password: '',
              newPassword: '',
              confirmNewPassword: '',
            }}
            onSubmit={(values) => {
              this.handleSubmit(values);
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required("Vui lòng nhập"),
              newPassword: Yup.string().required("Vui lòng nhập").matches(/^[\w~!@#$%^&*()_+{}|:"<>?`\-=[\];',./\s]{6,32}$/, 'Hãy sử dụng từ 6 đến 32 ký tự'),
              confirmNewPassword: Yup.string().required("Vui lòng nhập").oneOf([Yup.ref('newPassword'), null], 'Mật khẩu xác nhận không trùng khớp'),
            })}
          >
            {(props) => {
              const {
                values,
                errors,
                handleChange,
                handleSubmit,
              } = props;
              return (
                <form noValidate autoComplete="off">
                  <div className={classes.FormInner}>
                    <p className={classes.HelperText}>Để bảo mật tài khoản, vui lòng không chia sẻ mật khẩu cho người khác</p>
                  
                    <TextField
                      variant="outlined"
                      name="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange}
                      label="Mật khẩu hiện tại *"
                      className={`${classes.Field}`}
                      error={!!errors.password}
                      helperText={errors.password}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              size="small"
                              onClick={() => this.setState({ showPassword: !showPassword })}
                            >
                              {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      variant="outlined"
                      name="newPassword"
                      label="Mật khẩu mới *"
                      className={`${classes.Field}`}
                      value={values.newPassword}
                      onChange={handleChange}
                      error={!!errors.newPassword}
                      helperText={errors.newPassword}
                      autoComplete="off"
                      type={this.state.showNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              size="small"
                              onClick={() => this.setState({ showNewPassword: !showNewPassword })}
                            >
                              {showNewPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      variant="outlined"
                      name="confirmNewPassword"
                      label="Xác nhận mật khẩu mới *"
                      className={`${classes.Field}`}
                      value={values.confirmNewPassword}
                      onChange={handleChange}
                      error={!!errors.confirmNewPassword}
                      helperText={errors.confirmNewPassword}
                      autoComplete="off"
                      type={this.state.showConfirmPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              size="small"
                              onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })}
                            >
                              {showConfirmPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className={classes.Divider}/>
                    <div className={classes.MessagesBlock}>
                      <p className={classes.HelperText}>Hãy sử dụng từ 6 đến 32 ký tự. Không sử dụng mật khẩu cho trang web khác hoặc nội dung quá rõ ràng như tên thú cưng hoặc họ tên của bạn.</p>
                    </div>
                    <div className={classes.Control}>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleSubmit}
                      >Cập nhật</Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectLoginUser(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(ChangePassword);
