import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import authApi from 'utils/api/authApi';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileUpload from 'components/FileUpload/FileUpload';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { toast } from 'react-toastify';

import classes from './ImageImportDialog.module.scss';

class ImageImportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      images: [],
    };
  }
  _resetState = () => {
    this.setState({
      isLoading: false,
      images: [],
    });
  }
  _setStateImages = ({ action, value, index }) => {
    let images = [...this.state.images];
    switch (action) {
      case "ADD": {
        images = images.concat([...value]);
        break;
      }
      case "REMOVE": {
        images.splice(index,1);
        break;
      }
      default:
    }
    this.setState({images},() => {
      this.state.images.forEach( (image,imageIndex) => this._readImageFile({file: image.file,imageIndex }))
    });
  }
  isFormValid = () => {
    return (
      ( this.state.images?.length && this.state.images.length > 0 && !this.state.isLoading )
    );
  }
  _readImageFile = ({file,imageIndex}) => {
    let fr = new FileReader();
    let rm = this;
    let images = [...this.state.images];
    fr.onload = function () {
      if ( images[imageIndex] ) images[imageIndex].url = fr.result;
      rm.setState({
        images
      });
    };
    fr.readAsDataURL(file);
  }
  handleFilesAdded = async (files) => {
    const { images } = this.state;
    const nextId = images.length > 0 ? Math.max(...images.map( image => image.id )) : 0;
    const value = files.map( (file,index) => {
      return {
        id: nextId + index + 1,
        name: file.name,
        url: null,
        file: file,
      }
    })
    this._setStateImages({
      action: "ADD",
      value,
    })
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
    this._resetState();
  };
  handleSubmitImport = async () => {
    this.setState({
      isLoading: true,
    })
    const files = this.state.images.map( image => image.file );
    const imageFiles = await this.handleUploadImageFiles({ params: { file: files } });
    this.setState({
      isLoading: false,
    })
    if ( !imageFiles ) return;
    const imageUrls = imageFiles.map( imageFile => imageFile.url );
    this.props.onCompleteImport(imageUrls);
    this.props.onClose();
  };
  handleUploadImageFiles = async ({ params }) => {
    try {
      const { data: response } = await authApi.uploadMultipleFiles({
        params,
      });
      if (!response?.result || !response.data) {
        toast.error(response.message);
        return;
      }
      return response.data;
    } catch(err) {
      toast.error(err);
    }
    return;
  }

  render() {
    const { isOpen, title } = this.props;
    const { images, isLoading } = this.state;
    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        disableBackdropClick
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <div className={classes.FieldControl}>
                <FileUpload onFilesAdded={this.handleFilesAdded} accept="image/*" multiple/>

                <div className={classes.HelpBlock}>
                  <p className={classes.FileName}>{this.state.file?.name}</p>
                  <ul className={classes.HelpText}>
                    <li>- Chỉ hỗ trợ import file hình ảnh. Tối đa 5MB</li>
                  </ul>
                </div>
              </div>
              {
                images.length > 0 &&
                <Grid container spacing={2}>
                  {images.map((image,index) => (
                    <Grid key={index} item xs={3}>
                      <div className={classes.ImageItem}> 
                          <img src={`${image.url}`} alt='Image_upload' />
                          <div className={classes.ImageItemActions}>
                            <IconButton
                              style={{ padding: '0' }}
                              onClick={() => this._setStateImages({ action: "REMOVE", index: index })}
                            >
                              <DeleteOutlineIcon color="secondary" />
                            </IconButton>
                          </div>
                          <div className={classes.ImageItemOverlay}></div>
                      </div> 
                    </Grid>
                  ))}
                </Grid>
              }
            </DialogContent>
            <DialogActions className={`${classes.DialogActions}`}>
              <Button variant="outlined" onClick={this.onClose}>
                Huỷ
              </Button>
              <Button
                disabled={!this.isFormValid()}
                variant="contained"
                onClick={this.handleSubmitImport}
                color="primary"
                autoFocus
              >
                Import
              </Button>
            </DialogActions>
        </div>
      </Dialog>
    );
  }
}

ImageImportDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onCompleteImport: PropTypes.func,
};

ImageImportDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default ImageImportDialog;
