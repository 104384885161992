import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PolicyPriceListItem from './PolicyPriceListItem/PolicyPriceListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PolicyPriceListGrid.module.scss';
import classes from './PolicyPriceList.module.scss';

class PolicyPriceList extends PureComponent {
  render() {
    const { policyPriceList, isLoading, displayFields } = this.props;

    return <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
      <div className={`${classes.Header}`}>
      <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      </div>
      <div className={`${classes.Body}`}>
      
        { !isLoading && policyPriceList.length
          ? policyPriceList.map(item => (
            <PolicyPriceListItem
              key={`group-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có data</p>)
        }
      </div>
    </div>;
  }
}

PolicyPriceList.propTypes = {
  displayFields: PropTypes.array,
  policyPriceList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

PolicyPriceList.defaultProps = {
  displayFields: [],
  policyPriceList: [],
  isLoading: false,
};

export default PolicyPriceList;
