export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã khách hàng',
  },
  {
    name: 'name',
    label: 'Tên khách hàng',
  },
  {
    name: 'tax_code',
    label: 'Mã số thuế',
  },
  {
    name: 'type_pharmacy',
    label: 'Loại hình',
  },
  {
    name: 'representative_name',
    label: 'Người đại diện',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: 'is_mine',
  keyword: '',
}
