import {
  SAVE_VENDOR_PRODUCT_LIST,
  SAVE_VENDOR_PRODUCT_LIST_TOTAL,
  SAVE_LOADING,
  REMOVE_VENDOR_PRODUCT_ITEMS,
  SAVE_VENDOR_PRODUCT_ITEM,
  SAVE_ORI_VENDOR_PRODUCT_LIST,
  SAVE_ACTION_LOADING,
} from 'redux/constants/vendor/vendorProductConstants';

const initialState = {
  vendorProductList: [],
  vendorProductListTotal: 0,
  loading: false,
  oriVendorProductList: [],
  actionLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_ACTION_LOADING: {
      return {
        ...state,
        actionLoading: action.actionLoading,
      };
    }
    case SAVE_VENDOR_PRODUCT_ITEM: {
      const list = [...state.vendorProductList];
      let newTotal = state.vendorProductListTotal;
      const index = list.findIndex((p) => {
        const comparedField = action.vendorProductItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.vendorProductItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.vendorProductItem };
      } else {
        list.unshift({ ...action.vendorProductItem });
        newTotal++;
      }
      return {
        ...state,
        vendorProductList: list,
        vendorProductListTotal: newTotal,
      };
    }
    case REMOVE_VENDOR_PRODUCT_ITEMS: {
      const newTotal = state.vendorProductListTotal - action.vendorProductItems.length;
      const list = state.vendorProductList.filter(
        (p) =>
          !action.vendorProductItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        vendorProductList: list,
        vendorProductListTotal: newTotal,
      };
    }
    case SAVE_VENDOR_PRODUCT_LIST: {
      const list = action.vendorProductList;
      return {
        ...state,
        vendorProductList: list ? [...list] : [],
      };
    }
    case SAVE_VENDOR_PRODUCT_LIST_TOTAL: {
      return {
        ...state,
        vendorProductListTotal: action.vendorProductListTotal,
      };
    }

    case SAVE_ORI_VENDOR_PRODUCT_LIST: {
      const list = action.oriVendorProductList;
      return {
        ...state,
        oriVendorProductList: list ? [...list] : [],
      };
    }
    default:
      return state;
  }
}

export default reducer;
