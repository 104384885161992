import axiosAuth from 'utils/axios/axiosAuth';

const VENDOR_API_URL = 'getcare_vendor/';
const vendorApi = {
  get: (id) => {
    return axiosAuth.get(`${VENDOR_API_URL}${id}`);
  },
  getAll: ({ params, cancelToken }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(VENDOR_API_URL, {
      params: queryParams,
      cancelToken,
    });
  },
  updateVendor: ({ id, params }) => {
    return axiosAuth.put(`${VENDOR_API_URL}${id}`, params);
  },
  getDocuments: ({ id, params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_vendor_document/`, {
      params: { ...queryParams, getcare_vendor_id: id },
    });
  },
  getApprovalInfo: ({ id }) => {
    const queryParams = id ? { getcare_vendor_id: id } : {};
    return axiosAuth.get(`getcare_vendor_approval/`,  {
      params: { ...queryParams },
    });
  },
  saveApprovalInfo: ({ id, params }) => {
    const formParams = {
      ...params,
      getcare_vendor_id: id,
    }
    return axiosAuth.post(`getcare_vendor_approval/`, formParams);
  },
};

export default vendorApi;
