import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionBonuses } from 'redux/selectors';
import { currencyFormatVN } from 'utils/helper';
import {
  orderModeMap,
  DISCOUNT_PERCENT,
  isPromotionLine,
  isPromotionGroup,
  isPromotionDocument,
} from 'utils/constanst/tmkPromotionConstants';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import gridClasses from '../GetcarePromotionRulesGrid.module.scss';
import classes from './GetcarePromotionRule.module.scss';

class GetcarePromotionRule extends PureComponent {
  _hasBonus = (params) => {
    return !!params.bonus_number && Number(params.bonus_number) > 0;
  }
  _hasDiscountMode = (params) => {
    return !!params.getcare_promotion_discount;
  }
  _hasDiscountAmount = (params) => {
    return !!params.discount_amount && Number(params.discount_amount) > 0;
  }
  _isDiscountValid = (params) => {
    return this._hasDiscountMode(params) && this._hasDiscountAmount(params);
  }
  _hasBonusAndDiscount = (params) => {
    return this._hasBonus(params) && this._isDiscountValid(params);
  }
  _isBonusLevelEnough = (params) => {
    if (!this._hasBonus(params)) return false;
    const levelBonusesQuantity = this.props.promotionBonuses.filter(
      item => {
        const isItemNotEditing = item.id || (item.getcare_product?.id || item.getcare_erp_group?.id || item.getcare_product_bonus?.id)
        return (isItemNotEditing && item.level === this.props.level)
      }
    ).reduce((memo, item) => memo += item.quantity_number, 0);
    return (levelBonusesQuantity > 0) && (levelBonusesQuantity <= params.bonus_number);
  }
  _getDiscountText = () => {
    const { getcare_promotion_discount, discount_amount, maximum_amount } = this.props;
    if (!discount_amount || Number(discount_amount) <= 0) return '';
    return <>
      { getcare_promotion_discount?.name }: {
        getcare_promotion_discount?.id === DISCOUNT_PERCENT
        ? <strong>{ discount_amount } %</strong>
        : <strong>{ currencyFormatVN(discount_amount) }</strong>
      }{ maximum_amount && <>; Tối đa: <strong>{ currencyFormatVN(maximum_amount) }</strong></> }
    </>;
  }

  render() {
    const {
      level,
      quantity_number,
      order_amount,
      order_mode,
      bonus_number,
      getcare_promotion_operator,
      promotionTypeId,
      bonus_mode,
      listClassName,
      level_name,
    } = this.props;
    const isDoc = isPromotionDocument(promotionTypeId);

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row} ${gridClasses[listClassName]}`}>
          <div className={gridClasses.Col}>{`L${level}`}</div>

          <div className={gridClasses.Col}>
            { level_name }
          </div>

          { !isDoc && <div className={`${gridClasses.Col}`}>
            <strong>{ quantity_number }</strong>
          </div> }

          <div className={`${gridClasses.Col}`}>
            { isDoc ? `` : order_mode ? `${orderModeMap[order_mode]} | ` : `` }
            <strong>{ !['', null, undefined].includes(order_amount) ? currencyFormatVN(order_amount) : `` }</strong>
          </div>
          <div className={`${gridClasses.Col}`}>
            { <strong className={this._isBonusLevelEnough(this.props) ? '' : classes.Invalid}>{ bonus_number }</strong> }
          </div>
          { (isPromotionGroup(promotionTypeId) || isPromotionLine(promotionTypeId)) && (
            <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
              { bonus_mode === 1 ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/> }
            </div>
          )}
          <div className={`${gridClasses.Col}`}>
            { this._hasBonusAndDiscount(this.props) ? getcare_promotion_operator?.name : ''}
          </div>
          <div className={`${gridClasses.Col}`}>
            { this._getDiscountText() }
          </div>
        </div>
      </>
    );
  }
}

GetcarePromotionRule.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  index: PropTypes.number,
};

GetcarePromotionRule.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionBonuses: makeSelectPromotionBonuses(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionRule);
