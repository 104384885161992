import vendorProductApi from 'utils/api/mdm/vendorProductApi';
import { put } from 'redux-saga/effects';
import { vendorProductActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadVendorProductList(payload) {
  const { params, vendorId } = payload.payload;
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } = yield vendorProductApi.getVendorProductList({ params, vendorId });

    yield put(vendorProductActions.saveLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(
      vendorProductActions.saveVendorProductList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      vendorProductActions.saveOriVendorProductList(
        response.data ? [...response.data] : []
      )
    );
    yield put(
      vendorProductActions.saveVendorProductListTotal(response.total_records)
    );
  } catch (err) {
    console.log(err);
  }
}
export function* postVendorProductList(payload) {
  const { params, currentListParams } = payload.payload;
  try {
    yield put(vendorProductActions.saveActionLoading(true));

    const { data: response } = yield vendorProductApi.updateVendorProductList({ params });

    yield put(vendorProductActions.saveActionLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(vendorProductActions.getVendorProductList(currentListParams));
  } catch (err) {
    console.log(err);
  }
}


export function* exportVendorProductList(payload) {
  const { vendorId } = payload.payload;
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } = yield vendorProductApi.exportVendorProducts({ vendorId });
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(vendorProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
export function* exportVendorProductListTemplate(payload) {
  try {
    yield put(vendorProductActions.saveLoading(true));

    const { data: response } = yield vendorProductApi.exportVendorProductsTempate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();

    yield put(vendorProductActions.saveLoading(false));
  } catch (err) {
    console.log(err);
  }
}
