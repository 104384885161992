import React, { PureComponent } from 'react';

import ChangePassword from 'components/ecom/MyProfile/ChangePassword/ChangePassword';

import classes from './MyProfile.module.scss';

class MyProfile extends PureComponent {
  render() {
    return (
      <div className={classes.PageWrap}>
        <div className={`${classes.PageContent}`}>
          <div className={classes.PageMain}>
            <ChangePassword/>
          </div>
        </div>
      </div>
    );
  }
}

export default MyProfile;
