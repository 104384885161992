export const GET_VENDOR_PRICE_LIST = 'getcare/vendor/vendorPrice/GET_VENDOR_PRICE_LIST';
export const UPDATE_VENDOR_PRICE_LIST = 'getcare/vendor/vendorPrice/UPDATE_VENDOR_PRICE_LIST';

// mutation
export const SAVE_LOADING = 'getcare/vendor/vendorPrice/SAVE_LOADING';
export const SAVE_TEMP_VENDOR_PRICE_ITEM = 'getcare/vendor/vendorPrice/SAVE_TEMP_VENDOR_PRICE_ITEM';
export const REMOVE_TEMP_VENDOR_PRICE_ITEM = 'getcare/vendor/vendorPrice/REMOVE_TEMP_VENDOR_PRICE_ITEM';
export const SAVE_TEMP_VENDOR_PRICE_LIST = 'getcare/vendor/vendorPrice/SAVE_TEMP_VENDOR_PRICE_LIST';
export const SAVE_VENDOR_PRICE_LIST = 'getcare/vendor/vendorPrice/SAVE_VENDOR_PRICE_LIST';
export const SAVE_VENDOR_PRICE_LIST_TOTAL = 'getcare/vendor/vendorPrice/SAVE_VENDOR_PRICE_LIST_TOTAL';
export const SAVE_VENDOR_PRICE_ITEM = 'getcare/vendor/vendorPrice/SAVE_VENDOR_PRICE_ITEM';
