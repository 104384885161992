import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { removeAccents, validDate } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPolicyPriceDetails,
  makeSelectPricePiorities,
} from 'redux/selectors';
import {
  savePolicyPriceDetails,
  getPricePiorities,
} from 'redux/actions/mdm/policyPriceActions';
import { isEqual, sortBy } from 'lodash';
import {
  PRIORITY_LOW,
  ACTIVITY_IN_COMING,
  ACTIVITY_IN_PROGRESS,
  ACTIVITY_STOP,
  activityMap,
} from 'utils/constanst/adminPolicyPriceContants';
import { ACTIVE, DEACTIVE } from 'utils/constanst/common';
import { dateFormat, dateTimeFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';

import Button from '@material-ui/core/Button';
import FieldEditable from 'components/FieldEditable/FieldEditable';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

import classes from './PolicyPriceDetails.module.scss';

const PRICE_POLICIES = [
  {
    id: 'RETAIL', name: 'Giá bán lẻ'
  },
  {
    id:'BASE', name: 'Giá bán base'
  }
]

class PolicyPriceDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmDialogOpen: false,
    };
  }
  componentDidMount() {
    this._initData();
    if (!this.props.isEditing) {
      this._initCreateForm();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { pricePiorities, isEditing } = this.props;
    if (!isEditing && pricePiorities &&
      !isEqual(sortBy(pricePiorities), sortBy(prevProps.pricePiorities))
    ) {
      this._initCreateForm();
    }
  }
  _initData = () => {
    this.props.getPricePiorities();
  };
  _initCreateForm = () => {
    this.handleSaveField({
      start_date: new Date().toISOString(),
      getcare_policy_priority_id: PRIORITY_LOW
    });
  }
  _getPriorityItem = (priorityId) => {
    return this.props.pricePiorities.find((item) => item.id === priorityId);
  }
  _getSelectedPriority = () => {
    const { policyPriceDetails } = this.props;
    return (policyPriceDetails
            && this._getPriorityItem(
                policyPriceDetails.getcare_policy_priority_id
                || policyPriceDetails.getcare_policy_priority?.id
              )
            )
        || null;
  };
  _getSelectedPricePolicy = () => {
    const { policyPriceDetails } = this.props;
    return policyPriceDetails?.is_retail ? PRICE_POLICIES[0] : PRICE_POLICIES[1];
  };
  _isActive = () => {
    return this.props.policyPriceDetails?.active === ACTIVE;
  };
  _getActivityStatus = () => {
    const { policyPriceDetails } = this.props;
    if (!policyPriceDetails) return ACTIVITY_IN_COMING;

    return policyPriceDetails.apply;
  };
  _getCombinedActivityAndStatus = () => {
    const activityStatus = this._getActivityStatus();
    const isActive = this._isActive();
    if (!isActive || activityStatus === ACTIVITY_STOP) return ACTIVITY_STOP;
    return activityStatus;
  }
  _isInProgress = () => {
    return this._getActivityStatus() === ACTIVITY_IN_PROGRESS && this._isActive();
  }
  _isIncomingProgress = () => {
    return this._getActivityStatus() === ACTIVITY_IN_COMING && this._isActive();
  }
  _isStopProgress = () => {
    return this._getActivityStatus() === ACTIVITY_STOP || !this._isActive();
  }
  _preprocessCodeValue = (value) => {
    return removeAccents(value).toUpperCase();
  };
  handleChangeField = (e, value) => {
    const fieldName = e.target.name;
    if (!this.props.isEditing) {
      this.handleSaveField({ [fieldName]: value });
    }
  };
  handleSaveField = (fieldMap) => {
    if ('is_retail' in fieldMap) {
      fieldMap = {
        ...fieldMap,
        is_retail: fieldMap.is_retail === 'RETAIL'
      }
    }
    const params = {
      ...this.props.policyPriceDetails,
      ...fieldMap,
    };
    this.props.savePolicyPriceDetails(params);
  };
  handleToggleActive = () => {
    if (this._isInProgress()) {
      this.handleOpenConfirmDialog();
      return;
    }
    if (this._isIncomingProgress()) {
      this.props.onUpdateStatus({
        active: DEACTIVE,
      });
      return;
    }
    this.props.onUpdateStatus({
      active: ACTIVE,
    });
  };
  handleSubmitConfirmUpdateStatus = () => {
    this.props.onUpdateStatus({
      active: DEACTIVE,
    });
    this.handleCloseConfirmDialog();
  };
  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  };
  handleOpenConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };
  handleBlurCodeField = (e, value) => {
    this.handleSaveField({code: this._preprocessCodeValue(value)});
  }

  render() {
    const {
      readOnly,
      policyPriceDetails,
      isEditing,
      isCodeValid,
      isDescriptionValid,
      isValid,
      isEndDateValid,
      isStartDateValid,
    } = this.props;
    const isRetail = policyPriceDetails?.is_retail;
    return (
      <div className={`${classes.Details} ${isEditing ? classes.Editing : ''}`}>
        <div>
          <div className={classes.FieldControl}>
            <label>
              Mã chính sách <span className={classes.RequiredMark}>*</span>
            </label>
            <FieldEditable
                editModeOnly={!isEditing}
                fieldName="code"
                error={!isCodeValid}
                maxLength={20}
                value={policyPriceDetails?.code || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
                onBlur={this.handleBlurCodeField}
              />
          </div>
          <div className={classes.FieldControl}>
            <label>
              Ngày bắt đầu <span className={classes.RequiredMark}>*</span>
            </label>
            {isEditing && !this._isIncomingProgress() ? (
              validDate(policyPriceDetails?.start_date) ? (
                format(validDate(policyPriceDetails?.start_date), dateFormat)
              ) : (
                ''
              )
            ) : (
              <FieldEditable
                error={!isStartDateValid}
                fieldType="date"
                editModeOnly={!isEditing}
                displayedValue={
                  validDate(policyPriceDetails?.start_date)
                    ? format(validDate(policyPriceDetails?.start_date), dateFormat)
                    : ''
                }
                minValue={(new Date()).toISOString()}
                fieldName="start_date"
                value={policyPriceDetails?.start_date || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
          <div className={`${classes.FieldControl} ${classes.AutolabelFieldControl}`}>
            <label>Loại chính sách</label>
            {readOnly ? (
              isRetail ? 'Giá bán lẻ' : 'Giá bán base'
            ) : (
              <FieldEditable
                fieldType="select"
                editModeOnly={!isEditing}
                fieldName="is_retail"
                value={this._getSelectedPricePolicy()?.id}
                displayedValue={this._getSelectedPricePolicy()?.name}
                options={PRICE_POLICIES}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
          <div
            className={`${classes.FieldControl} ${classes.AutolabelFieldControl}`}
          >
            <label>Số lượng khách hàng áp dụng</label>
            {!policyPriceDetails ||
            ['', undefined, null].includes(policyPriceDetails.count_customer)
              ? 'N/A'
              : policyPriceDetails.count_customer}
          </div>
        </div>
        <div>
          <div
            className={`${classes.FieldControl} ${classes.DescriptionFieldControl}`}
          >
            <label>Mô tả</label>
            {readOnly ? (
              policyPriceDetails?.description
            ) : (
              <FieldEditable
                editModeOnly={!isEditing}
                fieldName="description"
                error={!isDescriptionValid}
                value={policyPriceDetails?.description || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
          <div className={classes.FieldControl}>
            <label>Ngày kết thúc</label>
            {readOnly ? (
              validDate(policyPriceDetails?.end_date) ?
                format(validDate(policyPriceDetails?.end_date), dateFormat)
                : ''
            ) : (
              <FieldEditable
                error={!isEndDateValid}
                fieldType="date"
                editModeOnly={!isEditing}
                displayedValue={
                  validDate(policyPriceDetails?.end_date)
                    ? format(validDate(policyPriceDetails?.end_date), dateFormat)
                    : ''
                }
                fieldName="end_date"
                minValue={policyPriceDetails?.start_date}
                value={policyPriceDetails?.end_date || ''}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
          <div
            className={`${classes.FieldControl} ${classes.AutolabelFieldControl}`}
          >
            <label>Ưu tiên</label>
            {readOnly ? (
              this._getSelectedPriority()?.name
            ) : (
              <FieldEditable
                fieldType="select"
                editModeOnly={!isEditing}
                fieldName="getcare_policy_priority_id"
                value={this._getSelectedPriority()?.id}
                displayedValue={this._getSelectedPriority()?.name}
                options={this.props.pricePiorities}
                onSave={this.handleSaveField}
                onChange={this.handleChangeField}
              />
            )}
          </div>
          <div className={`${classes.FieldControl} ${classes.AutolabelFieldControl}`}>
            <label>Số lượng sản phẩm áp dụng</label>
            {!policyPriceDetails ||
            ['', undefined, null].includes(policyPriceDetails.count_product)
              ? 'N/A'
              : policyPriceDetails.count_product}
          </div>
        </div>
        {isEditing && (
          <>
            <div className={classes.TimeInfoCol}>
              <div className={`${classes.FieldControl}`}>
                <label>Thời gian tạo</label>
                {validDate(policyPriceDetails?.created_at)
                  ? format(
                      validDate(policyPriceDetails?.created_at),
                      dateTimeFormat
                    )
                  : ''}
              </div>
              <div className={`${classes.FieldControl}`}>
                <label>Thời gian sửa</label>
                {validDate(policyPriceDetails?.updated_at)
                  ? format(
                      validDate(policyPriceDetails?.updated_at),
                      dateTimeFormat
                    )
                  : ''}
              </div>
              <div className={`${classes.FieldControl}`}>
                <label>Thay đổi bởi</label>
                {policyPriceDetails?.getcare_user?.name}
              </div>
            </div>
            <div className={classes.ActivityCol}>
              <div className={`${classes.FieldControl}`}>
                <label>Trạng thái</label>
                {activityMap[this._getCombinedActivityAndStatus()]}
              </div>
              <div
                className={`${classes.FieldControl} ${classes.ActivityBtnControl}`}
              >
                {this._isInProgress() || this._isIncomingProgress() ? (
                  <Button
                    disabled={!isValid}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    onClick={this.handleToggleActive}
                  >
                    Dừng hoạt động
                  </Button>
                ) : ''}
              </div>
            </div>
          </>
        )}
        {this.state.isConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isConfirmDialogOpen}
            title={`Xác nhận dừng hoạt động ${this.props.policyPriceDetails?.code}`}
            message={
              <>
                <p>
                  Khi bạn xác nhận dừng hoạt động chính sách giá{' '}
                  <strong>{this.props.policyPriceDetails?.code}</strong>, chương
                  trình giá này sẽ mất vài phút để dừng hoàn toàn. Những đơn
                  hàng trong khoảng thời gian đó có thể vẫn được áp dụng chính
                  sách này.
                </p>
                <p>Xin xác nhận hoặc huỷ bỏ hành động này</p>
              </>
            }
            onClose={this.handleCloseConfirmDialog}
            onSubmit={this.handleSubmitConfirmUpdateStatus}
          />
        )}
      </div>
    );
  }
}

PolicyPriceDetails.propTypes = {
  isEditing: PropTypes.bool,
  readOnly: PropTypes.bool,
  isCodeValid: PropTypes.bool,
  isDescriptionValid: PropTypes.bool,
  isValid: PropTypes.bool,
  isStartDateValid: PropTypes.bool,
  isEndDateValid: PropTypes.bool,
  onUpdateStatus: PropTypes.func,
};

PolicyPriceDetails.defaultProps = {
  isEditing: false,
  readOnly: false,
  isCodeValid: true,
  isDescriptionValid: true,
  isValid: true,
};

const mapStateToProps = createStructuredSelector({
  policyPriceDetails: makeSelectPolicyPriceDetails(),
  pricePiorities: makeSelectPricePiorities(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePolicyPriceDetails: (payload) =>
      dispatch(savePolicyPriceDetails(payload)),
    getPricePiorities: () => dispatch(getPricePiorities()),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(PolicyPriceDetails);
