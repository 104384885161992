import React, { PureComponent } from 'react';
import salesOrderApi from 'utils/api/oms/salesOrderApi';
import { toast } from 'react-toastify';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import SalesOrderCustomerDeliveryForm from 'components/so/SalesOrderCustomerDeliveryForm/SalesOrderCustomerDeliveryForm';

import classes from './SalesOrderCustomerDeliveryFormDialog.module.scss';

class SalesOrderCustomerDeliveryFormDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }
  onClose = () => {
    this.props.onClose();
  };
  onEntered = () => {
  };
  handleSubmit = async (values) => {
    this.setState({
      isLoading: true,
    });
    const { data: response } = await salesOrderApi.updateCustomerDelivery({
      params: {
        id: this.props.salesOrderId,
        is_taxcode: values.hasTaxCode,
        taxcode: values.taxCode,
        name: values.name,
        phone: (/^(84)/).test(values.phone) ? values.phone.replace(/^(84)/, '+84') : values.phone,
        label: values.label,
        address: values.address,
        getcare_country_id: values.countryId,
        getcare_province_id: values.provinceId,
        getcare_district_id: values.districtId,
        getcare_ward_id: values.wardId,
      }
    });
    if (!response.result) {
      toast.error(response.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({
      isLoading: false,
    }, () => {
      this.props.onSaveSuccess(response.data);
      this.props.onClose();
    });
  }

  render() {
    const { isOpen } = this.props;
    const { isLoading } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentWrap} ${isLoading && 'OverlayLoading'}`}>
          <DialogTitle>Thay đổi thông tin nhận hàng</DialogTitle>
          <SalesOrderCustomerDeliveryForm
            isOnDialog
            onClose={this.props.onClose}
            onSubmit={this.handleSubmit}
          />
        </div>
      </Dialog>
    );
  }
}

export default SalesOrderCustomerDeliveryFormDialog;
