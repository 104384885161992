import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/omsSalesOrderConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getSalesOrderStatuses } from 'redux/actions/oms/salesOrderActions';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderStatuses } from 'redux/selectors';

import FilterField from 'components/FilterField/FilterField';
import PriceRange from 'components/FilterField/PriceRange/PriceRange';
import DateRange from 'components/FilterField/DateRange/DateRange';
import MultiSelect from 'components/FilterField/MultiSelect';

import classes from './SalesOrderListFilters.module.scss';

class SalesOrderListFilters extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }
  componentDidMount() {
    this.props.getSalesOrderStatuses();
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex((item) => item.name === fieldName);
  };
  handleFieldChange = ({ name, value }) => {
    const fieldIndex = this._getFieldIndex(name);
    if (fieldIndex < 0) return;
    const fields = [...this.state.fields];
    fields[fieldIndex] = { ...this.state.fields[fieldIndex], value: value };
    this.setState({
      fields: fields,
    });
    this.props.onFilterChange(fields, true);
  };

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            ['amount_total'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<PriceRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['created_at'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={['', '']}
                customField={<DateRange
                  name={field.name}
                  value={field.value}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : ['getcare_order_status_id'].includes(field.name) ? (
              <FilterField
                key={field.name}
                {...field}
                defaultValue={[]}
                customField={<MultiSelect
                  name={field.name}
                  value={field.value}
                  placeholder={field.placeholder}
                  options={this.props.salesOrderStatuses}
                  handleFieldChange={this.handleFieldChange}
                />}
                handleFieldChange={this.handleFieldChange}
              />
            ) : (<FilterField
                key={field.name}
                {...field}
                handleFieldChange={this.handleFieldChange}
              />)
          ))}
        </div>
      </div>
    );
  }
}

SalesOrderListFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  salesOrderStatuses: makeSelectSalesOrderStatuses(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSalesOrderStatuses: (payload) => dispatch(getSalesOrderStatuses(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderListFilters);
