import React from 'react';
import PropTypes from 'prop-types';
import { filterFields, approvalStatusMap } from 'utils/constanst/adminCustomerConstants';

import FilterField from 'components/FilterField/FilterField';
import MultiSelect from 'components/FilterField/MultiSelect';
import Checkboxes from 'components/FilterField/Checkboxes';
import customerApi from 'utils/api/mdm/customerApi';

import classes from './CustomerFilters.module.scss';

class CustomerFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
      customerTypes: []
    }
  }

  async componentDidMount() {
    const { data: response } = await customerApi.getCustomerTypes();
    if (!response.result || !response.data) return;
    this.setState({
      customerTypes: [...response.data],
    })
  }

  _getFieldIndex = (fieldName) => {
    return this.state.fields.findIndex(item => item.name === fieldName);
  }

  handleFieldChange = ({ name, value }) => {
    const fieldIndex = this._getFieldIndex(name);
    if (fieldIndex < 0) return;
    const fields = [...this.state.fields];
    fields[fieldIndex] = {...this.state.fields[fieldIndex], value: value};
    this.setState({
      fields: fields,
    });
    this.props.onFilterChange(fields, true);
  }

  render() {
    const { fields, customerTypes } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              defaultValue={['approval', 'getcare_customer_type_id'].includes(field.name) ? [] : ''}
              handleFieldChange={this.handleFieldChange}
              customField={
                field.name === 'approval' ? (
                  <Checkboxes
                    name={field.name}
                    value={field.value}
                    multiple
                    valuesMap={approvalStatusMap}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : (field.name === 'getcare_customer_type_id' ? (
                  <MultiSelect
                    name={field.name}
                    value={field.value}
                    placeholder={field.placeholder}
                    options={customerTypes}
                    handleFieldChange={this.handleFieldChange}
                  />
                ) : '')
              }
            />
          ))}
        </div>
      </div>
    )
  }
}

CustomerFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default CustomerFilters;
