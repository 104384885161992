import React, { PureComponent } from 'react';
import { currencyFormatVN } from 'utils/helper';
import gridClasses from '../FastOrderProductsGrid.module.scss';

class FastOrderDiscount extends PureComponent {
  render() {
    const { promotion_name, promotion_code, amount } = this.props;

    return (
      <div className={`${gridClasses.Row} ${gridClasses.DiscountRow}`}>
        <div className={gridClasses.GroupCol}>
          <div className={`${gridClasses.Col}`}>{`Chương trình chiết khấu ${
            promotion_name ? promotion_name + ` ` : ``
          }${promotion_code}`}</div>
          <div className={`${gridClasses.Col} TextRight`}>
            {['', null, undefined].includes(amount)
              ? ''
              : `-${currencyFormatVN(amount)}`}
          </div>
        </div>
      </div>
    );
  }
}

export default FastOrderDiscount;
