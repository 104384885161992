import authApi from 'utils/api/authApi';
import { put } from 'redux-saga/effects';
import { authActions } from 'redux/actions';

export function* checkAuthentication(payload) {
  try {
    yield put(authActions.saveAuth(null));

    const { data: response } = yield authApi.checkAuth();

    if (!response?.result) {
      yield put(authActions.saveAuth({}));

      return;
    }
    yield put(authActions.saveAuth(response.data ? {...response.data} : {}));
    yield put(authActions.saveAuthRedirectUrl(response.redirect_url ? response.redirect_url : ''));
  } catch (err) {
    console.log(err);
  }
}
export function* signMeOut(payload) {
  try {
    const { data: response } = yield authApi.signOut();
    if (!response?.result) {
      return;
    }
    const { data: authResponse } = yield authApi.checkAuth();
    if (!authResponse?.result || !authResponse.data) {
      yield put(authActions.removeAuth());
      return;
    }
    yield put(authActions.saveAuth(authResponse.data));
  } catch (err) {
    console.log(err);
  }
}
