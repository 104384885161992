import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionBonuses,
  makeSelectPromotionRules,
} from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionBonusesDisplayFields,
  promotionBonusesParamsMap,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionBonus from './GetcarePromotionBonus/GetcarePromotionBonus';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionBonusesGrid.module.scss';
import classes from './GetcarePromotionBonuses.module.scss';

class GetcarePromotionBonuses extends PureComponent {
  _rerenderPromotionBonuses = () => {
    const { promotionBonuses, promotionRules } = this.props;
    return promotionBonuses.map(item => {
      const isAtleastOne = promotionBonuses.filter(p => p.level === item.level).length <= 1;
      const isInRule = promotionRules.some(r => r.level === item.level && r.bonus_mode !== 1 && parseFloat(r.bonus_number) > 0);
      return {
        ...item,
        disabledRemoving: isAtleastOne && isInRule,
      }
    });
  }

  render() {
    const displayFields = getDisplayFields(
      promotionBonusesParamsMap,
      promotionBonusesDisplayFields
    );
    const list = this._rerenderPromotionBonuses();

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {list.length ? (
            list.map((item) => (
              <GetcarePromotionBonus
                key={`promotion-product-${item.id || item.idStr}`}
                {...item}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionBonuses.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionBonuses.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionBonuses: makeSelectPromotionBonuses(),
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionBonuses);
