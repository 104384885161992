import fastOrderApi from 'utils/api/ecom/fastOrderApi';
import { put } from 'redux-saga/effects';
import { fastOrderActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadPRDraft(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveLoading(true));
    yield put(fastOrderActions.savePRDetails(null));

    yield put(fastOrderActions.savePRDraftBonuses([]));
    yield put(fastOrderActions.savePRDraftDiscounts([]));
    yield put(fastOrderActions.savePRDraftNotes([]));
    yield put(fastOrderActions.savePRDraftLogistic(null));
    yield put(fastOrderActions.savePRDraftDelivery(null));
    yield put(fastOrderActions.savePRProducts([]));

    const { data: response } = yield fastOrderApi.getPurchaseRequestDraft({
      params,
    });

    yield put(fastOrderActions.saveLoading(false));

    if (!response?.result || !response?.data) {
      return;
    }

    yield put(
      fastOrderActions.savePRDetails({
        ...response.data,
        getcare_purchase_request_items: undefined,
        getcare_purchase_request_bonuss: undefined,
        getcare_purchase_request_discounts: undefined,
        getcare_purchase_request_notes: undefined,
      })
    );
    yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
    yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
    yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
    yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
    yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
    yield put(fastOrderActions.savePRProducts(response.data.getcare_purchase_request_items || []));
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    toast.error(err.name || JSON.stringify(err));
  }
}
export function* updatePRDraft(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveLoading(params.willSaveLoading ? true : false));

    const { data: response } = yield fastOrderApi.updatePurchaseRequestDraft({
      params,
    });
    yield put(fastOrderActions.savePromotionAvailables([]));

    yield put(fastOrderActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      yield put(fastOrderActions.savePromotionAvailables('Out of stock'));
      return;
    }
    if (params.willUpdatePRDetails) {
      yield put(
        fastOrderActions.savePRDetails({
          ...response.data,
          getcare_purchase_request_items: undefined,
          getcare_purchase_request_bonuss: undefined,
          getcare_purchase_request_discounts: undefined,
          getcare_purchase_request_notes: undefined,
        })
      );
      yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
      yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
      yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
      yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
      yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
      yield put(fastOrderActions.savePRProducts(response.data.getcare_purchase_request_items || []));
    }
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    toast.error(err.name || JSON.stringify(err));
  }
}
export function* createPR(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveLoading(true));

    const { data: response } = yield fastOrderApi.createPurchaseRequest({
      params,
    });

    yield put(fastOrderActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(
      fastOrderActions.savePRDetails({
        ...response.data,
        getcare_purchase_request_items: undefined,
        getcare_purchase_request_bonuss: undefined,
        getcare_purchase_request_discounts: undefined,
        getcare_purchase_request_notes: undefined,
      })
    );
    yield put(fastOrderActions.savePRDraftBonuses(response.data.getcare_purchase_request_bonuss || []));
    yield put(fastOrderActions.savePRDraftNotes(response.data.getcare_purchase_request_notes || []));
    yield put(fastOrderActions.savePRDraftDiscounts(response.data.getcare_purchase_request_discounts || []));
    yield put(fastOrderActions.savePRDraftLogistic(response.data.logistic_provider || null));
    yield put(fastOrderActions.savePRDraftDelivery(response.data.customer_delivery || null));
    yield put(fastOrderActions.savePRProducts(response.data.getcare_purchase_request_items || []));
  } catch (err) {
    yield put(fastOrderActions.saveLoading(false));
    toast.error(err.name || JSON.stringify(err));
  }
}

export function* testPromotion(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveActionLoading(true));

    const { data: response } = yield fastOrderApi.checkPromotion({ params });
    yield put(fastOrderActions.saveActionLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(fastOrderActions.savePromotionAvailables(response.data.promotion_availables || []));
    yield put(fastOrderActions.savePromotionActives(response.data.promotion_actives || []));
  } catch (err) {
    yield put(fastOrderActions.saveActionLoading(false));
    toast.error(err.name || JSON.stringify(err));
  }
}

export function* rateOrdering(payload) {
  const { params } = payload.payload;
  try {
    yield put(fastOrderActions.saveActionLoading(true));

    const { data: response } = yield fastOrderApi.rateOrdering({ params });
    yield put(fastOrderActions.saveActionLoading(false));
    if (!response?.result || !response?.data) {
      toast.error(response.message);
      return;
    }
    yield put(fastOrderActions.savePRDetails(null));

    yield put(fastOrderActions.savePRDraftBonuses([]));
    yield put(fastOrderActions.savePRDraftDiscounts([]));
    yield put(fastOrderActions.savePRDraftNotes([]));
    yield put(fastOrderActions.savePRDraftLogistic(null));
    yield put(fastOrderActions.savePRDraftDelivery(null));
    yield put(fastOrderActions.savePRProducts([]));
  } catch (err) {
    yield put(fastOrderActions.saveActionLoading(false));
    toast.error(err.name || JSON.stringify(err));
  }
}
