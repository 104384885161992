import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getDisplayFields } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectFastOrderBonuses } from 'redux/selectors';
import { prBonusesParamsMap, prBonusesDisplayFields } from 'utils/constanst/ecomFastOrderConstants';

import FastOrderBonus from './FastOrderBonus/FastOrderBonus';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import classes from './FastOrderBonuses.module.scss';
import gridClasses from './FastOrderBonusesGrid.module.scss';

class FastOrderBonuses extends PureComponent {
  render() {
    const { prBonuses } = this.props;
    const displayFields = getDisplayFields(prBonusesParamsMap, prBonusesDisplayFields);

    return (<>
      { prBonuses && prBonuses.length > 0 &&
        <section className={`${classes.Wrap}`}>
          <h3 className="SectionTitle">Danh sách hàng khuyến mãi</h3>
          <div className={`${classes.List}`}>
            <div className={`${gridClasses.Row} ${gridClasses.Header}`}>
              {displayFields.map((item) => (
                <ListHeaderCol
                  key={item.name}
                  className={gridClasses.Col}
                  {...item}
                />
              ))}
            </div>
            <div className={`${classes.Body}`}>
              {prBonuses.map((item, index) => (
                <FastOrderBonus
                  key={`so-bonus-${item.id || index + 1}`}
                  {...item}
                  index={index}
                />
              ))}
            </div>
          </div>
        </section>
      }
    </>);
  }
}

FastOrderBonuses.propTypes = {
  prBonuses: PropTypes.array,
};

FastOrderBonuses.defaultProps = {
  prBonuses: [],
};

const mapStateToProps = createStructuredSelector({
  prBonuses: makeSelectFastOrderBonuses(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(FastOrderBonuses);
