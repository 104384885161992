import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder } from 'redux/selectors';

import Panel from 'components/Panel/Panel';

import classes from './SalesOrderSalesInfo.module.scss';

class SalesOrderSalesInfo extends PureComponent {
  render() {
    const { salesOrder } = this.props;

    return (<>
      <Panel
        title="Phụ trách bởi"
        size="sm"
        panelClassName={classes.Panel}
        content={<div className={classes.Wrap}>
          { salesOrder && salesOrder?.sales_name && (
            <>
              <div className={classes.Info}>
                <label>Trình Dược Viên</label>
                {salesOrder?.sales_name || `-`}
              </div>
              <div className={classes.Info}>
                <label>Số điện thoại</label>
                {salesOrder?.sales_phone || `-`}
              </div>
              { salesOrder?.sales_note &&
                (<div className={`${classes.Info} ${classes.Note}`}>
                  <div className={classes.NoteContent}>{salesOrder.sales_note}</div>
                </div>)
              }
            </>)
          }
        </div>}
        isBorder
      />
    </>)
  }
}

SalesOrderSalesInfo.propTypes = {
  salesOrder: PropTypes.object,
};
SalesOrderSalesInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderSalesInfo);
