import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  vendorGroupTypes,
  GROUP_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import classes from './GetcarePromotionVendor.module.scss';
import gridClasses from '../GetcarePromotionVendorsGrid.module.scss';

class GetcarePromotionVendor extends PureComponent {
  render() {
    const {
      type_id,
      getcare_vendor,
      getcare_erp_group,
    } = this.props;

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row}`}>
          <div className={gridClasses.Col}>
            <Select
              disabled
              className={classes.SelectWrap}
              value={type_id}
              name="type_id"
              size="small"
              fullWidth
            >
              {
                vendorGroupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
          <div className={`${gridClasses.Col}`}>
            { type_id === GROUP_TYPE_ID ? getcare_erp_group?.code : getcare_vendor?.code }
          </div>
          <div className={`${gridClasses.Col}`}>
            { type_id === GROUP_TYPE_ID ? getcare_erp_group?.name : getcare_vendor?.name }
          </div>
          <div className={`${gridClasses.Col}`}>
            { type_id === GROUP_TYPE_ID ? getcare_erp_group?.description : getcare_vendor?.description }
          </div>
          <div className={`${gridClasses.Col}`}>
            { type_id === GROUP_TYPE_ID ? getcare_erp_group?.count : getcare_vendor?.count }
          </div>
        </div>
      </>
    );
  }
}

GetcarePromotionVendor.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionVendor.defaultProps = {
};

export default GetcarePromotionVendor;
