import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrder, makeSelectSalesOrderDelivery } from 'redux/selectors';

import {
  UPDATE_CUSTOMER_DELIVERY,
  getActionItem,
} from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeliveryView from './DeliveryView/DeliveryView';
import SalesOrderCustomerDeliveryFormDialog from 'components/so/SalesOrderCustomerDeliveryFormDialog/SalesOrderCustomerDeliveryFormDialog';

import classes from './SalesOrderDeliveryInfo.module.scss';

class SalesOrderDeliveryInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
    }
  }

  handleOpenDialog = () => {
    this.setState({
      isDialogOpen: true,
    });
  }
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  }
  handleSaveDeliverySuccess = (newSalesOrderData) => {
    this.props.onSaveDeliveryInfoSuccess(newSalesOrderData);
  }

  render() {
    const { salesOrder } = this.props;

    return (<>
      <Panel
        title="Thông tin nhận hàng"
        titleAction={
          getActionItem(UPDATE_CUSTOMER_DELIVERY, salesOrder?.actions)
          ? <IconButton
              size="small"
              variant="contained"
              color="primary"
              onClick={this.handleOpenDialog}
              ><EditIcon fontSize="small" /></IconButton>
          : ''
        }
        size="sm"
        panelClassName={classes.Panel}
        content={<DeliveryView
          salesOrder={{...this.props.salesOrder}}
          salesOrderDelivery={{...this.props.salesOrderDelivery}}
        />}
        isBorder
      />
      {
        this.state.isDialogOpen &&
        <SalesOrderCustomerDeliveryFormDialog
          isOpen={this.state.isDialogOpen}
          salesOrderId={salesOrder?.id}
          onClose={this.handleCloseDialog}
          onSaveSuccess={this.handleSaveDeliverySuccess}
        />
      }
    </>)
  }
}

SalesOrderDeliveryInfo.propTypes = {
};
SalesOrderDeliveryInfo.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  salesOrder: makeSelectSalesOrder(),
  salesOrderDelivery: makeSelectSalesOrderDelivery(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(SalesOrderDeliveryInfo);
