import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { validDate, currencyFormatVN } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';
import { getStatusClass } from 'utils/constanst/omsSalesOrderConstants';
import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import gridClasses from '../SalesOrderListGrid.module.scss';
import classes from './SalesOrderListItem.module.scss';

class SalesOrderListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/so/${this.props.code}`);
  }
  stopPropagation = (e) => {
    e.stopPropagation();
  };

  render() {
    const {
      code,
      getcare_order_status,
      customer_name,
      customer_code,
      created_at,
      sales_name,
      amount_total,
      amount_delivery,
      delivery_by_getcare,
      order_most_items,
    } = this.props;

    return <Link
      to={`/so/${code}`}
      className={`${classes.Item} ${gridClasses.Row}`}
    >
      <div className={gridClasses.Col}>{ code }</div>
      <div className={gridClasses.Col}>{ validDate(created_at) ? format(validDate(created_at), dateTimeFormat) : '' }</div>
      <div className={gridClasses.Col}><p>{ customer_name }</p><p className={classes.CustomerCode}>{ customer_code }</p></div>
      <div className={`${gridClasses.Col} ${classes.OrderMostItemsCol}`}>{ order_most_items.map((item, index) => (
        <p key={`order-item-${index}`}>{`• ${item.product_name} `}<span>{`(${item.quantity_number} ${item.uom_base_name})`}</span></p>
      )) }</div>
      <div className={gridClasses.Col}>{ sales_name }</div>
      <div className={`${gridClasses.Col}`}>{ ['', null, undefined].includes(amount_total) ? '' : currencyFormatVN(amount_total) }</div>
      <div className={gridClasses.Col}>{ ['', null, undefined].includes(amount_delivery) ? '-' : currencyFormatVN(amount_delivery) }</div>
      <div className={`${gridClasses.Col} TextCenter`}>
        <span className={getStatusClass(getcare_order_status?.id)}>
          {getcare_order_status?.name}
        </span>
      </div>
      <div className={`${gridClasses.Col} TextCenter`}>
        <span className={delivery_by_getcare === true ? getStatusClass(delivery_by_getcare): null}>
          {delivery_by_getcare === true ?  <CheckIcon fontSize="small" /> : ''}
        </span>
      </div>
    </Link>;
  }
}

SalesOrderListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  code: PropTypes.string,
  getcare_order_type: PropTypes.object,
  getcare_purchase_request_status: PropTypes.object,
  customer_name: PropTypes.string,
  created_at: PropTypes.string,
  sales_name: PropTypes.string,
  amount_total: PropTypes.number,
  order_most_items: PropTypes.array,
  note_latest: PropTypes.string,
};

SalesOrderListItem.defaultProps = {
  order_most_items: [],
};

export default withRouter(SalesOrderListItem);
