import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { approvalStatusMap } from 'utils/constanst/adminCustomerConstants';
import { Link } from 'react-router-dom';

import gridClasses from '../CustomerListGrid.module.scss';
import classes from './CustomerListItem.module.scss';

class CustomerListItem extends PureComponent {
  goToDetails = (e) => {
    this.props.history.push(`/customer/${this.props.id}`);
  }

  render() {
    const {
      id,
      customer_vendor_code,
      name,
      getcare_customer_type,
      registration_number,
      tax_code,
      type_pharmacy,
      representative_name,
      expertise_person,
      expertise_number,
      phone,
      address,
      approval,
    } = this.props;

    return <Link
      className={`${classes.ProductItem} ${gridClasses.Row}`}
      to={`/customer/${id}`}
    >
      <div className={gridClasses.Col}>{ getcare_customer_type?.name }</div>
      <div className={gridClasses.Col}>{ customer_vendor_code }</div>
      <div className={gridClasses.Col}>{ name }</div>
      <div className={gridClasses.Col}>{ tax_code }</div>
      <div className={gridClasses.Col}>{ phone }</div>
      <div className={gridClasses.Col}>{ address }</div>
      <div className={gridClasses.Col}>{ registration_number }</div>
      <div className={gridClasses.Col}>{ type_pharmacy }</div>
      <div className={gridClasses.Col}>{ representative_name }</div>
      <div className={gridClasses.Col}>{ expertise_person }</div>
      <div className={gridClasses.Col}>{ expertise_number }</div>
      <div className={gridClasses.Col}>{ approvalStatusMap[approval] }</div>
    </Link>;
  }
}

CustomerListItem.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  customer_vendor_code: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  registration_number: PropTypes.string,
  tax_code: PropTypes.string,
  type_pharmacy: PropTypes.string,
  branch_create: PropTypes.string,
  representative_name: PropTypes.string,
  expertise_person: PropTypes.string,
  expertise_number: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  approval: PropTypes.number,
};

export default withRouter(CustomerListItem);
