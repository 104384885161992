import customerApi from 'utils/api/mdm/customerApi';
import { pharmacyActions } from 'redux/actions';
import { put } from 'redux-saga/effects';

export function* loadPharmacyList(payload) {
  const { params, cancelToken } = payload.payload;
  try {
    yield put(pharmacyActions.saveLoading(true));
    const { data: response } = yield customerApi.getList({ params, cancelToken });
    yield put(pharmacyActions.saveLoading(false));
    if (!response?.result || !response?.data) {
      return;
    }
    yield put(pharmacyActions.savePharmacyList([...response.data]));
    yield put(pharmacyActions.savePharmacyListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadPharmacy(payload) {
  const { id, params } = payload.payload;
  try {
    yield put(pharmacyActions.savePharmacy(null));
    yield put(pharmacyActions.saveLoading(true));

    const { data: response } = yield customerApi.get({ id, params });

    yield put(pharmacyActions.saveLoading(false));
    if (!response?.result) {
      return;
    }
    yield put(pharmacyActions.savePharmacy(response.data ? {...response.data} : null));
  } catch (err) {
    console.log(err);
  }
}
