import {
  GET_PROMOTION_TYPES,
  GET_PROMOTION_OPERATORS,
  GET_PROMOTION_COMPARES,
  GET_PROMOTION_PRIORITIES,
  GET_PROMOTION_APPLIES,
  GET_PROMOTION_DISCOUNTS,
  SAVE_PROMOTION_TYPES,
  SAVE_PROMOTION_OPERATORS,
  SAVE_PROMOTION_COMPARES,
  SAVE_PROMOTION_PRIORITIES,
  SAVE_PROMOTION_APPLIES,
  SAVE_PROMOTION_DISCOUNTS,
  GET_PROMOTION_LIST,
  SAVE_PROMOTION_LIST,
  SAVE_PROMOTION_LIST_TOTAL,
  SAVE_LOADING,
  GET_PROMOTION,
  UPDATE_PROMOTION,
  RESET_PROMOTION,

  GET_PROMOTION_HISTORY,
  SAVE_PROMOTION_HISTORY,

  SAVE_ORI_PROMOTION_DETAILS,
  SAVE_PROMOTION_DETAILS,

  SAVE_ORI_PROMOTION_RULES,
  SAVE_PROMOTION_RULES,
  SAVE_PROMOTION_RULE_ITEM,
  REMOVE_PROMOTION_RULE_ITEMS,

  SAVE_ORI_PROMOTION_PRODUCTS,
  SAVE_PROMOTION_PRODUCTS,
  SAVE_PROMOTION_PRODUCT_ITEM,
  REMOVE_PROMOTION_PRODUCT_ITEMS,

  SAVE_ORI_PROMOTION_MAX_PRODUCTS,
  SAVE_PROMOTION_MAX_PRODUCTS,
  SAVE_PROMOTION_MAX_PRODUCT_ITEM,
  REMOVE_PROMOTION_MAX_PRODUCT_ITEMS,

  SAVE_ORI_PROMOTION_BONUSES,
  SAVE_PROMOTION_BONUSES,
  SAVE_PROMOTION_BONUS_ITEM,
  REMOVE_PROMOTION_BONUS_ITEMS,

  SAVE_ORI_PROMOTION_VENDORS,
  SAVE_PROMOTION_VENDORS,
  SAVE_PROMOTION_VENDOR_ITEM,
  REMOVE_PROMOTION_VENDOR_ITEMS,

  SAVE_ORI_PROMOTION_CUSTOMERS,
  SAVE_PROMOTION_CUSTOMERS,
  SAVE_PROMOTION_CUSTOMER_ITEM,
  REMOVE_PROMOTION_CUSTOMER_ITEMS,
} from 'redux/constants/tmk/promotionConstants';

export function getPromotionTypes(payload) {
  return {
    type: GET_PROMOTION_TYPES,
    payload,
  };
}
export function getPromotionOperators(payload) {
  return {
    type: GET_PROMOTION_OPERATORS,
    payload,
  };
}
export function getPromotionCompares(payload) {
  return {
    type: GET_PROMOTION_COMPARES,
    payload,
  };
}
export function getPromotionPriorites(payload) {
  return {
    type: GET_PROMOTION_PRIORITIES,
    payload,
  };
}
export function getPromotionApplies(payload) {
  return {
    type: GET_PROMOTION_APPLIES,
    payload,
  };
}
export function getPromotionDiscounts(payload) {
  return {
    type: GET_PROMOTION_DISCOUNTS,
    payload,
  };
}

export function getPromotionList(payload) {
  return {
    type: GET_PROMOTION_LIST,
    payload,
  };
}
export function getPromotion(payload) {
  return {
    type: GET_PROMOTION,
    payload,
  };
}

export function updatePromotion(payload) {
  return {
    type: UPDATE_PROMOTION,
    payload,
  };
}

export function getPromotionHistory(payload) {
  return {
    type: GET_PROMOTION_HISTORY,
    payload,
  };
}

// mutation
export function savePromotionTypes(promotionTypes) {
  return {
    type: SAVE_PROMOTION_TYPES,
    promotionTypes,
  };
}
export function savePromotionOperators(promotionOperators) {
  return {
    type: SAVE_PROMOTION_OPERATORS,
    promotionOperators,
  };
}
export function savePromotionCompares(promotionCompares) {
  return {
    type: SAVE_PROMOTION_COMPARES,
    promotionCompares,
  };
}
export function savePromotionPriorites(promotionPriorites) {
  return {
    type: SAVE_PROMOTION_PRIORITIES,
    promotionPriorites,
  };
}
export function savePromotionApplies(promotionApplies) {
  return {
    type: SAVE_PROMOTION_APPLIES,
    promotionApplies,
  };
}
export function savePromotionDiscounts(promotionDiscounts) {
  return {
    type: SAVE_PROMOTION_DISCOUNTS,
    promotionDiscounts,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function savePromotionList(promotionList) {
  return {
    type: SAVE_PROMOTION_LIST,
    promotionList,
  };
}
export function savePromotionListTotal(promotionListTotal) {
  return {
    type: SAVE_PROMOTION_LIST_TOTAL,
    promotionListTotal,
  };
}

export function resetPromotion(payload) {
  return {
    type: RESET_PROMOTION,
    payload,
  };
}

export function saveOriPromotionDetails(oriPromotionDetails) {
  return {
    type: SAVE_ORI_PROMOTION_DETAILS,
    oriPromotionDetails,
  };
}
export function savePromotionDetails(promotionDetails) {
  return {
    type: SAVE_PROMOTION_DETAILS,
    promotionDetails,
  };
}

export function saveOriPromotionRules(oriPromotionRules) {
  return {
    type: SAVE_ORI_PROMOTION_RULES,
    oriPromotionRules,
  };
}
export function savePromotionRules(promotionRules) {
  return {
    type: SAVE_PROMOTION_RULES,
    promotionRules,
  };
}
export function savePromotionRuleItem(promotionRuleItem) {
  return {
    type: SAVE_PROMOTION_RULE_ITEM,
    promotionRuleItem,
  };
}
export function removePromotionRuleItems(promotionRuleItems) {
  return {
    type: REMOVE_PROMOTION_RULE_ITEMS,
    promotionRuleItems,
  };
}

export function saveOriPromotionProducts(oriPromotionProducts) {
  return {
    type: SAVE_ORI_PROMOTION_PRODUCTS,
    oriPromotionProducts,
  };
}
export function savePromotionProducts(promotionProducts) {
  return {
    type: SAVE_PROMOTION_PRODUCTS,
    promotionProducts,
  };
}
export function savePromotionProductItem(promotionProductItem) {
  return {
    type: SAVE_PROMOTION_PRODUCT_ITEM,
    promotionProductItem,
  };
}
export function removePromotionProductItems(promotionProductItems) {
  return {
    type: REMOVE_PROMOTION_PRODUCT_ITEMS,
    promotionProductItems,
  };
}

export function saveOriPromotionMaxProducts(oriPromotionMaxProducts) {
  return {
    type: SAVE_ORI_PROMOTION_MAX_PRODUCTS,
    oriPromotionMaxProducts,
  };
}
export function savePromotionMaxProducts(promotionMaxProducts) {
  return {
    type: SAVE_PROMOTION_MAX_PRODUCTS,
    promotionMaxProducts,
  };
}
export function savePromotionMaxProductItem(promotionMaxProductItem) {
  return {
    type: SAVE_PROMOTION_MAX_PRODUCT_ITEM,
    promotionMaxProductItem,
  };
}
export function removePromotionMaxProductItems(promotionMaxProductItems) {
  return {
    type: REMOVE_PROMOTION_MAX_PRODUCT_ITEMS,
    promotionMaxProductItems,
  };
}

export function saveOriPromotionBonuses(oriPromotionBonuses) {
  return {
    type: SAVE_ORI_PROMOTION_BONUSES,
    oriPromotionBonuses,
  };
}
export function savePromotionBonuses(promotionBonuses) {
  return {
    type: SAVE_PROMOTION_BONUSES,
    promotionBonuses,
  };
}
export function savePromotionBonusItem(promotionBonusItem) {
  return {
    type: SAVE_PROMOTION_BONUS_ITEM,
    promotionBonusItem,
  };
}
export function removePromotionBonusItems(promotionBonusItems) {
  return {
    type: REMOVE_PROMOTION_BONUS_ITEMS,
    promotionBonusItems,
  };
}

export function saveOriPromotionVendors(oriPromotionVendors) {
  return {
    type: SAVE_ORI_PROMOTION_VENDORS,
    oriPromotionVendors,
  };
}
export function savePromotionVendors(promotionVendors) {
  return {
    type: SAVE_PROMOTION_VENDORS,
    promotionVendors,
  };
}
export function savePromotionVendorItem(promotionVendorItem) {
  return {
    type: SAVE_PROMOTION_VENDOR_ITEM,
    promotionVendorItem,
  };
}
export function removePromotionVendorItems(promotionVendorItems) {
  return {
    type: REMOVE_PROMOTION_VENDOR_ITEMS,
    promotionVendorItems,
  };
}

export function saveOriPromotionCustomers(oriPromotionCustomers) {
  return {
    type: SAVE_ORI_PROMOTION_CUSTOMERS,
    oriPromotionCustomers,
  };
}
export function savePromotionCustomers(promotionCustomers) {
  return {
    type: SAVE_PROMOTION_CUSTOMERS,
    promotionCustomers,
  };
}
export function savePromotionCustomerItem(promotionCustomerItem) {
  return {
    type: SAVE_PROMOTION_CUSTOMER_ITEM,
    promotionCustomerItem,
  };
}
export function removePromotionCustomerItems(promotionCustomerItems) {
  return {
    type: REMOVE_PROMOTION_CUSTOMER_ITEMS,
    promotionCustomerItems,
  };
}

export function savePromotionHistory(promotionHistory) {
  return {
    type: SAVE_PROMOTION_HISTORY,
    promotionHistory,
  };
}
