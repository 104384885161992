import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { validDate } from 'utils/helper';
import { format, isSameDay, isAfter } from 'date-fns';
import { dateFormat } from 'utils/constanst/dateConstants';
import {
  makeSelectOriPromotionDetails,
  makeSelectPromotionDetails,
  makeSelectPromotionLoading,
  makeSelectOriPromotionRules,
  makeSelectPromotionRules,
  makeSelectOriPromotionProducts,
  makeSelectPromotionProducts,
  makeSelectOriPromotionMaxProducts,
  makeSelectPromotionMaxProducts,
  makeSelectOriPromotionBonuses,
  makeSelectPromotionBonuses,
  makeSelectOriPromotionVendors,
  makeSelectPromotionVendors,
  makeSelectOriPromotionCustomers,
  makeSelectPromotionCustomers,
  makeSelectLoginUser,
} from 'redux/selectors';
import {
  getPromotion,
  resetPromotion,
  updatePromotion,
  savePromotionDetails,
  getPromotionTypes,
  getPromotionApplies,
  getPromotionPriorites,
  getPromotionOperators,
  getPromotionDiscounts,
  savePromotionRules,
  savePromotionProducts,
  savePromotionMaxProducts,
  savePromotionBonuses,
  savePromotionVendors,
  savePromotionCustomers,
} from 'redux/actions/tmk/promotionActions';
import { isEqual } from 'lodash';
import {
  VENDOR_MODE_ALL,
  VENDOR_MODE_OPTION,
  CUSTOMER_MODE_ALL,
  BASE_ON_PERIOD,
  APPLY_AWAITING,
  STATUS_INACTIVE,
  APPLY_UNDEFINED,
  ACTIVITY_STOP,
  PROMOTION_TYPE_DOCUMENT,
  COMPARE_EQUAL,
  isPromotionCombo,
} from 'utils/constanst/tmkPromotionConstants';
import { toast } from 'react-toastify';
import { ACTIVE, DEACTIVE } from 'utils/constanst/common';
import promotionApi from 'utils/api/tmk/promotionApi';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import PromotionDetails from 'components/tmk/PromotionDetails/PromotionDetails';
import PromotionRules from 'components/tmk/PromotionRules/PromotionRules';
import PromotionProducts from 'components/tmk/PromotionProducts/PromotionProducts';
import PromotionBonuses from 'components/tmk/PromotionBonuses/PromotionBonuses';
import PromotionMaxProducts from 'components/tmk/PromotionMaxProducts/PromotionMaxProducts';
import PromotionHistory from 'components/tmk/PromotionHistory/PromotionHistory';
import ConfirmInactiveDialog from 'components/tmk/PromotionDetails/ConfirmInactiveDialog/ConfirmInactiveDialog';

import classes from './DuplicatePromotion.module.scss';

class DuplicatePromotion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmDialogOpen: false,
      createDuplicate: null,
      // isMine = true if this order had been created from the vendor
      isMine: true,
    }
    this.validate = {
      code: {
        template: /^[a-zA-Z0-9\-_\s]*$/,
        required: true,
        maxLength: 20,
      },
      name: {
        required: true,
        maxLength: 55,
      },
      description: {
        maxLength: 255,
      },
      start_date: {
        required: true,
      },
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this._loadData(id);
  }
  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.match.params;
    if (id !== prevProps.match.params.id) {
      this._loadData(id);
    }
    const { promotionDetails } = this.props;
    const { createDuplicate } = this.state;
    if (promotionDetails && promotionDetails.id) {
      if (
        !prevProps.promotionDetails ||
        promotionDetails.id !== prevProps.promotionDetails.id
      ) {
        if( createDuplicate !== prevState.createDuplicate && createDuplicate){
          this.props.history.push(`/dup/${promotionDetails.id}`);
        }else{
          this.props.history.push(`/promotion/${promotionDetails.id}`);
        }
        if (
          !prevProps.promotionDetails ||
          !prevProps.promotionDetails.id ||
          isNaN(prevProps.promotionDetails.id)
        ) {
          // created successfull
          this.props.history.push(`/dup/${promotionDetails.id}`);

          return;
        }

      }
    }
    if (promotionDetails && prevProps.promotionDetails
      && promotionDetails.getcare_promotion_type_id !== prevProps.promotionDetails.getcare_promotion_type_id) {
        this._resetVendorModeOption();
        this._resetTabsData();
    }
    if (promotionDetails && promotionDetails.id && isEqual(promotionDetails, prevProps.promotionDetails)) {
      this._setView();
    }
  }

  isCodeValid = () => {
    const code = this.props.promotionDetails?.code || '';
    return (
      code.trim() !== '' &&
      this.validate.code.template.test(code)
    );
  };
  isNameValid = () => {
    const name = this.props.promotionDetails?.name || '';
    return name.trim() !== '';
  };
  isTypeValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_type_id);
  };
  isVendorModeValid = () => {
    return !!this.props.promotionDetails?.vendor_mode;
  };
  isCustomerModeValid = () => {
    return !!this.props.promotionDetails?.customer_mode;
  };
  isApplyValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_apply_id || promotionDetails?.getcare_promotion_apply?.id);
  };
  isPriorityValid = () => {
    const { promotionDetails } = this.props;
    return !!(promotionDetails?.getcare_promotion_priority_id || promotionDetails?.getcare_promotion_priority?.id);
  };
  isStartDateValid = () => {
    if (!this.props.promotionDetails) return false;
    if (this._isEditing() && !this._isAwaitingProgress()) return true;
    const { start_date } = this.props.promotionDetails;
    return !!start_date && (validDate(start_date) && (isSameDay(new Date(start_date), new Date()) || isAfter(new Date(start_date), new Date())));
  }
  isEndDateValid = () => {
    if (!this.props.promotionDetails) return false;
    const { end_date, start_date } = this.props.promotionDetails;
    return !end_date
    || (start_date && validDate(start_date)
        && validDate(end_date)
        && (isSameDay(new Date(end_date), new Date(start_date)) || isAfter(new Date(end_date), new Date(start_date)))
    );
  }

  hasRules = () => {
    const { promotionRules } = this.props;
    return promotionRules && promotionRules.length > 0;
  }
  hasProducts = () => {
    const { promotionProducts, promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT) return true;
    return promotionProducts && promotionProducts.length > 0;
  }
  hasVendors = () => {
    return true;
  }
  hasCustomers = () => {
    const { promotionDetails } = this.props;
    if (promotionDetails && promotionDetails.customer_mode === CUSTOMER_MODE_ALL) return true;
    const { promotionCustomers } = this.props;
    return promotionCustomers && promotionCustomers.length > 0;
  }
  _isValid = () => {
    return (
      this.isCodeValid() &&
      this.isNameValid() &&
      this.isTypeValid() &&
      this.isVendorModeValid() &&
      this.isCustomerModeValid() &&
      this.isApplyValid() &&
      this.isPriorityValid() &&
      this.isStartDateValid() &&
      this.isEndDateValid() &&
      // this.hasRules() &&
      // this.hasProducts() &&
      this.hasVendors()
      // this.hasCustomers()
    );
  }

  _isAwaitingProgress = () => {
    return this._getActivityStatus() === APPLY_AWAITING;
  }
  _getActivityStatus = () => {
    const { promotionDetails } = this.props;
    if (!promotionDetails) return APPLY_AWAITING;
    if (promotionDetails.active === STATUS_INACTIVE || promotionDetails.apply === APPLY_UNDEFINED) return ACTIVITY_STOP;
    return promotionDetails.apply;
  }

  _getPromotionDetailsContrains = (params) => {
    return {
      code: params.code,
      name: params.name,
      description: params.description,

      baseOn: params.base_on,
      startDate: validDate(params.start_date) ? format(validDate(params.start_date), dateFormat) : '',
      endDate: validDate(params.end_date) ? format(validDate(params.end_date), dateFormat) : '',
      isStockFinished: params.is_stock_finished,

      applyId: params.getcare_promotion_apply_id || params.getcare_promotion_apply?.id,
      priorityId: params.getcare_promotion_priority_id || params.getcare_promotion_priority?.id,
    }
  }
  _isDetailsChanged = () => {
    if (!this._isEditing()) return false;
    const { oriPromotionDetails, promotionDetails } = this.props;
    if (!oriPromotionDetails || !promotionDetails) return false;
    return !isEqual(this._getPromotionDetailsContrains(promotionDetails), this._getPromotionDetailsContrains(oriPromotionDetails));
  }

  _getRuleContrains = (params) => {
    return {
      id: params.id,
      level: params.level,
      level_name: params.level_name,
      quantity_number: params.quantity_number,
      order_mode: params.order_mode,
      order_amount: params.order_amount,
      bonus_number: params.bonus_number,
      bonus_mode: params.bonus_mode,
      getcare_promotion_operator_id: params.getcare_promotion_operator?.id,
      getcare_promotion_discount_id: params.getcare_promotion_discount?.id,
      discount_amount: params.discount_amount,
      maximum_amount: params.maximum_amount,
    }
  }
  _getProductContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      product_vendor_code: params.getcare_product?.product_vendor_code,
      getcare_erp_group_id: params.getcare_erp_group?.id,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      ratio: params.ratio,
      focus: params.focus,
      quantity_number: params.quantity_number,
    }
  }
  _getMaxProductContrains = (params) => {
    return {
      id: params.id,
      product_vendor_code: params.getcare_product?.product_vendor_code,
      quantity_number: params.quantity_number,
    }
  }
  _getBonusContrains = (params) => {
    return {
      id: params.id,
      level: params.level,
      type_id: params.type_id,
      product_vendor_code: params.getcare_product?.product_vendor_code,
      getcare_erp_group_id: params.getcare_erp_group?.id,
      getcare_product_bonus_id: params.getcare_product_bonus?.id,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      ratio: params.ratio,
      quantity_number: params.quantity_number,
    }
  }
  _getVendorContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      getcare_vendor_id: params.getcare_vendor?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    }
  }
  _getCustomerContrains = (params) => {
    return {
      id: params.id,
      type_id: params.type_id,
      getcare_customer_id: params.getcare_customer?.id,
      getcare_erp_group_id: params.getcare_erp_group?.id,
    }
  }

  handleClickDuplicate = () => {
    this.props.history.push(`/promotion/new?dup=${this.props.promotionDetails?.id}`);
  };
  _isRulesChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionRules, oriPromotionRules } = this.props;
    if (!promotionRules || !oriPromotionRules) return false;

    const hasDeletedItem = oriPromotionRules.some(item => item.id && !promotionRules.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionRules.some(tempItem => {
      const tempItemMap = this._getRuleContrains(tempItem);
      return !oriPromotionRules.some(item => isEqual(
        tempItemMap,
        this._getRuleContrains(item)
      ))
    });
  }
  _isProductsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionProducts, oriPromotionProducts } = this.props;
    if (!promotionProducts || !oriPromotionProducts) return false;

    const hasDeletedItem = oriPromotionProducts.some(item => item.id && !promotionProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionProducts.some(tempItem => {
      const tempItemMap = this._getProductContrains(tempItem);
      return !oriPromotionProducts.some(item => isEqual(
        tempItemMap,
        this._getProductContrains(item)
      ))
    });
  }
  _isMaxProductsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionMaxProducts, oriPromotionMaxProducts } = this.props;
    if (!promotionMaxProducts || !oriPromotionMaxProducts) return false;

    const hasDeletedItem = oriPromotionMaxProducts.some(item => item.id && !promotionMaxProducts.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionMaxProducts.some(tempItem => {
      const tempItemMap = this._getMaxProductContrains(tempItem);
      return !oriPromotionMaxProducts.some(item => isEqual(
        tempItemMap,
        this._getMaxProductContrains(item)
      ))
    });
  }
  _isBonusesChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionBonuses, oriPromotionBonuses } = this.props;
    if (!promotionBonuses || !oriPromotionBonuses) return false;

    const hasDeletedItem = oriPromotionBonuses.some(item => item.id && !promotionBonuses.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionBonuses.some(tempItem => {
      const tempItemMap = this._getBonusContrains(tempItem);
      return !oriPromotionBonuses.some(item => isEqual(
        tempItemMap,
        this._getBonusContrains(item)
      ))
    });
  }
  _isVendorsChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionVendors, oriPromotionVendors } = this.props;
    if (!promotionVendors || !oriPromotionVendors) return false;

    const hasDeletedItem = oriPromotionVendors.some(item => item.id && !promotionVendors.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionVendors.some(tempItem => {
      const tempItemMap = this._getVendorContrains(tempItem);
      return !oriPromotionVendors.some(item => isEqual(
        tempItemMap,
        this._getVendorContrains(item)
      ))
    });
  }
  _isCustomersChanged = () => {
    if (!this._isEditing()) return false;

    const { promotionCustomers, oriPromotionCustomers } = this.props;
    if (!promotionCustomers || !oriPromotionCustomers) return false;

    const hasDeletedItem = oriPromotionCustomers.some(item => item.id && !promotionCustomers.some(p => p.id && p.id === item.id));
    if (hasDeletedItem) return true;

    return promotionCustomers.some(tempItem => {
      const tempItemMap = this._getCustomerContrains(tempItem);
      return !oriPromotionCustomers.some(item => isEqual(
        tempItemMap,
        this._getCustomerContrains(item)
      ))
    });
  }
  _resetVendorModeOption = () => {
    const { promotionDetails } = this.props;
    const params = {
      ...promotionDetails,
      vendor_mode: isPromotionCombo(promotionDetails?.getcare_promotion_type_id || promotionDetails?.getcare_promotion_type?.id)
        ? VENDOR_MODE_OPTION
        : (promotionDetails?.vendor_mode || VENDOR_MODE_ALL),
    };
    this.props.savePromotionDetails(params);
  }
  _resetTabsData = () => {
    this._loadRuleData();

    this.props.savePromotionRules([]);
    this.props.savePromotionProducts([]);
    this.props.savePromotionMaxProducts([]);
    this.props.savePromotionBonuses([]);
    this.props.savePromotionVendors([]);
    this.props.savePromotionCustomers([]);
  }
  _loadRuleData = () => {
    this.props.getPromotionDiscounts({
      params: {
        getcare_promotion_type_id: this.props.promotionDetails?.getcare_promotion_type_id,
      },
    });
    this.props.getPromotionOperators();
  }
  _loadData = (id) => {
    this.props.getPromotionTypes();
    this.props.getPromotionApplies();
    this.props.getPromotionPriorites();

    if (this._isEditing()) {
      this.props.getPromotion(id);
      this._loadRuleData();
      return;
    }
    this.props.resetPromotion();
  };
  _backToList = () => {
    this.props.history.push('/promotion');
  };
  _isVendorModeAll = () => {
    const { promotionDetails } = this.props;
    return promotionDetails?.vendor_mode === VENDOR_MODE_ALL;
  }
  _isCustomerModeAll = () => {
    return this.props.promotionDetails?.customer_mode === CUSTOMER_MODE_ALL;
  }
  _hasPromotionType = () => {
    const { promotionDetails } = this.props;
    return !!promotionDetails?.getcare_promotion_type_id || !!promotionDetails?.getcare_promotion_type;
  }
  _hasProductBonuses = () => {
    const { promotionRules } = this.props;
    return promotionRules.filter(rule => rule.bonus_number && rule.bonus_mode !== 1).length > 0;
  }
  _isBaseOnPeriod = () => {
    return this.props.promotionDetails?.base_on === BASE_ON_PERIOD;
  }
  _isEditing = () => {
    // const { id } = this.props.match.params;
    return true;
  };
  _isRulesNotCompleted = () => {
    return this.props.promotionRules.some(item => item.isEditing);
  }
  _isProductsNotCompleted = () => {
    return this.props.promotionProducts.some(item => item.isEditing);
  }
  _isProductMaximumsNotCompleted = () => {
    return this.props.promotionMaxProducts.some(item => !item.disabled && item.isEditing);
  }
  _isBonusesNotCompleted = () => {
    return this.props.promotionBonuses.some(item => item.isEditing);
  }
  _isVendorsNotCompleted = () => {
    return this.props.promotionVendors.some(item => item.isEditing);
  }
  _isCustomersNotCompleted = () => {
    return this.props.promotionCustomers.some(item => item.isEditing);
  }
  _isDocumentType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT;
  }
  hasNotCompletedForm = () => {
    return this._isRulesNotCompleted()
      || this._isProductsNotCompleted()
      || this._isProductMaximumsNotCompleted()
      || this._isBonusesNotCompleted()
      || this._isVendorsNotCompleted()
      || this._isCustomersNotCompleted()
  }
  isRulesValid = () => {
    let messages = [];
    if (!this.hasRules() || this._isDocumentType()) return messages;
    const { promotionRules } = this.props;
    for(let i = 1; i < promotionRules.length; i++) {
      if (promotionRules[i].quantity_number <= promotionRules[i - 1].quantity_number) {
        messages.push('• Số lượng mua level sau phải lớn hơn level trước');
        break;
      }
    }
    return messages;
  }
  isProductsValid = () => {
    let messages = [];
    if (!this.hasProducts()) return messages;
    const dupplicatedProducts = this.props.promotionProducts.reduce((memo, product, index) => {
      const idStr = `${product.type_id}${product.getcare_product?.product_vendor_code}${product.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedProducts).some(key => dupplicatedProducts[key] > 0)) {
      messages.push('• Nhóm/sản phẩm hàng bán không được trùng nhau');
    }
    return messages;
  }
  _isBonusesDuplicated = () => {
    const dupplicatedProducts = this.props.promotionBonuses.reduce((memo, product, index) => {
      const idStr = `${product.type_id}${product.getcare_product?.product_vendor_code}${product.getcare_erp_group?.id}${product.getcare_product_bonus?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    return Object.keys(dupplicatedProducts).some(key => dupplicatedProducts[key] > 0);
  }
  isBonusesValid = () => {
    let messages = [];
    const { promotionRules, promotionBonuses } = this.props;
    const promotionRulesNeedBonus = promotionRules.filter(item => item.bonus_mode !== 1);
    const quantityRulesMap = promotionRulesNeedBonus.reduce((memo, item) => {
      if (!!item.bonus_number) memo[item.level] = item.bonus_number;
      return memo;
    }, {});
    const quantityBonusesMap = promotionBonuses.reduce((memo, item) => {
      if (!memo[item.level]) {
        memo[item.level] = item.quantity_number;
      } else {
        memo[item.level] += item.quantity_number;
      }
      return memo;
    }, {});

    const hasNoNeedBonuses = Object.keys(quantityBonusesMap).some(key => quantityRulesMap[key] === undefined);
    if (hasNoNeedBonuses) {
      messages.push('• Bạn đang setup sai hàng tặng khác cho level chỉ tặng hàng tương tự');
    }

    const isBonusesQuantityEnough = !Object.keys(quantityRulesMap).some(key => {
      const bonusNumber = quantityBonusesMap[key];
      const rulesNumber = quantityRulesMap[key];
      return [null, undefined, 0].includes(bonusNumber) || Number(bonusNumber) > Number(rulesNumber);
    });
    if (!isBonusesQuantityEnough) {
      messages.push('• Hàng tặng theo từng level phải đủ số lượng cơ cấu');
    }
    return messages;
  }
  isVendorsValid = () => {
    let messages = [];
    if (!this.hasVendors()) return false;
    const dupplicatedItems = this.props.promotionVendors.reduce((memo, item, index) => {
      const idStr = `${item.type_id}${item.getcare_vendor?.id}${item.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedItems).some(key => dupplicatedItems[key] > 0)) {
      messages.push('• Nhóm/nhà cung cấp không được trùng nhau');
    }
    return messages;
  }
  isCustomersValid = () => {
    let messages = [];
    if (!this.hasCustomers()) return false;
    const dupplicatedItems = this.props.promotionCustomers.reduce((memo, item, index) => {
      const idStr = `${item.type_id}${item.getcare_customer?.id}${item.getcare_erp_group?.id}`;
      memo[idStr] = ++memo[idStr] || 0;
      return memo;
    }, {});
    if (Object.keys(dupplicatedItems).some(key => dupplicatedItems[key] > 0)) {
      messages.push('• Nhóm/khách hàng không được trùng nhau');
    }
    return messages;
  }
  isCodeDuplicated = async (codeStr) => {
    let messages = [];
    const value = codeStr.trim();
    const { data: response } = await promotionApi.checkPromotionCodeDuplicated({
      params: {
        code: value,
        id: this.props.promotionDetails?.id,
      }
    });
    if (!response?.result) {
      messages.push('• Mã promotion bị trùng');
    }
    return messages;
  }
  _prepareData = () => {
    const isEditing = this._isEditing();
    const isBaseOnPeriod = this._isBaseOnPeriod();

    const {
      promotionDetails,
    } = this.props;
    return {
      id: undefined,
      code: promotionDetails.code,
      name: promotionDetails.name,
      description: promotionDetails.description,

      base_on: Number(promotionDetails.base_on),
      start_date: isBaseOnPeriod && !!promotionDetails.start_date ? promotionDetails.start_date : null,
      end_date: isBaseOnPeriod && promotionDetails.end_date ? promotionDetails.end_date : null,
      is_stock_finished: isBaseOnPeriod && promotionDetails.is_stock_finished ? promotionDetails.is_stock_finished : null,

      active: !isEditing ? ACTIVE : promotionDetails.active,

      getcare_promotion_type_id: promotionDetails.getcare_promotion_type_id,
      getcare_promotion_apply_id: promotionDetails.getcare_promotion_apply_id,
      getcare_promotion_priority_id: promotionDetails.getcare_promotion_priority_id,

      vendor_mode: promotionDetails.vendor_mode,
      customer_mode: promotionDetails.customer_mode,
      document_mode: promotionDetails.document_mode,

      promotion_rules: this.props.promotionRules.map((item) => ({
        id: item.id ? item.id : undefined,
        level: item.level,
        level_name: item.level_name,
        getcare_promotion_compare_id: COMPARE_EQUAL, // will remove
        quantity_number: ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
        order_mode: item.order_mode,
        order_amount: ['', null, undefined].includes(item.order_amount) ? null : Number(item.order_amount),
        bonus_number: ['', null, undefined].includes(item.bonus_number) ? null : Number(item.bonus_number),
        bonus_mode: item.bonus_mode,
        getcare_promotion_operator_id: item.getcare_promotion_operator?.id || null,
        getcare_promotion_discount_id: item.getcare_promotion_discount?.id || null,
        discount_amount: ['', null, undefined].includes(item.discount_amount) ? null : Number(item.discount_amount),
        maximum_amount: ['', null, undefined].includes(item.maximum_amount) ? null : Number(item.maximum_amount),
      })),

      promotion_products: this.props.promotionProducts.map((item) => ({
        id: item.id ? item.id : undefined,
        type_id: item.type_id,
        product_vendor_code: item.getcare_product?.product_vendor_code || null,
        getcare_erp_group_id: item.getcare_erp_group?.id || null,
        getcare_uom_base_id: item.getcare_uom_base?.id || null,
        ratio: ['', null, undefined].includes(item.ratio) ? null : Number(item.ratio),
        focus: item.focus,
        quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
      })),

      promotion_product_maximums: this.props.promotionMaxProducts.filter(
          item => !item.disabled
        ).map((item) => ({
          id: item.id ? item.id : undefined,
          product_vendor_code: item.getcare_product?.product_vendor_code || null,
          quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
      })),

      promotion_product_bonuss: this.props.promotionBonuses.map((item) => ({
        id: item.id ? item.id : undefined,
        level: item.level,
        type_id: item.type_id,
        product_vendor_code: item.getcare_product?.product_vendor_code || null,
        getcare_erp_group_id: item.getcare_erp_group?.id || null,
        getcare_product_bonus_id: item.getcare_product_bonus?.id || null,
        getcare_uom_base_id: item.getcare_uom_base?.id || null,
        ratio:  ['', null, undefined].includes(item.ratio) ? null : Number(item.ratio),
        quantity_number:  ['', null, undefined].includes(item.quantity_number) ? null : Number(item.quantity_number),
      })),

      promotion_vendors: this._isVendorModeAll() ? [] : this.props.promotionVendors.map((item) => ({
        id: item.id ? item.id : undefined,
        type_id: item.type_id,
        getcare_vendor_id: item.getcare_vendor?.id || null,
        getcare_erp_group_id: item.getcare_erp_group?.id || null,
      })),

      promotion_customers: this._isCustomerModeAll() ? [] : this.props.promotionCustomers.map((item) => ({
        id: item.id ? item.id : undefined,
        type_id: item.type_id,
        getcare_customer_id: item.getcare_customer?.id || null,
        getcare_erp_group_id: item.getcare_erp_group?.id || null,
      })),
    };
  };
  hasWarningMessages = async () => {
    if (this.hasNotCompletedForm()) {
      toast.error('Có một dòng bạn đang làm dang dở. Hãy hoàn thành để tiếp tục.');
      return true;
    }
    const codeDuplicatedMsgs = await this.isCodeDuplicated(this.props.promotionDetails?.code);
    const messages = [
      ...this.isRulesValid(),
      ...this.isProductsValid(),
      ...this.isBonusesValid(),
      ...this.isVendorsValid(),
      ...this.isCustomersValid(),
      ...codeDuplicatedMsgs,
    ];
    if (messages.length) {
      toast.error(messages.join('\n'));
      return true;
    }
    return false;
  }
  _setView = () => {
    const { promotionDetails, user } = this.props;
    this.setState({
      isMine: promotionDetails?.getcare_vendor_id === Number(user?.getcare_vendor_id),
    });
  }

  handleCancelPromotion = () => {
    this._loadData(this.props.match.params.id);
  }
  handleSubmitPromotion = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;
    await this.setState({
      createDuplicate: this._prepareData(),
    });  

    this.props.updatePromotion({
      params: {
        ...this._prepareData(),
      },
    });
  }
  handleDeactivePromotion = async () => {
    const hasErrors = await this.hasWarningMessages();
    if (hasErrors) return;
    this.handleOpenConfirmDialog();
  };
  handleSubmitConfirmUpdateStatus = ({ deactive_reason }) => {
    this.props.savePromotionDetails({
      ...this.props.promotionDetails,
      active: DEACTIVE,
      deactive_reason,
    });
    this.props.updatePromotion({
      params: {
        ...this._prepareData(),
        active: DEACTIVE,
        deactive_reason,
      },
    });
    this.handleCloseConfirmDialog();
  };
  handleCloseConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: false,
    });
  };
  handleOpenConfirmDialog = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };

  render() {
    const isEditing = this._isEditing();
    const isDetailsChanged = this._isDetailsChanged();
    const isRulesChanged = this._isRulesChanged();
    const isProductsChanged = this._isProductsChanged();
    const isMaxProductsChanged = this._isMaxProductsChanged();
    const isBonusesChanged = this._isBonusesChanged();
    const isVendorsChanged = this._isVendorsChanged();
    const isCustomersChanged = this._isCustomersChanged();
    const isChanged = isDetailsChanged
      || isRulesChanged
      || isProductsChanged
      || isMaxProductsChanged
      || isBonusesChanged
      || isVendorsChanged
      || isCustomersChanged;
    const isValid = this._isValid();
    const promotionTypeId = this.props.promotionDetails?.getcare_promotion_type_id || this.props.promotionDetails?.getcare_promotion_type?.id;
    const isDocumentType = this._isDocumentType();

    return (<>
     <div className={`${classes.PageWrap} ${this.props.loading ? 'OverlayLoading' : ''}`}>
            <div className={classes.PageHeader}>
              <h1 className={classes.PageTitle}>
                {isEditing ? `Xem chương trình khuyến mãi` : `Tạo mới chương trình khuyến mãi`}
              </h1>
            
              {!isEditing && (
                <Button
                  variant="outlined"
                  startIcon={<ArrowBack />}
                  onClick={this._backToList}
                >
                  Huỷ và Trở về
                </Button>
              )}

              {isEditing && (
                <>
                  <Button
                    variant="outlined"
                    startIcon={<ArrowBack />}
                    onClick={this._backToList}
                  >
                    Trở về
                  </Button>
                  <Button
                    disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                    variant="outlined"
                    color="secondary"
                    onClick={this.handleCancelPromotion}
                  >
                    Huỷ thay đổi
                  </Button>
                </>
              )}

              <Button
                variant="contained"
                color="primary"
                disabled={(!isEditing && !isValid) || (isEditing && (!isValid || !isChanged))}
                startIcon={<CheckOutlined />}
                onClick={this.handleSubmitPromotion}
              >
                {isEditing ? `Xác nhận thay đổi` : `Tạo khuyến mãi`}
              </Button>
            </div>
            <div className={classes.PageMain}>
              <PromotionDetails
                key={`policy-${this.props.promotionDetails?.id || `new`}`}
                // isEditing={isEditing}
                isValid={isValid}
                isDuplicate={true}
                isStartDateValid={this.isStartDateValid()}
                isEndDateValid={this.isEndDateValid()}
                isCodeValid={this.isCodeValid()}
                isNameValid={this.isNameValid()}
                isTypeValid={this.isTypeValid()}
                isVendorModeValid={this.isVendorModeValid()}
                hasVendors={this.hasVendors()}
                isCustomerModeValid={this.isCustomerModeValid()}
                isApplyValid={this.isApplyValid()}
                isPriorityValid={this.isPriorityValid()}
                isDocumentType={isDocumentType}
                onDeactivePromotion={this.handleDeactivePromotion}
              />
              { this._hasPromotionType() && <>
                <div className={classes.Wrap}>
                  <h4 className={classes.SectionTitle}>Cơ cấu khuyến mãi</h4>
                  <PromotionRules
                    isEditing={isEditing}
                    promotionId={this.props.promotionDetails?.id}
                    promotionTypeId={promotionTypeId}
                  />
                </div>
                <div className={!isDocumentType ? classes.Wrap : classes.TabHidden}>
                  <h4 className={classes.SectionTitle}>Hàng bán</h4>
                  <PromotionProducts
                    isEditing={isEditing}
                    promotionId={this.props.promotionDetails?.id}
                    promotionTypeId={promotionTypeId}
                  />
                </div>
                <div className={!isDocumentType ? classes.Wrap : classes.TabHidden}>
                  <h4 className={classes.SectionTitle}>Hàng bán tối đa</h4>
                  <PromotionMaxProducts
                    isEditing={isEditing}
                    promotionId={this.props.promotionDetails?.id}
                    promotionTypeId={promotionTypeId}
                  />
                </div>
                <div className={classes.Wrap}>
                  <h4 className={classes.SectionTitle}>Hàng tặng</h4>
                  <PromotionBonuses
                    isEditing={isEditing}
                    promotionId={this.props.promotionDetails?.id}
                    promotionTypeId={promotionTypeId}
                  />
                </div>
                { isEditing && <PromotionHistory promotionId={this.props.promotionDetails?.id}/> }
              </> }
            </div>

            {this.state.isConfirmDialogOpen && (
              <ConfirmInactiveDialog
                isOpen={this.state.isConfirmDialogOpen}
                title={`Xác nhận dừng hoạt động ${this.props.promotionDetails?.code}`}
                promotionCode={this.props.promotionDetails?.code}
                onClose={this.handleCloseConfirmDialog}
                onSubmit={this.handleSubmitConfirmUpdateStatus}
              />
            )}
          </div>

   
    </>);
  }
}

const mapStateToProps = createStructuredSelector({
  oriPromotionDetails: makeSelectOriPromotionDetails(),
  promotionDetails: makeSelectPromotionDetails(),
  oriPromotionRules: makeSelectOriPromotionRules(),
  promotionRules: makeSelectPromotionRules(),
  oriPromotionProducts: makeSelectOriPromotionProducts(),
  promotionProducts: makeSelectPromotionProducts(),
  oriPromotionMaxProducts: makeSelectOriPromotionMaxProducts(),
  promotionMaxProducts: makeSelectPromotionMaxProducts(),
  oriPromotionBonuses: makeSelectOriPromotionBonuses(),
  promotionBonuses: makeSelectPromotionBonuses(),
  oriPromotionVendors: makeSelectOriPromotionVendors(),
  promotionVendors: makeSelectPromotionVendors(),
  oriPromotionCustomers: makeSelectOriPromotionCustomers(),
  promotionCustomers: makeSelectPromotionCustomers(),
  loading: makeSelectPromotionLoading(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPromotion: (payload) => dispatch(getPromotion(payload)),
    resetPromotion: (payload) => dispatch(resetPromotion(payload)),
    updatePromotion: (payload) => dispatch(updatePromotion(payload)),
    savePromotionDetails: (payload) => dispatch(savePromotionDetails(payload)),
    getPromotionTypes: (payload) => dispatch(getPromotionTypes(payload)),
    getPromotionApplies: (payload) => dispatch(getPromotionApplies(payload)),
    getPromotionPriorites: (payload) => dispatch(getPromotionPriorites(payload)),
    getPromotionDiscounts: (payload) => dispatch(getPromotionDiscounts(payload)),
    getPromotionOperators: (payload) => dispatch(getPromotionOperators(payload)),
    savePromotionRules: (payload) => dispatch(savePromotionRules(payload)),
    savePromotionProducts: (payload) => dispatch(savePromotionProducts(payload)),
    savePromotionMaxProducts: (payload) => dispatch(savePromotionMaxProducts(payload)),
    savePromotionBonuses: (payload) => dispatch(savePromotionBonuses(payload)),
    savePromotionVendors: (payload) => dispatch(savePromotionVendors(payload)),
    savePromotionCustomers: (payload) => dispatch(savePromotionCustomers(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(DuplicatePromotion);
