import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'routes/routes';
import RouteWithSubRoutes from 'components/RouteWithSubRoutes';

import './App.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Redirect to="/so" />
          </Route>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
    );
  }
}

export default App;
