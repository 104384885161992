import {
  SAVE_GROUP_LIST,
  SAVE_GROUP_LIST_TOTAL,
  SAVE_LOADING,
  SAVE_GROUP,
  RESET_GROUP,
  SAVE_GROUP_PRODUCTS,
  SAVE_GROUP_PRODUCT,
  REMOVE_GROUP_PRODUCTS,
  SAVE_GROUP_VENDORS,
  SAVE_GROUP_VENDOR,
  REMOVE_GROUP_VENDORS,
  SAVE_GROUP_CUSTOMER_MAPPINGS,
  SAVE_GROUP_CUSTOMER_MAPPING,
  REMOVE_GROUP_CUSTOMER_MAPPINGS,
  SAVE_GROUP_CUSTOMERS,
  SAVE_GROUP_CUSTOMER,
  REMOVE_GROUP_CUSTOMERS,
  SAVE_GROUP_CUSTOMERS_TOTAL,
  SAVE_CUSTOMERS_TYPES,
} from 'redux/constants/mdm/groupConstants';

const initialState = {
  groupList: [],
  groupTotal: 0,
  loading: false,
  groupInfo: null,
  groupProducts: [],
  groupVendors: [],
  groupCustomers: [],
  groupCustomersTotal: 0,
  groupCustomerMappings: [],
  customerTypes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case SAVE_GROUP_LIST: {
      const list = action.groupList;
      return {
        ...state,
        groupList: list ? [...list] : [],
      };
    }
    case SAVE_GROUP_LIST_TOTAL: {
      return {
        ...state,
        groupTotal: action.groupTotal,
      };
    }
    case SAVE_GROUP: {
      return {
        ...state,
        groupInfo: state.groupInfo
          ? { ...state.groupInfo, ...action.groupInfo }
          : action.groupInfo,
      };
    }
    case RESET_GROUP: {
      return {
        ...state,
        groupInfo: null,
        groupProducts: [],
        groupVendors: [],
        groupCustomers: [],
        groupCustomersTotal: 0,
        groupCustomerMappings: [],
        customerTypes: [],
      };
    }

    case SAVE_GROUP_PRODUCTS: {
      return {
        ...state,
        groupProducts: action.groupProducts ? [...action.groupProducts] : [],
      };
    }
    case SAVE_GROUP_PRODUCT: {
      const list = [...state.groupProducts];
      const comparedField = action.groupProduct.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.groupProduct[comparedField]);
      if (index > -1) {
        list[index] = { ...action.groupProduct };
      }
      if (index <= -1) {
        list.unshift({ ...action.groupProduct });
      }
      return {
        ...state,
        groupProducts: list,
      };
    }
    case REMOVE_GROUP_PRODUCTS: {
      const list = state.groupProducts.filter(
        (p) =>
          !action.groupProducts.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        groupProducts: action.groupProducts.length
          ? list
          : [...state.groupProducts],
      };
    }

    case SAVE_GROUP_VENDORS: {
      return {
        ...state,
        groupVendors: action.groupVendors ? [...action.groupVendors] : [],
      };
    }
    case SAVE_GROUP_VENDOR: {
      const list = [...state.groupVendors];
      const comparedField = action.groupVendor.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.groupVendor[comparedField]);
      if (index > -1) {
        list[index] = { ...action.groupVendor };
      }
      if (index <= -1) {
        list.unshift({ ...action.groupVendor });
      }
      return {
        ...state,
        groupVendors: list,
      };
    }
    case REMOVE_GROUP_VENDORS: {
      const list = state.groupVendors.filter(
        (p) =>
          !action.groupVendors.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        groupVendors: action.groupVendors.length
          ? list
          : [...state.groupVendors],
      };
    }

    case SAVE_GROUP_CUSTOMERS: {
      return {
        ...state,
        groupCustomers: action.groupCustomers ? [...action.groupCustomers] : [],
      };
    }
    case SAVE_GROUP_CUSTOMERS_TOTAL: {
      return {
        ...state,
        groupCustomersTotal: action.groupCustomersTotal,
      };
    }
    case SAVE_GROUP_CUSTOMER: {
      const list = [...state.groupCustomers];
      const comparedField = action.groupCustomer.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.groupCustomer[comparedField]);
      if (index > -1) {
        list[index] = { ...action.groupCustomer };
      }
      if (index <= -1) {
        list.unshift({ ...action.groupCustomer });
      }
      return {
        ...state,
        groupCustomers: list,
      };
    }
    case REMOVE_GROUP_CUSTOMERS: {
      const list = state.groupCustomers.filter(
        (p) =>
          !action.groupCustomers.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        groupCustomers: action.groupCustomers.length
          ? list
          : [...state.groupCustomers],
      };
    }

    case SAVE_GROUP_CUSTOMER_MAPPINGS: {
      return {
        ...state,
        groupCustomerMappings: action.groupCustomerMappings
          ? [...action.groupCustomerMappings]
          : [],
      };
    }
    case SAVE_GROUP_CUSTOMER_MAPPING: {
      const list = [...state.groupCustomerMappings];
      const comparedField = action.groupCustomerMapping.idStr ? 'idStr' : 'id';
      const index = list.findIndex((p) => p[comparedField] === action.groupCustomerMapping[comparedField]);
      if (index > -1) {
        list[index] = { ...action.groupCustomerMapping };
      }
      if (index <= -1) {
        list.unshift({ ...action.groupCustomerMapping });
      }
      return {
        ...state,
        groupCustomerMappings: list,
      };
    }
    case REMOVE_GROUP_CUSTOMER_MAPPINGS: {
      const list = state.groupCustomerMappings.filter(
        (p) =>
          !action.groupCustomerMappings.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        groupCustomerMappings: action.groupCustomerMappings.length
          ? list
          : [...state.groupCustomerMappings],
      };
    }

    case SAVE_CUSTOMERS_TYPES: {
      const list = action.customerTypes;
      return {
        ...state,
        customerTypes: list ? [...list] : [],
      };
    }

    default:
      return state;
  }
};

export default reducer;
