import {
  GET_CUSTOMER_DETAILS,
  GET_CUSTOMER_LIST,
  SAVE_CUSTOMER_DETAILS,
  SAVE_CUSTOMER_LIST,
  SAVE_CUSTOMER_LIST_TOTAL,
  SAVE_CUSTOMER_LIST_LOADING,
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
  EXPORT_CUSTOMER_LIST_TEMPLATE,
  SAVE_CUSTOMER_ACTION_LOADING,
} from 'redux/constants/vendor/customerConstants';

export function getCustomerDetails(id) {
  return {
    type: GET_CUSTOMER_DETAILS,
    id,
  }
}
export function getCustomerList(payload) {
  return {
    type: GET_CUSTOMER_LIST,
    payload,
  }
}
export function updateCustomer(payload) {
  return {
    type: UPDATE_CUSTOMER,
    payload,
  }
}
export function exportCustomerListTemplate(payload) {
  return {
    type: EXPORT_CUSTOMER_LIST_TEMPLATE,
    payload,
  }
}
export function createCustomer(payload) {
  return {
    type: CREATE_CUSTOMER,
    payload,
  }
}

// mutation
export function saveCustomerDetails(customerDetails) {
  return {
    type: SAVE_CUSTOMER_DETAILS,
    customerDetails,
  }
}
export function saveCustomerList(customerList) {
  return {
    type: SAVE_CUSTOMER_LIST,
    customerList,
  }
}
export function saveCustomerListLoading(isCustomerListLoading) {
  return {
    type: SAVE_CUSTOMER_LIST_LOADING,
    isCustomerListLoading,
  }
}
export function saveCustomerActionLoading(isCustomerActionLoading) {
  return {
    type: SAVE_CUSTOMER_ACTION_LOADING,
    isCustomerActionLoading,
  }
}
export function saveCustomerListTotal(customerListTotal) {
  return {
    type: SAVE_CUSTOMER_LIST_TOTAL,
    customerListTotal,
  }
}