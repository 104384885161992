import vendorPriceApi from 'utils/api/mdm/vendorPriceApi';
import { put } from 'redux-saga/effects';
import { vendorPriceActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadVendorPriceList(payload) {
  const { params } = payload.payload;
  try {
    yield put(vendorPriceActions.saveAcitonLoading(true));

    const { data: response } = yield vendorPriceApi.getList({ params });

    yield put(vendorPriceActions.saveAcitonLoading(false));
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    yield put(vendorPriceActions.saveVendorPriceList(response.data ? [ ...response.data] : []));
    yield put(vendorPriceActions.saveVendorPriceListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* putVendorPriceList(payload) {
  const { params, listParams } = payload.payload;
  try {
    yield put(vendorPriceActions.saveAcitonLoading(true));

    const { data: response } = yield vendorPriceApi.updateVendorPrices({ params });
    if (!response?.result) {
      vendorPriceActions.saveAcitonLoading(false);
      toast.error(response.message);
      return;
    }
    const { data: listResponse } = yield vendorPriceApi.getList({ params: listParams });
    yield put(vendorPriceActions.saveAcitonLoading(false));
    yield put(vendorPriceActions.saveTempVendorPriceList([]));
    yield put(vendorPriceActions.saveVendorPriceList(listResponse.data ? [ ...listResponse.data] : []));
    yield put(vendorPriceActions.saveVendorPriceListTotal(listResponse.total_records));
  } catch (err) {
    console.log(err);
  }
}
