import React, { PureComponent } from 'react';
import { currencyFormatVN, validNumber } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { rateOrdering } from 'redux/actions/ecom/fastOrderActions';
import { createStructuredSelector } from "reselect";
import { makeSelectFastOrderActionLoading } from 'redux/selectors';

import Dialog from '@material-ui/core/Dialog';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import StarOutlineSharpIcon from '@material-ui/icons/StarOutlineSharp';
import StarSharpIcon from '@material-ui/icons/StarSharp';

import classes from './FastOrderSuccessDialog.module.scss';

class FastOrderSuccessDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      rate: 4,
      rateHover: 4,
    }
    this.rateLabelsMap = {
      1: 'Rất khó',
      2: 'Khó',
      3: 'Bình thường',
      4: 'Dễ',
      5: 'Rất dễ',
    };
  }
  componentDidUpdate(prevProps) {
    const { isOpen, actionLoading } = this.props;
    if (isOpen && actionLoading && actionLoading !== prevProps.actionLoading) {
      this.props.onClose();
    }
  }

  handleClose = () => {
    this.props.rateOrdering({
      params: {
        getcare_order_id: this.props.prDetails?.so_id,
        rating: this.state.rate,
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  }

  render() {
    const { isOpen, prDetails } = this.props;
    const { rateHover, rate } = this.state;

    return (
      <Dialog
        disableBackdropClick={true}
        open={isOpen}
        onClose={this.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        maxWidth={false}
      >
        <div className={`${classes.DialogContentOuter}`}>
          <div className={classes.DialogInfo}>
            <CheckCircleOutlineOutlinedIcon className={classes.DialogInfoIcon} />
            <h4 className={classes.DialogInfoTitle}>Đặt hàng thành công</h4>
            <div className={classes.OrderInfo}>
              <div className={classes.Info}>
                <label>Mã đơn hàng</label>
                { prDetails.so_code }
              </div>
              <div className={classes.Info}>
                <label>Tổng tiền đơn hàng</label>
                <span className={classes.Amount}>{validNumber(prDetails?.amount_total) ? currencyFormatVN(prDetails?.amount_total) : `-`}</span>
              </div>
            </div>
            <div className={classes.OrderRating}>
              <label>Bạn có đặt hàng dễ dàng không?</label>
              <div className={classes.RatingBox}>
                <Rating
                  className={classes.Rating}
                  name="order_rating"
                  value={rate}
                  icon={<StarSharpIcon/>}
                  emptyIcon={<StarOutlineSharpIcon/>}
                  onChange={(event, newValue) => {
                    this.setState({ rate: newValue });
                  }}
                  onChangeActive={(event, newHover) => {
                    this.setState({ rateHover: newHover });
                  }}
                />
                {rate !== null && <span className={classes.RatingLabel}>{this.rateLabelsMap[rateHover !== -1 ? rateHover : rate]}</span>}
              </div>
            </div>
            <div className={classes.DialogInfoActions}>
              <Button variant="outlined" onClick={this.handleClose}>Đóng</Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  actionLoading: makeSelectFastOrderActionLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    rateOrdering: (payload) => dispatch(rateOrdering(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(FastOrderSuccessDialog);
