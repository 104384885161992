import React, { PureComponent } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';

import classes from './PromotionTooltip.module.scss';

class PromotionTooltip extends PureComponent {
  render() {
    return (
      <Tooltip
        placement="bottom-start"
        title={<div className={classes.Content}>
            <p>- Chương trình khuyến mãi chỉ áp dụng cho sản phẩm và số lượng sản phẩm chưa được tính khuyến mãi</p>
            <p>- Các chương trình khuyến mãi được thể hiện theo độ ưu tiên</p>
            <p>- Khuyến mãi thể hiện ở mỗi thời điểm có thể khác nhau</p>
          </div>
        }
      >
        <InfoOutlinedIcon className={classes.TooltipIcon}/>
      </Tooltip>);
  }
}

export default PromotionTooltip;
