import axiosAuth from 'utils/axios/axiosAuth';

const promotionApi = {
  getTypes: () => {
    return axiosAuth.get(`getcare_promotion_type`);
  },
  getOperators: () => {
    return axiosAuth.get(`getcare_promotion_operator`);
  },
  getCompares: () => {
    return axiosAuth.get(`getcare_promotion_compare`);
  },
  getPriorities: () => {
    return axiosAuth.get(`getcare_promotion_priority`);
  },
  getApplies: () => {
    return axiosAuth.get(`getcare_promotion_apply`);
  },
  getDiscounts: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_discount`, {
      params: queryParams,
    });
  },
  getProductSuggestions: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_product_search`, {
      params: queryParams,
    });
  },
  getProductBonusSuggestions: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_product_bonus_search`, {
      params: queryParams,
    });
  },
  getVendorSuggestions: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_vendor_search`, {
      params: queryParams,
    });
  },
  getCustomerSuggestions: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_customer_search`, {
      params: { ...queryParams, type_id: 2 },
    });
  },

  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_promotion/${id}`);
  },
  update: ({ params }) => {
    return axiosAuth.post(`getcare_promotion`, params);
  },
  getPromotionHistory: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_promotion_history`, {
      params: queryParams,
    });
  },

  checkPromotion: ({ params }) => {
    return axiosAuth.post(`getcare_promotion_check`, params);
  },

  checkPromotionCodeDuplicated: ({ params }) => {
    return axiosAuth.post(`getcare_promotion_check_duplicate`, params);
  },
};

export default promotionApi;
