import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  bonusGroupTypes,
  GROUP_TYPE_ID,
  PRODUCT_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionRules } from 'redux/selectors';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import classes from './GetcarePromotionBonus.module.scss';
import gridClasses from '../GetcarePromotionBonusesGrid.module.scss';

class GetcarePromotionBonus extends PureComponent {
  _isGroupType = (params) => {
    return params.type_id === GROUP_TYPE_ID;
  }
  _isProductType = (params) => {
    return params.type_id === PRODUCT_TYPE_ID;
  }

  render() {
    const {
      level,
      getcare_product,
      getcare_erp_group,
      quantity_number,
      ratio,
      getcare_uom_base,
      getcare_product_bonus,
      type_id,
    } = this.props;
    const isGroupType = this._isGroupType(this.props);
    const isProductType = this._isProductType(this.props);

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row}`}>
          <div className={gridClasses.Col}>
            { `L${level}` }
          </div>
          <div className={gridClasses.Col}>
            <Select
              disabled
              className={classes.SelectWrap}
              value={type_id}
              name="type_id"
              size="small"
              fullWidth
            >
              {
                bonusGroupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? getcare_erp_group?.code : (isProductType ? getcare_product?.product_vendor_code : getcare_product_bonus?.code) }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? getcare_erp_group?.name : (isProductType ? getcare_product?.product_vendor_name : getcare_product_bonus?.name) }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? '' : (getcare_uom_base ? getcare_uom_base.name : getcare_product_bonus?.getcare_product_unit?.name) }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType || !ratio ? '' : `${ratio}` }
          </div>
          <div className={`${gridClasses.Col}`}>
            { quantity_number }
          </div>
        </div>
      </>
    );
  }
}

GetcarePromotionBonus.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionBonus.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionRules: makeSelectPromotionRules(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionBonus);
