import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validDate } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionDetails,
  makeSelectPromotionApplies,
  makeSelectPromotionPriorites,
} from 'redux/selectors';
import { dateFormat, dateTimeFormat } from 'utils/constanst/dateConstants';
import { format } from 'date-fns';
import {
  IS_STOCK_FINISHED_YES,
  promotionStatusMap,
  applyStatusMap,
  customerModes,
} from 'utils/constanst/tmkPromotionConstants';
import promotionApi from 'utils/api/tmk/promotionApi';
import { debounce } from 'lodash';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import classes from './GetcarePromotionDetails.module.scss';

class GetcarePromotionDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      availablePromotions: [],
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { promotionDetails } = this.props;
    if (promotionDetails
      && promotionDetails?.name
      && promotionDetails?.name.trim().length > 0
      && promotionDetails?.name !== prevProps.promotionDetails?.name
    ) {
      this._loadAvailablePromotions({
        params: {
          name: promotionDetails.name,
          page_size: 1,
        },
      });
    }
  }

  _loadAvailablePromotions = debounce(async ({ params }) => {
    const { data: response } = await promotionApi.getAll({ params });
    const { promotionDetails } = this.props;
    if (!response?.result) return;
    this.setState({
      availablePromotions: response.data.filter(item =>
        item.id !== promotionDetails?.id
        && item.name.toLowerCase() === promotionDetails?.name.toLowerCase()
      )
    });
  }, 500);
  _isPromotionNameDuplicated = () => {
    const { availablePromotions } = this.state;
    return availablePromotions && availablePromotions.length > 0;
  }
  _getSelectedCustomerMode = () => {
    const { promotionDetails } = this.props;
    return (customerModes && customerModes.find(item => item.id === promotionDetails?.customer_mode)) || null;
  };
  _getSelectedApply = () => {
    const { promotionDetails, promotionApplies } = this.props;
    const applyId = (promotionDetails?.getcare_promotion_apply_id || promotionDetails?.getcare_promotion_apply?.id);
    return (promotionDetails && promotionApplies.find(item => item.id === applyId)) || null;
  };
  _getSelectedPriority = () => {
    const { promotionDetails, promotionPriorities } = this.props;
    const applyId = (promotionDetails?.getcare_promotion_priority_id || promotionDetails?.getcare_promotion_priority?.id);
    return (promotionDetails && promotionPriorities.find(item => item.id === applyId)) || null;
  };

  getPromotionVendorCount = () => {
    return this.props.promotionDetails?.count_vendor;
  }
  getPromotionVendorString = () => {
    const vendorCount = this.getPromotionVendorCount();
    if (vendorCount > 1) return <div><span className={`${classes.VendorCountBadge} Badge BlueGrey Text`}>{ vendorCount }</span></div>;

    return this.props.promotionDetails?.vendor_name;
  }

  render() {
    const {
      promotionDetails,
      isEditing,
    } = this.props;

    return (
      <div className={`${classes.Details}`}>
        <div className={classes.DetailsCol}>
          <div className={classes.FieldControl}>
            <label className={classes.Flex}>
              Mã promotion <span className={classes.RequiredMark}>*</span> {` `}
              { isEditing && promotionDetails?.is_empty && (<Tooltip title={`Chương trình rỗng`} arrow placement="bottom">
                  <span className="TextDanger"><ErrorOutline fontSize="small"/></span>
                </Tooltip>)
              }
            </label>
            { promotionDetails?.code }
          </div>

          <div className={classes.FieldControl}>
            <label className={classes.Flex}>
              Tên promotion <span className={classes.RequiredMark}>*</span>
            </label>
            { promotionDetails?.name || `-` }
          </div>

          <div className={classes.FieldControl}>
            <label>Loại promotion</label>
            { promotionDetails?.getcare_promotion_type?.name }
          </div>

          <div className={classes.FieldControl}>
            <label>Nhà cung cấp</label>
            { this.getPromotionVendorString() }
          </div>

          <div className={classes.FieldControl}>
            <label>Khách hàng</label>
            { this._getSelectedCustomerMode()?.name }
          </div>

          <div className={classes.FieldControl}>
            <label>Ngày bắt đầu <span className={classes.RequiredMark}>*</span></label>
            { validDate(promotionDetails?.start_date) ? (
                format(validDate(promotionDetails?.start_date), dateFormat)
              ) : `-`}
          </div>

          <div className={classes.FieldControl}>
            <label>Ngày kết thúc</label>
            { validDate(promotionDetails?.end_date) ? (
                format(validDate(promotionDetails?.end_date), dateFormat)
              ) : `-`}
          </div>

          <div className={`${classes.FieldControl} ${classes.AutolabelFieldControl}`}>
            <label>
              { promotionDetails?.is_stock_finished === IS_STOCK_FINISHED_YES
                ? <CheckBoxIcon fontSize="small"/>
                : <CheckBoxOutlineBlankIcon fontSize="small"/>
              }
            </label>
            <label>Tự động kết thúc khi hết hàng khuyến mãi</label>
          </div>
        </div>

        <div className={classes.DetailsCol}>
          <div className={`${classes.FieldControl}`}>
            <label>Mô tả promotion</label>
            { promotionDetails?.description || `-` }
          </div>

          <div className={`${classes.FieldControl}`}>
            <label>Áp dụng trong đơn hàng</label>
            { this._getSelectedApply()?.name }
          </div>

          <div className={`${classes.FieldControl}`}>
            <label>Ưu tiên</label>
            { this._getSelectedPriority()?.name }
          </div>

          <div className={`${classes.FieldControl}`}>
            <label>Trạng thái</label>
            { promotionStatusMap[promotionDetails?.active] }
          </div>

          <div className={`${classes.FieldControl}`}>
            <label>Áp dụng</label>
            {applyStatusMap[promotionDetails?.apply]}
          </div>

          <div className={`${classes.FieldControl}`}>
            <label>Thời gian tạo</label>
            { validDate(promotionDetails?.created_at)
              ? format(
                  validDate(promotionDetails?.created_at),
                  dateTimeFormat
                )
              : '' }
          </div>
          <div className={`${classes.FieldControl}`}>
            <label>Thời gian sửa</label>
            { validDate(promotionDetails?.updated_at)
              ? format(
                  validDate(promotionDetails?.updated_at),
                  dateTimeFormat
                )
              : '' }
          </div>
          <div className={`${classes.FieldControl}`}>
            <label>Người sửa</label>
            { promotionDetails?.getcare_user?.name }
          </div>

        </div>
      </div>
    );
  }
}

GetcarePromotionDetails.propTypes = {
  isEditing: PropTypes.bool,
};

GetcarePromotionDetails.defaultProps = {
  isEditing: false,
};

const mapStateToProps = createStructuredSelector({
  promotionDetails: makeSelectPromotionDetails(),
  promotionApplies: makeSelectPromotionApplies(),
  promotionPriorities: makeSelectPromotionPriorites(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(GetcarePromotionDetails);
