import axiosAuth from 'utils/axios/axiosAuth';

export default {
  getAll: ({ params }) => {
    return axiosAuth.get("/getcare_category_ecom");
  },
  createAndUpdateGetcareCategory: ({ params }) => {
    return axiosAuth.post("/getcare_category_ecom", params);
  },
  deleteMultipleGetcareCategory: ({ params }) => {
    return axiosAuth({
      url: "/getcare_category_ecom_delete_multiple",
      method: "DELETE",
      data: params,
    });
  },
};
