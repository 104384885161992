import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PharmacyListItem from './PharmacyListItem/PharmacyListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './PharmacyListGrid.module.scss';
import classes from './PharmacyList.module.scss';

class PharmacyList extends PureComponent {
  render() {
    const { pharmacyList, isLoading, displayFields } = this.props;

    return (
      <div className={`${classes.List} ${isLoading && 'OverlayLoading'}`}>
        <div className={`${classes.Body}`}>
          <div className={`${gridClasses.Row} ${classes.Header}`}>
            { displayFields.map((item) => (
              <ListHeaderCol
                key={item.name}
                {...item}
                className={`${gridClasses.Col} ${item.className}`}
                onSortChange={this.props.onSortChange}
              />
            )) }
          </div>

          {pharmacyList && pharmacyList.length ? (
            pharmacyList.map((item) => (
              <PharmacyListItem key={`store-${item.id}`} {...item} />
            ))
          ) : (
            <p className="NoData">Không có khách hàng nào</p>
          )}
        </div>
      </div>
    );
  }
}

PharmacyList.propTypes = {
  displayFields: PropTypes.array,
  pharmacyList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

PharmacyList.defaultProps = {
  displayFields: [],
  pharmacyList: [],
  isLoading: false,
};

export default PharmacyList;
