import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionDetails,
  makeSelectPromotionLoading,
} from 'redux/selectors';
import { PROMOTION_TYPE_DOCUMENT } from 'utils/constanst/tmkPromotionConstants';
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@material-ui/core/Button';
import ArrowBack from '@material-ui/icons/ArrowBack';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetcarePromotionDetails from 'components/tmk/GetcarePromotionDetails/GetcarePromotionDetails';
import GetcarePromotionRules from 'components/tmk/GetcarePromotionRules/GetcarePromotionRules';
import GetcarePromotionProducts from 'components/tmk/GetcarePromotionProducts/GetcarePromotionProducts';
import GetcarePromotionBonuses from 'components/tmk/GetcarePromotionBonuses/GetcarePromotionBonuses';
import GetcarePromotionMaxProducts from 'components/tmk/GetcarePromotionMaxProducts/GetcarePromotionMaxProducts';
import GetcarePromotionVendors from 'components/tmk/GetcarePromotionVendors/GetcarePromotionVendors';
import GetcarePromotionCustomers from 'components/tmk/GetcarePromotionCustomers/GetcarePromotionCustomers';
import PromotionHistory from 'components/tmk/PromotionHistory/PromotionHistory';

import classes from './GetcarePromotion.module.scss';

class GetcarePromotion extends PureComponent {
  _backToList = () => {
    this.props.history.push('/promotion');
  };
  _hasPromotionType = () => {
    const { promotionDetails } = this.props;
    return !!promotionDetails?.getcare_promotion_type_id || !!promotionDetails?.getcare_promotion_type;
  }
  _isDocumentType = () => {
    return this.props.promotionDetails?.getcare_promotion_type_id === PROMOTION_TYPE_DOCUMENT;
  }

  handleClickDuplicate = () => {
    this.props.history.push(`/promotion/new?dup=${this.props.promotionDetails?.id}`);
  };
  
  render() {
    const promotionTypeId = this.props.promotionDetails?.getcare_promotion_type_id || this.props.promotionDetails?.getcare_promotion_type?.id;
    const isDocumentType = this._isDocumentType();

    return (
      <div className={`${classes.PageWrap} ${this.props.loading ? 'OverlayLoading' : ''}`}>
        <div className={classes.PageHeader}>
          <h1 className={classes.PageTitle}>Xem chương trình khuyến mãi</h1>
          {
               this.props.promotionDetails?.getcare_vendor_id === null   ?
               null
              :  <Tooltip title="Duplicate" arrow placement="top">
              <Button
                variant="outlined"
                style={{ minWidth: 0 }}
                color="primary"
                onClick={this.handleClickDuplicate}
              ><FileCopyIcon/></Button>
            </Tooltip>
              }
          
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            onClick={this._backToList}
          >
            Trở về
          </Button>
        </div>
        <div className={classes.PageMain}>
          <GetcarePromotionDetails
            key={`policy-${this.props.promotionDetails?.id || `new`}`}
            isEditing={true}
          />
          { this._hasPromotionType() && <>
            <div className={classes.Wrap}>
              <h4 className={classes.SectionTitle}>Cơ cấu</h4>
              <GetcarePromotionRules
                promotionId={this.props.promotionDetails?.id}
                promotionTypeId={promotionTypeId}
              />
            </div>
            <div className={!isDocumentType ? classes.Wrap : classes.TabHidden}>
              <h4 className={classes.SectionTitle}>Hàng bán</h4>
              <GetcarePromotionProducts
                promotionId={this.props.promotionDetails?.id}
                promotionTypeId={promotionTypeId}
              />
            </div>
            <div className={!isDocumentType ? classes.Wrap : classes.TabHidden}>
              <h4 className={classes.SectionTitle}>Hàng bán tối đa</h4>
              <GetcarePromotionMaxProducts
                promotionId={this.props.promotionDetails?.id}
                promotionTypeId={promotionTypeId}
              />
            </div>
            <div className={classes.Wrap}>
              <h4 className={classes.SectionTitle}>Hàng tặng</h4>
              <GetcarePromotionBonuses
                promotionId={this.props.promotionDetails?.id}
                promotionTypeId={promotionTypeId}
              />
            </div>
            <div className={classes.Wrap}>
              <h4 className={classes.SectionTitle}>Nhà cung cấp</h4>
              <GetcarePromotionVendors promotionId={this.props.promotionDetails?.id} />
            </div>
            <div className={classes.Wrap}>
              <h4 className={classes.SectionTitle}>Khách hàng</h4>
              <GetcarePromotionCustomers promotionId={this.props.promotionDetails?.id} />
            </div>
            <PromotionHistory promotionId={this.props.promotionDetails?.id}/>
          </> }
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  promotionDetails: makeSelectPromotionDetails(),
  loading: makeSelectPromotionLoading(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, withRouter)(GetcarePromotion);
