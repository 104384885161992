export const GET_CUSTOMER_DETAILS = 'getcare/vendor/customer/GET_CUSTOMER_DETAILS';
export const GET_CUSTOMER_LIST = 'getcare/vendor/customer/GET_CUSTOMER_LIST';
export const UPDATE_CUSTOMER = 'getcare/vendor/customer/UPDATE_CUSTOMER';
export const CREATE_CUSTOMER = 'getcare/vendor/customer/CREATE_CUSTOMER';

export const EXPORT_CUSTOMER_LIST_TEMPLATE = 'getcare/vendor/customer/EXPORT_CUSTOMER_LIST_TEMPLATE';

// mutation
export const SAVE_CUSTOMER_DETAILS = 'getcare/vendor/customer/SAVE_CUSTOMER_DETAILS';
export const SAVE_CUSTOMER_LIST = 'getcare/vendor/customer/SAVE_CUSTOMER_LIST';
export const SAVE_CUSTOMER_LIST_LOADING = 'getcare/vendor/customer/SAVE_CUSTOMER_LIST_LOADING';
export const SAVE_CUSTOMER_ACTION_LOADING = 'getcare/vendor/customer/SAVE_CUSTOMER_ACTION_LOADING';
export const SAVE_CUSTOMER_LIST_TOTAL = 'getcare/vendor/customer/SAVE_CUSTOMER_LIST_TOTAL';
