import axiosAuth from 'utils/axios/axiosAuth';

const fastOrderApi = {
  getPurchaseRequestDraft: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_purchase_request_draft`, {
      params: queryParams,
    });
  },
  updatePurchaseRequestDraft: ({ params }) => {
    return axiosAuth.post(`getcare_purchase_request_draft`, params);
  },
  createPurchaseRequest: ({ params }) => {
    return axiosAuth.post(`getcare_purchase_request`, params);
  },

  checkPromotion: ({ params }) => {
    return axiosAuth.post(`getcare_purchase_request_check`, params);
  },

  rateOrdering: ({ params }) => {
    return axiosAuth.post(`getcare_order_rating`, params);
  },

  saveOrderDraft: ({ params }) => {
    return axiosAuth.post(`getcare_order_draft`, params);
  },
};

export default fastOrderApi;