import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import viewMore from 'assets/images/more.svg';
import { getVendorDetails } from 'redux/actions/vendor/vendorActions';
import {
  getVendorProductList,
  saveVendorProductItem,
  removeVendorProductItems,
  updateVendorProductList,
  exportVendorProductList,
  exportVendorProductListTemplate,
} from 'redux/actions/vendor/vendorProductActions';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectVendorProductList,
  makeSelectVendorProductListTotal,
  makeSelectVendorProductLoading,
  makeSelectVendorDetails,
  makeSelectOriVendorProductList,
  makeSelectVendorProductActionLoading,
  makeSelectLoginUser,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  productOptions,
} from 'utils/constanst/adminVendorProductConstants';
import {
  getSortsString,
  getDisplayFields,
  genID,
  validNumber,
} from 'utils/helper';
import { withRouter } from 'react-router-dom';
import { isEqual, sortBy } from 'lodash';
import CheckOutlined from '@material-ui/icons/CheckOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Popper, RadioGroup, Paper } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VendorProductList from 'components/vendor/VendorProductList/VendorProductList';
import VendorProductFilters from 'components/vendor/VendorProductList/VendorProductFilters/VendorProductFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FileImportDialog from 'components/vendor/VendorProductList/FileImportDialog/FileImportDialog';
import TagGetcareCategory from 'components/vendor/VendorProductList/TagGetcareCategory/TagGetcareCategory';

import classes from './VendorProducts.module.scss';
import { toast } from 'react-toastify';

class VendorProducts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
      selectedItems: [],
      anchorEl2: null,
      selectedSetting: [...listDisplayFields],
      openEl2: false,
      isConfirmDialogOpen: false,
      isImportDialogOpen: false,
      isTagGetcareCategoryDialogOpen: false,
      pausedAction: '',
    };
  }
  async componentDidMount() {
    this._loadData();
    if (sessionStorage.getItem('setting')) {
      this.setState({
        selectedSetting: JSON.parse(sessionStorage.getItem('setting')),
      });
    } else {
      sessionStorage.setItem('setting', JSON.stringify([...listDisplayFields]));
    }
  }
  componentDidUpdate(prevProps, preState) {
    const { vendorProductList } = this.props;
    const { selectedItems, remainItems } = this.state;
    if (
      !isEqual(
        sortBy(vendorProductList),
        sortBy(prevProps.vendorProductList)
      ) &&
      selectedItems.length > 0
    ) {
      const remainItems = selectedItems.filter((p) =>
        vendorProductList.some((item) => {
          const comparedField = item.idStr ? `idStr` : `id`;
          return item[comparedField] === p[comparedField];
        })
      );
      this.setState({
        selectedItems: remainItems,
      });
    }
    if (remainItems && remainItems !== preState.remainItems) {
      this.setState({
        selectedSetting: remainItems,
      });
    }
  }
  _isRowValid = (row) => {
    return !!row.product_vendor_name;
  };

  isActionAllowed(actionCode, allowedActions) {
    if (!allowedActions || !allowedActions.length) return;
    return allowedActions.includes(actionCode);
  }

  _loadData = () => {
    const vendorId = Number(this.props.user?.getcare_vendor_id);

    this.props.getVendorDetails(vendorId);
    this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: vendorId,
    });
  };
  _getVendorProductContrains = (params) => {
    return {
      id: params.id,
      getcare_product_id: params.getcare_product?.id,
      product_vendor_code: params.product_vendor_code
        ? params.product_vendor_code
        : '',
      product_vendor_name: params.product_vendor_name,
      getcare_uom_base_id: params.getcare_uom_base?.id,
      price_buy_total: params.price_buy_total,
      price_sales_total: params.price_sales_total,
      price_sales_retail_total: params.price_sales_retail_total,
      commission: params.commission,
      registration_number: params.registration_number,
      vat: params.vat,
      description: params.description,
      minimum_quantity: params.minimum_quantity,
      estimated_quantity: params.estimated_quantity,
      remaining_quantity: params.remaining_quantity,
      type_label: params.type_label,
      ka_days: params.ka_days,
      getcare_category_ecoms: params.getcare_category_ecoms,
      weight: params.weight,
      images: params.images,
    };
  };
  _getNewAndUpdatedVendorProducts = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    return vendorProductList.filter((tempItem) => {
      const oriItem = oriVendorProductList.find(
        (item) => item.id === tempItem.id
      );
      return (
        this._isRowValid(tempItem) &&
        (!oriItem ||
          !isEqual(
            this._getVendorProductContrains(tempItem),
            this._getVendorProductContrains(oriItem)
          ))
      );
    });
  };
  
  _isVendorProductsChanged = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    if (!vendorProductList || !oriVendorProductList) return false;
    return (
      this._getNewAndUpdatedVendorProducts().length > 0 ||
      this._getProductsDeletedData().length > 0
    );
  };
  _prepareData = () => {
    const list = this._getNewAndUpdatedVendorProducts().map((product) => ({
      id: product.id,
      getcare_vendor_id: Number(this.props.user?.getcare_vendor_id),
      getcare_product_id: product.getcare_product_id || null,
      product_vendor_code: product.product_vendor_code || null,
      product_vendor_name: product.product_vendor_name || null,
      getcare_uom_base_id: product.getcare_uom_base?.id || null,
      price_buy_total: validNumber(product.price_buy_total),
      price_sales_total: validNumber(product.price_sales_total),
      price_sales_retail_total: validNumber(product.price_sales_retail_total),
      commission: validNumber(product.commission),
      registration_number: product.registration_number,
      vat: validNumber(product.vat),
      minimum_quantity: validNumber(product.minimum_quantity),
      estimated_quantity: validNumber(product.remaining_quantity),
      remaining_quantity: validNumber(product.remaining_quantity),
      type_label: product.type_label,
      description: product.description,
      ka_days: validNumber(product.ka_days),
      getcare_category_ecoms: product.getcare_category_ecoms,
      weight: product.weight,
      images: product.images,
      deleted: 0,
    }));
    const deletedList = this._getProductsDeletedData();
    return [...list, ...deletedList];
  };
  _getProductsDeletedData = () => {
    const { vendorProductList, oriVendorProductList, user } = this.props;
    const remainItems = oriVendorProductList.filter(
      (p) => !vendorProductList.some((item) => item.id === p.id)
    );
    return remainItems.map((product) => ({
      id: product.id,
      getcare_vendor_id: Number(user?.getcare_vendor_id),
      deleted: 1,
    }));
  };
  _getHighlightedList = () => {
    const { vendorProductList, oriVendorProductList } = this.props;
    return vendorProductList.map((p) => {
      const oriItem = oriVendorProductList.find((item) => item.id === p.id);
      return {
        ...p,
        oriItem: oriItem ? { ...oriItem } : null,
      };
    });
  };
  _hasProductUomBaseDuplicated = () => {
    const productUomBaseMap = this.props.vendorProductList
      .filter((item) => item.getcare_product?.id && item.getcare_uom_base?.id)
      .reduce((memo, item) => {
        const key = `${item.getcare_product.id}-${item.getcare_uom_base.id}`;
        memo[key] = [null, undefined, ''].includes(memo[key])
          ? 0
          : memo[key] + 1;
        return memo;
      }, {});
    return Object.keys(productUomBaseMap).some(
      (key) => productUomBaseMap[key] > 0
    );
  };
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage = false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage
      ? {
          ...this.state.listParams,
          ...paramsFromFilters,
          page: 1,
        }
      : {
          ...this.state.listParams,
          ...paramsFromFilters,
        };
    this.setState({
      listParams: newParams,
    });
    if (this._isVendorProductsChanged()) {
      this.setState({
        pausedAction: 'filter',
      });
      this.handleConfirmDialogOpen();
      return;
    }
    this.props.getVendorProductList({
      params: newParams,
      vendorId: Number(this.props.user?.getcare_vendor_id),
    });
  };
  handleAllSelectedToggle = (e) => {
    this.setState({
      selectedItems: e.target.checked
        ? [...this.state.selectedItems, ...this.props.vendorProductList]
        : [],
    });
  };

  handleClick2 = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl2: currentTarget,
      openEl2: !state.openEl2,
      openEl1: false,
    }));
  };

  handleClose = () => {
    this.setState({
      openEl2: false,
    });
  };

  handleItemSelectedToggle = ({ item, isSelected }) => {
    const remainItems = this.state.selectedItems.filter((p) => {
      const comparedField = p.idStr ? 'idStr' : 'id';
      return p[comparedField] !== item[comparedField];
    });
    this.setState({
      selectedItems: isSelected ? [...remainItems, { ...item }] : remainItems,
    });
  };
  handleSelectChange = (e) => {
    this.handleFilterChange([
      {
        name: e.target.name,
        value: e.target.value,
      },
    ]);
  };
  handleCreateNewRow = () => {
    this.props.saveVendorProductItem({
      idStr: genID(),
      id: 0,
      getcare_product: null,
      product_vendor_code: '',
      product_vendor_name: '',
      getcare_uom_base: null,
      price_buy_total: '',
    });
  };
  handleSaveVendorProduct = (params) => {
    this.props.saveVendorProductItem({
      ...params,
    });
  };
  handleRemoveVendorProducts = (items) => {
    this.props.removeVendorProductItems(items);
  };
  handleRemoveSelectedItems = () => {
    this.handleRemoveVendorProducts([...this.state.selectedItems]);
  };

  handleCancelVendorProducts = () => {
    this._loadData();
  };
  handleSubmitVendorProducts = () => {
    const hasDupplicated = this._hasProductUomBaseDuplicated();
    if (hasDupplicated) {
      toast.error('Có một cặp sản phẩm - đơn vị tính trùng nhau.');
      return;
    }
    this.props.updateVendorProductList({
      params: [...this._prepareData()],
      currentListParams: {
        params: this.state.listParams,
        vendorId: Number(this.props.user?.getcare_vendor_id),
      },
    });
  };
  handleDownloadTemplate = () => {
    this.props.exportVendorProductListTemplate();
  };
  handleDownloadProducts = () => {
    this.props.exportVendorProductList({
      vendorId: Number(this.props.user?.getcare_vendor_id),
    });
  };
  handleConfirmDialogOpen = () => {
    this.setState({
      isConfirmDialogOpen: true,
    });
  };
  handleConfirmDialogClose = () => {
    if (this.state.pausedAction === 'filter') {
      this.props.getVendorProductList({
        params: this.state.listParams,
        vendorId: Number(this.props.user?.getcare_vendor_id),
      });
    }
    if (this.state.pausedAction === 'import') {
      this.handleImportDialogOpen();
    }
    this.setState({
      isConfirmDialogOpen: false,
      pausedAction: '',
    });
  };
  backToVendorDetails = () => {
    this.props.history.push(`/vendor/${this.props.user?.getcare_vendor_id}`);
  };
  handleConfirmDialogCancel = () => {
    this.handleConfirmDialogClose();
  };
  handleConfirmDialogSubmit = () => {
    this.handleSubmitVendorProducts();
    this.handleConfirmDialogClose();
  };
  handleConfirmImportDialogOpen = () => {
    if (this._isVendorProductsChanged()) {
      this.setState({
        pausedAction: 'import',
      });
      this.handleConfirmDialogOpen();
      return;
    }
    this.handleImportDialogOpen();
  };
  handleImportDialogOpen = () => {
    this.setState({
      isImportDialogOpen: true,
    });
  };
  handleImportDialogClose = () => {
    this.setState({
      isImportDialogOpen: false,
    });
  };
  handleCompleteImport = () => {
    this.setState({
      listParams: { ...listParamsMap },
    });
    this.props.getVendorProductList({
      params: { ...listParamsMap },
      vendorId: Number(this.props.user?.getcare_vendor_id),
    });
  };

  handleProductCategorySelectDialogOpen = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: true,
    });
  }
  handleTagGetcareCategoryDialogClose = () => {
    this.setState({
      isTagGetcareCategoryDialogOpen: false,
    });
  }
  handleSubmitSuccessTagGetcareCategory = () => {
    const { id } = this.props.match.params;
    this.props.getVendorProductList({
      params: this.state.listParams,
      vendorId: id,
    });
  }

  render() {
    const {
      vendorProductListTotal,
      loading,
      actionLoading,
      vendorDetails,
      user,
    } = this.props;
    const { listParams, selectedItems, openEl2, anchorEl2, selectedSetting, isTagGetcareCategoryDialogOpen } =
      this.state;
    const isListLoading = loading || actionLoading;
    const hasSelected = selectedItems.length > 0;
    const isChanged = this._isVendorProductsChanged();
    const dataSetting = JSON.parse(sessionStorage.getItem('setting'));

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>
              {vendorDetails?.name || '...'}
            </h1>
            <div className={classes.PageHeaderToolbar}>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!isChanged}
                onClick={this.handleCancelVendorProducts}
              >
                Huỷ thay đổi
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!isChanged}
                startIcon={<CheckOutlined />}
                onClick={this.handleSubmitVendorProducts}
              >
                Xác nhận thay đổi
              </Button>
            </div>
          </div>
          <div className={classes.PageHeader}>
            <div>
              <label className={classes.SelectLabel}>
                Hiển thị danh sách theo
              </label>
              <Select
                className={classes.SelectWrap}
                value={listParams.getcare_product_is_null}
                name="getcare_product_is_null"
                size="small"
                autoWidth
                onChange={this.handleSelectChange}
              >
                {productOptions.map((item) => (
                  <MenuItem key={`status-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleConfirmImportDialogOpen}
              >
                Import File
              </Button>
              <Button
                disabled={!this.props.vendorProductList.length}
                variant="contained"
                size="small"
                onClick={this.handleDownloadProducts}
              >
                Export File
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={this.handleDownloadTemplate}
              >
                Download template
              </Button>
            </div>
            <div>
              <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                variant="contained"
                color="primary"
                size="medium"
                onClick={this.handleProductCategorySelectDialogOpen}
              >
                Tag danh mục
              </Button>
              <Button
                className={`${classes.MultiActionBtn}`}
                disabled={!hasSelected}
                color="secondary"
                variant="contained"
                size="medium"
                onClick={this.handleRemoveSelectedItems}
              >
                Xoá
              </Button>
              <Button
                className={`${classes.MultiActionBtn}`}
                size="medium"
                onClick={this.handleClick2}
                variant="contained"
              >
                <img
                  src={viewMore}
                  alt="View more"
                  className={`${classes.viewMore}`}
                />
                Hiển thị
              </Button>
              <Popper
                open={openEl2}
                anchorEl={anchorEl2}
                placement="bottom"
                className={`${classes.wrapperContent}`}
              >
                <ClickAwayListener onClickAway={this.handleClose}>
                  <Paper>
                    <ul>
                      <RadioGroup
                        aria-label="gender"
                        className={`${classes.Radio}`}
                        name="radio-buttons-group"
                      >
                        {dataSetting
                          ? dataSetting.map((item, i) => (
                              <FormControlLabel
                                className={`${classes.ControlLabel}`}
                                control={
                                  <Checkbox
                                    className={`${classes.checkBox}`}
                                    disabled={
                                      item.name === 'product_vendor_code' ||
                                      item.name === 'product_vendor_name' ||
                                      item.name === 'getcare_uom_base_name' ||
                                      item.name === 'price_buy_total' ||
                                      item.name === 'price_sales_total' ||
                                      item.name === 'price_sales_retail_total'
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      if (e.target.checked === false) {
                                        const index = dataSetting.findIndex(
                                          (x) => x.name === item.name
                                        );
                                        dataSetting[index].checked = false;
                                        this.setState({
                                          selectedSetting: [...dataSetting],
                                        });
                                        sessionStorage.setItem(
                                          'setting',
                                          JSON.stringify([...dataSetting])
                                        );
                                      } else {
                                        const index = dataSetting.findIndex(
                                          (x) => x.name === item.name
                                        );
                                        dataSetting[index].checked = true;
                                        this.setState({
                                          selectedSetting: [...dataSetting],
                                        });
                                        sessionStorage.setItem(
                                          'setting',
                                          JSON.stringify([...dataSetting])
                                        );
                                      }
                                    }}
                                    checked={
                                      selectedSetting.filter(
                                        (e) =>
                                          e.name === item.name &&
                                          e.checked === true
                                      ).length > 0
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                key={i}
                                label={item.label}
                              />
                            ))
                          : null}
                      </RadioGroup>
                    </ul>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          </div>
          <div className={classes.PageMain}>
            <VendorProductFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <VendorProductList
              displayFields={getDisplayFields(
                listParams,
                selectedSetting.filter((e) => e.checked === true)
              )}
              vendorProductList={this._getHighlightedList()}
              selectedItems={selectedItems}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
              onItemSelectedToggle={this.handleItemSelectedToggle}
              onAllSelectedToggle={this.handleAllSelectedToggle}
              onSaveVendorProduct={this.handleSaveVendorProduct}
              onRemoveVendorProducts={this.handleRemoveVendorProducts}
              onAddNewRow={this.handleCreateNewRow}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={vendorProductListTotal}
              listName="sản phẩm"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
        {this.state.isConfirmDialogOpen && (
          <ConfirmationDialog
            isOpen={this.state.isConfirmDialogOpen}
            title="Lưu thay đổi"
            message="Bạn vừa có thay đổi, bạn có xác nhận lưu thay đổi này không?"
            onClose={this.handleConfirmDialogCancel}
            onSubmit={this.handleConfirmDialogSubmit}
          />
        )}
        {this.state.isImportDialogOpen && (
          <FileImportDialog
            vendorId={Number(user?.getcare_vendor_id)}
            title="Import danh sách sản phẩm"
            isOpen={this.state.isImportDialogOpen}
            onClose={this.handleImportDialogClose}
            onCompleteImport={this.handleCompleteImport}
            onViewImportLog={this.handleViewImportLog}
          />
        )}
        {isTagGetcareCategoryDialogOpen && (
          <TagGetcareCategory
            isOpen={isTagGetcareCategoryDialogOpen}
            onClose={this.handleTagGetcareCategoryDialogClose}
            productIds={selectedItems.map(item => item.id)}
            onSubmitSuccess={this.handleSubmitSuccessTagGetcareCategory}
            isTagForVendor
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  vendorProductListTotal: makeSelectVendorProductListTotal(),
  vendorProductList: makeSelectVendorProductList(),
  loading: makeSelectVendorProductLoading(),
  vendorDetails: makeSelectVendorDetails(),
  oriVendorProductList: makeSelectOriVendorProductList(),
  actionLoading: makeSelectVendorProductActionLoading(),
  user: makeSelectLoginUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorProductList: (payload) => dispatch(getVendorProductList(payload)),
    getVendorDetails: (payload) => dispatch(getVendorDetails(payload)),
    saveVendorProductItem: (payload) =>
      dispatch(saveVendorProductItem(payload)),
    removeVendorProductItems: (payload) =>
      dispatch(removeVendorProductItems(payload)),
    updateVendorProductList: (payload) =>
      dispatch(updateVendorProductList(payload)),
    exportVendorProductListTemplate: (payload) =>
      dispatch(exportVendorProductListTemplate(payload)),
    exportVendorProductList: (payload) =>
      dispatch(exportVendorProductList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(VendorProducts);
