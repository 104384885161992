import logisticApi from 'utils/api/logisticApi';
import { put } from 'redux-saga/effects';
import { logisticActions } from 'redux/actions';

export function* loadDeliveryLogisticList(payload) {
  const { params } = payload.payload;
  try {
    yield put(logisticActions.saveLogisticListLoading(true));

    const { data: response } = yield logisticApi.getAllByDelivery({ params });

    yield put(logisticActions.saveLogisticListLoading(false));

    if (!response?.result || !response.data) {
      yield put(logisticActions.saveDeliveryLogisticList([]));
      return;
    }
    yield put(logisticActions.saveDeliveryLogisticList(response.data));
  } catch (err) {
    console.log(err);
    yield put(logisticActions.saveLogisticListLoading(false));
  }
}
export function* loadLogisticList(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield logisticApi.getAll({ params });

    if (!response?.result || !response.data) {
      yield put(logisticActions.saveLogisticList([]));
      return;
    }
    yield put(logisticActions.saveLogisticList(response.data));
  } catch (err) {
    console.log(err);
  }
}
