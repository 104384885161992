import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCountryList,
  makeSelectProvinceList,
  makeSelectDistrictList,
  makeSelectWardList,
} from 'redux/selectors';
import {
  getCountryList,
  getProvinceList,
  getDistrictList,
  getWardList,
} from 'redux/actions/locationActions';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import classes from './CustomerDeliveryFormDialog.module.scss';

class CustomerAddressForm extends PureComponent {


  componentDidMount() {
    const { values } = this.props;
    this.props.getCountryList({
      params: { name: '',  getcare_country_id: 220
    },
    });
    this.props.getProvinceList({
      params: { name: '', 
      getcare_country_id: values?.countryId ?  values?.countryId  : ''
    },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { values } = this.props;
    if (values.countryId !== prevProps.values.countryId) {
      this.props.getProvinceList({
        params: { name: '', getcare_country_id: values.countryId },
      });
    }
    if (values.provinceId !== prevProps.values.provinceId) {
      this.props.getDistrictList({
        params: { name: '', getcare_province_id: values.provinceId },
      });
    }
    if (values.districtId !== prevProps.values.districtId) {
      this.props.getWardList({
        params: { name: '', getcare_district_id: values.districtId },
      });
    }
  }

  render() {
    const {
      isNewAddress,
      countryList,
      provinceList,
      districtList,
      wardList,
      values,
      errors,
      handleChange,
      setFieldValue,
      handleSubmit,
    } = this.props;

    return (<div>
      <div className={classes.FieldControlGroup}>
        <div className={classes.FieldControl}>
          <TextField
            label="Cơ sở nhận *"
            autoComplete="off"
            value={values.label}
            placeholder="Nhập..."
            name="label"
            variant="filled"
            className={`${classes.Field} TextFieldFilled`}
            error={!!errors.label}
            helperText={errors.label}
            onChange={handleChange}
          />
        </div>
        <div className={classes.FieldControl}>
          <TextField
            label="Tên người nhận *"
            autoComplete="off"
            value={values.name}
            placeholder="Nhập..."
            name="name"
            variant="filled"
            className={`${classes.Field} TextFieldFilled`}
            error={!!errors.name}
            helperText={errors.name}
            onChange={handleChange}
          />
        </div>
        <div className={classes.FieldControl}>
          <TextField
            label="Số điện thoại *"
            autoComplete="off"
            value={values.phone}
            placeholder="0912345678"
            name="phone"
            variant="filled"
            className={`${classes.Field} TextFieldFilled`}
            error={!!errors.phone}
            helperText={errors.phone}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={classes.FieldControlGroupBottom}>
      <div className={classes.FieldControl}>
      <Autocomplete
        name="getcare_country"
        disableClearable
        handleHomeEndKeys={false}
        value={values.country || null}
        options={countryList || []}
        getOptionLabel={(option) => option?.name || ''}
        renderOption={(option) => option?.name}
        getOptionSelected={(option, value) =>
          option.id === value.id
        }
        renderInput={(params) => (
          <TextField
            variant="filled"
            className={`${classes.Field} TextFieldFilled`}
            label="Quốc gia*"
            {...params}
            error={!!errors.countryId}
            helperText={errors.countryId}
          />
        )}
        onChange={async (e, newValue) => {
          await setFieldValue('country', newValue);
          await  setFieldValue('countryId', newValue?.id || '');
          await setFieldValue('province', null);
          await  setFieldValue('provinceId', '');
          await  setFieldValue('district', null);
          await setFieldValue('districtId', '');
          await setFieldValue('ward', null);
          await  setFieldValue('wardId', '');
        }}
      />
        </div>
        <div className={classes.FieldControl}>
          <Autocomplete
            disableClearable
            name="getcare_province"
            handleHomeEndKeys={false}
            value={values.province || null}
            options={provinceList || []}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(option) => option?.name}
            getOptionSelected={(option, value) =>
              option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                label="Tỉnh/TP *"
                placeholder="Chọn..."
                {...params}
                variant="filled"
                className={`${classes.Field} TextFieldFilled`}
                error={!!errors.provinceId}
                helperText={errors.provinceId}
              />
            )}
            onChange={async (e, newValue) => {
            await  setFieldValue('province', newValue);
            await  setFieldValue('provinceId', newValue?.id || '');
            await  setFieldValue('district', null);
            await  setFieldValue('districtId', '');
            await  setFieldValue('ward', null);
            await  setFieldValue('wardId', '');

            }}
          />
        </div>
        <div className={classes.FieldControl}>
          <Autocomplete
            disableClearable
            name="getcare_district"
            handleHomeEndKeys={false}
            value={values.district  || null}
            options={districtList || []}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(option) => option?.name}
            getOptionSelected={(option, value) =>
              option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                label="Quận/Huyện *"
                placeholder="Chọn..."
                {...params}
                variant="filled"
                className={`${classes.Field} TextFieldFilled`}
                error={!!errors.districtId}
                helperText={errors.districtId}
              />
            )}
            onChange={async (e, newValue) => {
              await setFieldValue('district', newValue);
              await setFieldValue('districtId', newValue?.id || '');
              await setFieldValue('ward', null);
              await setFieldValue('wardId', '');
            }}
          />
        </div>
        <div className={classes.FieldControl}>
          <Autocomplete
            disableClearable
            name="getcare_ward"
            handleHomeEndKeys={false}
            value={values.ward}
            options={wardList || []}
            getOptionLabel={(option) => option?.name || ''}
            renderOption={(option) => option?.name}
            getOptionSelected={(option, value) =>
              option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                variant="filled"
                className={`${classes.Field} TextFieldFilled`}
                label="Phường/Xã *"
                placeholder="Chọn..."
                {...params}
                error={!!errors.wardId}
                helperText={errors.wardId}
              />
            )}
            onChange={async (e, newValue) => {
             await  setFieldValue('ward', newValue);
             await  setFieldValue('wardId', newValue?.id || '');
            }}
          />
        </div>
      </div>
      <div className={classes.FieldControl}>
        <TextField
          label="Địa chỉ *"
          autoComplete="off"
          value={values.address}
          placeholder="Nhập..."
          variant="filled"
          className={`${classes.Field} TextFieldFilled`}
          color="primary"
          name="address"
          error={!!errors.address}
          helperText={errors.address}
          onChange={handleChange}
        />
      </div>
      <div className={`${classes.FieldControl} ${classes.FieldControlLabel}`}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              name="default"
              checked={values.default}
              onChange={(e) => {
                setFieldValue('default', e.target.checked);
              }}
            />
          }
          label="Làm địa chỉ mặc định"
        />
      </div>
      { isNewAddress &&
        <div className={classes.CustomInfoNote}>
          <p>Phahub sẽ xác thực thông tin địa chỉ mới trước khi giao hàng</p>
        </div>
      }
      <DialogActions className={classes.DialogActions}>
        <Button
          type="button"
          variant="contained"
          size="large"
          onClick={handleSubmit}
          color="secondary"
        >
          Áp dụng
        </Button>
      </DialogActions>
    </div>);
  }
}

const mapStateToProps = createStructuredSelector({
  countryList: makeSelectCountryList(),
  provinceList: makeSelectProvinceList(),
  districtList: makeSelectDistrictList(),
  wardList: makeSelectWardList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCountryList: (payload) => dispatch(getCountryList(payload)),
    getProvinceList: (payload) => dispatch(getProvinceList(payload)),
    getDistrictList: (payload) => dispatch(getDistrictList(payload)),
    getWardList: (payload) => dispatch(getWardList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default (compose(withConnect, withRouter)(CustomerAddressForm));
