import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FileUpload from 'components/FileUpload/FileUpload';
import policyPriceApi from 'utils/api/mdm/policyPriceApi';

import classes from './FileImportDialog.module.scss';

class FileImportDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstRowIncluded: false,
      file: null,
      isLoading: false,
      importResult: null,
      hasConfirmed: true,
    };
  }
  _resetState = () => {
    this.setState({
      isFirstRowIncluded: false,
      file: null,
      isLoading: false,
      importResult: null,
      hasConfirmed: true,
    });
  };
  _prepareSubmitData = () => {
    return {
      file: this.state.file,
      start_row: this.state.isFirstRowIncluded ? 1 : undefined,
      // import_type: this.state.importType,
    };
  };
  isFormValid = () => {
    return !!this.state.file;
  };
  handleFilesAdded = (files) => {
    this.setState({
      file: files[0],
    });
  };
  handleFirstRowCheckboxChange = (e) => {
    this.setState({
      isFirstRowIncluded: e.target.checked,
    });
  };
  onClose = () => {
    if (this.state.importResult && this.state.hasConfirmed) {
      this.props.onCompleteImport(this.state.importResult.importedData);
    }
    this.props.onClose();
  };
  onEntered = () => {
    this._resetState();
  };
  handleSubmitImport = async () => {
    const data = this._prepareSubmitData();
    this.setState({
      isLoading: true,
    });
    const { data: response } = await policyPriceApi.importPolicyPrice({
      params: { ...data },
    });
    this.setState({
      isLoading: false,
    });

    const isSuccess = response.result && response.data;
    this.setState({
      importResult: {
        isSuccess: isSuccess,
        message: isSuccess ? '' : response.message,
        totalRecords: response.rows_total,
        successRecords: isSuccess ? response.rows_affected : 0,
        importedData: response.data,
      },
    });
  };

  render() {
    const { isOpen, title } = this.props;
    const {
      isFirstRowIncluded,
      isLoading,
      importResult,
      hasConfirmed,
    } = this.state;

    return (
      <Dialog
        open={isOpen}
        onClose={this.onClose}
        onEntered={this.onEntered}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.Dialog}
        disableBackdropClick
      >
        <div
          className={`${classes.DialogContentWrap} ${
            isLoading && 'OverlayLoading'
          }`}
        >
          {!importResult ? (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <div className={classes.FieldControl}>
                  <FileUpload onFilesAdded={this.handleFilesAdded} />

                  <div className={classes.HelpBlock}>
                    <p className={classes.FileName}>{this.state.file?.name}</p>
                    <ul className={classes.HelpText}>
                      <li>- Hỗ trợ import file xlsx/xsl và csv. Tối đa 5MB</li>
                    </ul>
                  </div>
                </div>
                <div className={classes.FieldControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFirstRowIncluded}
                        onChange={this.handleFirstRowCheckboxChange}
                        value={true}
                        name="active"
                      />
                    }
                    label="Import cả dòng đầu tiên"
                  />
                </div>
              </DialogContent>
              <DialogActions className={`${classes.DialogActions}`}>
                <Button variant="outlined" onClick={this.onClose}>
                  Huỷ
                </Button>
                <Button
                  disabled={!this.isFormValid()}
                  variant="contained"
                  onClick={this.handleSubmitImport}
                  color="primary"
                  autoFocus
                >
                  Import File
                </Button>
              </DialogActions>
            </>
          ) : (
            <>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                {hasConfirmed && (
                  <h3 className={classes.ImportConfirmTitle}>
                    Hoàn tất import
                  </h3>
                )}
                <ul className={classes.HelpText}>
                  {/* <li>- Chế độ import: { importTypesMap[importType] }</li> */}
                  <li>
                    -{' '}
                    {isFirstRowIncluded
                      ? 'Import cả dòng đầu tiên'
                      : 'Không import dòng đầu tiên'}
                  </li>
                </ul>
                <br />
                <p className={classes.Info}>
                  <label>Tổng số dòng xử lý: </label>{' '}
                  {importResult.totalRecords}
                </p>
                {importResult.isSuccess ? (
                  <p className={`${classes.Info} ${classes.Success}`}>
                    <label>Tổng số dòng import thành công:</label>{' '}
                    {importResult.successRecords}
                  </p>
                ) : (
                  <p className={`${classes.Info} ${classes.Error}`}>
                    Không thể xử lý file này. Xin hãy kiểm tra bộ dữ liệu này.
                  </p>
                )}
              </DialogContent>

              {hasConfirmed ? (
                <DialogActions className={`${classes.DialogActions}`}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onClose}
                  >
                    OK
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions
                  className={`${classes.DialogActions} ${classes.DialogActionsFlex}`}
                >
                  <div className={classes.DialogActionsLeft}>
                    <Button variant="outlined" href={importResult.logLink}>
                      Xem log
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    onClick={this.handleCancelConfirmImport}
                  >
                    Huỷ Import
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleConfirmImport}
                  >
                    Xác nhận Import
                  </Button>
                </DialogActions>
              )}
            </>
          )}
        </div>
      </Dialog>
    );
  }
}

FileImportDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onCompleteImport: PropTypes.func,
};

FileImportDialog.defaultProps = {
  isOpen: false,
  isLoading: false,
};

export default FileImportDialog;
