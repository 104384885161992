import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  groupTypes,
  PROMOTION_TYPE_COMBO,
  GROUP_TYPE_ID,
} from 'utils/constanst/tmkPromotionConstants';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import classes from './GetcarePromotionProduct.module.scss';
import gridClasses from '../GetcarePromotionProductsGrid.module.scss';

class GetcarePromotionProduct extends PureComponent {
  _isGroupType = (params) => {
    return params.type_id === GROUP_TYPE_ID;
  }

  render() {
    const {
      getcare_product,
      getcare_erp_group,
      focus,
      quantity_number,
      ratio,
      getcare_uom_base,
      promotionTypeId,
      type_id,
    } = this.props;
    const isGroupType = this._isGroupType(this.props);

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}>
          <div className={gridClasses.Col}>
            <Select
              className={classes.SelectWrap}
              value={type_id}
              name="type_id"
              size="small"
              disabled
              fullWidth
            >
              {
                groupTypes.map(item => <MenuItem key={`type-${item.id}`} value={item.id}>{ item.name }</MenuItem>)
              }
            </Select>
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? getcare_erp_group?.code : getcare_product?.product_vendor_code }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? getcare_erp_group?.name : getcare_product?.product_vendor_name }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType ? '' : getcare_uom_base?.name }
          </div>
          <div className={`${gridClasses.Col}`}>
            { isGroupType || !ratio ? '' : `${ratio}` }
          </div>

          { [PROMOTION_TYPE_COMBO].includes(promotionTypeId) && (<>
            <div className={`${gridClasses.Col} ${gridClasses.Center}`}>
              { isGroupType ? '' : (focus === 1 ? <CheckBoxIcon fontSize="small"/> : <CheckBoxOutlineBlankIcon fontSize="small"/>) }
            </div>
            <div className={`${gridClasses.Col}`}>
              { isGroupType || !quantity_number ? '' : `${quantity_number}` }
            </div>
          </>)}
        </div>
      </>
    );
  }
}

GetcarePromotionProduct.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionProduct.defaultProps = {
};

export default GetcarePromotionProduct;

