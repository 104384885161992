import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN, validNumber, roundCurrency } from 'utils/helper';

import Panel from 'components/Panel/Panel';

import classes from './FastOrderAmount.module.scss';

class FastOrderAmount extends PureComponent {
  render() {
    const { isFinalStep, subTotal, amountDiscount, amountDelivery, amountShippingCoupon, amountTotal } = this.props;

    return (
      <Panel
        title={isFinalStep ? `Tổng đơn hàng` : `Hoá đơn tạm tính`}
        panelClassName={classes.Panel}
        isBorder
        content={
          <div className={classes.Wrap}>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Tiền hàng</label>
              <strong>
                {validNumber(subTotal)
                  ? currencyFormatVN(roundCurrency(subTotal))
                  : `-`}
              </strong>
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Giảm giá</label>
              <strong>
                {validNumber(amountDiscount)
                  ? `-${currencyFormatVN(
                      roundCurrency(amountDiscount)
                    )}`
                  : `-`}
              </strong>
            </div>
            <div className={`${classes.Info} ${classes.InfoLine}`}>
              <label>Phí vận chuyển</label>
              <strong>
                {validNumber(amountDelivery)
                  ? currencyFormatVN(roundCurrency(amountDelivery))
                  : `-`}
              </strong>
            </div>
            {amountShippingCoupon > 0 && (
              <div className={`${classes.Info} ${classes.InfoLine}`}>
                <label>Shipping coupon</label>
                <strong>
                  {`-${currencyFormatVN(
                    roundCurrency(amountShippingCoupon)
                  )}`}
                </strong>
              </div>
            )}
            <div
              className={`${classes.Info} ${classes.InfoLine} ${classes.InfoTotal}`}
            >
              <label>Tổng tiền</label>
              <span className={classes.Amount}>
                {validNumber(amountTotal)
                  ? currencyFormatVN(roundCurrency(amountTotal))
                  : `-`}
              </span>
            </div>
            <div className={`${classes.Info} ${classes.HelpLine}`}>
              (Đã bao gồm VAT nếu có)
            </div>
          </div>
        }
      />
    );
  }
}

FastOrderAmount.propTypes = {
  isFinalStep: PropTypes.bool,
};
FastOrderAmount.defaultProps = {
  isFinalStep: false,
};

export default FastOrderAmount;
