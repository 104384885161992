import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  isGetcareLogisticProvider,
  isChanhXeLogisticProvider,
  isThirdPartyLogisticProvider,
  logisticOtherMap,
  LOGISTIC_OTHER_ID,
  LOGISTIC_GETCARE_ID,
  LOGISTIC_CHANH_XE_ID,
  LOGISTIC_VENDOR_ID,
  suggestionPageSize,
  getAvailableLogisticProviders,
} from 'utils/constanst/common';
import { REQUIRED_NOTE_CHOXEMHANGKHONGTHU } from 'utils/constanst/omsSalesOrderConstants';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectDeliveryLogisticList, makeSelectDeliveryUserList } from 'redux/selectors';
import { getDeliveryLogisticList } from 'redux/actions/logisticActions';
import { getDeliveryUserList } from 'redux/actions/oms/deliveryActions';
import { isEqual, debounce } from 'lodash';
import { numberFormat } from 'utils/helper';

import DialogContent from '@material-ui/core/DialogContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import GetcareDelivery from './GetcareDelivery/GetcareDelivery';
import ChanhXeDelivery from './ChanhXeDelivery/ChanhXeDelivery';
import OtherLogisticDelivery from './OtherLogisticDelivery/OtherLogisticDelivery';
import FromAddress from './FromAddress/FromAddress';

import classes from './DeliverySentForm.module.scss';

class DeliverySentForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedProviderId: isThirdPartyLogisticProvider(props.salesOrderLogistic?.type) ? LOGISTIC_OTHER_ID : props.salesOrderLogistic?.id,
      isLoadingDeliveryLogisticList: false,
    }
    const MAX_TOTAL_FEE = 999999;
    this.validationShapeMap = {
      [LOGISTIC_GETCARE_ID]: {
        cod: Yup.number().required("Vui lòng nhập"),
        transporter_user_id: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
        total_fee: Yup.number().required("Vui lòng nhập").max(MAX_TOTAL_FEE, `Tối đa ${numberFormat(MAX_TOTAL_FEE)}`),
        weight: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        length: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        width: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        height: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        note: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      },
      [LOGISTIC_VENDOR_ID]: {
        cod: Yup.number().required("Vui lòng nhập"),
        total_fee: Yup.number().required("Vui lòng nhập").max(MAX_TOTAL_FEE, `Tối đa ${numberFormat(MAX_TOTAL_FEE)}`),
        weight: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        length: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        width: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        height: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        note: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      },
      [LOGISTIC_CHANH_XE_ID]: {
        cod: Yup.number().required("Vui lòng nhập"),
        name_2: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
        // transporter_user_id: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
        total_fee: Yup.number().required("Vui lòng nhập").max(MAX_TOTAL_FEE, `Tối đa ${numberFormat(MAX_TOTAL_FEE)}`),
        order_code: Yup.string().required("Vui lòng nhập").max(55, 'Tối đa 55 ký tự'),
        weight: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        length: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        width: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        height: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        note: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
      },
      [LOGISTIC_OTHER_ID]: {
        providerId: Yup.string().required("Vui lòng chọn"),
        cod: Yup.number().required("Vui lòng nhập"),
        total_fee: Yup.number().required("Vui lòng nhập").max(MAX_TOTAL_FEE, `Tối đa ${numberFormat(MAX_TOTAL_FEE)}`),
        weight: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        length: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        width: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        height: Yup.number().required("Vui lòng nhập").max(99, 'Tối đa 99'),
        service_id: Yup.string().required("Vui lòng chọn"),
        required_note: Yup.string().required("Vui lòng chọn"),
        pickup_date: Yup.string().required("Vui lòng chọn"),
        note: Yup.string().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
        from_address_address: Yup.string().trim().when('open_from_address', { 
          is: value => value,
          then: Yup.string().trim().required("Vui lòng nhập").max(255, 'Tối đa 255 ký tự'),
        }),
        from_address_getcare_province_id: Yup.string().trim().when('open_from_address', { 
          is: value => value,
          then: Yup.string().required("Vui lòng chọn"),
        }),
        from_address_getcare_district_id: Yup.string().trim().when('open_from_address', { 
          is: value => value,
          then: Yup.string().required("Vui lòng chọn"),
        }),
        from_address_getcare_ward_id: Yup.string().trim().when('open_from_address', { 
          is: value => value,
          then: Yup.string().required("Vui lòng chọn"),
        }),
      },
    };
  }

  componentDidMount() {
    this._loadDeliveryLogisticList();
    this._loadDeliveryUserList();
  }
  componentDidUpdate(prevProps) {
    const { customerCode, salesOrderLogistic, customerDelivery } = this.props;
    if ((customerCode && customerCode !== prevProps.customerCode)
      || (customerDelivery && !isEqual(customerDelivery, prevProps.customerDelivery))
      || (salesOrderLogistic && !isEqual(salesOrderLogistic, prevProps.salesOrderLogistic))
    ) {
      this._loadDeliveryLogisticList();
    }

    if (salesOrderLogistic && salesOrderLogistic.id && salesOrderLogistic.id !== prevProps.salesOrderLogistic?.id) {
      this.setState({
        selectedProviderId: isThirdPartyLogisticProvider(salesOrderLogistic.type) ? LOGISTIC_OTHER_ID : salesOrderLogistic.id,
      });
    }
  }

  _debounceLoadDeliveryLogisticList = debounce(({ length, width, height, weight, open_from_address, from_address_getcare_ward_id }) => {
    this._loadDeliveryLogisticList({ 
      length: Number(length), 
      width: Number(width), 
      height: Number(height), 
      weight: Math.round(Number(weight)*1000), 
      from_getcare_ward_id: (open_from_address && from_address_getcare_ward_id) ? from_address_getcare_ward_id : undefined,
    });
  },500)
  _loadDeliveryLogisticList = async (params) => {
    const { customerCode, salesOrderLogistic, customerDelivery } = this.props;
    if (customerDelivery && customerCode) {
      this.setState({
        isLoadingDeliveryLogisticList: true
      })
      await this.props.getDeliveryLogisticList({
        params: {
          getcare_pharmacy_code: customerCode,
          insurance_value: salesOrderLogistic?.insurance_value || undefined,
          getcare_ward_id: customerDelivery.getcare_ward?.id,
          ...params ? params : {
            weight: salesOrderLogistic?.weight || undefined,
            height: salesOrderLogistic?.height || undefined,
            length: salesOrderLogistic?.length || undefined,
            width: salesOrderLogistic?.width || undefined,
          }
        },
      })
      this.setState({
        isLoadingDeliveryLogisticList: false
      })
    }
  }
  _loadDeliveryUserList = () => {
    this.props.getDeliveryUserList({
      params: { page: 1, page_size: suggestionPageSize, },
    });
  }

  _getLogisticProvider = (providerId) => {
    return this.props.deliveryLogisticList.find(item => item.id === providerId);
  }
  _getService = (providerId, serviceId) => {
    const selectedProvider = this._getLogisticProvider(providerId);
    if (!selectedProvider || !selectedProvider.services) return undefined;
    return selectedProvider.services.find(item => item.service_id === serviceId);
  }
  _getAvailableProviders = () => {
    const amountTotal = this.props.amountGoods + this.props.deliveryFeeTotal;
    return getAvailableLogisticProviders(this.props.deliveryLogisticList, amountTotal);
  }
  _getThirdPartyLogisticProviders = () => {
    return this._getAvailableProviders().filter(item => isThirdPartyLogisticProvider(item.type));
  }
  _getInHouseLogisticProvider = () => {
    return this._getAvailableProviders().filter(item => !isThirdPartyLogisticProvider(item.type));
  }
  _getLogisticProviderOptions = () => {
    const inHouseProviders = this._getInHouseLogisticProvider();
    if (!inHouseProviders) return [{...logisticOtherMap}];
    return [
      ...this._getInHouseLogisticProvider(),
      {...logisticOtherMap},
    ]
  }
  _getDefaultNote = () => {
    const { salesOrder, selectedOrders } = this.props;

    const selectedOrderCodesString = selectedOrders ? selectedOrders.map(order => order.code).join(' - ') : '';
    const selectedOrderSales = selectedOrders ? selectedOrders[0] : null;

    return salesOrder
      ? `Mã đơn hàng ${salesOrder?.code}, nếu không gọi được khách, gọi cho ${salesOrder?.sales_name || '-'}, SĐT ${salesOrder?.sales_phone || '-'}`
      : (selectedOrders
        ? `Mã đơn hàng ${selectedOrderCodesString}, nếu không gọi được khách, gọi cho ${selectedOrderSales?.sales_name || '-'}, SĐT ${selectedOrderSales?.sales_phone || '-'}`
        : '');
  }
  _prepareData = (params) => {
    const { selectedProviderId } = this.state;
    const { amountGoods } = this.props;

    const _isThirdParty = isThirdPartyLogisticProvider(selectedProviderId);
    const _isGetcareLogisticProvider = isGetcareLogisticProvider(selectedProviderId);
    const _isChanhXeLogisticProvider = isChanhXeLogisticProvider(selectedProviderId);
    const providerId = _isThirdParty ? Number(params.providerId) : Number(selectedProviderId);
    const serviceId = _isThirdParty ? Number(params.service_id) : 0;
    const logistic_provider = { 
      id: providerId,
      name: this._getLogisticProvider(providerId)?.name || '',
      service_id: serviceId,
      service_name: _isThirdParty ? this._getService(providerId, params.service_id)?.service_name : undefined,
      total_fee: Number(params.total_fee),
      total_fee_2: Number(params.total_fee_2) || undefined,
      insurance_value: _isGetcareLogisticProvider ? undefined : (params.insurance_value ? amountGoods : 0),
      note: params.note,
      cod: Number(params.cod),
      required_note: _isThirdParty ? params.required_note : undefined,
      transporter_user_id: _isThirdParty ? undefined : (params.transporter_user_id || null),
      name_2: _isChanhXeLogisticProvider ? params.name_2 : undefined,
      order_code: _isChanhXeLogisticProvider ? params.order_code : undefined,
      weight: Math.round(Number(params.weight)*1000),
      width: Math.round(Number(params.width)),
      height: Math.round(Number(params.height)),
      length: Math.round(Number(params.length)),
      pickup_date: _isThirdParty ? params.pickup_date : undefined,
    };
    const from_address = params.open_from_address ? {
      address: params.from_address_address,
      getcare_province_id: params.from_address_getcare_province_id,
      getcare_district_id: params.from_address_getcare_district_id,
      getcare_ward_id: params.from_address_getcare_ward_id,
    } : undefined;

    return {
      logistic_provider,
      from_address,
    }
  }

  handleSubmit = (values) => {
    this.props.onSubmitDelivery({
      ...this._prepareData(values),
    });
  }
  handleAmountChange = async (values, setFieldValue) => {
    // recalculate cod
    setFieldValue('cod', Number(values.amount_goods) + Number(values.total_fee));
  }

  render() {
    const { deliveryLogisticList, salesOrderLogistic, amountGoods, deliveryFeeTotal, isShipmentCombined, deliveryUserList } = this.props;
    const { selectedProviderId, isLoadingDeliveryLogisticList } = this.state;

    const reparedDeliveryLogisticList = this._getLogisticProviderOptions();

    return (
      <Formik
        innerRef={this.props.formRef}
        initialValues={{
          providerId: salesOrderLogistic?.id,
          service_id: salesOrderLogistic?.service_id || '',
          total_fee: deliveryFeeTotal,
          total_fee_2: deliveryFeeTotal,
          insurance_value: salesOrderLogistic?.insurance_value || 0,
          pickup_date: salesOrderLogistic?.pickup_date || new Date(),
          note: salesOrderLogistic?.note || this._getDefaultNote(),
          amount_goods: amountGoods,
          cod: (amountGoods + deliveryFeeTotal) || salesOrderLogistic?.cod || '',
          required_note: salesOrderLogistic?.required_note || REQUIRED_NOTE_CHOXEMHANGKHONGTHU,
          transporter_user_id: salesOrderLogistic?.transporter_user_id || '',
          transporter_user: salesOrderLogistic?.transporter_user || null,
          name_2: salesOrderLogistic?.name_2 || '',
          order_code: salesOrderLogistic?.order_code || '',
          weight: salesOrderLogistic?.weight/1000 || '',
          width: salesOrderLogistic?.width || '',
          height: salesOrderLogistic?.height || '',
          length: salesOrderLogistic?.length || '',
          open_from_address: false,
          from_address_address: '',
          from_address_getcare_province_id: '',
          from_address_getcare_district_id: '',
          from_address_getcare_ward_id: '',
        }}
        enableReinitialize={isShipmentCombined}
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object().shape(this.validationShapeMap[this.state.selectedProviderId])}
      >
        {(props) => {
          return (
            <form noValidate autoComplete="off" className={classes.Dialog}>
              <DialogContent className={classes.DialogContent}>
                <FormControl component="fieldset">
                  <FromAddress
                    {...props}
                    setFieldValue={ (field,value) => {
                      props.setFieldValue(field,value)
                      if ( ["from_address_getcare_ward_id","open_from_address"].includes(field) ) {
                        props.setFieldValue('service_id','')
                        this._debounceLoadDeliveryLogisticList({ ...props.values, [field]: value });
                      }
                    }}
                    deliveryLogisticList={[...deliveryLogisticList]}
                    isLoadingField={{service_id: isLoadingDeliveryLogisticList}}
                    amountGoods={amountGoods}
                    isShipmentCombined={isShipmentCombined}
                    onAmountChange={this.handleAmountChange}
                  />
                  <RadioGroup
                    aria-label="logistic provider"
                    name="selectedProviderId"
                    value={selectedProviderId}
                    className={classes.RadioGroup}
                    onChange={(e) => {
                      this.setState({
                        selectedProviderId: parseFloat(e.target.value),
                      });
                    }}
                  >
                    { reparedDeliveryLogisticList && reparedDeliveryLogisticList.map(provider => (<div key={`provider-${provider.id}`}>
                      <FormControlLabel
                        value={provider.id}
                        control={<Radio size="small" style={{padding: '4px 0', marginRight: '12px'}} />}
                        fontSize="small"
                        className={classes.FormControlLabel}
                        label={provider.name}
                      />
                    </div>)) }
                  </RadioGroup>
                </FormControl>
                { isGetcareLogisticProvider(selectedProviderId) &&
                  <GetcareDelivery
                    {...props}
                    validationFields={this.validationShapeMap[this.state.selectedProviderId]}
                    deliveryUserOptions={[...deliveryUserList]}
                    onAmountChange={this.handleAmountChange}
                  />
                }
                { isChanhXeLogisticProvider(selectedProviderId) &&
                  <ChanhXeDelivery
                    {...props}
                    amountGoods={amountGoods}
                    deliveryUserOptions={[...deliveryUserList]}
                    onAmountChange={this.handleAmountChange}
                  />
                }
                { isThirdPartyLogisticProvider(selectedProviderId) &&
                  <OtherLogisticDelivery
                    {...props}
                    handleChange={ (e) => {
                      props.handleChange(e);
                      if ( ["length","width","height","weight"].includes(e?.target?.name) ) {
                        props.setFieldValue('service_id','')
                        this._debounceLoadDeliveryLogisticList({ ...props.values, [e.target.name]: Number(e.target.value) });
                      }
                    }}
                    setFieldValue={ (field,value) => {
                      props.setFieldValue(field,value)
                      if ( ["from_address_getcare_ward_id","open_from_address"].includes(field) ) {
                        props.setFieldValue('service_id','')
                        this._debounceLoadDeliveryLogisticList({ ...props.values, [field]: value });
                      }
                    }}
                    deliveryLogisticList={[...deliveryLogisticList]}
                    isLoadingField={{service_id: isLoadingDeliveryLogisticList}}
                    amountGoods={amountGoods}
                    isShipmentCombined={isShipmentCombined}
                    onAmountChange={this.handleAmountChange}
                  />
                }
              </DialogContent>
            </form>
          );
        }}
      </Formik>
    );
  }
}

DeliverySentForm.propTypes = {
  salesOrder: PropTypes.object,
  amountGoods: PropTypes.number,
  deliveryFeeTotal: PropTypes.number,
  customerCode: PropTypes.string,
  customerDelivery: PropTypes.object,
  salesOrderLogistic: PropTypes.object,
  isShipmentCombined: PropTypes.bool,
  selectedOrders: PropTypes.array,
};

DeliverySentForm.defaultProps = {
  salesOrder: null,
  amountGoods: 0,
  deliveryFeeTotal: 0,
  customerCode: '',
  customerDelivery: null,
  salesOrderLogistic: null,
  isShipmentCombined: false,
  selectedOrders: [],
};

const mapStateToProps = createStructuredSelector({
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  deliveryUserList: makeSelectDeliveryUserList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDeliveryLogisticList: (payload) => dispatch(getDeliveryLogisticList(payload)),
    getDeliveryUserList: (payload) => dispatch(getDeliveryUserList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(DeliverySentForm);
