import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import classes from './OtherLogisticDialog.module.scss';

class OtherLogisticDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      providerId: null,
      serviceId: null,
    };
  }
  handleSubmit = () => {
    this.props.onSave({ providerId: this.state.providerId, serviceId: this.state.serviceId, });
    this.props.onClose();
  }
  handleCancel = () => {
    this.props.onSave({ providerId: this.props.providerId, serviceId: this.props.serviceId, });
    this.props.onClose();
  }

  render() {
    const { isOpen, otherProviders } = this.props;
    const { serviceId, providerId } = this.state;

    return (<Dialog
      open={isOpen}
      onClose={this.props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.Dialog}
    >
      <div className={`${classes.DialogContentWrap}`}>
        <DialogTitle className={classes.DialogTitle}>Đơn Vị Vận Chuyển Khác</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          { otherProviders.map(provider => (<div className={classes.Logistic} key={`provider-${provider.id}`}>
            <p>{ provider.name }</p>
            <RadioGroup
              name="serviceId"
              value={serviceId}
              className={classes.SelectionGroup}
              onChange={(e) => {
                this.setState({
                  serviceId: parseFloat(e.target.value),
                  providerId: provider.id,
                });
              }}
            >
              { provider.services && provider.services.map(service => (<div key={`service-${service.service_id}`}>
                <FormControlLabel
                  value={service.service_id}
                  control={<Radio size="small" style={{padding: '0.25rem 0', marginRight: '0.5rem'}} />}
                  fontSize="small"
                  className={classes.SelectionItem}
                  label={<div className={`${classes.Item}`}>
                    <p className={classes.ItemName}>{ service.service_name }</p>
                  </div>}
                />
              </div>)) }
            </RadioGroup>
          </div>)) }
        </DialogContent>
        <DialogActions className={classes.DialogFooter}>
          <Button variant="outlined" onClick={this.handleCancel}>
            Huỷ
          </Button>
          <Button
            disabled={!serviceId || !providerId}
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
          >
            Áp dụng
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )}
}

OtherLogisticDialog.propTypes = {
  isOpen: PropTypes.bool,
  otherProviders: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

OtherLogisticDialog.defaultProps = {
  isOpen: false,
  otherProviders: [],
};

export default OtherLogisticDialog;
