import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionProducts,
  makeSelectOriPromotionProducts,
} from 'redux/selectors';
import {
  savePromotionProductItem,
  removePromotionProductItems,
} from 'redux/actions/tmk/promotionActions';
import { getDisplayFields, genID } from 'utils/helper';
import {
  vendorPromotionProductsDisplayFields,
  promotionProductsParamsMap,
  vendorPromotionProductsComboDisplayFields,
  PROMOTION_TYPE_COMBO,
  PRODUCT_TYPE_ID,
  GROUP_TYPE_ID,
  isPromotionGroup,
} from 'utils/constanst/tmkPromotionConstants';

import PromotionProduct from './PromotionProduct/PromotionProduct';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import Button from '@material-ui/core/Button';

import gridClasses from './PromotionProductsGrid.module.scss';
import classes from './PromotionProducts.module.scss';

class PromotionProducts extends PureComponent {
  handleCreateNewRow = () => {
    this.props.savePromotionProductItem({
      idStr: genID(),
      id: 0,
      type_id: isPromotionGroup(this.props.promotionTypeId) ? GROUP_TYPE_ID : PRODUCT_TYPE_ID,
      getcare_product: null,
      getcare_erp_group: null,
      getcare_uom_base: null,
      focus: 2,
      quantity_number: null,
      ratio: null,
      isEditing: true,
    });
  };
  handleSavePromotionProduct = (params) => {
    this.props.savePromotionProductItem({
      ...params,
    });
  };
  handleRemovePromotionProduct = (params) => {
    this.props.removePromotionProductItems([{ ...params }]);
  };
  handleRemovePromotionProducts = () => {
    this.props.removePromotionProductItems([...this.state.selectedItems]);
  };

  render() {
    const { promotionProducts, promotionTypeId } = this.props;
    const displayFields = getDisplayFields(
      promotionProductsParamsMap,
      [PROMOTION_TYPE_COMBO].includes(promotionTypeId)
        ? vendorPromotionProductsComboDisplayFields
        : vendorPromotionProductsDisplayFields,
    );

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header} ${[PROMOTION_TYPE_COMBO].includes(promotionTypeId) ? gridClasses.HasFocusProduct : ''}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={`${gridClasses.Col} ${gridClasses[item.className]}`}
              {...item}
            />
          ))}
          <div className={`${gridClasses.Col} ${classes.HeaderCol} ${classes.ActionsCol}`}>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={this.handleCreateNewRow}
            >
              Thêm dòng
            </Button>
          </div>
        </div>
        <div className={`${classes.Body}`}>
          {promotionProducts.length ? (
            promotionProducts.map((item) => (
              <PromotionProduct
                key={`promotion-product-${item.id || item.idStr}`}
                {...item}
                currentList={[...promotionProducts]}
                promotionTypeId={promotionTypeId}
                savePromotionProduct={this.handleSavePromotionProduct}
                removePromotionProduct={this.handleRemovePromotionProduct}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

PromotionProducts.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditing: PropTypes.bool,
};

PromotionProducts.defaultProps = {
  isEditing: false,
};

const mapDispatchToProps = (dispatch) => {
  return {
    savePromotionProductItem: (payload) =>
      dispatch(savePromotionProductItem(payload)),
    removePromotionProductItems: (payload) =>
      dispatch(removePromotionProductItems(payload)),
  };
};
const mapStateToProps = createStructuredSelector({
  promotionProducts: makeSelectPromotionProducts(),
  oriPromotionProducts: makeSelectOriPromotionProducts(),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(PromotionProducts);
