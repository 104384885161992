import uomApi from 'utils/api/mdm/uomApi';
import { put } from 'redux-saga/effects';
import { uomActions } from 'redux/actions';

export function* loadUomList(payload) {
  const { params } = payload.payload;
  try {
    yield put(uomActions.saveUomList(null));

    const { data: response } = yield uomApi.getAllUom({ params });

    if (!response?.result) {
      yield put(uomActions.saveUomList([]));
      yield put(uomActions.saveUomTotal(0));
      return;
    }
    yield put(uomActions.saveUomList(response.data ? [ ...response.data] : []));
    yield put(uomActions.saveUomTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadUomBaseList(payload) {
  const { params } = payload.payload;
  try {
    yield put(uomActions.saveUomBaseList(null));

    const { data: response } = yield uomApi.getAllUomBase({ params });

    if (!response?.result) {
      yield put(uomActions.saveUomBaseList([]));
      yield put(uomActions.saveUomBaseTotal(response.total_records));
      return;
    }
    yield put(uomActions.saveUomBaseList(response.data ? [ ...response.data] : []));
    yield put(uomActions.saveUomBaseTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}
export function* loadProductUnits(payload) {
  const { params } = payload.payload;
  try {
    yield put(uomActions.saveProductUnitList([]));

    const { data: response } = yield uomApi.getProductUnits({ params });

    if (!response?.result) {
      yield put(uomActions.saveProductUnitList([]));
      return;
    }
    yield put(uomActions.saveProductUnitList(response.data ? [ ...response.data] : []));
  } catch (err) {
    console.log(err);
  }
}

