import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { currencyFormatVN, numberFormat } from 'utils/helper';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectSalesOrderLogistic, makeSelectSalesOrder, makeSelectDeliveryLogisticList } from 'redux/selectors';
import { updateLogisticProvider } from 'redux/actions/oms/salesOrderActions';
import { getDeliveryLogisticList } from 'redux/actions/logisticActions';
import { validDate } from 'utils/helper';
import { dateTimeFormat } from 'utils/constanst/dateConstants';
import format from 'date-fns/format';

import {
  isThirdPartyLogisticProviderId,
  isChanhXeLogisticProviderId,
  isGetcareLogisticProviderId,
} from 'utils/constanst/common';
import { REQUIRED_NOTE_MAP } from 'utils/constanst/omsSalesOrderConstants';

import Panel from 'components/Panel/Panel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import classes from './SalesOrderLogisticProvider.module.scss';

class SalesOrderLogisticProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.innerFormRef = null;
  }
  componentDidUpdate(prevProps) {
    const { salesOrder } = this.props;
    if (salesOrder && !prevProps.salesOrder) {
      this._loadDeliveryLogisticList();
    }
  }

  _loadDeliveryLogisticList = () => {
    const { salesOrder } = this.props;
    this.props.getDeliveryLogisticList({
      params: {
        getcare_customer_code: salesOrder?.customer_code,
        getcare_ward_id: salesOrder?.customer_delivery?.getcare_ward?.id,
        weight: salesOrder?.logistic_provider?.weight || undefined,
        height: salesOrder?.logistic_provider?.height || undefined,
        length: salesOrder?.logistic_provider?.length || undefined,
        width: salesOrder?.logistic_provider?.width || undefined,
        insurance_value: salesOrder?.logistic_provider?.insurance_value || undefined,
      }
    });
  }
  _getSelectedLogistic = () => {
    const { deliveryLogisticList, salesOrderLogistic } = this.props;
    if (!deliveryLogisticList || !salesOrderLogistic?.id) return null;
    const selectedItem = deliveryLogisticList.find(item => item.id === salesOrderLogistic.id);
    return {
      ...salesOrderLogistic,
      allServices: selectedItem ? [...selectedItem.services] : [],
    }
  }
  _renderProviderBeforeDeliverySent = (selectedLogistic) => {
    const { salesOrderLogistic } = this.props;
    const _isThirdPartyLogisticProviderId = isThirdPartyLogisticProviderId(salesOrderLogistic?.id);

    return (<div className={classes.Wrap}>
      <h4>
        <strong>{selectedLogistic?.name}</strong>
        { (isChanhXeLogisticProviderId(salesOrderLogistic?.id) || isGetcareLogisticProviderId(salesOrderLogistic?.id))
          && ` (${selectedLogistic.total_fee ? 'Phí dự kiến ' + currencyFormatVN(selectedLogistic.total_fee) : 'Phí vận chuyển sẽ được chỉ định sau'})`
        }
      </h4>
      { _isThirdPartyLogisticProviderId &&
        <div className={classes.Options}>
          <p className={classes.Service}>
            <RadioButtonCheckedIcon fontSize="small" color="secondary"/>
            <span className={classes.ServiceName}>
              { `${selectedLogistic.service_name} ${selectedLogistic.total_fee ? ' (Phí dự kiến ' + currencyFormatVN(selectedLogistic.total_fee) + ')' : ``}` }
            </span>
          </p>
        </div>
      }
      { selectedLogistic?.note && <p className={`${classes.NoteContent} PreWrap`}>{selectedLogistic.note}</p> }
    </div>);
  }
  _renderProviderRightAfterDeliverySent = (selectedLogistic) => {
    return (<div className={classes.Wrap}>
      <div className={`${classes.WrapAfterSent}`}>
        <div className={classes.Info}>
          <label>Đơn vị vận chuyển</label>
          <h4><strong>{selectedLogistic?.name}</strong></h4>
        </div>
        { !!selectedLogistic?.order_code &&
          <div className={classes.Info}>
            <label>Mã vận đơn</label>
            <span className="TextSecondary">
              { selectedLogistic.tracking_url
                ? <a className="TextSecondary" href={selectedLogistic.tracking_url} rel="noopener noreferrer" target="_blank">{ selectedLogistic.order_code }</a>
                : selectedLogistic.order_code
              }
            </span>
          </div>
        }
        { !!selectedLogistic?.cod &&
          <div className={classes.Info}>
            <label>Tiền thu hộ</label>
            {currencyFormatVN(selectedLogistic.cod)}
          </div>
        }
        { !!selectedLogistic?.total_fee &&
          <div className={classes.Info}>
            <label>Phí vận chuyển</label>
            {currencyFormatVN(selectedLogistic.total_fee)}
          </div>
        }
        { !['', null, undefined].includes(selectedLogistic?.insurance_value) &&
          <div className={classes.Info}>
            <label>Bảo hiểm hàng hoá</label>
            {selectedLogistic.insurance_value > 0 ? 'Có' : 'Không'}
          </div>
        }
        { !!selectedLogistic?.insurance_value &&
          <div className={classes.Info}>
            <label>Giá trị bảo hiểm</label>
            {currencyFormatVN(selectedLogistic.insurance_value)}
          </div>
        }
        { !!selectedLogistic?.name_2 &&
          <div className={classes.Info}>
            <label>Nhà xe</label>
            {selectedLogistic?.name_2}
          </div>
        }
        { !!selectedLogistic?.transporter &&
          <div className={classes.Info}>
            <label>Người giao</label>
            {selectedLogistic?.transporter}
          </div>
        }
        { !!selectedLogistic?.required_note &&
          <div className={classes.Info}>
            <label>Yêu cầu khi giao</label>
            {REQUIRED_NOTE_MAP[selectedLogistic.required_note]}
          </div>
        }
        <div className={classes.Info}>
          <label>Trọng lượng</label>
          {!['', null, undefined].includes(selectedLogistic?.weight) ? `${numberFormat(selectedLogistic.weight/1000)} kg` : `-`}
        </div>
        <div className={`${classes.Info} ${classes.InfoSizes}`}>
          <div className={classes.Info}>
            <label>Dài</label>
            {!['', null, undefined].includes(selectedLogistic?.length) ? `${numberFormat(selectedLogistic.length)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>Rộng</label>
            {!['', null, undefined].includes(selectedLogistic?.width) ? `${numberFormat(selectedLogistic.width)} cm` : `-`}
          </div>
          <div className={classes.Info}>
            <label>Cao</label>
            {!['', null, undefined].includes(selectedLogistic?.height) ? `${numberFormat(selectedLogistic.height)} cm` : `-`}
          </div>
        </div>
      </div>
      { selectedLogistic?.note && <p className={`${classes.NoteContent} PreWrap`}>{selectedLogistic.note}</p> }
      <div className={classes.Actions}>
        { !!selectedLogistic.tracking_url
          && <a className="TextSecondary" href={selectedLogistic.tracking_url} rel="noopener noreferrer" target="_blank">Kiểm tra hành trình</a>
        }
      </div>
    </div>)
  }

  render() {
    const { salesOrderLogistic } = this.props;
    const selectedLogistic = {...salesOrderLogistic};

    return (<>
      <Panel
        title={<>Thông tin vận chuyển
          { selectedLogistic.last_changed_by
            ? <span className={classes.UpdateInfo}>
                Thay đổi bởi <strong>{ selectedLogistic.last_changed_by }</strong> lúc {`${validDate(selectedLogistic.last_changed_at) ? format(validDate(selectedLogistic.last_changed_at), dateTimeFormat) : ''}`}
              </span>
            : ''
          }
        </>}
        panelClassName={classes.Panel}
        content={<>
          { selectedLogistic ? this._renderProviderBeforeDeliverySent(selectedLogistic) : <p className="NoData">Không có dịch vụ vận chuyển</p>
          }
        </>}
        isBorder
      />
    </>)
  }
}

SalesOrderLogisticProvider.propTypes = {
  salesOrderLogistic: PropTypes.object,
};

SalesOrderLogisticProvider.defaultProps = {
  salesOrderLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  salesOrderLogistic: makeSelectSalesOrderLogistic(),
  salesOrder: makeSelectSalesOrder(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateLogisticProvider: (payload) => dispatch(updateLogisticProvider(payload)),
    getDeliveryLogisticList: (payload) => dispatch(getDeliveryLogisticList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SalesOrderLogisticProvider);
