import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getCustomerDetails } from 'redux/actions/vendor/customerActions';
import { createStructuredSelector } from "reselect";
import {
  makeSelectCustomerDetails,
  makeSelectCustomerList,
} from 'redux/selectors';
import { withRouter } from 'react-router-dom';

import CustomerDetails from 'components/vendor/CustomerDetails/CustomerDetails';
import DetailsPageHeader from 'components/DetailsPageHeader/DetailsPageHeader';
import classes from './Customer.module.scss';

class Customer extends PureComponent {
  constructor(props) {
    super(props);
    const customerId = props.match.params.id;
    this.state = {
      hasChanges: false,
      customerId,
      editMode: this.isNumber(customerId),
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const customerId = this.props.match.params.id;
    if (customerId && customerId !== prevProps.match.params.id) {
      this.setState({
        customerId,
        editMode: this.isNumber(customerId),
      }, this.loadData)
    }

    const { customerDetails } = this.props;
    if (customerDetails && customerDetails.id) {
      if (!prevProps.customerDetails || customerDetails.id !== prevProps.customerDetails.id) {
        if (
          !prevProps.customerDetails ||
          !prevProps.customerDetails.id ||
          isNaN(prevProps.customerDetails.id)
        ) {
          // Redirect to edit mode after successfully creating a customer
          if (this.props.location.pathname === '/store') {
            this.props.history.replace(`/fastorder?getcare_customer_code=${customerDetails.code}`);
          }else{
            this.props.history.replace(`/customer/${customerDetails.id}`);
          }
          return;
        }
      }
    }
  }

  isNumber = (value) => {
    return value && !isNaN(value) && Number(value) > 0;
  }

  loadData = () => {
    // Load customer info for edit mode only
    this.state.editMode && this.props.getCustomerDetails(this.state.customerId);
  }

  backToList = () => {
    this.props.history.push('/customer');
  }

  onChange = (hasChanges) => {
    this.setState({
      hasChanges
    })
  }

  onCancel = () => {
    this.customerInfoRef.revertChanges();
    this.setState({
      hasChanges: false
    })
  }

  onSave = () => {
    this.customerInfoRef.saveChanges();
      this.setState({
        hasChanges: false
      })
  }

  render() {
    const { customerDetails ,createCustomer} = this.props;
    const { hasChanges, editMode } = this.state;
    const isLoading = !customerDetails;
    const title = editMode ? 'Thông tin khách hàng' : 'Thêm mới khách hàng';
    return (
      <div className={classes.PageMain}>
        <div className={`${classes.PageMainHeader} ${createCustomer && classes.HideCustomer}`}>

          <DetailsPageHeader
            isLoading={isLoading}
            title={title}
            backToList={this.backToList}
            onCancel={editMode && this.onCancel}
            onSave={this.onSave}
            saveLabel={editMode ? 'Xác nhận thay đổi' : 'Thêm mới'}
            hasChanges={hasChanges}
            editMode={editMode}
          />
        </div>
        <div className={classes.PageContent}>
          <CustomerDetails
            editMode={editMode}
            className={isLoading && 'OverlayLoading'}
            customerDetails={customerDetails}
            setRef={(ref) => this.customerInfoRef = ref}
            onChange={this.onChange}
            onSave={this.onSave}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  customerDetails: makeSelectCustomerDetails(),
  customerList: makeSelectCustomerList(),
})

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetails: (payload) => dispatch(getCustomerDetails(payload)),
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
export default compose(withConnect, withRouter)(Customer);


