import {
  GET_PR_DRAFT,
  CREATE_PR_DRAFT,
  CREATE_PR,
  CHECK_PROMOTION,
  RATE_ORDERING,
  SAVE_SELECTED_CUSTOMER_CODE,
  SAVE_LOADING,
  SAVE_ACTION_LOADING,
  SAVE_PR_DRAFT_DETAILS,
  SAVE_PR_DRAFT_PRODUCTS,
  SAVE_PR_DRAFT_PRODUCT_ITEM,
  REMOVE_PR_DRAFT_PRODUCT_ITEMS,
  SAVE_PR_DRAFT_BONUSES,
  SAVE_PR_DRAFT_DISCOUNTS,
  SAVE_PR_DRAFT_NOTES,
  SAVE_PR_DRAFT_NOTE_ITEM,
  REMOVE_PR_DRAFT_NOTE_ITEM,
  SAVE_PR_DRAFT_LOGISTIC,
  SAVE_PR_DRAFT_DELIVERY,
  SAVE_PR_DRAFT_ALL_INFO,
  SAVE_PROMOTION_AVAILABLES,
  SAVE_PROMOTION_ACTIVES,
  SAVE_PROMOTION_INACTIVES,
  ADD_PROMOTION_INACTIVE_ITEMS,
  REMOVE_PROMOTION_INACTIVE_ITEMS,
  SAVE_USED_PROMOTIONS,
  SAVE_USED_PROMOTION_ITEM,
  REMOVE_USED_PROMOTION_ITEMS,
} from 'redux/constants/ecom/fastOrderConstants';

export function getPurchaseRequestDraft(payload) {
  return {
    type: GET_PR_DRAFT,
    payload,
  };
}

export function updatePurchaseRequestDraft(payload) {
  return {
    type: CREATE_PR_DRAFT,
    payload,
  };
}

export function createPurchaseRequest(payload) {
  return {
    type: CREATE_PR,
    payload,
  };
}

export function checkPromotion(payload) {
  return {
    type: CHECK_PROMOTION,
    payload,
  };
}

export function rateOrdering(payload) {
  return {
    type: RATE_ORDERING,
    payload,
  };
}

// mutation
export function saveSelectedCustomerCode(selectedCustomerCode) {
  return {
    type: SAVE_SELECTED_CUSTOMER_CODE,
    selectedCustomerCode,
  };
}

export function saveLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}
export function saveActionLoading(actionLoading) {
  return {
    type: SAVE_ACTION_LOADING,
    actionLoading,
  };
}

export function savePRDetails(prDetails) {
  return {
    type: SAVE_PR_DRAFT_DETAILS,
    prDetails,
  };
}

export function savePRProducts(prProducts) {
  return {
    type: SAVE_PR_DRAFT_PRODUCTS,
    prProducts,
  };
}
export function savePRProductItem(prProductItem) {
  return {
    type: SAVE_PR_DRAFT_PRODUCT_ITEM,
    prProductItem,
  };
}
export function removePRDraftProductItems(prProductItems) {
  return {
    type: REMOVE_PR_DRAFT_PRODUCT_ITEMS,
    prProductItems,
  };
}

export function savePRDraftBonuses(prBonuses) {
  return {
    type: SAVE_PR_DRAFT_BONUSES,
    prBonuses,
  };
}
export function savePRDraftDiscounts(prDiscounts) {
  return {
    type: SAVE_PR_DRAFT_DISCOUNTS,
    prDiscounts,
  };
}

export function savePRDraftNotes(prNotes) {
  return {
    type: SAVE_PR_DRAFT_NOTES,
    prNotes,
  };
}
export function savePRDraftNoteItem(prNoteItem) {
  return {
    type: SAVE_PR_DRAFT_NOTE_ITEM,
    prNoteItem,
  };
}
export function removePRDraftNoteItem(prNoteItem) {
  return {
    type: REMOVE_PR_DRAFT_NOTE_ITEM,
    prNoteItem,
  };
}

export function savePRDraftLogistic(prLogistic) {
  return {
    type: SAVE_PR_DRAFT_LOGISTIC,
    prLogistic,
  };
}

export function savePRDraftDelivery(prDelivery) {
  return {
    type: SAVE_PR_DRAFT_DELIVERY,
    prDelivery,
  };
}

export function savePRDraftAllInfo(prData) {
  return {
    type: SAVE_PR_DRAFT_ALL_INFO,
    prData,
  };
}

export function savePromotionAvailables(promotionAvailables) {
  return {
    type: SAVE_PROMOTION_AVAILABLES,
    promotionAvailables,
  };
}
export function savePromotionActives(promotionActives) {
  return {
    type: SAVE_PROMOTION_ACTIVES,
    promotionActives,
  };
}
export function savePromotionInactives(promotionInactives) {
  return {
    type: SAVE_PROMOTION_INACTIVES,
    promotionInactives,
  };
}
export function addPromotionInactiveItems(promotionInactiveItems) {
  return {
    type: ADD_PROMOTION_INACTIVE_ITEMS,
    promotionInactiveItems,
  };
}
export function removePromotionInactiveItems(promotionInactiveItems) {
  return {
    type: REMOVE_PROMOTION_INACTIVE_ITEMS,
    promotionInactiveItems,
  };
}
export function saveUsedPromotions(usedPromotions) {
  return {
    type: SAVE_USED_PROMOTIONS,
    usedPromotions,
  };
}
export function saveUsedPromotionItem(usedPromotionItem) {
  return {
    type: SAVE_USED_PROMOTION_ITEM,
    usedPromotionItem,
  };
}
export function removeUsedPromotionItems(usedPromotionItems) {
  return {
    type: REMOVE_USED_PROMOTION_ITEMS,
    usedPromotionItems,
  };
}
