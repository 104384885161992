import {
  GET_POLICY_PRICE_LIST,
  GET_POLICY_PRICE,
  GET_PRICE_PIORITIES,
  GET_PRICE_UNITS,
  SAVE_LOADING,
  SAVE_POLICY_PRICE_LIST,
  SAVE_POLICY_PRICE_ITEMS,
  SAVE_POLICY_PRICE_LIST_TOTAL,
  RESET_POLICY_PRICE,
  SAVE_ORIGINAL_POLICY_PRICE_DETAILS,
  SAVE_POLICY_PRICE_DETAILS,
  SAVE_PRICE_PIORITIES,
  SAVE_PRICE_UNITS,
  SAVE_POLICY_PRICE_PRODUCTS,
  SAVE_TEMP_POLICY_PRICE_PRODUCTS,
  SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM,
  REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS,
  SAVE_POLICY_PRICE_CUSTOMERS,
  SAVE_TEMP_POLICY_PRICE_CUSTOMERS,
  SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM,
  REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS,
  SAVE_POLICY_PRICE_CHANNELS,
  SAVE_TEMP_POLICY_PRICE_CHANNELS,
  SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM,
  REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS,
  SAVE_POLICY_PRICE_CUSTOMER_TYPES,
  SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES,
  SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS,
  UPDATE_POLICY_PRICE,
  GET_POLICY_PRICE_HISTORY,
  SAVE_POLICY_PRICE_HISTORY,
  EXPORT_POLICY_PRICE_TEMPLATE,
  GET_POLICY_PRICE_COUNTS,
  SAVE_POLICY_PRICE_COUNTS,
} from 'redux/constants/mdm/policyPriceConstants';

export function getPricePiorities(payload) {
  return {
    type: GET_PRICE_PIORITIES,
    payload,
  };
}
export function getPriceUnits(payload) {
  return {
    type: GET_PRICE_UNITS,
    payload,
  };
}

export function getPolicyPriceList(payload) {
  return {
    type: GET_POLICY_PRICE_LIST,
    payload,
  };
}
export function getPolicyPrice(id) {
  return {
    type: GET_POLICY_PRICE,
    id,
  };
}
export function updatePolicyPrice(payload) {
  return {
    type: UPDATE_POLICY_PRICE,
    payload,
  };
}
export function getPolicyPriceHistory(payload) {
  return {
    type: GET_POLICY_PRICE_HISTORY,
    payload,
  };
}
export function exportPolicyPriceTemplate(payload) {
  return {
    type: EXPORT_POLICY_PRICE_TEMPLATE,
    payload,
  };
}
export function getPolicyPriceCounts(payload) {
  return {
    type: GET_POLICY_PRICE_COUNTS,
    payload,
  };
}

// mutation
export function savePricePiorities(pricePiorities) {
  return {
    type: SAVE_PRICE_PIORITIES,
    pricePiorities,
  };
}
export function savePriceUnits(priceUnits) {
  return {
    type: SAVE_PRICE_UNITS,
    priceUnits,
  };
}

export function saveActionLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading,
  };
}

export function savePolicyPriceList(policyPriceList) {
  return {
    type: SAVE_POLICY_PRICE_LIST,
    policyPriceList,
  };
}
export function savePolicyPriceItems(items) {
  return {
    type: SAVE_POLICY_PRICE_ITEMS,
    items,
  };
}
export function savePolicyPriceListTotal(policyPriceListTotal) {
  return {
    type: SAVE_POLICY_PRICE_LIST_TOTAL,
    policyPriceListTotal,
  };
}

export function resetPolicyPrice(payload) {
  return {
    type: RESET_POLICY_PRICE,
    payload,
  };
}

export function saveOriginalPolicyPriceDetails(originalPolicyPriceDetails) {
  return {
    type: SAVE_ORIGINAL_POLICY_PRICE_DETAILS,
    originalPolicyPriceDetails,
  };
}
export function savePolicyPriceDetails(policyPriceDetails) {
  return {
    type: SAVE_POLICY_PRICE_DETAILS,
    policyPriceDetails,
  };
}

export function savePolicyPriceProducts(policyPriceProducts) {
  return {
    type: SAVE_POLICY_PRICE_PRODUCTS,
    policyPriceProducts,
  };
}
export function saveTempPolicyPriceProducts(tempPolicyPriceProducts) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_PRODUCTS,
    tempPolicyPriceProducts,
  };
}
export function saveTempPolicyPriceProductItem(tempPolicyPriceProductItem) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_PRODUCT_ITEM,
    tempPolicyPriceProductItem,
  };
}
export function removeTempPolicyPriceProductItems(tempPolicyPriceProductItems) {
  return {
    type: REMOVE_TEMP_POLICY_PRICE_PRODUCT_ITEMS,
    tempPolicyPriceProductItems,
  };
}

export function savePolicyPriceCustomers(policyPriceCustomers) {
  return {
    type: SAVE_POLICY_PRICE_CUSTOMERS,
    policyPriceCustomers,
  };
}
export function saveTempPolicyPriceCustomers(tempPolicyPriceCustomers) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CUSTOMERS,
    tempPolicyPriceCustomers,
  };
}
export function saveTempPolicyPriceCustomerItem(tempPolicyPriceCustomerItem) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CUSTOMER_ITEM,
    tempPolicyPriceCustomerItem,
  };
}
export function removeTempPolicyPriceCustomerItems(
  tempPolicyPriceCustomerItems
) {
  return {
    type: REMOVE_TEMP_POLICY_PRICE_CUSTOMER_ITEMS,
    tempPolicyPriceCustomerItems,
  };
}

export function savePolicyPriceChannels(policyPriceChannels) {
  return {
    type: SAVE_POLICY_PRICE_CHANNELS,
    policyPriceChannels,
  };
}
export function saveTempPolicyPriceChannels(tempPolicyPriceChannels) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CHANNELS,
    tempPolicyPriceChannels,
  };
}
export function saveTempPolicyPriceChannelItem(tempPolicyPriceChannelItem) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CHANNEL_ITEM,
    tempPolicyPriceChannelItem,
  };
}
export function removeTempPolicyPriceChannelItems(tempPolicyPriceChannelItems) {
  return {
    type: REMOVE_TEMP_POLICY_PRICE_CHANNEL_ITEMS,
    tempPolicyPriceChannelItems,
  };
}
export function saveTempPolicyPriceChannelsCounts(salesChannelsCounts) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CHANNELS_COUNTS,
    salesChannelsCounts,
  };
}

export function savePolicyPriceCustomerTypes(policyPriceCustomerTypes) {
  return {
    type: SAVE_POLICY_PRICE_CUSTOMER_TYPES,
    policyPriceCustomerTypes,
  };
}
export function saveTempPolicyPriceCustomerTypes(tempPolicyPriceCustomerTypes) {
  return {
    type: SAVE_TEMP_POLICY_PRICE_CUSTOMER_TYPES,
    tempPolicyPriceCustomerTypes,
  };
}

export function savePolicyPriceHistory(policyPriceHistory) {
  return {
    type: SAVE_POLICY_PRICE_HISTORY,
    policyPriceHistory,
  };
}
export function savePolicyPriceCounts(policyPriceCountsMap) {
  return {
    type: SAVE_POLICY_PRICE_COUNTS,
    policyPriceCountsMap,
  };
}
