import { groupBy, orderBy } from 'lodash';
import { genID } from 'utils/helper';

export const prProductsDisplayFields = [
  {
    name: 'product_name',
    label: 'Sản phẩm',
  },
  {
    name: 'vendor_name',
    label: 'Nhà cung cấp',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'type_label',
    label: 'Nhãn',
  },
  {
    name: 'price_sales_total',
    label: 'Đơn giá',
    classes: 'TextRight',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng',
  },
  {
    name: 'amount_total',
    label: 'Thành tiền',
    classes: 'TextRight',
  },
];
export const prProductsParamsMap = {
  page_size: 1000,
  page: 1,
  order: '',
};

export const prBonusesDisplayFields = [
  {
    name: 'promotion_name',
    label: 'Chương trình khuyến mãi',
  },
  {
    name: 'product_bonus_name',
    label: 'Sản phẩm khuyến mãi',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị',
  },
  {
    name: 'quantity_number',
    label: 'Số lượng',
  },
];
export const prBonusesParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
};

export function getPRItemData({ product, vendor, uom, quantity }) {
  return {
    idStr: genID(),
    getcare_product_id: product ? product.id : null,
    product_name: product ? product.name : '',
    getcare_vendor_id: vendor ? vendor.id : null,
    vendor_name: vendor ? vendor.name : '',
    getcare_uom_base_id: uom ? uom.uom_base_id : null,
    uom_base_name: uom ? uom.uom_base_name : '',
    quantity_number: quantity || 1,
    minimum_quantity: uom ? uom.minimum_quantity : null,
    remaining_quantity: uom ? uom.remaining_quantity : null,
    price_sales_total: uom ? uom.price : null,
    vat: uom ? uom.vat : null,
    type_label: uom ? uom.type_label : '',
  }
}
export function getCombinedValidOrderProductsData(prProducts) {
  const orderListData = prProducts.filter(
    item => isOrderItemValid(item)
  ).map(item => ({
    getcare_product_id: item.getcare_product_id,
    getcare_vendor_id: item.getcare_vendor_id,
    getcare_uom_base_id: item.getcare_uom_base_id,
    quantity_number: Number(item.quantity_number),
  }));

  const quantitiesMap = orderListData.reduce((memo, item) => {
    const key = `${item.getcare_product_id}-${item.getcare_vendor_id}-${item.getcare_uom_base_id}`;
    memo[key] = [null, undefined].includes(memo[key]) ? item.quantity_number : (memo[key] + item.quantity_number);
    return memo;
  }, {});

  return Object.keys(quantitiesMap).map(key => {
    const keyArr = key.split('-');
    return {
      getcare_product_id: Number(keyArr[0]),
      getcare_vendor_id: Number(keyArr[1]),
      getcare_uom_base_id: Number(keyArr[2]),
      quantity_number: quantitiesMap[key],
    }
  })
}
export function prepareUsedPromotionsData(usedPromotions) {
  // group the array by promotion id and sort by the order of promotion selection
  const groupByPromotionIdMap = groupBy(
    usedPromotions.map((item, index) => ({ ...item, index })),
    'promotion_id'
  );
  return orderBy(Object.keys(groupByPromotionIdMap).map(key => ({
    id: parseFloat(key),
    items: groupByPromotionIdMap[key],
    index: groupByPromotionIdMap[key][0]?.index,
  })), 'index');
}
export function getValidOrderItems(prProducts) {
  return prProducts.filter(
    item => isOrderItemValid(item)
  );
}
export function isOrderItemValid(order) {
  return order.getcare_product_id
    && order.getcare_vendor_id
    && order.getcare_uom_base_id
    && order.quantity_number > 0
}
export function getAvailableLogisticProviders(deliveryLogisticList, orderAmount) {
  if (!orderAmount || !deliveryLogisticList) return [];

  return deliveryLogisticList.filter(logistic => ['', undefined, null].includes(logistic.max_cod) || logistic.max_cod > orderAmount);
}
export function isOrderExceedMaximumCOD(deliveryLogisticList, orderAmount) {
  if (!orderAmount || !deliveryLogisticList) return false;

  const maxCODs = deliveryLogisticList.filter(
    logistic => !['', undefined, null].includes(logistic.max_cod)
  ).map(logistic => logistic.max_cod);
  const maxCOD = maxCODs.length > 0 ? Math.min.apply(null, maxCODs) : Number.MAX_VALUE;
  return orderAmount >= maxCOD;
}
