import customerApi from 'utils/api/mdm/customerApi';
import { put } from 'redux-saga/effects';
import { customerActions } from 'redux/actions';
import { toast } from 'react-toastify';

export function* loadCustomerDetails(payload) {
  try {
    yield put(customerActions.saveCustomerDetails(null));

    const { data: response } = yield customerApi.get({
      id: payload.id,
      params: {},
    });

    if (!response?.result) {
      yield put(customerActions.saveCustomerDetails({}));
      return;
    }
    yield put(
      customerActions.saveCustomerDetails(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}

export function* loadCustomerList(payload) {
  const { params } = payload.payload;
  try {
    yield put(customerActions.saveCustomerListLoading(true));

    const { data: response } = yield customerApi.getList({ params });

    yield put(customerActions.saveCustomerListLoading(false));

    if (!response?.result) {
      yield put(customerActions.saveCustomerList([]));
      yield put(customerActions.saveCustomerListTotal(0));
      return;
    }
    yield put(
      customerActions.saveCustomerList(response.data ? [...response.data] : [])
    );
    yield put(customerActions.saveCustomerListTotal(response.total_records));
  } catch (err) {
    console.log(err);
  }
}

export function* putCustomer(payload) {
  const { id, params } = payload.payload;
  try {
    const { data: response } = yield customerApi.updateCustomer({ id, params });
    if (!response?.result) {
      toast.error(response.message);
      yield put(customerActions.saveCustomerDetails({}));
      return;
    }

    toast.success(`Lưu thông tin khách hàng thành công.`);

    yield put(
      customerActions.saveCustomerDetails(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}

export function* exportCustomerListTemplate(payload) {
  try {
    const { data: response } = yield customerApi.exportCustomerTemplate();
    if (!response?.result) {
      toast.error(response.message);
      return;
    }
    const anchor = document.createElement('a');
    anchor.setAttribute('href', `${response.data ? response.data : ''}`);
    anchor.click();
    anchor.remove();
  } catch (err) {
    console.log(err);
  }
}

export function* postCustomer(payload) {
  const { params } = payload.payload;
  try {
    const { data: response } = yield customerApi.createCustomer({ params });
    if (!response?.result) {
      toast.error(response.message);
      yield put(customerActions.saveCustomerDetails({}));
      return;
    }

    toast.success(`Thêm mới khách hàng thành công.`);

    yield put(
      customerActions.saveCustomerDetails(response.data ? { ...response.data } : {})
    );
  } catch (err) {
    console.log(err);
  }
}