import {
  SAVE_PHARMACY_LIST,
  SAVE_LOADING,
  SAVE_PHARMACY_LIST_TOTAL,
  SAVE_PHARMACY,
} from 'redux/constants/ecom/pharmacyConstants';

const initialState = {
  pharmacyList: [],
  loading: false,
  pharmacyListTotal: 0,
  pharmacyDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_PHARMACY_LIST_TOTAL: {
      return {
        ...state,
        pharmacyListTotal: action.pharmacyListTotal,
      };
    }
    case SAVE_PHARMACY_LIST: {
      return {
        ...state,
        pharmacyList: action.pharmacyList,
      };
    }

    case SAVE_PHARMACY: {

      return {
        ...state,
        pharmacyDetails: { ...action.pharmacy },
      };
    }

    default:
      return state;
  }
};

export default reducer;
