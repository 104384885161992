import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { sortBy, isEqual } from 'lodash';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFastOrderLogistic,
  makeSelectFastOrderDetails,
  makeSelectDeliveryLogisticList,
  makeSelectFastOrderDelivery,
  makeSelectLogisticListLoading,
} from 'redux/selectors';
import { savePRDraftLogistic } from 'redux/actions/ecom/fastOrderActions';
import { getDeliveryLogisticList } from 'redux/actions/logisticActions';

import {
  isChanhXeLogisticProvider,
  isThirdPartyLogisticProvider,
  isThirdPartyLogisticProviderId,
  isGetcareLogisticProvider,
  LOGISTIC_OTHER_TYPE,
  getProvidersGroups,
  getGroup,
} from 'utils/constanst/common';
import {
  getAvailableLogisticProviders,
  isOrderExceedMaximumCOD,
} from 'utils/constanst/ecomFastOrderConstants';

import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChanhXeDialog from './ChanhXeDialog/ChanhXeDialog';
import OtherLogisticDialog from './OtherLogisticDialog/OtherLogisticDialog';
import CustomerDeliveryFormDialog from 'components/ecom/FastOrder/FastOrderCustomerDelivery/CustomerDeliveryFormDialog/CustomerDeliveryFormDialog';

import classes from './LogisticProvider.module.scss';

class LogisticProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypeAndIdMap: {},
      selectedTypeAndServiceIdMap: {},

      isChanhXeDialogOpen: false,
      isOtherProviderDialogOpen: false,
      isCustomerDeliveryDialogOpen: false,
    };
  }
  componentDidMount() {
    const { prDelivery, prLogistic } = this.props;
    if (prDelivery) {
      this._loadLogisticList();
    }
    if (prLogistic && prLogistic.id && isThirdPartyLogisticProviderId(prLogistic.id)) {
      this.setState({
        selectedTypeAndIdMap: {
          [LOGISTIC_OTHER_TYPE]: prLogistic.id,
        },
        selectedTypeAndServiceIdMap: {
          [LOGISTIC_OTHER_TYPE]: prLogistic.service_id,
        }
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { prDelivery, deliveryLogisticList } = this.props;
    if (prDelivery && prevProps.prDelivery &&  prDelivery.getcare_ward?.id !== prevProps.prDelivery.getcare_ward?.id) {
      this._loadLogisticList();
    }
    if (deliveryLogisticList && deliveryLogisticList.length > 0 && !isEqual(sortBy(deliveryLogisticList), sortBy(prevProps.deliveryLogisticList))) {
      this._resetDefaultProvider();
    }
  }

  _loadLogisticList = () => {
    const { prDelivery, prLogistic, prDetails } = this.props;
    this.props.getDeliveryLogisticList({
      params: {
        getcare_customer_code: prDetails?.customer_code,
        getcare_ward_id: prDelivery?.getcare_ward?.id?prDelivery?.getcare_ward?.id: null,
        insurance_value: prLogistic?.insurance_value || 0,
        weight: prLogistic?.weight || 0,
      },
    });
  };
  _resetDefaultProvider = ()=> {
    const { deliveryLogisticList, prDetails, prLogistic } = this.props;
    const isExceedMaxCOD = isOrderExceedMaximumCOD(deliveryLogisticList, prDetails?.amount_total);
    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList, prDetails?.amount_total);
    const willSetDefaultProvider = (isExceedMaxCOD && availablesProviders && availablesProviders.length) || availablesProviders.length === 1;
    const willReset = !this._getSelectedProvider(prLogistic?.id);
    if (willSetDefaultProvider || willReset) {
      this.handleSaveLogisticProvider({
        id: willSetDefaultProvider ? availablesProviders[0].id : 0,
        service_id: 0,
        total_fee: 0,
      });
    }
  }

  _getSelectedProvider = (providerId) => {
    const { deliveryLogisticList, prDetails } = this.props;

    if (!deliveryLogisticList || !providerId) return null;

    const availablesProviders = getAvailableLogisticProviders(deliveryLogisticList, prDetails?.amount_total);
    return availablesProviders.find((item) => item.id === providerId);
  };
  _getSelectedService = (providerId, serviceId) => {
    const provider = this._getSelectedProvider(providerId);
    if (!provider) return null;
    return provider.services.find(
      (service) => service.service_id === serviceId
    );
  };

  handleOpenChanhXeDialog = () => {
    this.setState({
      isChanhXeDialogOpen: true,
    });
  };
  handleCloseChanhXeDialog = () => {
    this.setState({
      isChanhXeDialogOpen: false,
    });
  };
  handleOpenOtherProviderDialog = () => {
    this.setState({
      isOtherProviderDialogOpen: true,
    });
  };
  handleCloseOtherProviderDialog = () => {
    this.setState({
      isOtherProviderDialogOpen: false,
    });
  };

  handleOpenCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: true,
    });
  };
  handleCloseCustomerDeliveryDialog = () => {
    this.setState({
      isCustomerDeliveryDialogOpen: false,
    });
  };

  handleSelectionClick = (e) => {
    if (isChanhXeLogisticProvider(parseFloat(e.target.value))) {
      this.setState({
        isChanhXeDialogOpen: true,
      });
    }
    if (isThirdPartyLogisticProvider(parseFloat(e.target.value))) {
      this.setState({
        isOtherProviderDialogOpen: true,
      });
    }
  };
  handleSaveLogisticProvider = (fieldMap) => {
    this.props.onUpdatePRDraft({
      prLogisticData: {
        ...this.props.prLogistic,
        ...fieldMap,
      },
      willUpdatePRDetails: true,
    });
  };

  render() {
    const { deliveryLogisticList, prLogistic, prDetails, logisticListLoading } = this.props;
    const {
      selectedTypeAndIdMap,
      selectedTypeAndServiceIdMap,
      isChanhXeDialogOpen,
      isOtherProviderDialogOpen,
    } = this.state;
    const availablesProviders = getAvailableLogisticProviders(
      deliveryLogisticList,
      prDetails?.amount_total
    );
    const isExceedMaxCOD = isOrderExceedMaximumCOD(
      deliveryLogisticList,
      prDetails?.amount_total
    );
    const providersGroups = deliveryLogisticList
      ? [...getProvidersGroups(availablesProviders)]
      : [];

    return (
      <section className={`${classes.Wrap} ${logisticListLoading ? 'OverlayLoading' : ''}`}>
        <h3 className="SectionTitle">Đơn vị vận chuyển</h3>
        {!deliveryLogisticList || !deliveryLogisticList.length || !availablesProviders.length ? (
          <p className="NoData">
            Hiện không có đơn vị vận chuyển nào thích hợp trong khu vực nhận
            hàng của bạn. <br />
            Hãy{` `}
            <Button
              size="small"
              color="primary"
              onClick={this.handleOpenCustomerDeliveryDialog}
            >
              thay đổi địa chỉ nhận hàng
            </Button>
            .
          </p>
        ) : (
          <>
            {isExceedMaxCOD && (
              <p className={classes.WarningInfo}>
                Đơn hàng giá trị cao không hỗ trợ COD. Vui lòng liên hệ Phahub
                để chuyển khoản thanh toán trước khi giao hàng.
              </p>
            )}
            <Formik
              initialValues={{
                typeId: this._getSelectedProvider(prLogistic?.id)?.type || null,
                id: prLogistic?.id || null,
                service_id: prLogistic?.service_id || null,
                note: prLogistic?.note || '',
              }}
              enableReinitialize
            >
              {(props) => {
                const { setFieldValue, values } = props;

                return (
                  <form
                    noValidate
                    autoComplete="off"
                    className={classes.Dialog}
                  >
                    <RadioGroup
                      aria-label="logistic provider"
                      name="typeId"
                      value={values.id ? values.typeId + `` : ``}
                      className={classes.SelectionGroup}
                      onChange={(e) => {
                        const typeId = e.target.value;
                        const selectedGroup = getGroup(typeId, providersGroups);
                        const willSelectProviderId =
                          selectedGroup.providers.length === 1 &&
                          selectedGroup.providers[0].services.length === 1;
                        const newProviderId = willSelectProviderId
                          ? selectedGroup.providers[0].id
                          : selectedTypeAndIdMap[typeId];
                        const newServiceId = willSelectProviderId
                          ? selectedGroup.providers[0].services[0].id
                          : selectedTypeAndServiceIdMap[typeId];

                        setFieldValue('typeId', typeId);

                        this.setState({
                          selectedTypeAndIdMap: {
                            ...selectedTypeAndIdMap,
                            [typeId]: newProviderId,
                          },
                          selectedTypeAndServiceIdMap: {
                            ...selectedTypeAndServiceIdMap,
                            [typeId]: newServiceId,
                          },
                        });
                        this.handleSaveLogisticProvider({
                          id: newProviderId,
                          service_id: newServiceId,
                          total_fee: 0,
                        });
                      }}
                      onClick={this.handleSelectionClick}
                    >
                      {providersGroups.map((group) => {
                        const provider = this._getSelectedProvider(
                          selectedTypeAndIdMap[group.typeId]
                        );
                        const service = this._getSelectedService(
                          selectedTypeAndIdMap[group.typeId],
                          selectedTypeAndServiceIdMap[group.typeId]
                        );

                        return (
                          <div key={`provider-${group.typeId}`}>
                            <FormControlLabel
                              value={group.typeId}
                              control={
                                <Radio
                                  size="small"
                                  style={{
                                    padding: '0.08rem 0',
                                    marginRight: '0.5rem',
                                  }}
                                />
                              }
                              fontSize="small"
                              className={classes.SelectionItem}
                              label={
                                <div className={`${classes.Item} PreWrap`}>
                                  {!isThirdPartyLogisticProvider(
                                    group.typeId
                                  ) && (
                                    <>
                                      <p className={classes.ItemName}>
                                        {group.typeLabel}
                                      </p>
                                      {(isChanhXeLogisticProvider(
                                        group.typeId
                                      ) ||
                                        isGetcareLogisticProvider(
                                          group.typeId
                                        )) && (
                                        <p className={classes.TextExplained}>
                                          (Phí vận chuyển sẽ được chỉ định
                                          sau)
                                        </p>
                                      )}
                                      <p className={classes.ItemName}>
                                        {isChanhXeLogisticProvider(group.typeId)
                                          ? values.note
                                          : ``}
                                      </p>
                                      {isChanhXeLogisticProvider(
                                        group.typeId
                                      ) &&
                                        !!values.note && (
                                          <Button
                                            style={{
                                              padding: 0,
                                              textDecoration: 'underline',
                                            }}
                                            fontSize="small"
                                            color="primary"
                                            onClick={
                                              this.handleOpenChanhXeDialog
                                            }
                                          >
                                            Thay đổi
                                          </Button>
                                        )}
                                    </>
                                  )}
                                  {isThirdPartyLogisticProvider(
                                    group.typeId
                                  ) && (
                                    <>
                                      <p className={classes.ItemName}>
                                        {provider?.name || group.typeLabel}
                                      </p>
                                      <p className={classes.TextExplained}>
                                        {service ? (
                                          <span
                                            className={classes.ServiceSelected}
                                          >
                                            <span>{service.service_name}</span>
                                          </span>
                                        ) : (
                                          `(Chọn đơn vị vận chuyển)`
                                        )}
                                      </p>
                                      {service && (
                                        <Button
                                          style={{
                                            padding: 0,
                                            textDecoration: 'underline',
                                          }}
                                          fontSize="small"
                                          color="primary"
                                          onClick={
                                            this.handleOpenOtherProviderDialog
                                          }
                                        >
                                          Thay đổi
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </div>
                              }
                            />
                          </div>
                        );
                      })}
                    </RadioGroup>
                    {isChanhXeDialogOpen && (
                      <ChanhXeDialog
                        isOpen={isChanhXeDialogOpen}
                        note={values.note}
                        onClose={this.handleCloseChanhXeDialog}
                        onSave={(note) => {
                          setFieldValue('note', note);
                          this.handleSaveLogisticProvider({
                            note: note,
                            total_fee: 0,
                          });
                        }}
                      />
                    )}
                    {isOtherProviderDialogOpen && (
                      <OtherLogisticDialog
                        otherProviders={
                          getGroup(LOGISTIC_OTHER_TYPE, providersGroups)
                            ?.providers
                        }
                        providerId={selectedTypeAndIdMap[LOGISTIC_OTHER_TYPE]}
                        serviceId={
                          selectedTypeAndServiceIdMap[LOGISTIC_OTHER_TYPE]
                        }
                        isOpen={isOtherProviderDialogOpen}
                        onClose={this.handleCloseOtherProviderDialog}
                        onSave={({ providerId, serviceId }) => {
                          this.setState({
                            selectedTypeAndIdMap: {
                              ...selectedTypeAndIdMap,
                              [LOGISTIC_OTHER_TYPE]: providerId,
                            },
                            selectedTypeAndServiceIdMap: {
                              ...selectedTypeAndServiceIdMap,
                              [LOGISTIC_OTHER_TYPE]: serviceId,
                            },
                          });
                          this.handleSaveLogisticProvider({
                            id: providerId,
                            service_id: serviceId,
                            total_fee:
                              this._getSelectedService(providerId, serviceId)
                                ?.fee?.total || 0,
                          });
                        }}
                      />
                    )}
                  </form>
                );
              }}
            </Formik>
          </>
        )}

        {this.state.isCustomerDeliveryDialogOpen && (
          <CustomerDeliveryFormDialog
            isOpen={this.state.isCustomerDeliveryDialogOpen}
            onClose={this.handleCloseCustomerDeliveryDialog}
            onUpdatePRDraft={this.props.onUpdatePRDraft}
          />
        )}
      </section>
    );
  }
}

LogisticProvider.propTypes = {
  prLogistic: PropTypes.object,
};

LogisticProvider.defaultProps = {
  prLogistic: null,
};

const mapStateToProps = createStructuredSelector({
  prLogistic: makeSelectFastOrderLogistic(),
  prDetails: makeSelectFastOrderDetails(),
  deliveryLogisticList: makeSelectDeliveryLogisticList(),
  logisticListLoading: makeSelectLogisticListLoading(),
  prDelivery: makeSelectFastOrderDelivery(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRDraftLogistic: (payload) => dispatch(savePRDraftLogistic(payload)),
    getDeliveryLogisticList: (payload) =>
      dispatch(getDeliveryLogisticList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(LogisticProvider);
