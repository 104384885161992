import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { validNumber } from 'utils/helper';
import { isEqual, sortBy } from 'lodash';

import EditOutlined from '@material-ui/icons/EditOutlined';
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom';

import classes from './PromotionMaxProduct.module.scss';
import gridClasses from '../PromotionMaxProductsGrid.module.scss';

class PromotionMaxProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: props.isEditing,
      params: this._getInitParams(props),
      groupProductOptions: this._initGroupProductOptions(props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { groupProductCodeInput, groupProductNameInput, params, isEditing } = this.state;
    if (groupProductCodeInput && groupProductCodeInput !== prevState.groupProductCodeInput) {
      this._loadSuggestedProducts({
        params: {
          code: groupProductCodeInput,
        },
      });
    }
    if (groupProductNameInput && groupProductNameInput !== prevState.groupProductNameInput) {
      this._loadSuggestedProducts({
        params: {
          name: groupProductNameInput,
        },
      });
    }
    if (isEditing !== prevState.isEditing && this._isRowValid(params)) {
      this.props.savePromotionMaxProduct({
        ...params,
        idStr: this.props.idStr,
        id: this.props.id,
        isEditing,
        disabled: this._isInPromotionProducts() ? false : true,
      });
    }
    const { promotionProducts } = this.props;
    if (!isEqual(sortBy(promotionProducts), sortBy(prevProps.promotionProducts)) && !this.state.isEditing) {
      this.props.savePromotionMaxProduct({
        ...params,
        disabled: this._isInPromotionProducts() ? false : true,
      });
    }
  }
  _initGroupProductOptions = (params) => {
    return this._filteredOptions(this._getPromotionProducts());
  }
  _loadSuggestedProducts = ({ params }) => {
    this.setState({
      groupProductOptions: this._filteredOptions(this._getPromotionProducts())
    });
  }
  _getPromotionProducts = () => {
    return this.props.promotionProducts.filter(item =>
      (!item.focus || item.focus !== 1) && !!item.getcare_product
    ).map(item => ({...item.getcare_product}));
  }
  _isInPromotionProducts = () => {
    if (!this.state.params.getcare_product) return true;
    return this._getPromotionProducts().some(item =>
      item.product_vendor_code && item.product_vendor_code === this.state.params.getcare_product.product_vendor_code
    );
  }
  _filteredOptions = (fullArr) => {
    return fullArr.filter(p =>
      (p?.product_vendor_code === this.props.getcare_product?.product_vendor_code) ||
      !this.props.currentList.some(
        (item) => item.getcare_product?.product_vendor_code === p.product_vendor_code
      )
    );
  };
  _getProductOptionLabel = (option) => {
    return `${option.code || option.product_vendor_code} - ${option.product_vendor_name}`
  }
  _getInitParams = (params) => {
    return {
      idStr: params?.idStr,
      id: params?.id,
      getcare_product: params.getcare_product ? {...params.getcare_product} : null,
      quantity_number: params.quantity_number,
    }
  }
  _resetState = () => {
    this.setState({
      isEditing: false,
      params: this._getInitParams(this.props),
      groupProductOptions: this._initGroupProductOptions(this.props),
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _resetFormAfterChangeTypeId = (typeIdValue) => {
    this.setState({
      isEditing: true,
      params: this._getInitParams({
        ...this.state.params,
        getcare_product: null,
        quantity_number: null,
      }),
      groupProductOptions: [],
      groupProductCodeInput: '',
      groupProductNameInput: '',
    });
  }
  _hasProduct = (params) => {
    return !!params.getcare_product && !!params.getcare_product.product_vendor_code;
  }
  _hasQuantityNumber = (params) => {
    return !!params.quantity_number && Number(params.quantity_number) > 0;
  }
  _isRowValid = (params) => {
    return this._hasProduct(params) && this._hasQuantityNumber(params);
  };
  stopPropagation = (e) => {
    e.stopPropagation();
  }
  handleSelectedProductChange = (e, newValue) => {
    this.handleSaveField({target: { name: 'getcare_product' }}, newValue);
  }
  handleSaveField = (e, value) => {
    this.setState({
      params: {
        ...this.state.params,
        [e.target.name]: (e.target.type === 'number')  ? validNumber(value) : value,
      },
    });
  }
  handleRowClick = () => {
    if (!this.state.isEditing) {
      this.handleEdit();
    }
  }
  handleEdit = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: true,
    });
  }
  handleCancel = (e) => {
    if (e) e.stopPropagation();
    if (this._isRowValid(this.props)) {
      // if it was saved to DB or it was used to save on client successful
      this._resetState();
      return;
    }
    this.props.removePromotionMaxProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleRemove = (e) => {
    if (e) e.stopPropagation();
    this.props.removePromotionMaxProduct({
      id: this.props.id,
      idStr: this.props.idStr,
    });
  }
  handleSave = (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isEditing: false,
    });
  }

  render() {
    const {
      getcare_product,
      quantity_number,
      disabled,
    } = this.props;
    const {
      isEditing,
      params,
      groupProductOptions,
    } = this.state;

    return (
      <>
        <div
          className={`${classes.Item} ${gridClasses.Row} ${disabled ? classes.Disabled : ''}`}
          onClick={this.handleRowClick}
        >
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                name="getcare_product"
                handleHomeEndKeys={false}
                value={params.getcare_product}
                onChange={this.handleSelectedProductChange}
                options={groupProductOptions || []}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => (option && option.product_vendor_code) || ''}
                getOptionSelected={(option, value) =>
                  value && value.product_vendor_code && option ? option.product_vendor_code === value.product_vendor_code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupProductCodeInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_product?.product_vendor_code
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <Autocomplete
                openOnFocus
                selectOnFocus
                disableClearable
                fullWidth
                size="small"
                name="getcare_product"
                handleHomeEndKeys={false}
                value={params.getcare_product}
                onChange={this.handleSelectedProductChange}
                options={groupProductOptions || []}
                renderOption={(option) => this._getProductOptionLabel(option)}
                getOptionLabel={(option) => ((option && option.product_vendor_name) || '')}
                getOptionSelected={(option, value) =>
                  value && value.product_vendor_code && option ? option.product_vendor_code === value.product_vendor_code : null
                }
                onInputChange={(e, newInputValue) => {
                  this.setState({
                    groupProductNameInput: newInputValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="- Chọn -" />
                )}
              />
            ) : (
              getcare_product?.product_vendor_name
            )}
          </div>
          <div className={`${gridClasses.Col}`}>
            {isEditing ? (
              <TextField
                className={classes.Field}
                value={params.quantity_number || ''}
                placeholder="Nhập..."
                name="quantity_number"
                autoComplete="off"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                onChange={(e) => {
                  this.handleSaveField(e, e.target.floatValue);
                }}
                error={!this._hasQuantityNumber(params)}
              />
            ) : quantity_number
            }
          </div>

          <div className={`${gridClasses.Col} ${classes.ActionsCol}`}>
            {isEditing ? (
              <>
                <IconButton
                  onClick={this.handleCancel}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CancelOutlined fontSize="small" color="secondary" />
                </IconButton>
                <IconButton
                  onClick={this.handleSave}
                  disabled={!this._isRowValid(params)}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <CheckCircleOutlineOutlined
                    fontSize="small"
                    color={this._isRowValid(params) ? `primary` : `inherit`}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={this.handleRemove}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <DeleteOutline fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={this.handleEdit}
                  style={{ padding: '0' }}
                  size="small"
                >
                  <EditOutlined fontSize="small" color="primary" />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

PromotionMaxProduct.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentList: PropTypes.array,
  savePromotionMaxProduct: PropTypes.func,
  removePromotionMaxProduct: PropTypes.func,
};

PromotionMaxProduct.defaultProps = {
};

export default PromotionMaxProduct;

