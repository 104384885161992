import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Panel from 'components/Panel/Panel';
import CustomerInfo from './CustomerInfo/CustomerInfo';

import classes from './CustomerDetails.module.scss';

class CustomerDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...props
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerDetails?.id !== prevProps.customerDetails?.id) {
      this.setState({
        ...this.props
      })
    }
  }

  render() {
    const { customerDetails, ...others } = this.state;
    return (
      <div className={classes.StoreDetails}>
        <Panel
          title="Thông tin chung"
          panelClassName={classes.Panel}
          contentClassName={classes.PanelContent}
          content={
            <CustomerInfo {...customerDetails} {...others} />
          }
          isBorder
        />
      </div>
    )
  }
}

CustomerDetails.propTypes = {
  customerDetails: PropTypes.object,
};

CustomerDetails.defaultProps = {
  customerDetails: null,
};

export default CustomerDetails;
