export const CHECK_AUTH = 'getcare/sso/CHECK_AUTH';
export const SIGN_OUT = 'getcare/sso/SIGN_OUT';

// mutation
export const SAVE_AUTH_LOADING = 'getcare/sso/SAVE_AUTH_LOADING';
export const SAVE_AUTH_ERROR = 'getcare/sso/SAVE_AUTH_ERROR';
export const SAVE_AUTH = 'getcare/sso/SAVE_AUTH';
export const SAVE_AUTH_REDIRECT_URL = 'getcare/sso/SAVE_AUTH_REDIRECT_URL';
export const SAVE_AUTH_FIELDS_MAP = 'getcare/sso/SAVE_AUTH_FIELDS_MAP';
export const REMOVE_AUTH = 'getcare/sso/REMOVE_AUTH';
