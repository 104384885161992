import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { getPromotionList } from 'redux/actions/tmk/promotionActions';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectPromotionLoading,
  makeSelectPromotionList,
  makeSelectPromotionListTotal,
} from 'redux/selectors';
import {
  listParamsMap,
  listDisplayFields,
  STATUS_EMPTY,
} from 'utils/constanst/tmkPromotionConstants';
import { getSortsString, getDisplayFields } from 'utils/helper';
import { withRouter } from 'react-router-dom';

import PromotionList from 'components/tmk/PromotionList/PromotionList';
import PromotionListFilters from 'components/tmk/PromotionList/PromotionListFilters/PromotionListFilters';
import ListPagination from 'components/ListPagination/ListPagination';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

import classes from './Promotions.module.scss';

class Promotions extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listParams: { ...listParamsMap },
    };
  }
  componentDidMount() {
    const { listParams } = this.state;
    this.props.getPromotionList({
      params: {
        ...listParams,
        active: listParams.active ? listParams.active.filter(status => status !== STATUS_EMPTY) : undefined,
        is_empty: listParams.active && listParams.active.includes(STATUS_EMPTY) ? true : undefined,
      }
    });
  }
  handleSortChange = ({ sortBy, sortDir }) => {
    const newSortString = getSortsString({
      [sortBy]: sortDir,
    });
    this.handleFilterChange([
      {
        name: 'order',
        value: newSortString,
      },
    ]);
  };
  handleFilterChange = (filters, forceResetPage: false) => {
    const paramsFromFilters = filters.reduce((memo, item) => {
      memo[item.name] = item.value;
      return memo;
    }, {});
    const newParams = forceResetPage ? {
      ...this.state.listParams,
      ...paramsFromFilters,
      page: 1,
    } : {
      ...this.state.listParams,
      ...paramsFromFilters,
    };
    this.setState({
      listParams: newParams,
    });
    this.props.getPromotionList({
      params: {
        ...newParams,
        active: newParams.active ? newParams.active.filter(status => status !== STATUS_EMPTY) : undefined,
        is_empty: newParams.active && newParams.active.includes(STATUS_EMPTY) ? true : undefined,
      },
    });
  };
  createNewPromotion = () => {
    this.props.history.push(`/promotion/new`);
  }

  render() {
    const { promotionList, promotionListTotal, loading } = this.props;
    const { listParams } = this.state;
    const isListLoading = loading;

    return (
      <React.Fragment>
        <div className={classes.PageWrap}>
          <div className={classes.PageHeader}>
            <h1 className={classes.PageTitle}>Danh sách chương trình khuyến mãi</h1>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={this.createNewPromotion}
            >Tạo mới</Button>
          </div>
          <div className={classes.PageMain}>
            <PromotionListFilters
              onFilterChange={debounce(this.handleFilterChange, 500)}
            />
            <PromotionList
              displayFields={getDisplayFields(
                listParams,
                listDisplayFields
              )}
              promotionList={promotionList}
              isLoading={isListLoading}
              onSortChange={this.handleSortChange}
            />
          </div>
          <div className={classes.PageFooter}>
            <ListPagination
              page={listParams.page}
              pageSize={listParams.page_size}
              total={promotionListTotal}
              listName="chương trình"
              onFilterChange={this.handleFilterChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  promotionList: makeSelectPromotionList(),
  promotionListTotal: makeSelectPromotionListTotal(),
  loading: makeSelectPromotionLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: (payload) => dispatch(getPromotionList(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withRouter)(Promotions);
