import { ACTIVE } from 'utils/constanst/common';

export const PRIORITY_HIGH = 1;
export const PRIORITY_MEDIUM = 2;
export const PRIORITY_LOW = 3;
export const priorityMap = {
  [PRIORITY_HIGH]: 'Cao',
  [PRIORITY_MEDIUM]: 'Trung bình',
  [PRIORITY_LOW]: 'Thấp',
};

export const POLICY_PRICE_GROUP_TYPE = 2;
export const POLICY_PRICE_PRODUCT_TYPE = 1;
export const groupTypes = [
  {
    id: POLICY_PRICE_GROUP_TYPE,
    name: 'Nhóm',
  },
  {
    id: POLICY_PRICE_PRODUCT_TYPE,
    name: 'Sản phẩm',
  }
]

export const PLUS_OPERATION = 1;
export const MINUS_OPERATION = -1;
export const operationsMap = {
  [PLUS_OPERATION]: '\u254B',
  [MINUS_OPERATION]: '\u2500',
}
export const operations = [
  {
    id: 1,
    value: PLUS_OPERATION,
    name: operationsMap[PLUS_OPERATION],
  },
  {
    id: 2,
    value: MINUS_OPERATION,
    name: operationsMap[MINUS_OPERATION],
  },
];

export const ACTIVITY_IN_COMING = -1;
export const ACTIVITY_IN_PROGRESS = 1;
export const ACTIVITY_STOP = 0;
export const activityMap = {
  [ACTIVITY_IN_COMING]: 'Chưa áp dụng',
  [ACTIVITY_IN_PROGRESS]: 'Đang áp dụng',
  [ACTIVITY_STOP]: 'Ngừng hoạt động',
};

export const APPLY_IN_COMING = -1;
export const APPLY_IN_PROGRESS = 1;
export const APPLY_UNDEFINED = 0;
export const applyStatusMap = {
  [APPLY_IN_COMING]: 'Chưa áp dụng',
  [APPLY_IN_PROGRESS]: 'Đang áp dụng',
  [APPLY_UNDEFINED]: '',
}

export const POLICY_PRICE_VND_UNIT = 1;
export const POLICY_PRICE_PERCENT_UNIT = 2;
export const POLICY_PRICE_PERCENT_UNIT_NAME = '%';

export const listDisplayFields = [
  {
    name: 'code',
    label: 'Mã chính sách',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'description',
    label: 'Chính sách giá',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'is_retail',
    label: 'Loại chính sách',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'count_customer',
    label: 'Số khách hàng\náp dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'count_product',
    label: 'Số lượng sản phẩm\náp dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'end_date',
    label: 'Ngày kết thúc',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'apply',
    label: 'Áp dụng',
    sortable: false,
    sortDir: '',
  },
  {
    name: 'active',
    label: 'Trạng thái',
    sortable: false,
    sortDir: '',
  },
];
export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  active: ACTIVE,
  code: '',
  description: '',
  start_date: '',
  end_date: '',
};
export const filterFields = [
  {
    name: 'active',
    label: 'Trạng thái',
    value: ACTIVE,
    type: 'custom',
  },
  {
    name: 'code',
    label: 'Mã chính sách',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'description',
    label: 'Chính sách giá',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'start_date',
    label: 'Ngày bắt đầu',
    value: '',
    type: 'date',
    placeholder: 'Nhập hoặc chọn...',
  },
  {
    name: 'end_date_group',
    label: 'Ngày kết thúc',
    type: 'custom',
    value: ['', ''],
  },
];

// price products
export const policyPriceProductsParamsMap = {
  order: '',
};
export const baseInfoDisplayFields = [
  {
    name: 'code',
    label: 'Mã hàng',
  },
  {
    name: 'product_vendor_name',
    label: 'Sản phẩm',
  },
  {
    name: 'vendor_name',
    label: 'Nhà cung cấp',
  },
  {
    name: 'uom_base_name',
    label: 'Đơn vị tính',
  },
];
export const actionDisplayFields = [
  {
    name: 'operation',
    label: '\u254B \u2500',
    extraClassName: 'OperationCol',
  },
  {
    name: 'amount',
    label: 'Số tiền / Tỉ lệ',
  },
];
export const baseDisplayFields = baseInfoDisplayFields.concat([
  {
    name: 'price_sales',
    label: 'Giá bán base',
  },
]).concat(actionDisplayFields);
export const retailDisplayFields = baseInfoDisplayFields.concat([
  {
    name: 'price_sales_retail',
    label: 'Giá bán lẻ',
  },
]).concat(actionDisplayFields);

// price customers
export const POLICY_PRICE_CUSTOMER_TYPE = 1;
export const POLICY_PRICE_GROUP_CUSTOMER_TYPE = 2;
export const customerGroupTypes = [
  {
    id: POLICY_PRICE_CUSTOMER_TYPE,
    name: 'Khách hàng',
  },
  {
    id: POLICY_PRICE_GROUP_CUSTOMER_TYPE,
    name: 'Nhóm',
  }
]

export const policyPriceCustomersParamsMap = {
  order: '',
};
export const policyPriceCustomersDisplayFields = [
  {
    name: 'code',
    label: 'Mã khách hàng',
  },
  {
    name: 'name',
    label: 'Tên khách hàng',
  },
];

// price channels
export const policyPriceChannelsParamsMap = {
  order: '',
};
export const policyPriceChannelsDisplayFields = [
  {
    name: 'code',
    label: 'Khu vực bán hàng',
  },
  {
    name: 'count',
    label: 'Số lượng khách hàng',
  },
];
