import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import classes from './GetcarePromotionCustomer.module.scss';
import gridClasses from '../GetcarePromotionCustomersGrid.module.scss';

class GetcarePromotionCustomer extends PureComponent {
  render() {
    const { getcare_erp_group } = this.props;

    return (
      <>
        <div className={`${classes.Item} ${gridClasses.Row}`}>
          <div className={`${gridClasses.Col}`}>
            { getcare_erp_group?.code }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getcare_erp_group?.name }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getcare_erp_group?.description }
          </div>
          <div className={`${gridClasses.Col}`}>
            { getcare_erp_group?.count }
          </div>
        </div>
      </>
    );
  }
}

GetcarePromotionCustomer.propTypes = {
  idStr: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionCustomer.defaultProps = {
};

export default GetcarePromotionCustomer;
