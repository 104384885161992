import {
  SAVE_PROMOTION_TYPES,
  SAVE_PROMOTION_OPERATORS,
  SAVE_PROMOTION_COMPARES,
  SAVE_PROMOTION_PRIORITIES,
  SAVE_PROMOTION_APPLIES,
  SAVE_PROMOTION_DISCOUNTS,
  SAVE_PROMOTION_LIST,
  SAVE_PROMOTION_LIST_TOTAL,
  SAVE_LOADING,
  RESET_PROMOTION,
  SAVE_ORI_PROMOTION_DETAILS,
  SAVE_PROMOTION_DETAILS,

  SAVE_ORI_PROMOTION_RULES,
  SAVE_PROMOTION_RULES,
  SAVE_PROMOTION_RULE_ITEM,
  REMOVE_PROMOTION_RULE_ITEMS,

  SAVE_ORI_PROMOTION_PRODUCTS,
  SAVE_PROMOTION_PRODUCTS,
  SAVE_PROMOTION_PRODUCT_ITEM,
  REMOVE_PROMOTION_PRODUCT_ITEMS,

  SAVE_ORI_PROMOTION_MAX_PRODUCTS,
  SAVE_PROMOTION_MAX_PRODUCTS,
  SAVE_PROMOTION_MAX_PRODUCT_ITEM,
  REMOVE_PROMOTION_MAX_PRODUCT_ITEMS,

  SAVE_ORI_PROMOTION_BONUSES,
  SAVE_PROMOTION_BONUSES,
  SAVE_PROMOTION_BONUS_ITEM,
  REMOVE_PROMOTION_BONUS_ITEMS,

  SAVE_ORI_PROMOTION_VENDORS,
  SAVE_PROMOTION_VENDORS,
  SAVE_PROMOTION_VENDOR_ITEM,
  REMOVE_PROMOTION_VENDOR_ITEMS,

  SAVE_ORI_PROMOTION_CUSTOMERS,
  SAVE_PROMOTION_CUSTOMERS,
  SAVE_PROMOTION_CUSTOMER_ITEM,
  REMOVE_PROMOTION_CUSTOMER_ITEMS,

  SAVE_PROMOTION_HISTORY,
} from 'redux/constants/tmk/promotionConstants';

const initialState = {
  promotionTypes: [],
  promotionOperators: [],
  promotionCompares: [],
  promotionPriorites: [],
  promotionApplies: [],
  promotionDiscounts: [],

  promotionList: [],
  promotionListTotal: 0,

  loading: false,

  oriPromotionDetails: null,
  promotionDetails: null,

  oriPromotionRules: [],
  promotionRules: [],

  oriPromotionProducts: [],
  promotionProducts: [],

  oriPromotionMaxProducts: [],
  promotionMaxProducts: [],

  oriPromotionBonuses: [],
  promotionBonuses: [],

  oriPromotionVendors: [],
  promotionVendors: [],

  oriPromotionCustomers: [],
  promotionCustomers: [],

  promotionHistory: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PROMOTION_TYPES: {
      const list = action.promotionTypes;
      return {
        ...state,
        promotionTypes: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_OPERATORS: {
      const list = action.promotionOperators;
      return {
        ...state,
        promotionOperators: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_COMPARES: {
      const list = action.promotionCompares;
      return {
        ...state,
        promotionCompares: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_PRIORITIES: {
      const list = action.promotionPriorites;
      return {
        ...state,
        promotionPriorites: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_APPLIES: {
      const list = action.promotionApplies;
      return {
        ...state,
        promotionApplies: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_DISCOUNTS: {
      const list = action.promotionDiscounts;
      return {
        ...state,
        promotionDiscounts: list ? [...list] : [],
      };
    }

    case SAVE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case SAVE_PROMOTION_LIST: {
      const list = action.promotionList;
      return {
        ...state,
        promotionList: list ? [...list] : [],
      };
    }
    case SAVE_PROMOTION_LIST_TOTAL: {
      return {
        ...state,
        promotionListTotal: action.promotionListTotal,
      };
    }

    case RESET_PROMOTION: {
      return {
        ...state,
        oriPromotionDetails: null,
        promotionDetails: null,
        oriPromotionRules: [],
        promotionRules: [],
        oriPromotionProducts: [],
        promotionProducts: [],
        oriPromotionBonuses: [],
        promotionBonuses: [],
        oriPromotionVendors: [],
        promotionVendors: [],
        oriPromotionCustomers: [],
        promotionCustomers: [],
      };
    }

    case SAVE_ORI_PROMOTION_DETAILS: {
      return {
        ...state,
        oriPromotionDetails: action.oriPromotionDetails,
      };
    }
    case SAVE_PROMOTION_DETAILS: {
      return {
        ...state,
        promotionDetails: action.promotionDetails,
      };
    }

    case SAVE_ORI_PROMOTION_RULES: {
      return {
        ...state,
        oriPromotionRules: [...action.oriPromotionRules],
      };
    }
    case SAVE_PROMOTION_RULES: {
      return {
        ...state,
        promotionRules: [...action.promotionRules],
      };
    }
    case SAVE_PROMOTION_RULE_ITEM: {
      const list = [...state.promotionRules];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionRuleItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionRuleItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionRuleItem };
      } else {
        list.push({ ...action.promotionRuleItem });
      }
      return {
        ...state,
        promotionRules: list,
      };
    }
    case REMOVE_PROMOTION_RULE_ITEMS: {
      const list = state.promotionRules.filter(
        (p) =>
          !action.promotionRuleItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionRules: list,
      };
    }

    case SAVE_ORI_PROMOTION_PRODUCTS: {
      return {
        ...state,
        oriPromotionProducts: [...action.oriPromotionProducts],
      };
    }
    case SAVE_PROMOTION_PRODUCTS: {
      return {
        ...state,
        promotionProducts: [...action.promotionProducts],
      };
    }
    case SAVE_PROMOTION_PRODUCT_ITEM: {
      const list = [...state.promotionProducts];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionProductItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionProductItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionProductItem };
      } else {
        list.push({ ...action.promotionProductItem });
      }
      return {
        ...state,
        promotionProducts: list,
      };
    }
    case REMOVE_PROMOTION_PRODUCT_ITEMS: {
      const list = state.promotionProducts.filter(
        (p) =>
          !action.promotionProductItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionProducts: list,
      };
    }

    case SAVE_ORI_PROMOTION_MAX_PRODUCTS: {
      return {
        ...state,
        oriPromotionMaxProducts: [...action.oriPromotionMaxProducts],
      };
    }
    case SAVE_PROMOTION_MAX_PRODUCTS: {
      return {
        ...state,
        promotionMaxProducts: [...action.promotionMaxProducts],
      };
    }
    case SAVE_PROMOTION_MAX_PRODUCT_ITEM: {
      const list = [...state.promotionMaxProducts];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionMaxProductItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionMaxProductItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionMaxProductItem };
      } else {
        list.push({ ...action.promotionMaxProductItem });
      }
      return {
        ...state,
        promotionMaxProducts: list,
      };
    }
    case REMOVE_PROMOTION_MAX_PRODUCT_ITEMS: {
      const list = state.promotionMaxProducts.filter(
        (p) =>
          !action.promotionMaxProductItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionMaxProducts: list,
      };
    }

    case SAVE_ORI_PROMOTION_BONUSES: {
      return {
        ...state,
        oriPromotionBonuses: [...action.oriPromotionBonuses],
      };
    }
    case SAVE_PROMOTION_BONUSES: {
      return {
        ...state,
        promotionBonuses: [...action.promotionBonuses],
      };
    }
    case SAVE_PROMOTION_BONUS_ITEM: {
      const list = [...state.promotionBonuses];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionBonusItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionBonusItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionBonusItem };
      } else {
        list.push({ ...action.promotionBonusItem });
      }
      return {
        ...state,
        promotionBonuses: list,
      };
    }
    case REMOVE_PROMOTION_BONUS_ITEMS: {
      const list = state.promotionBonuses.filter(
        (p) =>
          !action.promotionBonusItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionBonuses: list,
      };
    }

    case SAVE_ORI_PROMOTION_VENDORS: {
      return {
        ...state,
        oriPromotionVendors: [...action.oriPromotionVendors],
      };
    }
    case SAVE_PROMOTION_VENDORS: {
      return {
        ...state,
        promotionVendors: [...action.promotionVendors],
      };
    }
    case SAVE_PROMOTION_VENDOR_ITEM: {
      const list = [...state.promotionVendors];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionVendorItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionVendorItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionVendorItem };
      } else {
        list.push({ ...action.promotionVendorItem });
      }
      return {
        ...state,
        promotionVendors: list,
      };
    }
    case REMOVE_PROMOTION_VENDOR_ITEMS: {
      const list = state.promotionVendors.filter(
        (p) =>
          !action.promotionVendorItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionVendors: list,
      };
    }

    case SAVE_ORI_PROMOTION_CUSTOMERS: {
      return {
        ...state,
        oriPromotionCustomers: [...action.oriPromotionCustomers],
      };
    }
    case SAVE_PROMOTION_CUSTOMERS: {
      return {
        ...state,
        promotionCustomers: [...action.promotionCustomers],
      };
    }
    case SAVE_PROMOTION_CUSTOMER_ITEM: {
      const list = [...state.promotionCustomers];
      const index = list.findIndex((p) => {
        const comparedField = action.promotionCustomerItem.idStr
          ? 'idStr'
          : 'id';
        return (
          p[comparedField] === action.promotionCustomerItem[comparedField]
        );
      });
      if (index > -1) {
        const tempItem = { ...list[index] };
        list[index] = { ...tempItem, ...action.promotionCustomerItem };
      } else {
        list.push({ ...action.promotionCustomerItem });
      }
      return {
        ...state,
        promotionCustomers: list,
      };
    }
    case REMOVE_PROMOTION_CUSTOMER_ITEMS: {
      const list = state.promotionCustomers.filter(
        (p) =>
          !action.promotionCustomerItems.some((item) => {
            const comparedField = item.idStr ? 'idStr' : 'id';
            return item[comparedField] === p[comparedField];
          })
      );
      return {
        ...state,
        promotionCustomers: list,
      };
    }

    case SAVE_PROMOTION_HISTORY: {
      return {
        ...state,
        promotionHistory: [...action.promotionHistory],
      };
    }

    default:
      return state;
  }
};

export default reducer;
