import axiosAuth from 'utils/axios/axiosAuth';

const salesOrderApi = {
  getAllStatuses: () => {
    return axiosAuth.get(`getcare_order_status`);
  },

  getAll: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_order`, {
      params: queryParams,
    });
  },
  get: (id) => {
    return axiosAuth.get(`getcare_order/${id}`);
  },

  updateSalesUser: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/update_salesman`, params);
  },
  cancelSalesOrder: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/cancel`, params);
  },
  createOrUpdateSalesOrderNote: ({ params }) => {
    return axiosAuth.post(`getcare_order_note`, params);
  },
  deleteSalesOrderNote: ({ id }) => {
    return axiosAuth({
      method: 'DELETE',
      url: `getcare_order_note/${id}`,
    });
  },
  updateCustomerDelivery: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/customer_delivery`, params);
  },
  updateLogisticProvider: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/update_logistic_provider`, params);
  },
  updateDeliveryInfo: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/submit_delivery_info`, params);
  },
  confirmPurchasing: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/confirm`, params);
  },
  confirmDeliverySuccess: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/success_delivery`, params);
  },
  confirmSuccess: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/success`, params);
  },
  exportDeliveryPaper: ({ params }) => {

    return axiosAuth.post(`getcare_order_action/purchase_delivery_export`, params);
  },
  sendShipments: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/send_shipments`, params);
  },
  packShipments: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/packing_shipment`, params);
  },
  udpateStatus: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/update_status`, params);
  },

  returnDelivery: ({ params }) => {
    return axiosAuth.post(`getcare_order_action/return_delivery`, params);
  },
  
  getSalesOrderHistory: ({ params }) => {
    const queryParams = Object.keys(params).reduce((memo, key) => {
      if (!!params[key]) memo[key] = params[key];
      return memo;
    }, {});
    return axiosAuth.get(`getcare_order_history`, {
      params: queryParams,
    });
  },
};

export default salesOrderApi;
