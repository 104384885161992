export const GET_GROUP_LIST = 'getcare/mdm/group/GET_GROUP_LIST';
export const GET_GROUP = 'getcare/mdm/group/GET_GROUP';
export const UPDATE_GROUP = 'getcare/mdm/group/UPDATE_GROUP';

export const UPDATE_GROUP_PRODUCT = 'getcare/mdm/group/UPDATE_GROUP_PRODUCT';
export const DELETE_GROUP_PRODUCTS = 'getcare/mdm/group/DELETE_GROUP_PRODUCTS';
export const EXPORT_GROUP_PRODUCTS = 'getcare/mdm/group/EXPORT_GROUP_PRODUCTS';

export const UPDATE_GROUP_VENDOR = 'getcare/mdm/group/UPDATE_GROUP_VENDOR';
export const DELETE_GROUP_VENDORS = 'getcare/mdm/group/DELETE_GROUP_VENDORS';
export const EXPORT_GROUP_VENDORS = 'getcare/mdm/group/EXPORT_GROUP_VENDORS';

export const GET_GROUP_CUSTOMER_MAPPINGS = 'getcare/mdm/group/GET_GROUP_CUSTOMER_MAPPINGS';
export const UPDATE_GROUP_CUSTOMER_MAPPING = 'getcare/mdm/group/UPDATE_GROUP_CUSTOMER_MAPPING';
export const DELETE_GROUP_CUSTOMER_MAPPINGS = 'getcare/mdm/group/DELETE_GROUP_CUSTOMER_MAPPINGS';
export const GET_GROUP_CUSTOMERS_BY_MAPPINGS = 'getcare/mdm/group/GET_GROUP_CUSTOMERS_BY_MAPPINGS';

export const GET_GROUP_CUSTOMERS = 'getcare/mdm/group/GET_GROUP_CUSTOMERS';
export const UPDATE_GROUP_CUSTOMER = 'getcare/mdm/group/UPDATE_GROUP_CUSTOMER';
export const DELETE_GROUP_CUSTOMERS = 'getcare/mdm/group/DELETE_GROUP_CUSTOMERS';
export const EXPORT_GROUP_CUSTOMERS = 'getcare/mdm/group/EXPORT_GROUP_CUSTOMERS';

export const GET_CUSTOMERS_TYPES = 'getcare/mdm/group/GET_CUSTOMERS_TYPES';

// mutation
export const SAVE_GROUP_LIST = 'getcare/mdm/group/SAVE_GROUP_LIST';
export const SAVE_GROUP_LIST_TOTAL = 'getcare/mdm/group/SAVE_GROUP_LIST_TOTAL';

export const SAVE_LOADING = 'getcare/mdm/group/SAVE_LOADING';

export const SAVE_GROUP = 'getcare/mdm/group/SAVE_GROUP';
export const RESET_GROUP = 'getcare/mdm/group/RESET_GROUP';

export const SAVE_GROUP_PRODUCTS = 'getcare/mdm/group/SAVE_GROUP_PRODUCTS';
export const SAVE_GROUP_PRODUCT = 'getcare/mdm/group/SAVE_GROUP_PRODUCT';
export const REMOVE_GROUP_PRODUCTS = 'getcare/mdm/group/REMOVE_GROUP_PRODUCTS';

export const SAVE_GROUP_VENDORS = 'getcare/mdm/group/SAVE_GROUP_VENDORS';
export const SAVE_GROUP_VENDOR = 'getcare/mdm/group/SAVE_GROUP_VENDOR';
export const REMOVE_GROUP_VENDORS = 'getcare/mdm/group/REMOVE_GROUP_VENDORS';

export const SAVE_GROUP_CUSTOMER_MAPPINGS = 'getcare/mdm/group/SAVE_GROUP_CUSTOMER_MAPPINGS';
export const SAVE_GROUP_CUSTOMER_MAPPING = 'getcare/mdm/group/SAVE_GROUP_CUSTOMER_MAPPING';
export const REMOVE_GROUP_CUSTOMER_MAPPINGS = 'getcare/mdm/group/REMOVE_GROUP_CUSTOMER_MAPPINGS';

export const SAVE_GROUP_CUSTOMERS = 'getcare/mdm/group/SAVE_GROUP_CUSTOMERS';
export const SAVE_GROUP_CUSTOMER = 'getcare/mdm/group/SAVE_GROUP_CUSTOMER';
export const REMOVE_GROUP_CUSTOMERS = 'getcare/mdm/group/REMOVE_GROUP_CUSTOMERS';
export const SAVE_GROUP_CUSTOMERS_TOTAL = 'getcare/mdm/group/SAVE_GROUP_CUSTOMERS_TOTAL';

export const SAVE_CUSTOMERS_TYPES = 'getcare/mdm/group/SAVE_CUSTOMERS_TYPES';
