import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CustomerListItem from './CustomerListItem/CustomerListItem';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './CustomerListGrid.module.scss';
import classes from './CustomerList.module.scss';

class CustomerList extends PureComponent {
  render() {
    const { customerList, isLoading, displayFields } = this.props;

    return <div
      className={`${classes.List} ${isLoading && 'OverlayLoading'}`}
      style={{
        overflowX: this.rowRef ? 'scroll' : '',
      }}
    >
      <div
        className={`${classes.Header}`}
        ref={(el) => (this.rowRef = el)}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          { displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
              onSortChange={this.props.onSortChange}
            />
          )) }
        </div>
      </div>
      <div
        className={`${classes.Body}`}
        style={{width: (this.rowRef?.offsetWidth) || 'auto'}}
      >
        { (customerList && customerList.length)
          ? customerList.map(item => (
            <CustomerListItem
              key={`cust-${item.id}`}
              {...item}
            />))
          : (<p className="NoData">Không có khách hàng nào</p>)
        }
      </div>
    </div>;
  }
}

CustomerList.propTypes = {
  displayFields: PropTypes.array,
  customerList: PropTypes.array,
  isLoading: PropTypes.bool,
  onSortChange: PropTypes.func,
};

CustomerList.defaultProps = {
  displayFields: [],
  customerList: [],
  isLoading: false,
};

export default CustomerList;
