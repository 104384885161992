import React, { PureComponent } from 'react';

import classes from './FastOrderBonus.module.scss';
import gridClasses from '../FastOrderBonusesGrid.module.scss';

class FastOrderBonus extends PureComponent {
  render() {
    const {
      promotion_name,
      promotion_bonus_name,
      promotion_code,
      uom_base_name,
      quantity_number,
    } = this.props;

    return (
      <div className={`${gridClasses.Row} ${classes.Item}`}>
        <div className={`${gridClasses.Col}`}>{`${
          promotion_name ? promotion_name : ''
        } ${promotion_code}`}</div>
        <div className={`${gridClasses.Col}`}>{promotion_bonus_name}</div>
        <div className={`${gridClasses.Col}`}>{uom_base_name}</div>
        <div className={`${gridClasses.Col}`}>{quantity_number}</div>
      </div>
    );
  }
}

export default FastOrderBonus;
