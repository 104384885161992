import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@material-ui/core';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined, ArrowBack, CheckOutlined } from '@material-ui/icons';
import classes from './DetailsPageHeader.module.scss';

class DetailsHeader extends PureComponent {
  render() {
    const {
      title,
      prevItemId,
      nextItemId,
      goToPrevItem,
      goToNextItem,
      onCancel,
      onSave,
      hasChanges,
      saveLabel,
    } = this.props;

    return (
      <div className={classes.Wrap}>
        <div className={classes.NameWrap}>
          {prevItemId && goToPrevItem && (
            <IconButton disabled={!prevItemId} size="small" onClick={goToPrevItem}>
              <ArrowBackIosOutlined fontSize="small" />
            </IconButton>
          )}
          {nextItemId && goToNextItem && (
            <IconButton disabled={!nextItemId} size="small" onClick={goToNextItem}>
              <ArrowForwardIosOutlined fontSize="small" />
            </IconButton>
          )}
          <h1 className={classes.Name}>{title ? title : '...'}</h1>
        </div>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={this.props.backToList}
        >Trở về</Button>
        {onCancel && (
          <Button
            disabled={!hasChanges}
            variant="outlined"
            color="secondary"
            onClick={onCancel}>
            Huỷ thay đổi
          </Button>
        )}
        {onSave && (
          <Button
            variant="contained"
            color="primary"
            disabled={!hasChanges}
            startIcon={<CheckOutlined />}
            onClick={onSave}>
            {saveLabel}
          </Button>
        )}
      </div>
    );
  }
}

DetailsHeader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  prevItemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  nextItemId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  backToList: PropTypes.func,
  goToPrevItem: PropTypes.func,
  goToNextItem: PropTypes.func,
};

DetailsHeader.defaultProps = {
  title: '',
  isLoading: false,
};

export default DetailsHeader;
