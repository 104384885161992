import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';

import { createStructuredSelector } from 'reselect';
import { makeSelectFastOrderSelectedCustomerCode } from 'redux/selectors';
import { savePRProductItem } from 'redux/actions/ecom/fastOrderActions';
import { getPRItemData } from 'utils/constanst/ecomFastOrderConstants';

import productApi from 'utils/api/mdm/productApi';
import { debounce, isEqual, sortBy } from 'lodash';

import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Panel from 'components/Panel/Panel';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import FastOrderSelectedProduct from './FastOrderSelectedProduct/FastOrderSelectedProduct';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import classes from './FastOrderProductsToolbar.module.scss';
import productsGridClasses from './FastOrderProductsToolbarGrid.module.scss';

class FastOrderProductsToolbar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',

      isLoading: true,
      productOptions: [],
      isPopoverOpen: false,
    }
    this.keywordInputRef = React.createRef();
  }
  componentDidMount() {
    this.setState({
      keyword: '',

      isLoading: true,
      productOptions: [],
      isPopoverOpen: false,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    const { isPopoverOpen, keyword, productOptions } = this.state;
    const { selectedCustomerCode, selectedVendors } = this.props;
    if (
      (isPopoverOpen && isPopoverOpen !== prevState.isPopoverOpen && !productOptions.length)
      || (isPopoverOpen && keyword !== prevState.keyword)
      || !isEqual(sortBy(selectedVendors), sortBy(prevProps.selectedVendors))
    ) {
      this.loadProducts({
        params: {
          keyword: keyword,
          getcare_vendor_id: selectedVendors.map(vendor => vendor.id),
          getcare_customer_code: selectedCustomerCode,
          page_size: 20,
          mode: 'HAS_VENDORS',
        },
      });
    }
  }

  loadProducts = ({ params }) => {
    this.source && this.source.cancel();
    this._loadSuggestedProducts({ params });
  }
  _loadSuggestedProducts = debounce(async ({ params }) => {
    this.source = axios.CancelToken.source();
    try {
      this.setState({ isLoading: true });
      const { data: response } = await productApi.getAll({ params, cancelToken: this.source.token });
      this.setState({ isLoading: false });
      if (!response?.result) return;
      this.setState({ productOptions: response.data ? response.data : [] });
    } catch (err) {
    }
  }, 200);

  handleKeywordChange = (e) => {
    this.setState({
      keyword: e.target.value,
    });
  }
  handleKeywordFocus = () => {
    this.handleOpen();
  }
  handleClearKeyword = () => {
    this.setState({
      keyword: '',
      isLoading: true,
      productOptions: [],
    }, () => {
      this.keywordInputRef && this.keywordInputRef.current && this.keywordInputRef.current.focus();
    });
  }
  handleKeywordKeyPress = (e) => {
    const { productOptions } = this.state;
    if (e.key === 'Enter' && productOptions.length === 1) {
      // auto add this product to cart
      const defaultProduct = { ...productOptions[0] };
      const defaultVendor = (defaultProduct?.getcare_vendors && defaultProduct.getcare_vendors[0]) || null;
      const defaultUom = (defaultVendor?.uom && defaultVendor.uom[0]) || null;
      this.handleAddProductToCart({
        product: defaultProduct,
        vendor: defaultVendor ? { ...defaultVendor } : null,
        uom: defaultUom ? { ...defaultUom } : null,
        quantity: 1,
      });
    }
  }
  handleOpen = () => {
    this.setState({
      isPopoverOpen: true,
    });
  }
  handleClose = () => {
    this.setState({
      isPopoverOpen: false,
    }, () => {
      this.keywordInputRef && this.keywordInputRef.current && this.keywordInputRef.current.blur();
    });
  }
  handleAddProductToCart = ({ product, vendor, uom, quantity }) => {
    this.props.savePRProductItem({
      ...getPRItemData({ product, vendor, uom, quantity }),
      rankingKeyword: this.state.keyword || undefined,
    });
    this.handleClose();
  }

  render() {
    const { productOptions, keyword, isPopoverOpen, isLoading } = this.state;

    return (<div className={classes.Toolbar}>
      <ClickAwayListener onClickAway={this.handleClose}>
        <div className={classes.SearchWrap}>
          <TextField
            inputRef={this.keywordInputRef}
            value={keyword}
            autoComplete="off"
            name="keyword"
            variant="outlined"
            size="small"
            className={`${classes.SearchField} ${isPopoverOpen ? classes.Focus : ''}`}
            placeholder="Nhập tên thuốc, hoạt chất bạn muốn tìm"
            onFocus={this.handleKeywordFocus}
            onChange={this.handleKeywordChange}
            onKeyPress={this.handleKeywordKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  { !keyword ? <Search fontSize="small" /> : <IconButton size="small" onClick={this.handleClearKeyword}><ClearIcon fontSize="small" /></IconButton> }
                </InputAdornment>
              ),
            }}
          />
          { isPopoverOpen &&
            <div className={classes.ListWrap}>
              <Panel
                panelClassName={classes.ProductListPanel}
                content={<div className={classes.ProductList}>
                  <div className={`${classes.Body} ${isLoading ? 'OverlayLoading' : ''}`}>
                    <div className={`${productsGridClasses.ProductListRow} ${productsGridClasses.ProductListRowHeader}`}>
                      <ListHeaderCol
                        className={`${productsGridClasses.ProductListCol}`}
                        label="Tên thuốc"
                      />
                      <ListHeaderCol
                        className={`${productsGridClasses.ProductListCol} TextCenter`}
                        label="Giá"
                      />
                      <ListHeaderCol
                        className={`${productsGridClasses.ProductListCol}`}
                        label="Nhãn"
                      />
                      <ListHeaderCol
                        className={`${productsGridClasses.ProductListCol}`}
                        label="Số lượng"
                      />
                      <ListHeaderCol
                        className={`${productsGridClasses.ProductListCol}`}
                        label=""
                      />
                    </div>

                    {productOptions.length ? (
                      productOptions.map((product, index) => (
                        <FastOrderSelectedProduct
                          key={`selected-product-${product.id}`}
                          product={product}
                          onAddProductToCart={this.handleAddProductToCart}
                        />
                      ))
                    ) : (
                      <p className={classes.EmptyLine}>{ isLoading ? '' : `Không tìm thấy sản phẩm` }</p>
                    )}
                  </div>
                </div>}
              />
            </div>
          }
        </div>
      </ClickAwayListener>
    </div>);
  }
}

FastOrderProductsToolbar.propTypes = {
};

FastOrderProductsToolbar.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  selectedCustomerCode: makeSelectFastOrderSelectedCustomerCode(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    savePRProductItem: (payload) => dispatch(savePRProductItem(payload)),
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(FastOrderProductsToolbar);
