import React from 'react';
import PropTypes from 'prop-types';
import { filterFields } from 'utils/constanst/adminProductBonusConstants';

import FilterField from 'components/FilterField/FilterField';

import classes from './ProductBonusFilters.module.scss';

class ProductBonusFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fields: [...filterFields],
    };
  }

  handleFieldChange = ({ name, value }) => {
    const newFields = this.state.fields.map(field => field.name === name ? { ...field, value } : field);
    this.setState({
      fields: newFields,
    });
    this.props.onFilterChange(newFields, true);
  }

  render() {
    const { fields } = this.state;

    return (
      <div className={classes.Panel}>
        <h3 className={classes.PanelTitle}>Lọc theo:</h3>
        <div className={classes.PanelContent}>
          {fields.map((field) => (
            <FilterField
              key={field.name}
              {...field}
              handleFieldChange={this.handleFieldChange}
            />
          ))}
        </div>
      </div>
    );
  }
}

ProductBonusFilters.propTypes = {
  onFilterChange: PropTypes.func,
};

export default ProductBonusFilters;
