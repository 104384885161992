import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPromotionVendors } from 'redux/selectors';
import { getDisplayFields } from 'utils/helper';
import {
  promotionVendorsDisplayFields,
  promotionVendorsParamsMap,
} from 'utils/constanst/tmkPromotionConstants';

import GetcarePromotionVendor from './GetcarePromotionVendor/GetcarePromotionVendor';
import ListHeaderCol from 'components/ListHeaderCol/ListHeaderCol';

import gridClasses from './GetcarePromotionVendorsGrid.module.scss';
import classes from './GetcarePromotionVendors.module.scss';

class GetcarePromotionVendors extends PureComponent {
  render() {
    const { promotionVendors } = this.props;
    const displayFields = getDisplayFields(
      promotionVendorsParamsMap,
      promotionVendorsDisplayFields
    );

    return (
      <div className={`${classes.List}`}>
        <div className={`${gridClasses.Row} ${classes.Header}`}>
          {displayFields.map((item) => (
            <ListHeaderCol
              key={item.name}
              className={gridClasses.Col}
              {...item}
            />
          ))}
        </div>
        <div className={`${classes.Body}`}>
          {promotionVendors.length ? (
            promotionVendors.map((item) => (
              <GetcarePromotionVendor
                key={`promotion-vendor-${item.id || item.idStr}`}
                {...item}
              />
            ))
          ) : (
            <p className="NoData">Không có data</p>
          )}
        </div>
      </div>
    );
  }
}

GetcarePromotionVendors.propTypes = {
  promotionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

GetcarePromotionVendors.defaultProps = {
};

const mapStateToProps = createStructuredSelector({
  promotionVendors: makeSelectPromotionVendors(),
});
const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(GetcarePromotionVendors);
