export const ACTIVE = 1;
export const DEACTIVE = -1;
export const APPROVE = 1;
export const UNAPPROVE = -1;
export const approvalStatusMap = {
  [APPROVE]: 'Đã duyệt',
  [UNAPPROVE]: 'Chưa duyệt',
};
export const activeStatusMap = {
  [ACTIVE]: 'Đang hoạt động',
  [DEACTIVE]: 'Ngừng hoạt động',
};
export const relatedPageSize = 20;
export const listDisplayFields = [
  {
    name: 'getcare_customer_type_name',
    label: 'Loại khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'code',
    label: 'Mã khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'name',
    label: 'Tên khách hàng',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'tax_code',
    label: 'Mã số thuế',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'phone',
    label: 'Số điện thoại',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'registration_number',
    label: 'Số đăng ký',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'type_pharmacy',
    label: 'Loại hình',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'representative_name',
    label: 'Tên người đại diện',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'expertise_person',
    label: 'Người chịu trách nhiệm chuyên môn',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'expertise_number',
    label: 'Số chứng chỉ hành nghề của NCTNCM',
    sortable: true,
    sortDir: '',
  },
  {
    name: 'approval',
    label: 'Trạng thái duyệt',
    sortable: true,
    sortDir: '',
  },
]

export const listParamsMap = {
  page_size: 50,
  page: 1,
  order: '',
  code: '',
  name: '',
  registration_number: '',
  tax_code: '',
  type_pharmacy: '',
  representative_name: '',
  expertise_person: '',
  expertise_number: '',
  phone: '',
  address: '',
  approval: '',
}

export const filterFields = [
  {
    name: 'getcare_customer_type_id',
    label: 'Loại khách hàng',
    value: '',
    type: 'custom',
    placeholder: '- Chọn -',
  },
  {
    name: 'customer_vendor_code',
    label: 'Mã khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'name',
    label: 'Tên khách hàng',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'tax_code',
    label: 'Mã số thuế',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'phone',
    label: 'Điện thoại',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'address',
    label: 'Địa chỉ',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'registration_number',
    label: 'Số đăng ký',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'representative_name',
    label: 'Tên người đại diện',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'type_pharmacy',
    label: 'Loại hình',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'expertise_person',
    label: 'Người chịu trách nhiệm chuyên môn',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'expertise_number',
    label: 'Số chứng chỉ hành nghề của NCTNCM',
    value: '',
    type: 'input',
    placeholder: 'Nhập...',
  },
  {
    name: 'approval',
    label: 'Trạng thái duyệt',
    value: '',
    type: 'custom',
    placeholder: 'Nhập...',
  },
]
