import {
  GET_VENDOR_PRICE_LIST,
  SAVE_LOADING,
  SAVE_VENDOR_PRICE_LIST,
  SAVE_VENDOR_PRICE_LIST_TOTAL,
  SAVE_TEMP_VENDOR_PRICE_ITEM,
  REMOVE_TEMP_VENDOR_PRICE_ITEM,
  SAVE_TEMP_VENDOR_PRICE_LIST,
  UPDATE_VENDOR_PRICE_LIST,
  SAVE_VENDOR_PRICE_ITEM,
} from 'redux/constants/vendor/vendorPriceConstants';

export function getVendorPriceList(payload) {
  return {
    type: GET_VENDOR_PRICE_LIST,
    payload
  };
}
export function updateVendorPriceList(payload) {
  return {
    type: UPDATE_VENDOR_PRICE_LIST,
    payload
  };
}

// mutation
export function saveAcitonLoading(loading) {
  return {
    type: SAVE_LOADING,
    loading
  };
}
export function saveTempVendorPriceItem(tempVendorPriceItem) {
  return {
    type: SAVE_TEMP_VENDOR_PRICE_ITEM,
    tempVendorPriceItem
  };
}
export function removeTempVendorPriceItem(tempVendorPriceItem) {
  return {
    type: REMOVE_TEMP_VENDOR_PRICE_ITEM,
    tempVendorPriceItem
  };
}
export function saveTempVendorPriceList(tempVendorPriceList) {
  return {
    type: SAVE_TEMP_VENDOR_PRICE_LIST,
    tempVendorPriceList
  };
}
export function saveVendorPriceList(vendorPriceList) {
  return {
    type: SAVE_VENDOR_PRICE_LIST,
    vendorPriceList
  };
}
export function saveVendorPriceListTotal(vendorPriceListTotal) {
  return {
    type: SAVE_VENDOR_PRICE_LIST_TOTAL,
    vendorPriceListTotal
  };
}
export function saveVendorPriceItem(vendorPriceItem) {
  return {
    type: SAVE_VENDOR_PRICE_ITEM,
    vendorPriceItem
  };
}
