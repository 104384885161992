import {
  SAVE_AUTH,
  REMOVE_AUTH,
  SAVE_AUTH_LOADING,
  SAVE_AUTH_ERROR,
  SAVE_AUTH_FIELDS_MAP,
  SAVE_AUTH_REDIRECT_URL,
} from 'redux/constants/authConstants';
import jwtService from 'utils/services/jwtService';

const initialState = {
  user: null,
  authLoading: false,
  authError: '',
  authRedirectUrl: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTH_ERROR: {
      return {
        ...state,
        authError: action.error,
      }
    }
    case SAVE_AUTH_LOADING: {
      return {
        ...state,
        authLoading: action.loading,
      }
    }
    case SAVE_AUTH: {
      jwtService.saveToken(action.user?.token);
      return {
        ...state,
        user: action.user ? { ...action.user } : null,
      };
    }
    case SAVE_AUTH_REDIRECT_URL: {
      return {
        ...state,
        authRedirectUrl: action.authRedirectUrl ? action.authRedirectUrl : '',
      };
    }
    case SAVE_AUTH_FIELDS_MAP: {
      return {
        ...state,
        user: action.fieldsMap ? { ...state.user, ...action.fieldsMap } : { ...state.user },
      };
    }
    case REMOVE_AUTH: {
      jwtService.destroyToken();
      return {
        ...state,
        user: null,
      };
    }
    default:
      return state;
  }
}

export default reducer;
